<template>
    <div class="omni" v-bind:class="[{'omni--floating': floating, 'omni--multiple':multiple}]">
        <div class="omni__search" v-if="!disabled" ref="omni_search">
            <div id="omni-search-form" class="form form--omni" v-bind:class="[{'form--rounded': rounded}]">
                <b-form-group :label="label" :state="state" :invalid-feedback="invalidFeedback">
                    <b-input-group :size="size" v-if="(!value && floating) || !floating || multiple">
                        <b-form-input
                            v-model="search"
                            type="search"
                            autocomplete="off"
                            :placeholder="placeholder"
                            @focus="(e) => initSearch(e)"
                            @keyup="navigate"
                            @blur="blur"
                            @keyup.enter="(e) => pickItemByIndex(e)" />
                        <template slot="append">
                            <div class="input-group-text">
                                <i class="nav-icon fas fa-search"></i>
                            </div>
                        </template>
                    </b-input-group>
                </b-form-group>
            </div>
        </div>
        <div v-if="searching">
            <ul ref="omni_result" class="omni__result">
                <li class="px-3 text-muted">Searching...</li>
            </ul>
        </div>
        <div v-else-if="options">
            <ul ref="omni_result" v-if="!floating || (value === 0 || value === '' && floating) || multiple" :class="`omni__result ${result_dirrection}`">
                <li v-for="(data, i) in options" :key="i">
                    <a @click.prevent="(e) => pickItem(e, data.id, i, data.label)" :class="{hovered:hovered === i, selected:selected === i || data.id === value}" href="javascript:;">
                        {{ data.label }}
                        <p v-if="data.description">
                            {{ data.description }}
                        </p>
                    </a>
                </li>
            </ul>
            <ul ref="omni_result" v-else-if="!multiple" class="omni__result omni__result--selected">
                <li v-for="(data, i) in options.filter(option => option.id === value)" :key="i">
                    <a @click.prevent="(e) => pickItem(e, data.id, i, data.label)" :class="{hovered:hovered === i, selected:selected === i || data.id === value}" href="javascript:;">
                        {{ data.label }}
                        <p v-if="data.description">
                            {{ data.description }}
                        </p>
                    </a>
                </li>
            </ul>
            <ul v-if="selecteds.length" class="omni__selecteds">
                <li v-for="(item, i) in selecteds" :key="`selected-${i}`">
                    {{ item.label }} <a href="javascript:;" @click.prevent="removeSelectedItem(i)">
                        <i class="nav-icon fas fa-times orange"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    model: {
		event: 'change',
		prop: 'value'
	},
    props: {
        value: {
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default: () => []
        },
        state: {
            type: Boolean,
            default: true
        },
        invalidFeedback: {
            type: String,
            state: ''
        },
        size: {
            type: String,
            default: 'lg'
        },
        rounded: {
            type: Boolean,
            default: true
        },
        floating: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        multiple: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            search: '',
            searching: false,
            results: [],
            hovered: 0,
            selected: null,
            selecteds: [],
            result_dirrection: 'none'
        }
    },
    methods: {
        blur() {
            const vm = this
        },
        initSearch(e) {
            const vm = this

            vm.selected = null
            // vm.$emit('input', '')
            vm.$emit('search', vm.search, 'focus')
        },
        removeSelectedItem(index) {
            const vm = this

            vm.selecteds.splice(index, 1)
            console.log(vm.selecteds.map((item) => item.value))
            vm.$emit('input', vm.selecteds.map((item) => item.value))
            vm.$emit('change', vm.selecteds.map((item) => item.value))
        },
        pickItem(e, value, index, label) {
            const vm = this

            if ( vm.multiple ) {
                vm.selecteds.push({
                    id: index,
                    value: value,
                    label: label
                })
                vm.search = ''
                vm.selected = ''
                const val = vm.selecteds.map((item) => item.value)
                vm.$emit('input', val)
                vm.$emit('change', val)
                
            } else if (vm.value === value && vm.floating) { // Remove
                vm.search = ''
                vm.selected = ''
                vm.$emit('input', '')
                vm.$emit('change', '')
            } else {
                vm.selected = index
                vm.$emit('input', value)
                vm.$emit('change', value)
            }
        },
        pickItemByIndex(e) {
            e.stopPropagation()
            const vm = this
            let value = vm.options[vm.hovered].id

            vm.selected = vm.hovered

            vm.$emit('input', value)
            vm.$emit('change', value)
        },
        navigate(e) {
            const vm = this

            if ( e.key == 'ArrowDown' ) {
                if ( vm.hovered < (vm.options.length - 1)) {
                    vm.hovered += 1
                }
            } else if (e.key == 'ArrowUp') {
                if ( vm.hovered > 0 ) {
                    vm.hovered -= 1
                }
            }
        }
    },
    watch: {
        options(options) {
            const vm = this
            if ( options.length > 0 ) {
                vm.searching = false
                setTimeout(() => {
                    let doc = document.documentElement;
                    let windowHeight = window.innerHeight

                    let resultHeight = vm.$refs.omni_result.getBoundingClientRect().height
                    let fieldOffset = vm.$refs.omni_search.getBoundingClientRect().top
                    let fieldHeight = vm.$refs.omni_search.getBoundingClientRect().height

                    if ( (fieldOffset + fieldHeight + resultHeight) <= windowHeight ) {
                        vm.result_dirrection = 'down'
                    } else {
                        vm.result_dirrection = 'up'
                    }
                }, 10);
            } else {
                vm.searching = false
                vm.result_dirrection = 'none'
            }
        },
        search(search) {
            const vm = this

            if ( search ) {
                vm.$set(vm, 'searching', true)
            } else {
                vm.$set(vm, 'searching', false)
            }
            vm.$emit('search', search)
            if ( !vm.multiple ) {
                vm.selected = ''
                vm.$emit('input', '')
            }
        }
    }
}
</script>