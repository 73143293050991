<template>
    <div class="panel__body pt-4 ml-4">
        <b-tabs content-class="mt-3">
            <b-tab title="Manual Entry" active>
                <BranchForm :preparation="isLoading" :question="question" :question_groups="question_groups" :state="false"></BranchForm>

                <div class="row mb-6">
                    <div class="col-12 mb-2">
                        <button type="submit" v-if="$gate.hasAccess('question.create')" :disabled="isLoading" @click="submit" class="btn btn-secondary btn-lg mr-2">Save</button>
                        <router-link :to="{ name : 'question.data'}" class="btn btn-outline-primary btn-lg">Back</router-link>
                    </div>
                </div>
            </b-tab>
            <b-tab title="Upload">
                <b-form class="form"  enctype="multipart/form-data">
                    <b-row>
                        <b-col>
                            <div class="form-group">
                                <label>File Question</label>
                                <uploader name="upload_file" description="Maks 2MB dengan ekstensi (xls, xlsx)." accept=".xls,.xlsx" v-bind:files.sync="upload_fields.files" id="upload_file" :class="{ 'is-invalid': upload_fields.errors.has('files') }"/>
                                <has-error :form="upload_fields" field="files"></has-error>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class = "mb-6">
                        <b-col class = "mb-2">
                            <b-button v-if="$gate.hasAccess('question.create')" @click.prevent="uploadFiles" class="btn btn-outline-secondary btn-lg mr-2">Upload</b-button>
                            <router-link :to="{ name : 'question.data'}" class="btn btn-outline-primary btn-lg mr-2">Back</router-link>
                        </b-col>
                    </b-row>
                </b-form>
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>

import BranchForm from './Form'

export default {
    data(){
        return {
            isLoading : true,
            question: new Form({
                id:'',
                question: '',
                question_group_id: '',
                question_type: '',
                is_active: '',
                jawaban: '',
                pil_a: '',
                pil_b: '',
                pil_c: '',
                pil_d: '',
                pil_e: '',
                ilustration:''
            }),
            question_groups : [],
            upload_fields: new Form({
                files: ''
            }),
        }
    },
    created() {
        const vm = this
        const getGroupData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/questiongroup/fetch/all`
            }).then((data) => {
                vm.question_groups = data.data.data.items.map((group) => {
                                    return {
                                        id: group.id,
                                        label: group.name
                                    }
                                });
                resolve(data);
            });
        })
        Promise.all([getGroupData]).then(() => {
            vm.isLoading = false
        })
    },
    methods: {
        uploadFiles(){
            const vm = this
            vm.isLoading = true;
            this.upload_fields.submit('post', '/api/question/upload', {
                transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'question.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
            });
        },
        submit() {
            this.isLoading = true;

            this.question.submit('post', '/api/question/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'ilustration'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'question.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                
            });
            this.isLoading = false;
        }
    },
    components: {
        BranchForm
    }
}
</script>