export default class Gate{
    constructor(user){
        this.user = user;
    }

    isloggedIn(){
        return this.user.length > 0 && this.user.user_roles.length > 0 ? true : false;
    }

    hasAccess(code){
        if (this.user.user_roles.accesses.some(data => data.access_code  === code)){
            return true;
        }else{
            return false;
        }
    }

    printuser(){
        console.log(this.user);
    }

    iconlist(){
        return [
            {id:"1", label: "500px",customLabel: "fa-500px"},
            {id:"2", label: "Adjust",customLabel: "fa-adjust"},
            {id:"3", label: "Adn",customLabel: "fa-adn"},
            {id:"4", label: "Align center",customLabel: "fa-align-center"},
            {id:"5", label: "Align justify",customLabel: "fa-align-justify"},
            {id:"6", label: "Align left",customLabel: "fa-align-left"},
            {id:"7", label: "Align right",customLabel: "fa-align-right"},
            {id:"8", label: "Amazon",customLabel: "fa-amazon"},
            {id:"9", label: "Ambulance",customLabel: "fa-ambulance"},
            {id:"10", label: "Anchor",customLabel: "fa-anchor"},
            {id:"11", label: "Android",customLabel: "fa-android"},
            {id:"12", label: "Angellist",customLabel: "fa-angellist"},
            {id:"13", label: "Angle double down",customLabel: "fa-angle-double-down"},
            {id:"14", label: "Angle double left",customLabel: "fa-angle-double-left"},
            {id:"15", label: "Angle double right",customLabel: "fa-angle-double-right"},
            {id:"16", label: "Angle double up",customLabel: "fa-angle-double-up"},
            {id:"17", label: "Angle down",customLabel: "fa-angle-down"},
            {id:"18", label: "Angle left",customLabel: "fa-angle-left"},
            {id:"19", label: "Angle right",customLabel: "fa-angle-right"},
            {id:"20", label: "Angle up",customLabel: "fa-angle-up"},
            {id:"21", label: "Apple",customLabel: "fa-apple"},
            {id:"22", label: "Archive",customLabel: "fa-archive"},
            {id:"23", label: "Area chart",customLabel: "fa-area-chart"},
            {id:"24", label: "Arrow circle down",customLabel: "fa-arrow-circle-down"},
            {id:"25", label: "Arrow circle left",customLabel: "fa-arrow-circle-left"},
            {id:"26", label: "Arrow circle o down",customLabel: "fa-arrow-circle-o-down"},
            {id:"27", label: "Arrow circle o left",customLabel: "fa-arrow-circle-o-left"},
            {id:"28", label: "Arrow circle o right",customLabel: "fa-arrow-circle-o-right"},
            {id:"29", label: "Arrow circle o up",customLabel: "fa-arrow-circle-o-up"},
            {id:"30", label: "Arrow circle right",customLabel: "fa-arrow-circle-right"},
            {id:"31", label: "Arrow circle up",customLabel: "fa-arrow-circle-up"},
            {id:"32", label: "Arrow down",customLabel: "fa-arrow-down"},
            {id:"33", label: "Arrow left",customLabel: "fa-arrow-left"},
            {id:"34", label: "Arrow right",customLabel: "fa-arrow-right"},
            {id:"35", label: "Arrow up",customLabel: "fa-arrow-up"},
            {id:"36", label: "Arrows",customLabel: "fa-arrows"},
            {id:"37", label: "Arrows alt",customLabel: "fa-arrows-alt"},
            {id:"38", label: "Arrows h",customLabel: "fa-arrows-h"},
            {id:"39", label: "Arrows v",customLabel: "fa-arrows-v"},
            {id:"40", label: "Asterisk",customLabel: "fa-asterisk"},
            {id:"41", label: "At",customLabel: "fa-at"},
            {id:"42", label: "Backward",customLabel: "fa-backward"},
            {id:"43", label: "Balance scale",customLabel: "fa-balance-scale"},
            {id:"44", label: "Ban",customLabel: "fa-ban"},
            {id:"45", label: "Bar chart",customLabel: "fa-bar-chart"},
            {id:"46", label: "Barcode",customLabel: "fa-barcode"},
            {id:"47", label: "Bars",customLabel: "fa-bars"},
            {id:"48", label: "Battery empty",customLabel: "fa-battery-empty"},
            {id:"49", label: "Battery full",customLabel: "fa-battery-full"},
            {id:"50", label: "Battery half",customLabel: "fa-battery-half"},
            {id:"51", label: "Battery quarter",customLabel: "fa-battery-quarter"},
            {id:"52", label: "Battery three quarters",customLabel: "fa-battery-three-quarters"},
            {id:"53", label: "Bed",customLabel: "fa-bed"},
            {id:"54", label: "Beer",customLabel: "fa-beer"},
            {id:"55", label: "Behance",customLabel: "fa-behance"},
            {id:"56", label: "Behance square",customLabel: "fa-behance-square"},
            {id:"57", label: "Bell",customLabel: "fa-bell"},
            {id:"58", label: "Bell o",customLabel: "fa-bell-o"},
            {id:"59", label: "Bell slash",customLabel: "fa-bell-slash"},
            {id:"60", label: "Bell slash o",customLabel: "fa-bell-slash-o"},
            {id:"61", label: "Bicycle",customLabel: "fa-bicycle"},
            {id:"62", label: "Binoculars",customLabel: "fa-binoculars"},
            {id:"63", label: "Birthday cake",customLabel: "fa-birthday-cake"},
            {id:"64", label: "Bitbucket",customLabel: "fa-bitbucket"},
            {id:"65", label: "Bitbucket square",customLabel: "fa-bitbucket-square"},
            {id:"66", label: "Black tie",customLabel: "fa-black-tie"},
            {id:"67", label: "Bold",customLabel: "fa-bold"},
            {id:"68", label: "Bolt",customLabel: "fa-bolt"},
            {id:"69", label: "Bomb",customLabel: "fa-bomb"},
            {id:"70", label: "Book",customLabel: "fa-book"},
            {id:"71", label: "Bookmark",customLabel: "fa-bookmark"},
            {id:"72", label: "Bookmark o",customLabel: "fa-bookmark-o"},
            {id:"73", label: "Briefcase",customLabel: "fa-briefcase"},
            {id:"74", label: "Btc",customLabel: "fa-btc"},
            {id:"75", label: "Bug",customLabel: "fa-bug"},
            {id:"76", label: "Building",customLabel: "fa-building"},
            {id:"77", label: "Building o",customLabel: "fa-building-o"},
            {id:"78", label: "Bullhorn",customLabel: "fa-bullhorn"},
            {id:"79", label: "Bullseye",customLabel: "fa-bullseye"},
            {id:"80", label: "Bus",customLabel: "fa-bus"},
            {id:"81", label: "Buysellads",customLabel: "fa-buysellads"},
            {id:"82", label: "Calculator",customLabel: "fa-calculator"},
            {id:"83", label: "Calendar",customLabel: "fa-calendar"},
            {id:"84", label: "Calendar check o",customLabel: "fa-calendar-check-o"},
            {id:"85", label: "Calendar minus o",customLabel: "fa-calendar-minus-o"},
            {id:"86", label: "Calendar o",customLabel: "fa-calendar-o"},
            {id:"87", label: "Calendar plus o",customLabel: "fa-calendar-plus-o"},
            {id:"88", label: "Calendar times o",customLabel: "fa-calendar-times-o"},
            {id:"89", label: "Camera",customLabel: "fa-camera"},
            {id:"90", label: "Camera retro",customLabel: "fa-camera-retro"},
            {id:"91", label: "Car",customLabel: "fa-car"},
            {id:"92", label: "Caret down",customLabel: "fa-caret-down"},
            {id:"93", label: "Caret left",customLabel: "fa-caret-left"},
            {id:"94", label: "Caret right",customLabel: "fa-caret-right"},
            {id:"95", label: "Caret square o down",customLabel: "fa-caret-square-o-down"},
            {id:"96", label: "Caret square o left",customLabel: "fa-caret-square-o-left"},
            {id:"97", label: "Caret square o right",customLabel: "fa-caret-square-o-right"},
            {id:"98", label: "Caret square o up",customLabel: "fa-caret-square-o-up"},
            {id:"99", label: "Caret up",customLabel: "fa-caret-up"},
            {id:"100", label: "Cart arrow down",customLabel: "fa-cart-arrow-down"},
            {id:"101", label: "Cart plus",customLabel: "fa-cart-plus"},
            {id:"102", label: "Cc",customLabel: "fa-cc"},
            {id:"103", label: "Cc amex",customLabel: "fa-cc-amex"},
            {id:"104", label: "Cc diners club",customLabel: "fa-cc-diners-club"},
            {id:"105", label: "Cc discover",customLabel: "fa-cc-discover"},
            {id:"106", label: "Cc jcb",customLabel: "fa-cc-jcb"},
            {id:"107", label: "Cc mastercard",customLabel: "fa-cc-mastercard"},
            {id:"108", label: "Cc paypal",customLabel: "fa-cc-paypal"},
            {id:"109", label: "Cc stripe",customLabel: "fa-cc-stripe"},
            {id:"110", label: "Cc visa",customLabel: "fa-cc-visa"},
            {id:"111", label: "Certificate",customLabel: "fa-certificate"},
            {id:"112", label: "Chain broken",customLabel: "fa-chain-broken"},
            {id:"113", label: "Check",customLabel: "fa-check"},
            {id:"114", label: "Check circle",customLabel: "fa-check-circle"},
            {id:"115", label: "Check circle o",customLabel: "fa-check-circle-o"},
            {id:"116", label: "Check square",customLabel: "fa-check-square"},
            {id:"117", label: "Check square o",customLabel: "fa-check-square-o"},
            {id:"118", label: "Chevron circle down",customLabel: "fa-chevron-circle-down"},
            {id:"119", label: "Chevron circle left",customLabel: "fa-chevron-circle-left"},
            {id:"120", label: "Chevron circle right",customLabel: "fa-chevron-circle-right"},
            {id:"121", label: "Chevron circle up",customLabel: "fa-chevron-circle-up"},
            {id:"122", label: "Chevron down",customLabel: "fa-chevron-down"},
            {id:"123", label: "Chevron left",customLabel: "fa-chevron-left"},
            {id:"124", label: "Chevron right",customLabel: "fa-chevron-right"},
            {id:"125", label: "Chevron up",customLabel: "fa-chevron-up"},
            {id:"126", label: "Child",customLabel: "fa-child"},
            {id:"127", label: "Chrome",customLabel: "fa-chrome"},
            {id:"128", label: "Circle",customLabel: "fa-circle"},
            {id:"129", label: "Circle o",customLabel: "fa-circle-o"},
            {id:"130", label: "Circle o notch",customLabel: "fa-circle-o-notch"},
            {id:"131", label: "Circle thin",customLabel: "fa-circle-thin"},
            {id:"132", label: "Clipboard",customLabel: "fa-clipboard"},
            {id:"133", label: "Clock o",customLabel: "fa-clock-o"},
            {id:"134", label: "Clone",customLabel: "fa-clone"},
            {id:"135", label: "Cloud",customLabel: "fa-cloud"},
            {id:"136", label: "Cloud download",customLabel: "fa-cloud-download"},
            {id:"137", label: "Cloud upload",customLabel: "fa-cloud-upload"},
            {id:"138", label: "Code",customLabel: "fa-code"},
            {id:"139", label: "Code fork",customLabel: "fa-code-fork"},
            {id:"140", label: "Codepen",customLabel: "fa-codepen"},
            {id:"141", label: "Coffee",customLabel: "fa-coffee"},
            {id:"142", label: "Cog",customLabel: "fa-cog"},
            {id:"143", label: "Cogs",customLabel: "fa-cogs"},
            {id:"144", label: "Columns",customLabel: "fa-columns"},
            {id:"145", label: "Comment",customLabel: "fa-comment"},
            {id:"146", label: "Comment o",customLabel: "fa-comment-o"},
            {id:"147", label: "Commenting",customLabel: "fa-commenting"},
            {id:"148", label: "Commenting o",customLabel: "fa-commenting-o"},
            {id:"149", label: "Comments",customLabel: "fa-comments"},
            {id:"150", label: "Comments o",customLabel: "fa-comments-o"},
            {id:"151", label: "Compass",customLabel: "fa-compass"},
            {id:"152", label: "Compress",customLabel: "fa-compress"},
            {id:"153", label: "Connectdevelop",customLabel: "fa-connectdevelop"},
            {id:"154", label: "Contao",customLabel: "fa-contao"},
            {id:"155", label: "Copyright",customLabel: "fa-copyright"},
            {id:"156", label: "Creative commons",customLabel: "fa-creative-commons"},
            {id:"157", label: "Credit card",customLabel: "fa-credit-card"},
            {id:"158", label: "Crop",customLabel: "fa-crop"},
            {id:"159", label: "Crosshairs",customLabel: "fa-crosshairs"},
            {id:"160", label: "Css3",customLabel: "fa-css3"},
            {id:"161", label: "Cube",customLabel: "fa-cube"},
            {id:"162", label: "Cubes",customLabel: "fa-cubes"},
            {id:"163", label: "Cutlery",customLabel: "fa-cutlery"},
            {id:"164", label: "Dashcube",customLabel: "fa-dashcube"},
            {id:"165", label: "Database",customLabel: "fa-database"},
            {id:"166", label: "Delicious",customLabel: "fa-delicious"},
            {id:"167", label: "Desktop",customLabel: "fa-desktop"},
            {id:"168", label: "Deviantart",customLabel: "fa-deviantart"},
            {id:"169", label: "Diamond",customLabel: "fa-diamond"},
            {id:"170", label: "Digg",customLabel: "fa-digg"},
            {id:"171", label: "Dot circle o",customLabel: "fa-dot-circle-o"},
            {id:"172", label: "Download",customLabel: "fa-download"},
            {id:"173", label: "Dribbble",customLabel: "fa-dribbble"},
            {id:"174", label: "Dropbox",customLabel: "fa-dropbox"},
            {id:"175", label: "Drupal",customLabel: "fa-drupal"},
            {id:"176", label: "Eject",customLabel: "fa-eject"},
            {id:"177", label: "Ellipsis h",customLabel: "fa-ellipsis-h"},
            {id:"178", label: "Ellipsis v",customLabel: "fa-ellipsis-v"},
            {id:"179", label: "Empire",customLabel: "fa-empire"},
            {id:"180", label: "Envelope",customLabel: "fa-envelope"},
            {id:"181", label: "Envelope o",customLabel: "fa-envelope-o"},
            {id:"182", label: "Envelope square",customLabel: "fa-envelope-square"},
            {id:"183", label: "Eraser",customLabel: "fa-eraser"},
            {id:"184", label: "Eur",customLabel: "fa-eur"},
            {id:"185", label: "Exchange",customLabel: "fa-exchange"},
            {id:"186", label: "Exclamation",customLabel: "fa-exclamation"},
            {id:"187", label: "Exclamation circle",customLabel: "fa-exclamation-circle"},
            {id:"188", label: "Exclamation triangle",customLabel: "fa-exclamation-triangle"},
            {id:"189", label: "Expand",customLabel: "fa-expand"},
            {id:"190", label: "Expeditedssl",customLabel: "fa-expeditedssl"},
            {id:"191", label: "External link",customLabel: "fa-external-link"},
            {id:"192", label: "External link square",customLabel: "fa-external-link-square"},
            {id:"193", label: "Eye",customLabel: "fa-eye"},
            {id:"194", label: "Eye slash",customLabel: "fa-eye-slash"},
            {id:"195", label: "Eyedropper",customLabel: "fa-eyedropper"},
            {id:"196", label: "Facebook",customLabel: "fa-facebook"},
            {id:"197", label: "Facebook official",customLabel: "fa-facebook-official"},
            {id:"198", label: "Facebook square",customLabel: "fa-facebook-square"},
            {id:"199", label: "Fast backward",customLabel: "fa-fast-backward"},
            {id:"200", label: "Fast forward",customLabel: "fa-fast-forward"},
            {id:"201", label: "Fax",customLabel: "fa-fax"},
            {id:"202", label: "Female",customLabel: "fa-female"},
            {id:"203", label: "Fighter jet",customLabel: "fa-fighter-jet"},
            {id:"204", label: "File",customLabel: "fa-file"},
            {id:"205", label: "File archive o",customLabel: "fa-file-archive-o"},
            {id:"206", label: "File audio o",customLabel: "fa-file-audio-o"},
            {id:"207", label: "File code o",customLabel: "fa-file-code-o"},
            {id:"208", label: "File excel o",customLabel: "fa-file-excel-o"},
            {id:"209", label: "File image o",customLabel: "fa-file-image-o"},
            {id:"210", label: "File o",customLabel: "fa-file-o"},
            {id:"211", label: "File pdf o",customLabel: "fa-file-pdf-o"},
            {id:"212", label: "File powerpoint o",customLabel: "fa-file-powerpoint-o"},
            {id:"213", label: "File text",customLabel: "fa-file-text"},
            {id:"214", label: "File text o",customLabel: "fa-file-text-o"},
            {id:"215", label: "File video o",customLabel: "fa-file-video-o"},
            {id:"216", label: "File word o",customLabel: "fa-file-word-o"},
            {id:"217", label: "Files o",customLabel: "fa-files-o"},
            {id:"218", label: "Film",customLabel: "fa-film"},
            {id:"219", label: "Filter",customLabel: "fa-filter"},
            {id:"220", label: "Fire",customLabel: "fa-fire"},
            {id:"221", label: "Fire extinguisher",customLabel: "fa-fire-extinguisher"},
            {id:"222", label: "Firefox",customLabel: "fa-firefox"},
            {id:"223", label: "Flag",customLabel: "fa-flag"},
            {id:"224", label: "Flag checkered",customLabel: "fa-flag-checkered"},
            {id:"225", label: "Flag o",customLabel: "fa-flag-o"},
            {id:"226", label: "Flask",customLabel: "fa-flask"},
            {id:"227", label: "Flickr",customLabel: "fa-flickr"},
            {id:"228", label: "Floppy o",customLabel: "fa-floppy-o"},
            {id:"229", label: "Folder",customLabel: "fa-folder"},
            {id:"230", label: "Folder o",customLabel: "fa-folder-o"},
            {id:"231", label: "Folder open",customLabel: "fa-folder-open"},
            {id:"232", label: "Folder open o",customLabel: "fa-folder-open-o"},
            {id:"233", label: "Font",customLabel: "fa-font"},
            {id:"234", label: "Fonticons",customLabel: "fa-fonticons"},
            {id:"235", label: "Forumbee",customLabel: "fa-forumbee"},
            {id:"236", label: "Forward",customLabel: "fa-forward"},
            {id:"237", label: "Foursquare",customLabel: "fa-foursquare"},
            {id:"238", label: "Frown o",customLabel: "fa-frown-o"},
            {id:"239", label: "Futbol o",customLabel: "fa-futbol-o"},
            {id:"240", label: "Gamepad",customLabel: "fa-gamepad"},
            {id:"241", label: "Gavel",customLabel: "fa-gavel"},
            {id:"242", label: "Gbp",customLabel: "fa-gbp"},
            {id:"243", label: "Genderless",customLabel: "fa-genderless"},
            {id:"244", label: "Get pocket",customLabel: "fa-get-pocket"},
            {id:"245", label: "Gg",customLabel: "fa-gg"},
            {id:"246", label: "Gg circle",customLabel: "fa-gg-circle"},
            {id:"247", label: "Gift",customLabel: "fa-gift"},
            {id:"248", label: "Git",customLabel: "fa-git"},
            {id:"249", label: "Git square",customLabel: "fa-git-square"},
            {id:"250", label: "Github",customLabel: "fa-github"},
            {id:"251", label: "Github alt",customLabel: "fa-github-alt"},
            {id:"252", label: "Github square",customLabel: "fa-github-square"},
            {id:"253", label: "Glass",customLabel: "fa-glass"},
            {id:"254", label: "Globe",customLabel: "fa-globe"},
            {id:"255", label: "Google",customLabel: "fa-google"},
            {id:"256", label: "Google plus",customLabel: "fa-google-plus"},
            {id:"257", label: "Google plus square",customLabel: "fa-google-plus-square"},
            {id:"258", label: "Google wallet",customLabel: "fa-google-wallet"},
            {id:"259", label: "Graduation cap",customLabel: "fa-graduation-cap"},
            {id:"260", label: "Gratipay",customLabel: "fa-gratipay"},
            {id:"261", label: "H square",customLabel: "fa-h-square"},
            {id:"262", label: "Hacker news",customLabel: "fa-hacker-news"},
            {id:"263", label: "Hand lizard o",customLabel: "fa-hand-lizard-o"},
            {id:"264", label: "Hand o down",customLabel: "fa-hand-o-down"},
            {id:"265", label: "Hand o left",customLabel: "fa-hand-o-left"},
            {id:"266", label: "Hand o right",customLabel: "fa-hand-o-right"},
            {id:"267", label: "Hand o up",customLabel: "fa-hand-o-up"},
            {id:"268", label: "Hand paper o",customLabel: "fa-hand-paper-o"},
            {id:"269", label: "Hand peace o",customLabel: "fa-hand-peace-o"},
            {id:"270", label: "Hand pointer o",customLabel: "fa-hand-pointer-o"},
            {id:"271", label: "Hand rock o",customLabel: "fa-hand-rock-o"},
            {id:"272", label: "Hand scissors o",customLabel: "fa-hand-scissors-o"},
            {id:"273", label: "Hand spock o",customLabel: "fa-hand-spock-o"},
            {id:"274", label: "Hdd o",customLabel: "fa-hdd-o"},
            {id:"275", label: "Header",customLabel: "fa-header"},
            {id:"276", label: "Headphones",customLabel: "fa-headphones"},
            {id:"277", label: "Heart",customLabel: "fa-heart"},
            {id:"278", label: "Heart o",customLabel: "fa-heart-o"},
            {id:"279", label: "Heartbeat",customLabel: "fa-heartbeat"},
            {id:"280", label: "History",customLabel: "fa-history"},
            {id:"281", label: "Home",customLabel: "fa-home"},
            {id:"282", label: "Hospital o",customLabel: "fa-hospital-o"},
            {id:"283", label: "Hourglass",customLabel: "fa-hourglass"},
            {id:"284", label: "Hourglass end",customLabel: "fa-hourglass-end"},
            {id:"285", label: "Hourglass half",customLabel: "fa-hourglass-half"},
            {id:"286", label: "Hourglass o",customLabel: "fa-hourglass-o"},
            {id:"287", label: "Hourglass start",customLabel: "fa-hourglass-start"},
            {id:"288", label: "Houzz",customLabel: "fa-houzz"},
            {id:"289", label: "Html5",customLabel: "fa-html5"},
            {id:"290", label: "I cursor",customLabel: "fa-i-cursor"},
            {id:"291", label: "Ils",customLabel: "fa-ils"},
            {id:"292", label: "Inbox",customLabel: "fa-inbox"},
            {id:"293", label: "Indent",customLabel: "fa-indent"},
            {id:"294", label: "Industry",customLabel: "fa-industry"},
            {id:"295", label: "Info",customLabel: "fa-info"},
            {id:"296", label: "Info circle",customLabel: "fa-info-circle"},
            {id:"297", label: "Inr",customLabel: "fa-inr"},
            {id:"298", label: "Instagram",customLabel: "fa-instagram"},
            {id:"299", label: "Internet explorer",customLabel: "fa-internet-explorer"},
            {id:"300", label: "Ioxhost",customLabel: "fa-ioxhost"},
            {id:"301", label: "Italic",customLabel: "fa-italic"},
            {id:"302", label: "Joomla",customLabel: "fa-joomla"},
            {id:"303", label: "Jpy",customLabel: "fa-jpy"},
            {id:"304", label: "Jsfiddle",customLabel: "fa-jsfiddle"},
            {id:"305", label: "Key",customLabel: "fa-key"},
            {id:"306", label: "Keyboard o",customLabel: "fa-keyboard-o"},
            {id:"307", label: "Krw",customLabel: "fa-krw"},
            {id:"308", label: "Language",customLabel: "fa-language"},
            {id:"309", label: "Laptop",customLabel: "fa-laptop"},
            {id:"310", label: "Lastfm",customLabel: "fa-lastfm"},
            {id:"311", label: "Lastfm square",customLabel: "fa-lastfm-square"},
            {id:"312", label: "Leaf",customLabel: "fa-leaf"},
            {id:"313", label: "Leanpub",customLabel: "fa-leanpub"},
            {id:"314", label: "Lemon o",customLabel: "fa-lemon-o"},
            {id:"315", label: "Level down",customLabel: "fa-level-down"},
            {id:"316", label: "Level up",customLabel: "fa-level-up"},
            {id:"317", label: "Life ring",customLabel: "fa-life-ring"},
            {id:"318", label: "Lightbulb o",customLabel: "fa-lightbulb-o"},
            {id:"319", label: "Line chart",customLabel: "fa-line-chart"},
            {id:"320", label: "Link",customLabel: "fa-link"},
            {id:"321", label: "Linkedin",customLabel: "fa-linkedin"},
            {id:"322", label: "Linkedin square",customLabel: "fa-linkedin-square"},
            {id:"323", label: "Linux",customLabel: "fa-linux"},
            {id:"324", label: "List",customLabel: "fa-list"},
            {id:"325", label: "List alt",customLabel: "fa-list-alt"},
            {id:"326", label: "List ol",customLabel: "fa-list-ol"},
            {id:"327", label: "List ul",customLabel: "fa-list-ul"},
            {id:"328", label: "Location arrow",customLabel: "fa-location-arrow"},
            {id:"329", label: "Lock",customLabel: "fa-lock"},
            {id:"330", label: "Long arrow down",customLabel: "fa-long-arrow-down"},
            {id:"331", label: "Long arrow left",customLabel: "fa-long-arrow-left"},
            {id:"332", label: "Long arrow right",customLabel: "fa-long-arrow-right"},
            {id:"333", label: "Long arrow up",customLabel: "fa-long-arrow-up"},
            {id:"334", label: "Magic",customLabel: "fa-magic"},
            {id:"335", label: "Magnet",customLabel: "fa-magnet"},
            {id:"336", label: "Male",customLabel: "fa-male"},
            {id:"337", label: "Map",customLabel: "fa-map"},
            {id:"338", label: "Map marker",customLabel: "fa-map-marker"},
            {id:"339", label: "Map o",customLabel: "fa-map-o"},
            {id:"340", label: "Map pin",customLabel: "fa-map-pin"},
            {id:"341", label: "Map signs",customLabel: "fa-map-signs"},
            {id:"342", label: "Mars",customLabel: "fa-mars"},
            {id:"343", label: "Mars double",customLabel: "fa-mars-double"},
            {id:"344", label: "Mars stroke",customLabel: "fa-mars-stroke"},
            {id:"345", label: "Mars stroke h",customLabel: "fa-mars-stroke-h"},
            {id:"346", label: "Mars stroke v",customLabel: "fa-mars-stroke-v"},
            {id:"347", label: "Maxcdn",customLabel: "fa-maxcdn"},
            {id:"348", label: "Meanpath",customLabel: "fa-meanpath"},
            {id:"349", label: "Medium",customLabel: "fa-medium"},
            {id:"350", label: "Medkit",customLabel: "fa-medkit"},
            {id:"351", label: "Meh o",customLabel: "fa-meh-o"},
            {id:"352", label: "Mercury",customLabel: "fa-mercury"},
            {id:"353", label: "Microphone",customLabel: "fa-microphone"},
            {id:"354", label: "Microphone slash",customLabel: "fa-microphone-slash"},
            {id:"355", label: "Minus",customLabel: "fa-minus"},
            {id:"356", label: "Minus circle",customLabel: "fa-minus-circle"},
            {id:"357", label: "Minus square",customLabel: "fa-minus-square"},
            {id:"358", label: "Minus square o",customLabel: "fa-minus-square-o"},
            {id:"359", label: "Mobile",customLabel: "fa-mobile"},
            {id:"360", label: "Money",customLabel: "fa-money"},
            {id:"361", label: "Moon o",customLabel: "fa-moon-o"},
            {id:"362", label: "Motorcycle",customLabel: "fa-motorcycle"},
            {id:"363", label: "Mouse pointer",customLabel: "fa-mouse-pointer"},
            {id:"364", label: "Music",customLabel: "fa-music"},
            {id:"365", label: "Neuter",customLabel: "fa-neuter"},
            {id:"366", label: "Newspaper o",customLabel: "fa-newspaper-o"},
            {id:"367", label: "Object group",customLabel: "fa-object-group"},
            {id:"368", label: "Object ungroup",customLabel: "fa-object-ungroup"},
            {id:"369", label: "Odnoklassniki",customLabel: "fa-odnoklassniki"},
            {id:"370", label: "Odnoklassniki square",customLabel: "fa-odnoklassniki-square"},
            {id:"371", label: "Opencart",customLabel: "fa-opencart"},
            {id:"372", label: "Openid",customLabel: "fa-openid"},
            {id:"373", label: "Opera",customLabel: "fa-opera"},
            {id:"374", label: "Optin monster",customLabel: "fa-optin-monster"},
            {id:"375", label: "Outdent",customLabel: "fa-outdent"},
            {id:"376", label: "Pagelines",customLabel: "fa-pagelines"},
            {id:"377", label: "Paint brush",customLabel: "fa-paint-brush"},
            {id:"378", label: "Paper plane",customLabel: "fa-paper-plane"},
            {id:"379", label: "Paper plane o",customLabel: "fa-paper-plane-o"},
            {id:"380", label: "Paperclip",customLabel: "fa-paperclip"},
            {id:"381", label: "Paragraph",customLabel: "fa-paragraph"},
            {id:"382", label: "Pause",customLabel: "fa-pause"},
            {id:"383", label: "Paw",customLabel: "fa-paw"},
            {id:"384", label: "Paypal",customLabel: "fa-paypal"},
            {id:"385", label: "Pencil",customLabel: "fa-pencil"},
            {id:"386", label: "Pencil square",customLabel: "fa-pencil-square"},
            {id:"387", label: "Pencil square o",customLabel: "fa-pencil-square-o"},
            {id:"388", label: "Phone",customLabel: "fa-phone"},
            {id:"389", label: "Phone square",customLabel: "fa-phone-square"},
            {id:"390", label: "Picture o",customLabel: "fa-picture-o"},
            {id:"391", label: "Pie chart",customLabel: "fa-pie-chart"},
            {id:"392", label: "Pied piper",customLabel: "fa-pied-piper"},
            {id:"393", label: "Pied piper alt",customLabel: "fa-pied-piper-alt"},
            {id:"394", label: "Pinterest",customLabel: "fa-pinterest"},
            {id:"395", label: "Pinterest p",customLabel: "fa-pinterest-p"},
            {id:"396", label: "Pinterest square",customLabel: "fa-pinterest-square"},
            {id:"397", label: "Plane",customLabel: "fa-plane"},
            {id:"398", label: "Play",customLabel: "fa-play"},
            {id:"399", label: "Play circle",customLabel: "fa-play-circle"},
            {id:"400", label: "Play circle o",customLabel: "fa-play-circle-o"},
            {id:"401", label: "Plug",customLabel: "fa-plug"},
            {id:"402", label: "Plus",customLabel: "fa-plus"},
            {id:"403", label: "Plus circle",customLabel: "fa-plus-circle"},
            {id:"404", label: "Plus square",customLabel: "fa-plus-square"},
            {id:"405", label: "Plus square o",customLabel: "fa-plus-square-o"},
            {id:"406", label: "Power off",customLabel: "fa-power-off"},
            {id:"407", label: "Print",customLabel: "fa-print"},
            {id:"408", label: "Puzzle piece",customLabel: "fa-puzzle-piece"},
            {id:"409", label: "Qq",customLabel: "fa-qq"},
            {id:"410", label: "Qrcode",customLabel: "fa-qrcode"},
            {id:"411", label: "Question",customLabel: "fa-question"},
            {id:"412", label: "Question circle",customLabel: "fa-question-circle"},
            {id:"413", label: "Quote left",customLabel: "fa-quote-left"},
            {id:"414", label: "Quote right",customLabel: "fa-quote-right"},
            {id:"415", label: "Random",customLabel: "fa-random"},
            {id:"416", label: "Rebel",customLabel: "fa-rebel"},
            {id:"417", label: "Recycle",customLabel: "fa-recycle"},
            {id:"418", label: "Reddit",customLabel: "fa-reddit"},
            {id:"419", label: "Reddit square",customLabel: "fa-reddit-square"},
            {id:"420", label: "Refresh",customLabel: "fa-refresh"},
            {id:"421", label: "Registered",customLabel: "fa-registered"},
            {id:"422", label: "Renren",customLabel: "fa-renren"},
            {id:"423", label: "Repeat",customLabel: "fa-repeat"},
            {id:"424", label: "Reply",customLabel: "fa-reply"},
            {id:"425", label: "Reply all",customLabel: "fa-reply-all"},
            {id:"426", label: "Retweet",customLabel: "fa-retweet"},
            {id:"427", label: "Road",customLabel: "fa-road"},
            {id:"428", label: "Rocket",customLabel: "fa-rocket"},
            {id:"429", label: "Rss",customLabel: "fa-rss"},
            {id:"430", label: "Rss square",customLabel: "fa-rss-square"},
            {id:"431", label: "Rub",customLabel: "fa-rub"},
            {id:"432", label: "Safari",customLabel: "fa-safari"},
            {id:"433", label: "Scissors",customLabel: "fa-scissors"},
            {id:"434", label: "Search",customLabel: "fa-search"},
            {id:"435", label: "Search minus",customLabel: "fa-search-minus"},
            {id:"436", label: "Search plus",customLabel: "fa-search-plus"},
            {id:"437", label: "Sellsy",customLabel: "fa-sellsy"},
            {id:"438", label: "Server",customLabel: "fa-server"},
            {id:"439", label: "Share",customLabel: "fa-share"},
            {id:"440", label: "Share alt",customLabel: "fa-share-alt"},
            {id:"441", label: "Share alt square",customLabel: "fa-share-alt-square"},
            {id:"442", label: "Share square",customLabel: "fa-share-square"},
            {id:"443", label: "Share square o",customLabel: "fa-share-square-o"},
            {id:"444", label: "Shield",customLabel: "fa-shield"},
            {id:"445", label: "Ship",customLabel: "fa-ship"},
            {id:"446", label: "Shirtsinbulk",customLabel: "fa-shirtsinbulk"},
            {id:"447", label: "Shopping cart",customLabel: "fa-shopping-cart"},
            {id:"448", label: "Sign in",customLabel: "fa-sign-in"},
            {id:"449", label: "Sign out",customLabel: "fa-sign-out"},
            {id:"450", label: "Signal",customLabel: "fa-signal"},
            {id:"451", label: "Simplybuilt",customLabel: "fa-simplybuilt"},
            {id:"452", label: "Sitemap",customLabel: "fa-sitemap"},
            {id:"453", label: "Skyatlas",customLabel: "fa-skyatlas"},
            {id:"454", label: "Skype",customLabel: "fa-skype"},
            {id:"455", label: "Slack",customLabel: "fa-slack"},
            {id:"456", label: "Sliders",customLabel: "fa-sliders"},
            {id:"457", label: "Slideshare",customLabel: "fa-slideshare"},
            {id:"458", label: "Smile o",customLabel: "fa-smile-o"},
            {id:"459", label: "Sort",customLabel: "fa-sort"},
            {id:"460", label: "Sort alpha asc",customLabel: "fa-sort-alpha-asc"},
            {id:"461", label: "Sort alpha desc",customLabel: "fa-sort-alpha-desc"},
            {id:"462", label: "Sort amount asc",customLabel: "fa-sort-amount-asc"},
            {id:"463", label: "Sort amount desc",customLabel: "fa-sort-amount-desc"},
            {id:"464", label: "Sort asc",customLabel: "fa-sort-asc"},
            {id:"465", label: "Sort desc",customLabel: "fa-sort-desc"},
            {id:"466", label: "Sort numeric asc",customLabel: "fa-sort-numeric-asc"},
            {id:"467", label: "Sort numeric desc",customLabel: "fa-sort-numeric-desc"},
            {id:"468", label: "Soundcloud",customLabel: "fa-soundcloud"},
            {id:"469", label: "Space shuttle",customLabel: "fa-space-shuttle"},
            {id:"470", label: "Spinner",customLabel: "fa-spinner"},
            {id:"471", label: "Spoon",customLabel: "fa-spoon"},
            {id:"472", label: "Spotify",customLabel: "fa-spotify"},
            {id:"473", label: "Square",customLabel: "fa-square"},
            {id:"474", label: "Square o",customLabel: "fa-square-o"},
            {id:"475", label: "Stack exchange",customLabel: "fa-stack-exchange"},
            {id:"476", label: "Stack overflow",customLabel: "fa-stack-overflow"},
            {id:"477", label: "Star",customLabel: "fa-star"},
            {id:"478", label: "Star half",customLabel: "fa-star-half"},
            {id:"479", label: "Star half o",customLabel: "fa-star-half-o"},
            {id:"480", label: "Star o",customLabel: "fa-star-o"},
            {id:"481", label: "Steam",customLabel: "fa-steam"},
            {id:"482", label: "Steam square",customLabel: "fa-steam-square"},
            {id:"483", label: "Step backward",customLabel: "fa-step-backward"},
            {id:"484", label: "Step forward",customLabel: "fa-step-forward"},
            {id:"485", label: "Stethoscope",customLabel: "fa-stethoscope"},
            {id:"486", label: "Sticky note",customLabel: "fa-sticky-note"},
            {id:"487", label: "Sticky note o",customLabel: "fa-sticky-note-o"},
            {id:"488", label: "Stop",customLabel: "fa-stop"},
            {id:"489", label: "Street view",customLabel: "fa-street-view"},
            {id:"490", label: "Strikethrough",customLabel: "fa-strikethrough"},
            {id:"491", label: "Stumbleupon",customLabel: "fa-stumbleupon"},
            {id:"492", label: "Stumbleupon circle",customLabel: "fa-stumbleupon-circle"},
            {id:"493", label: "Subscript",customLabel: "fa-subscript"},
            {id:"494", label: "Subway",customLabel: "fa-subway"},
            {id:"495", label: "Suitcase",customLabel: "fa-suitcase"},
            {id:"496", label: "Sun o",customLabel: "fa-sun-o"},
            {id:"497", label: "Superscript",customLabel: "fa-superscript"},
            {id:"498", label: "Table",customLabel: "fa-table"},
            {id:"499", label: "Tablet",customLabel: "fa-tablet"},
            {id:"500", label: "Tachometer",customLabel: "fa-tachometer"},
            {id:"501", label: "Tag",customLabel: "fa-tag"},
            {id:"502", label: "Tags",customLabel: "fa-tags"},
            {id:"503", label: "Tasks",customLabel: "fa-tasks"},
            {id:"504", label: "Taxi",customLabel: "fa-taxi"},
            {id:"505", label: "Television",customLabel: "fa-television"},
            {id:"506", label: "Tencent weibo",customLabel: "fa-tencent-weibo"},
            {id:"507", label: "Terminal",customLabel: "fa-terminal"},
            {id:"508", label: "Text height",customLabel: "fa-text-height"},
            {id:"509", label: "Text width",customLabel: "fa-text-width"},
            {id:"510", label: "Th",customLabel: "fa-th"},
            {id:"511", label: "Th large",customLabel: "fa-th-large"},
            {id:"512", label: "Th list",customLabel: "fa-th-list"},
            {id:"513", label: "Thumb tack",customLabel: "fa-thumb-tack"},
            {id:"514", label: "Thumbs down",customLabel: "fa-thumbs-down"},
            {id:"515", label: "Thumbs o down",customLabel: "fa-thumbs-o-down"},
            {id:"516", label: "Thumbs o up",customLabel: "fa-thumbs-o-up"},
            {id:"517", label: "Thumbs up",customLabel: "fa-thumbs-up"},
            {id:"518", label: "Ticket",customLabel: "fa-ticket"},
            {id:"519", label: "Times",customLabel: "fa-times"},
            {id:"520", label: "Times circle",customLabel: "fa-times-circle"},
            {id:"521", label: "Times circle o",customLabel: "fa-times-circle-o"},
            {id:"522", label: "Tint",customLabel: "fa-tint"},
            {id:"523", label: "Toggle off",customLabel: "fa-toggle-off"},
            {id:"524", label: "Toggle on",customLabel: "fa-toggle-on"},
            {id:"525", label: "Trademark",customLabel: "fa-trademark"},
            {id:"526", label: "Train",customLabel: "fa-train"},
            {id:"527", label: "Transgender",customLabel: "fa-transgender"},
            {id:"528", label: "Transgender alt",customLabel: "fa-transgender-alt"},
            {id:"529", label: "Trash",customLabel: "fa-trash"},
            {id:"530", label: "Trash o",customLabel: "fa-trash-o"},
            {id:"531", label: "Tree",customLabel: "fa-tree"},
            {id:"532", label: "Trello",customLabel: "fa-trello"},
            {id:"533", label: "Tripadvisor",customLabel: "fa-tripadvisor"},
            {id:"534", label: "Trophy",customLabel: "fa-trophy"},
            {id:"535", label: "Truck",customLabel: "fa-truck"},
            {id:"536", label: "Try",customLabel: "fa-try"},
            {id:"537", label: "Tty",customLabel: "fa-tty"},
            {id:"538", label: "Tumblr",customLabel: "fa-tumblr"},
            {id:"539", label: "Tumblr square",customLabel: "fa-tumblr-square"},
            {id:"540", label: "Twitch",customLabel: "fa-twitch"},
            {id:"541", label: "Twitter",customLabel: "fa-twitter"},
            {id:"542", label: "Twitter square",customLabel: "fa-twitter-square"},
            {id:"543", label: "Umbrella",customLabel: "fa-umbrella"},
            {id:"544", label: "Underline",customLabel: "fa-underline"},
            {id:"545", label: "Undo",customLabel: "fa-undo"},
            {id:"546", label: "University",customLabel: "fa-university"},
            {id:"547", label: "Unlock",customLabel: "fa-unlock"},
            {id:"548", label: "Unlock alt",customLabel: "fa-unlock-alt"},
            {id:"549", label: "Upload",customLabel: "fa-upload"},
            {id:"550", label: "Usd",customLabel: "fa-usd"},
            {id:"551", label: "User",customLabel: "fa-user"},
            {id:"552", label: "User md",customLabel: "fa-user-md"},
            {id:"553", label: "User plus",customLabel: "fa-user-plus"},
            {id:"554", label: "User secret",customLabel: "fa-user-secret"},
            {id:"555", label: "User times",customLabel: "fa-user-times"},
            {id:"556", label: "Users",customLabel: "fa-users"},
            {id:"557", label: "Venus",customLabel: "fa-venus"},
            {id:"558", label: "Venus double",customLabel: "fa-venus-double"},
            {id:"559", label: "Venus mars",customLabel: "fa-venus-mars"},
            {id:"560", label: "Viacoin",customLabel: "fa-viacoin"},
            {id:"561", label: "Video camera",customLabel: "fa-video-camera"},
            {id:"562", label: "Vimeo",customLabel: "fa-vimeo"},
            {id:"563", label: "Vimeo square",customLabel: "fa-vimeo-square"},
            {id:"564", label: "Vine",customLabel: "fa-vine"},
            {id:"565", label: "Vk",customLabel: "fa-vk"},
            {id:"566", label: "Volume down",customLabel: "fa-volume-down"},
            {id:"567", label: "Volume off",customLabel: "fa-volume-off"},
            {id:"568", label: "Volume up",customLabel: "fa-volume-up"},
            {id:"569", label: "Weibo",customLabel: "fa-weibo"},
            {id:"570", label: "Weixin",customLabel: "fa-weixin"},
            {id:"571", label: "Whatsapp",customLabel: "fa-whatsapp"},
            {id:"572", label: "Wheelchair",customLabel: "fa-wheelchair"},
            {id:"573", label: "Wifi",customLabel: "fa-wifi"},
            {id:"574", label: "Wikipedia w",customLabel: "fa-wikipedia-w"},
            {id:"575", label: "Windows",customLabel: "fa-windows"},
            {id:"576", label: "Wordpress",customLabel: "fa-wordpress"},
            {id:"577", label: "Wrench",customLabel: "fa-wrench"},
            {id:"578", label: "Xing",customLabel: "fa-xing"},
            {id:"579", label: "Xing square",customLabel: "fa-xing-square"},
            {id:"580", label: "Y combinator",customLabel: "fa-y-combinator"},
            {id:"581", label: "Yahoo",customLabel: "fa-yahoo"},
            {id:"582", label: "Yelp",customLabel: "fa-yelp"},
            {id:"583", label: "Youtube",customLabel: "fa-youtube"},
            {id:"584", label: "Youtube play",customLabel: "fa-youtube-play"},
            {id:"585", label: "Youtube square",customLabel: "fa-youtube-square"},
        ];
    }
}

