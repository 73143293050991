<template>
    <div class="panel__body pt-4 ml-4">
        <BranchForm :article="article" :preparation="isLoading"  :multivalue="multivalue" v-on:updateEmployee="setEmployee($event)"></BranchForm>

        <div class="row mb-6">
            <div class="col-12 mb-2">
                <button type="submit" :disabled="isLoading" @click="submit" class="btn btn-secondary btn-lg mr-2">Save</button>
                <router-link :to="{ name : 'article.data'}" class="btn btn-outline-primary btn-lg">Back</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import BranchForm from './Form'
import moment from 'moment'

export default {
    data(){
        return {
            isLoading : false,
            multivalue: [],
            article : new Form({
                id:'',
                subject: '',
                berita: '',
                media: '',
                media_type: 'image',
                tanggal: moment().format('YYYY-MM-DD'),
                type: 'public',
                created_by : window.user.id
            })
        }
    },
    created() {
        this.isLoading = false
    },
    methods: {
        setEmployee(multivalue) {
            console.log(multivalue);
            this.multivalue = multivalue;
        },
        submit() {
            this.isLoading = true;
            this.article.listemployee = this.multivalue;

            this.article.submit('post', '/api/article/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'media' && data['media_type'] == 'image'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            if (key == 'listemployee'){
                                data.listemployee.map((obj, j) => {
                                    mdata.append(`${'employees'}[${j}][id]`, obj.id);
                                })
                            }else{
                                mdata.set(`${key}`,data[key]);
                            }
                        }
                    }
                    
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'article.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                
            });
            this.isLoading = false;
        }
    },
    components: {
        BranchForm
    }
}
</script>