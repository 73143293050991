<template>
    <div class="loading-overlay">
      <span class="fas fa-spinner fa-3x fa-spin"></span>
    </div>
</template>
<script>
  export default {
    name: 'loading',
    props: {
        visible: {
              type: Boolean,
              default: false
        },
    },
    mounted () {
      this.loading(this.visible);
    },
    methods: {
          loading: function (show) {
            let overlay = document.getElementsByClassName('loading-overlay')[0]

            if (show) {
              overlay.classList.toggle('is-active');
            } else {
              setTimeout( function() {
                overlay.classList.toggle('in-active');
                setTimeout( function() {
                  overlay.classList.toggle('in-active');
                }, 2000)
              }, 3000)
            }
          }
    },
    watch: {
        visible(val) {
            this.loading(val);
        }
    }
  };
  </script>
  <style lang="css">
    .loading-overlay {
      display: none;
      background: rgba(255, 255, 255, 0.7);
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      z-index: 9998;
      align-items: center;
      justify-content: center;
    }

    .loading-overlay.is-active {
      display: flex;
    }
  </style>