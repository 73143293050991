<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Product List</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0 ">
                <table class="table table-hover table--middle table--primary mt-2 text-nowrap text-center">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Name</th>
                      <th>Code</th>
                      <th>Description</th>
                      <th>Category</th>
                      <th>Price</th>
                      <th class="stick-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(product, index)  in products.data" :key="product.id">
                      <td>{{ index + 1 }}&nbsp;</td>
                      <td>{{product.name}}</td>
                      <td>{{product.code}}</td>
                      <td>{{product.description | truncate(30, '...')}}</td>
                      <td>{{product.category.name}}</td>
                      <td>{{product.price}}</td>
                      <!-- <td><img v-bind:src="'/' + product.photo" width="100" alt="product"></td> -->
                      <td class="stick-end">
                        
                        <a href="#" @click="editModal(product)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteProduct(product.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="products" @pagination-change-page="getResults" :limit="10"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" v-show="!editmode">Create New Product</h5>
                      <h5 class="modal-title" v-show="editmode">Edit Product</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>

                  <form enctype="multipart/form-data" @submit.prevent="editmode ? updateProduct() : createProduct()">
                    <div class="modal-body">
                      <b-tabs content-class="mt-3">
                        <b-tab title="Manual Entry" active @click="setType('manual')">
                          <div class="form-group">
                              <label>Name</label>
                              <input v-model="form.name" type="text" name="name"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                              <has-error :form="form" field="name"></has-error>
                          </div>
                          <div class="form-group">
                              <label>Code</label>
                              <input v-model="form.code" type="text" name="code"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('code') }">
                              <has-error :form="form" field="code"></has-error>
                          </div>
                          <div class="form-group">
                              <label>Description</label>
                              <input v-model="form.description" type="text" name="description"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                              <has-error :form="form" field="description"></has-error>
                          </div>
                          <div class="form-group">
                              <label>Price</label>
                              <input v-model="form.price" type="text" name="price"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('price') }">
                              <has-error :form="form" field="price"></has-error>
                          </div>
                          <div class="form-group">

                              <label>Category</label>
                              <select class="form-control" v-model="form.category_id">
                                <option 
                                    v-for="(cat,index) in categories" :key="index"
                                    :value="index"
                                    :selected="index == form.category_id">{{ cat }}</option>
                              </select>
                              <has-error :form="form" field="category_id"></has-error>
                          </div>
                          <div class="form-group">
                              <label>Tags</label>
                              <vue-tags-input
                                v-model="form.tag"
                                :tags="form.tags"
                                :autocomplete-items="filteredItems"
                                @tags-changed="newTags => form.tags = newTags"
                              />
                              <has-error :form="form" field="tags"></has-error>
                          </div>
                        </b-tab>
                        <b-tab title="Upload" @click="setType('upload')">
                            <b-row>
                                <b-col>
                                    <div class="form-group">
                                        <label>File Produk</label>
                                        <uploader name="upload_file" description="Maks 2MB dengan ekstensi (xls, xlsx)." accept=".xls,.xlsx" v-bind:files.sync="upload_fields.files" id="upload_file" :class="{ 'is-invalid': upload_fields.errors.has('files') }"/>
                                        <has-error :form="upload_fields" field="files"></has-error>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-tab>
                      </b-tabs>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode && type == 'manual'" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode && type == 'manual'" type="submit" class="btn btn-primary">Create</button>
                        <b-button v-show="type == 'upload'" @click.prevent="uploadFiles" class="btn btn-success">Upload</b-button>
                        <export-excel  class="btn btn-primary" 
                            :data  = "[{ name : 'diisi dengan nama product',
                                         code : 'diisi dengan code product, pastikan code sama dengan POS',
                                         description : 'diisi dengan deskripsi produk',
                                         price : 'diisi dengan harga product example : 32500.50',
                                         category_id : 'diisi dengan ID category atau code category yang terdapat dalam aplikasi',
                                         tags : 'disi dengan code tags yang terdapat dalam aplikasi, untuk multiple tags, gunakan tanda koma sebagai pemisah. example: LNR,BOX,AGRI'
                                    }]">
                            <i class="fa fa-download red"></i> Template
                        </export-excel>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input';

    export default {
      components: {
          VueTagsInput,
        },
        data () {
            return {
                editmode: false,
                products : {},
                form: new Form({
                    id : '',
                    category : '',
                    name: '',
                    code : '',
                    description: '',
                    tags:  [],
                    photo: '',
                    category_id: '',
                    price: '',
                    photoUrl: '',
                }),
                upload_fields: new Form({
                  files: ''
                }),
                type:'manual',
                categories: [],
                alltags : [],
                tag:  '',
                autocompleteItems: [],
            }
        },
        methods: {
          setType(type){
            this.type = type;
          },
          uploadFiles(){
            const vm = this
            this.upload_fields.submit('post', '/api/product/upload', {
                transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                  Toast.fire({
                      icon: 'success',
                      title: response.data.message
                  });
                  this.loadProducts()
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
            });
          },
          getResults(page = 1) {

              this.$Progress.start();
              
              axios.get('api/product?page=' + page).then(({ data }) => (this.products = data.data));

              this.$Progress.finish();
          },
          loadProducts(){

            // if(this.$gate.isAdmin()){
              axios.get("api/product").then(({ data }) => (this.products = data.data));
            // }
          },
          loadCategories(){
              axios.get("/api/category/list").then(({ data }) => (this.categories = data.data));
          },
          loadTags(){
              axios.get("/api/tag/list").then(response => {
                  this.alltags = response.data.data;
                  this.autocompleteItems = response.data.data.map(a => {
                      return { text: a.name, id: a.id , type: a.type};
                  });
              }).catch(() => console.warn('Oh. Something went wrong'));
          },
          editModal(product){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(product);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createProduct(){
              this.$Progress.start();

              this.form.post('api/product')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadProducts();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateProduct(){
              this.$Progress.start();
              this.form.put('api/product/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadProducts();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deleteProduct(id){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {
                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/product/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Your file has been deleted.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadProducts();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadProducts();
            this.loadCategories();
            this.loadTags();

            this.$Progress.finish();
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        computed: {
          filteredItems() {
            return this.autocompleteItems.filter(i => {
              return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1 && !this.form.tags.filter((mdt) => mdt.type === i.type).length;
            });
          },
        }
    }
</script>
