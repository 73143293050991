<template>
    <b-form v-if="!preparation" class="form" enctype="multipart/form-data">
        <b-row class="align-items-center">
            <b-col  lg="12" class="mt-2 text-right">
                <b-form-group v-if="$gate.hasAccess('emplpolling.update')">
                    <b-button :disabled="!checkedanswer.length && !checkedemplpolling.length" @click="Save" class="btn btn-primary btm-lg" size="lg"><i class="fa fa-save blue"></i>&nbsp;Save</b-button>
                </b-form-group>
            </b-col>
        </b-row>

        <vue-good-table
            class="table--middle table--primary" show-empty responsive
            :columns="columns"
            :rows="rows"
            :group-options="{
                    enabled: true,
                    collapsable: true
            }"
            :search-options="{
                    enabled: true,
                    trigger: 'enter',
                    placeholder: 'nama karyawan',
                    searchFn: searchData,
            }"
            :pagination-options="{
                enabled: true,
                mode: 'pages'
            }"
            > 
            <template slot="table-header-row" slot-scope="props">
                <span v-if="props.column.field === 'nilai'">
                    <div v-if="$gate.hasAccess('emplpolling.update')">
                        <b-form-group>
                            <b-form-input :id="`inputempl-${props.row.id}`" required placeholder="Summary Nilai" :value="props.row.nilai" @input="updateemplpolling($event,props.row.description,props.row.id)"></b-form-input>
                        </b-form-group>
                    </div>
                    <span v-else>{{ props.formattedRow[props.row.nilai] }}</span>
                </span>
                <span v-else-if="props.column.field === 'description' && props.row.type == 'empl'">
                    <div v-if="$gate.hasAccess('emplpolling.update') ">
                        <b-form-group>
                            <b-form-input :id="`input-${props.row.id}`" required placeholder="Summary Description" :value="props.row.description" @input="updateemplpolling(props.row.nilai,$event,props.row.id)"></b-form-input>
                        </b-form-group>
                    </div>
                    <span v-else>{{ props.formattedRow[props.row.description] }}</span>
                </span>
                <span v-else>
                    {{props.formattedRow[props.column.field]}}
                </span>
            </template>
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'nilai'">
                    <div v-if="$gate.hasAccess('emplpolling.update') && props.row.nilai_id !== 0 && props.row.nilai_id !== null">
                        <b-form-group>
                            <b-form-input :id="`inputanswer-${props.row.nilai_id}`" required placeholder="Nilai" :value="props.row.nilai" @input="updateanswer($event,props.row.nilai_id)"></b-form-input>
                        </b-form-group>
                    </div>
                    <span v-else>{{ props.formattedRow[props.row.nilai] }}</span>
                </span>
                <span v-else>
                    {{props.formattedRow[props.column.field]}}
                </span>
            </template>
        </vue-good-table>        
    </b-form>
    <div v-else class="text-center text-primary py-14">
        <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
    </div>
</template>
<script>

import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';

export default {
    props: ['pollingid'],
    data(){
        return {
            preparation : true,
            checkedemplpolling: [],
            checkedanswer: [],
            filter:{
                q : ''
            },
            columns: [
                        {
                            label: 'Name',
                            field: 'name',
                            width: '200px',
                        },
                        {
                            label: 'Description',
                            field: 'description',
                        },
                        {
                            label: 'Nilai',
                            field: 'nilai'
                        }
                    ],
            rows: [],
        }
    },
    mounted() {
        this.getNilaiPolling();
    },
    methods: {
        Save(){
            Swal.fire({
            title: 'Are you sure, please recheck again!!',
            text: "You won't be able to revert this!!!",
            showCancelButton: true,
            confirmButtonColor: '#3334dd',
            cancelButtonColor: '#30d63b',
            confirmButtonText: 'Yes, save it!'
            }).then((result) => {
                if (result.value) {
                    const DeleteRoles = new Promise((resolve) => {
                        axios.post('/api/emplpolling/penilaian', {
                            polling_id:this.pollingid, 
                            employee_id : window.user.employee.id,
                            nilaiempl : this.checkedemplpolling, 
                            nilai : this.checkedanswer})
                        .then((response) => {
                            Toast.fire({
                                icon: 'success',
                                title: response.message
                            });
                            
                            resolve(response)
                        });
                    });
                    Promise.all([DeleteRoles]).then((response) => {
                        this.getNilaiPolling()
                    });
                }
            });
        },
        searchData(row, col, cellValue, searchTerm){
            this.filter.q = searchTerm.toLowerCase();
            return cellValue.indexOf(this.filter.q);
        },
        onSearch(){
            this.getNilaiPolling();
        },
        updateemplpolling(val,descr,id) {
            this.$nextTick(() => {
                let find = -1;
                if (this.checkedemplpolling){
                    find  = this.checkedemplpolling.findIndex((lst) => lst.id == id) ;
                }else{
                    find = -1;
                }

                if (find >= 0){
                    this.checkedemplpolling[find].id = id;
                    this.checkedemplpolling[find].nilai = val;
                    this.checkedemplpolling[find].description = descr;
                }else{
                    this.checkedemplpolling.push({
                            id : id,
                            nilai : (val ? val : 0),
                            description : (descr ? descr : '')
                    });
                }
            });
        },
        updateanswer (val,id) {
            this.$nextTick(() => {
                let find = -1;
                if (this.checkedanswer){
                    find  = this.checkedanswer.findIndex((lst) => lst.id == id) ;
                }else{
                    find = -1;
                }

                if (find >= 0){
                    this.checkedanswer[find].id = id;
                    this.checkedanswer[find].nilai = val;
                }else{
                    this.checkedanswer.push({
                            id : id,
                            nilai : val,
                    });
                }
            });
        },
        getNilaiPolling(){
            this.preparation = true;
            this.$Progress.start();

            const geChecklistData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    params: this.filter,
                    url: `/api/polling/fetch/${this.pollingid}`
                }).then((data) => {
                    this.rows = data.data.data.penilaian;
                    resolve(data);
                });
            })
            Promise.all([geChecklistData]).then(() => {
                this.preparation = false;
            });
            this.$Progress.finish();
        },       
    },
    watch: {
        'filter.q': function() {
            this.getNilaiPolling()
        },
    },
    components: {
        VueGoodTable,
    }
}
</script>