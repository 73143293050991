<template>
      <li class="nav-item dropdown" v-if="!preparation && notifications.length">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-bell fa-shake"></i>
          <span class="badge badge-warning">{{ notifications.length }}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" style="width:300px;">
          <span class="dropdown-item dropdown-header">{{ notifications.length }} Notifications</span>
          
            <div v-for="notification in notifications" :key="notification.id">
                <div class="dropdown-divider"></div>
                <router-link  :to="{ name: notification.slug, params: {id: notification.subject_id} }" class="font-weight-medium text-primary" style="box-sizing: content-box;height: 17px;"
                replace
                v-slot="{ href, navigate }" custom>
                    <a :href="href" @click="(e) => goToNotification(e, notification, navigate)">
                        <div class="info-box no-border">
                            <div class="info-box-content">
                                <span class="info-box-text" v-html="notification.message"></span>
                                <div class="progress">
                                    <div class="progress-bar bg-info" style="width: 100%"></div>
                                </div>
                                <span class="progress-description text-right" style="margin-top:-7px;">
                                    <time>
                                        <time-ago :refresh="60" :datetime="notification.updated_at" long />
                                    </time>
                                </span>
                            </div>
                        </div>
                        
                    </a>
                </router-link>
            </div>

            <div class="dropdown-divider"></div>
            <b-button v-if = "page < totalpage"  @click="page = page++" variant="success" class="btn btn-tool btn-success" size="sm"><i class="fas fa-forward"></i>More Notification</b-button>   

          <div class="dropdown-divider"></div>
            <router-link :to="{ name: 'notification.data' }" class="font-weight-medium text-primary ml-2" style="box-sizing: content-box;height: 17px;">
                <span class="info-box-number">see all notifications</span>
            </router-link>
          </div>
      </li>
      <li class="nav-item dropdown" v-else>
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-bell"></i>
          <span class="badge badge-warning">No new notification</span>
        </a>
      </li>
</template>
<script>
import { TimeAgo } from 'vue2-timeago'
import { eventBus } from '../../app.js'

export default {
    data(){
        return {
            notifications : [],
            limit : 10,
            page :1,
            totalpage : 1,
            preparation :true,
            conversation : false,
            questionindex : -1,
            alltext : []
        }
    },
    mounted() {
        this.getnotifications(this.page);
        if (annyang && responsiveVoice) {
            var entryjawaban = function(jawaban){
                console.log(jawaban)
                if (['a','b','c','d','e','setuju','sangat setuju','kurang setuju','tidak setuju'].includes(jawaban.toLowerCase)){
                    eventBus.$emit('get_answer', {index: this.questionindex, answer: jawaban.toLowerCase, user_id : window.user.id});
                    this.conversation = false;
                }
            }
            var open = function(perintah){
                console.log(perintah)
            }
            var finishtraining = function(){
                console.log("finish training")
                eventBus.$emit('get_answer', {index: this.questionindex, answer: 'done', user_id : window.user.id});
            }
            /*
            var commands = {
                'jawab :pilihan': {'regexp': /^jawab (a|b|c|d|e|setuju|sangat setuju|kurang setuju|tidak setuju)$/, 'callback': this.entryjawaban},
                'open :command': {'regexp': /^open (a|b|c|d|e|setuju|sangat setuju|kurang setuju|tidak setuju)$/, 'callback': this.entrycommand},
                'selesai' :  this.entrycommand
            };
            entryjawaban(jawaban){
                console.log(jawaban)
                if (['a','b','c','d','e','setuju','sangat setuju','kurang setuju','tidak setuju'].includes(jawaban.toLowerCase)){
                    eventBus.$emit('get_answer', {index: this.questionindex, answer: jawaban.toLowerCase, user_id : window.user.id});
                    this.conversation = false;
                }
            },
            entrycommand(command){
                //if (['pr','po'].includes(command.toLowerCase)){
                    console.log(command)
                //}
            },

            */
            var commands = {
                '*jawaban' : entryjawaban,
                'open *perintah'  : open,
                'saya paham' : finishtraining
            }
            annyang.init(commands);
            annyang.setLanguage('id-ID');
            annyang.start();
            annyang.addCallback('result', function (userSaid){
                if (this.conversation){
                    eventBus.$emit('get_answer', {index: this.questionindex, answer: userSaid, user_id : window.user.id});
                    this.conversation = false;
                }
            }); 
            annyang.addCallback('resultMatch', function (userSaid, commandText, phrases) {        
                console.log(userSaid);  
                console.log(commandText);
                console.log(passphrases);
            })
        }else console.log('annyang and voice not loading');
    },
    created(){
        const vm = this;

        eventBus.$on('new_notification', () =>  {
            this.getnotifications(this.page);
        })

        eventBus.$on('read_notification', function(arrmessage) {
                this.conversation = false;
                responsiveVoice.speak(
                    arrmessage.message,
                        "Indonesian Female",
                        {
                            onstart: function(){annyang.pause();},
                            pitch: 1, 
                            rate: 1, 
                            volume: 1
                        }
                    );
        });

        eventBus.$on('read_question', function(message) {
                this.conversation = true;
                this.questionindex = message.idx;
                responsiveVoice.speak(
                                message.pertanyaan,
                                "Indonesian Female", 
                                { pitch: 1, 
                                    rate: 1, 
                                    volume: 1,
                                    onstart: function(){annyang.pause();},
                                    onend: function(){ 
                                            setTimeout(()=> {
                                                                annyang.resume();
                                                                console.log("done >>>> " + message.idx);
                                                            }
                                            ,3000);
                                        }
                                });
        });
    },
    methods: {
        
        goToNotification(e, notification, navigate) {
            navigate(e)
            const vm = this
            notification.isRead = true;
            return new Promise((resolve, reject) => {
                axios.post(`/api/notifications/store`, notification)
                .then((response) => {
                    this.getnotifications(this.page);
                })
                .catch((response) => {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            })
        },
        getnotifications(selpage){
            const vm = this
            const getAllNotifData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    url: `/api/notifications`,
                    params: {
                        limit: this.limit,
                        page: selpage,
                        isread : 0,
                    }
                }).then((response) => {
                    vm.notifications = response.data.data;
                    vm.totalpage = response.data.total
                    resolve(response.data);
                });
            })
            Promise.all([getAllNotifData]).then(() => {
                vm.preparation = false
                if (!sessionStorage.getItem("iswelcoming" )){
                    let message = `${window.user.name}' selamat datang di Agrinesia Portal,
                            ${this.notifications.length ? ' anda memiliki ' + this.notifications.length +' notifikasi' :  ' tidak ada notifikasi hari ini'}`
                    responsiveVoice.speak(
                        message,
                        "Indonesian Female",
                        {
                            onstart: function(){annyang.pause();},
                            pitch: 1, 
                            rate: 1, 
                            volume: 1
                        }
                    );
                    sessionStorage.setItem("iswelcoming", true )
                }
            });
        }
    },
    //computed: {
   //     ceknotification: {
    //        get() {
    //            return sessionStorage.getItem("ceknotification") == 'true' ? 1 : 0 ;
    //        }
    //    }
    //},
    watch: {
        //ceknotification() {
        //    if(ceknotification){
        //        console.log('get notif '+ val)
        //        this.getnotifications(this.page);
        //    }
        //},
        page() {
            this.getnotifications(this.page);
        }
    },
    components: {
        TimeAgo
    }
}
</script>