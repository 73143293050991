<template>
    <b-form v-if="!preparation" class="form" enctype="multipart/form-data">
        <b-row>
            <b-col lg="5" class="mr-2">
                <b-form-group label="Periode *" label-for="periode_id">
                    <treeselect
                        id="periode_id"
                        :options="allperiode"
                        :show-count="true"
                        :default-expand-level="1"
                        v-model="polling.periode_id"
                        @input="updateValue"
                        placeholder="Pilih periode?"
                        />
                    <has-error :form="polling" field="periode_id"></has-error>
                </b-form-group>
                <b-form-group label="Type" label-for="type" >
                    <b-form-radio-group
                        size="sm"
                        id="type"
                        v-model="polling.type"
                        :options="[{value:'learning', text:'Learning'},{value:'training', text:'Training'}]"
                        required 
                        buttons
                        button-variant="outline-primary"
                        :class="{ 'is-invalid': polling.errors.has('type') }"
                    ></b-form-radio-group>
                </b-form-group>
                <b-form-group label="Name *" label-for="name">
                    <b-form-input  v-model="polling.name" id="name" required placeholder="Learning Name"  :class="{ 'is-invalid': polling.errors.has('name') }"></b-form-input>
                    <has-error :form="polling" field="name"></has-error>
                </b-form-group>
                <b-form-group label="Description" label-for="description">
                    <b-form-textarea
                        id="description"
                        v-model.trim="polling.description"
                        placeholder="Tulis Keterangan jika perlu." :class="{ 'is-invalid': polling.errors.has('description') }"/>
                        <has-error :form="polling" field="description" ></has-error>
                </b-form-group>
                <b-form-group label="Is Active" label-for="is_active" >
                    <b-form-radio-group
                        size="sm"
                        id="is_active"
                        v-model="polling.is_active"
                        :options="[{value:'1', text:'Active'},{value:'0', text:'Inactive'}]"
                        required 
                        buttons
                        button-variant="outline-primary"
                        :class="{ 'is-invalid': polling.errors.has('is_active') }"
                    ></b-form-radio-group>
                </b-form-group>
            </b-col>  
            <b-col lg="5" class="mr-2">
                <b-form-group label="Trainer *" label-for="trainer_id">
                    <treeselect
                        id="trainer_id"
                        :options="alltrainer"
                        :show-count="true"
                        :default-expand-level="1"
                        v-model="polling.trainer_id"
                        @input="updateTrainer"
                        placeholder="Pilih Trainer?"
                        />
                    <has-error :form="polling" field="periode_id"></has-error>
                </b-form-group>
                <b-form-group label="From" label-for="from">
                    <Datetime
                        value-zone="UTC+7"
                        type="datetime"
                        input-id="from"
                        v-model="polling.from"
                        format="yyyy-MM-dd HH:mm:ss"
                        input-class="form-control" class="input-group">
                        <div class="input-group-append" slot="after">
                            <div class="input-group-text">
                                <i class="fas fa-calendar" @click="polling.from = '1900-00-00 00:00:00'"></i>
                            </div>
                        </div>
                    </Datetime>
                </b-form-group>
                <b-form-group label="To" label-for="to">
                    <Datetime
                    value-zone="UTC+7"
                        type="datetime"
                        input-id="to"
                        v-model="polling.to"
                        format="yyyy-MM-dd HH:mm:ss"
                        input-class="form-control" class="input-group">
                        <div class="input-group-append" slot="after">
                            <div class="input-group-text">
                                <i class="fas fa-calendar" @click="polling.to = '1900-00-00 00:00:00'"></i>
                            </div>
                        </div>
                    </Datetime>
                </b-form-group>
                <b-form-group label="Parent">
                    <treeselect
                        :options="allpollings"
                        :show-count="true"
                        :default-expand-level="1"
                        v-model="polling.parent_polling_id"
                        @input="updateParent"
                        placeholder="Parent?"
                        />
                    <has-error :form="polling" field="parent_polling_id"></has-error>
                </b-form-group>
                <b-form-group label="Time Limit" label-for="time_limit">
                    <span>{{this.polling.time_limit}} Minutes</span>
                </b-form-group>
            </b-col>
        </b-row>
        <div class="separator mt-2 mb-6"></div>
    </b-form>
    <div v-else class="text-center text-primary py-14">
        <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
<!--   COMPONENT NOT FOUND
<div>
    <not-found></not-found>
</div>
-->   
    </div>
</template>
<script>
import { Datetime } from 'vue-datetime'
import moment from 'moment';
export default {
    data(){
        return {
            isLoading : true,
            allpollings : [],
        }
    },
    props: ['preparation','polling', 'allperiode', 'state','alltrainer'],
    created() {
        const vm = this
        const getAllPollingData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/polling/fetch/all`
            }).then((data) => {
                let tmp = data.data.data.items.filter((polling) => !polling.parent_job_id );
                vm.allpollings = tmp.map((polling) => {
                    return {
                        id: polling.id,
                        label: polling.name,
                        children : polling.children ? this.$helper.prosesarraypolling(polling.children,data.data.data.items) : []
                    }    
                });
                resolve(data);
            });
        })
        Promise.all([getAllPollingData]).then(() => {
            vm.isLoading = false
        })
    },
    methods: {
        updateValue(val){
            if (val) this.polling.periode_id = val;
            else this.polling.periode_id = ''
            Toast.fire({
                      icon: 'success',
                      title:val
                    });
        },
        updateTrainer(val){
            if (val) this.polling.trainer_id = val;
            else this.polling.trainer_id = ''
        },
        updateParent(val){
            if (val) this.polling.parent_polling_id = val;
            else this.polling.parent_polling_id = ''
        }
    },
    watch: {
        'polling.from': function(val) {
            if (val && this.polling.to){
                if (moment(val).isBefore(moment(this.polling.to))){
                    var matchDate = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
                    var firstDateParsed = matchDate.exec(moment(val).format('YYYY-MM-DD HH:mm:ss'));
                    var secondDateParsed = matchDate.exec(moment(this.polling.to).format('YYYY-MM-DD HH:mm:ss'));

                    var b = new Date(firstDateParsed[1], firstDateParsed[2], firstDateParsed[3], firstDateParsed[4], firstDateParsed[5], firstDateParsed[6], 0);
                    var a = new Date(secondDateParsed[1], secondDateParsed[2], secondDateParsed[3], secondDateParsed[4], secondDateParsed[5], secondDateParsed[6], 0);
                    var differenceInMilliseconds = a.getTime() - b.getTime();
                    this.polling.time_limit = differenceInMilliseconds / 1000 / 60;
                }else{
                    this.polling.time_limit = 0;
                    Toast.fire({
                        icon: 'warning',
                        title: "From Value can't be after To Value"
                    });
                }
            }else{
                this.polling.time_limit = 0;
            }
        },
        'polling.to': function(val) {
            if (val && this.polling.from){
                if (moment(val).isAfter(moment(this.polling.from))){
                    var matchDate = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
                    var firstDateParsed = matchDate.exec(moment(this.polling.from).format('YYYY-MM-DD HH:mm:ss'));
                    var secondDateParsed = matchDate.exec(moment(val).format('YYYY-MM-DD HH:mm:ss'));
                
                    var b = new Date(firstDateParsed[1], firstDateParsed[2], firstDateParsed[3], firstDateParsed[4], firstDateParsed[5], firstDateParsed[6], 0);
                    var a = new Date(secondDateParsed[1], secondDateParsed[2], secondDateParsed[3], secondDateParsed[4], secondDateParsed[5], secondDateParsed[6], 0);
                    var differenceInMilliseconds = a.getTime() - b.getTime();
                    this.polling.time_limit = differenceInMilliseconds / 1000 / 60;
                    
                }else{
                    this.polling.time_limit = 0;
                    Toast.fire({
                        icon: 'warning',
                        title: "To Value can't be before From Value"
                    });
                }
            }else{
                this.polling.time_limit = 0;
            }
        },
    },
    components: {
        Datetime
    }
}
</script>