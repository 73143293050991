<template>
    <b-form v-if="!preparation" class="form" enctype="multipart/form-data">
        <b-row>
            <b-col lg="4" class="mr-2">
                <b-form-group label="Name *" label-for="name">
                    <b-form-input  v-model="document.name" id="name" required placeholder="Document Name"  :class="{ 'is-invalid': document.errors.has('name') }"></b-form-input>
                    <has-error :form="document" field="name"></has-error>
                </b-form-group>
                <b-form-group label="Path *" label-for="path">
                    <b-form-input  v-model="document.path" id="path" required placeholder="Path"  :class="{ 'is-invalid': document.errors.has('path') }"></b-form-input>
                    <has-error :form="document" field="path"></has-error>
                </b-form-group>
                <b-form-group label="Image *" label-for="image">
                    <b-form-input v-model="document.image" id="image" required placeholder="Image"  :class="{ 'is-invalid': document.errors.has('image') }"></b-form-input>
                    <has-error :form="document" field="image"></has-error>
                </b-form-group>
                <b-form-group label="Is Active" label-for="is_active" >
                    <b-form-radio-group
                        size="sm"
                        id="is_active"
                        v-model="document.active"
                        :options="[{value:'1', text:'Active'},{value:'0', text:'Inactive'}]"
                        required 
                        buttons
                        button-variant="outline-primary"
                        :class="{ 'is-invalid': document.errors.has('active') }"
                    ></b-form-radio-group>
                </b-form-group>
                <b-form-group label="Lifetime *" label-for="lifetime">
                    <b-form-input v-model="document.lifetime" id="lifetime" required placeholder="jumlah lifetime sesuai periode"  :class="{ 'is-invalid': document.errors.has('lifetime') }"></b-form-input>
                    <has-error :form="document" field="lifetime"></has-error>
                </b-form-group>
                <b-form-group label="Periode" label-for="lifeperiod" >
                    <b-form-radio-group
                        size="sm"
                        id="lifeperiod"
                        v-model="document.lifeperiod"
                        :options="[{value:'day', text:'Day'},{value:'month', text:'Month'},{value:'year', text:'Year'}]"
                        required 
                        buttons
                        button-variant="outline-primary"
                        :class="{ 'is-invalid': document.errors.has('lifeperiod') }"
                    ></b-form-radio-group>
                </b-form-group>
                <b-form-group label="Expired offset *" label-for="expiredoffset">
                    <b-form-input v-model="document.expiredoffset" id="expiredoffset" required placeholder="jumlah expired offset sesuai periode"  :class="{ 'is-invalid': document.errors.has('expiredoffset') }"></b-form-input>
                    <has-error :form="document" field="expiredoffset"></has-error>
                </b-form-group>
                <b-form-group label="Periode Offset Expired" label-for="expiredperiod" >
                    <b-form-radio-group
                        size="sm"
                        id="expiredperiod"
                        v-model="document.expiredperiod"
                        :options="[{value:'day', text:'Day'},{value:'month', text:'Month'},{value:'year', text:'Year'}]"
                        required 
                        buttons
                        button-variant="outline-primary"
                        :class="{ 'is-invalid': document.errors.has('expiredperiod') }"
                    ></b-form-radio-group>
                </b-form-group>
                <b-form-group label="Viewers *" label-for="users">
                    <treeselect 
                        :options="employees"
                        :show-count="true"
                        :default-expand-level="1"
                        v-model="document.users"
                        :disable-branch-nodes="true"
                        search-nested
                        :value-consists-of="'LEAF_PRIORITY'"
                        :valueFormat="'object'"
                        placeholder="Choose"
                        :multiple="true"
                    />
                    <has-error :form="document" field="users"></has-error>
                </b-form-group>
            </b-col>  
            <b-col lg="7" class="mr-2">
                
                <div class="card card-secondary">
                    <div class="card-header">
                        <h3 class="card-title">List of Flow</h3>
                    </div>
                    <div class="card-body">
                        <draggable v-model="document.flows" v-if="document.flows" @end="MoveFlow">
                            <div :class="'alert alert-danger alert-dismissible ' + (flowselindex == index ? 'shadow' : 'secondary')" v-for="(element, index) in document.flows" :key="element.name">
                                <div @click="ChooseFlow(index)">
                                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true" @click="deleteFlow(element,index)">×</button>
                                    <h5>{{element.name}} <span :title="element.doc_flowitems ? element.doc_flowitems.length : 0" class="badge badge-warning">{{element.doc_flowitems ? element.doc_flowitems.length : 0}}</span></h5>
                                </div>
                                <div class="alert alert-warning alert-dismissible" v-for="(item,indexitem) in element.doc_flowitems" :key="item.employee_id">
                                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true" @click="deleteFlowItem(item,index,indexitem)">×</button>
                                    {{ item.approver.name }}
                                </div>
                                
                                <div v-if="flowselindex == index">
                                    <b-form-group label="Approval" label-for="empls" class="mt-2" >
                                        <treeselect 
                                            :options="empls"
                                            :show-count="true"
                                            :default-expand-level="1"
                                            v-model="tmpuser"
                                            :disable-branch-nodes="true"
                                            search-nested
                                            :value-consists-of="'LEAF_PRIORITY'"
                                            :valueFormat="'object'"
                                            placeholder="Choose"
                                            :multiple="true"
                                        />
                                    </b-form-group>
                                    <a slot="header" class="btn btn-success btm-sm" @click="addApprover(index)" variant="primary">
                                        <i class="fas fa-plus"></i> Add Approver
                                    </a>
                                </div>
                            </div>
                        </draggable>
                        <b-form-group label="Flow" label-for="image" class="mt-2">
                            <b-form-input  v-model="flowname" id="flowname" required placeholder="Flowname"></b-form-input>
                        </b-form-group>
                        <a slot="header" class="btn btn-success btm-sm" @click="addFlow()" variant="primary">
                            <i class="fas fa-plus"></i> Add Flow
                        </a>
                    </div>
                </div>
            
            </b-col>
        </b-row>
        <div class="separator mt-2 mb-6"></div>
    </b-form>
    <div v-else class="text-center text-primary py-14">
        <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
<!--   COMPONENT NOT FOUND
<div>
    <not-found></not-found>
</div>
-->   
    </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
    data(){
        return {
            isLoading : true,
            flowselindex : -1,
            flowname :'',
            tmpuser:[],
            empls:[],
        }
    },
    props: ['preparation','document', 'employees'],
    methods: {
        addFlow(){
            const vm = this
            
            let notexist = vm.document.flows.filter((flow) => flow.name === this.flowname);
            if (!notexist.length){
                this.document.flows.push({
                                            id: '',
                                            name: this.flowname,
                                            idx : this.document.flows.length ,
                                            doc_flowitems : []
                                        })
                this.ChooseFlow(this.document.flows.length -1);
            }else{
                Toast.fire({
                    icon: 'error',
                    title: 'Flow duplicate, pastikan nama berbeda'
                });
            }
        },
        addApprover(index){
            const vm = this
            
            if (index != -1){
                let tmp = this.tmpuser.filter(empl => !vm.document.flows[vm.flowselindex].doc_flowitems.map((item)=> item.employee_id).includes(empl.id));
                
                tmp.forEach(employee => {
                    this.document.flows[vm.flowselindex].doc_flowitems.push({
                        id : '',
                        employee_id : employee.id,
                        approver : { name : employee.label },
                        doc_flow_id : this.document.flows[vm.flowselindex].id,
                    });
                });

                this.ChooseFlow(index)
            }else{
                Toast.fire({
                    icon: 'error',
                    title: 'Please select flow first to add approvers'
                });
            }
        },
        reloadempl(){
            const vm = this
            this.empls = vm.employees.map((branch) => {
                let cek  = []
                if (vm.document.flows[vm.flowselindex].doc_flowitems.length > 0){
                    cek = branch.children.filter((employee) => !vm.document.flows[vm.flowselindex].doc_flowitems.map(item => item.employee_id).includes(employee.employee_id));
                }else{
                    cek = branch.children
                }
                
                if (cek){
                    return {
                        id : branch.id,
                        label : branch.label,
                        children : cek
                    }
                }
            })
        },
        ChooseFlow(index){
            const vm = this
            vm.flowselindex = index
            vm.flowname = vm.document.flows[vm.flowselindex].name
            this.tmpuser = vm.document.flows[vm.flowselindex].doc_flowitems.map((user) => {
                return {
                    id : user.employee_id,
                    label : user.approver.name
                };
            })
            vm.reloadempl();                      
        },
        MoveFlow(event){
            const vm = this
            console.log(event)
            //let to = event.newIndex;
            //let from = event.oldIndex;
            
            this.restructure_flows();
            vm.flowselindex = -1;
        },
        deleteFlowItem(item,index,indexitem){
            const vm = this;

            if (index != -1){
                vm.flowselindex = index;
                vm.document.flows[vm.flowselindex].doc_flowitems.splice(indexitem,1);
                this.tmpuser = vm.document.flows[vm.flowselindex].doc_flowitems.map((user) => {
                    return {
                        id : user.employee_id,
                        label : user.approver.name
                    };
                })
                this.restructure_flows();
            }else{
                Toast.fire({
                    icon: 'error',
                    title: 'Please select flow first to delete approvers'
                });
            }
        },
        deleteFlow(item,index){
            const vm = this;
            
            this.document.flows.splice(index, 1);
            vm.flowselindex = -1;
            vm.restructure_flows();
            Toast.fire({
                    icon: 'success',
                    title: 'Item has been deleted, make sure to submit for finalize your changed'
                });
        },
        restructure_flows(){
            const vm = this
            this.document.flows.map((item,index) => {
                item.idx = index;
                return item;
            });
        }
    },
    watch: {
        
    },
    components: {
        draggable,
    },
}
</script>