<template>
    <div class="container-fluid" v-if="!preparation">
        <b-row class="align-items-center pb-1">
            <b-col  lg="8">
                <router-link v-if="$gate.hasAccess('loa.create')" :to="{ name: 'loa.create'}" class="btn btn-primary btm-sm mt-1">Create New</router-link>
            </b-col>
            <b-col  lg="4">
                <b-form class="form form-inline form--rounded justify-content-end btn-sm" @submit.prevent="getDocument()">
                    <b-form-group v-if="checked.length && $gate.hasAccess('loa.delete')">
                        <b-button @click="bulkDelete" class="btn btn-danger btm-sm" size="sm"><i class="fa fa-trash red"></i>Bulk Delete</b-button>
                    </b-form-group>
                    
                    <b-form-group class="form-inline ml-3" >
                        <b-input-group size="sm">
                            <b-form-input type="search" class="form-control form-control-navbar" v-model="filter.q" placeholder="Search" aria-label="Search" />
                            <div class="input-group-append">
                                <button class="btn btn-navbar" type="submit" >
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                            
                        </b-input-group>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>

        <b-table
            id="document-table"
            class="table--middle table--primary mt-2" show-empty responsive
            :items="alldocuments.items"
            :fields="tableFields"
            :tbody-tr-class="rowHighlight"
            :busy.sync="preparation">
            <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
                </div>
            </template>
            <template v-slot:empty><div class="text-center text-muted">Data not found</div></template>

            <template v-slot:head(check)>
                <div class="checkbox checkbox--all">
                    <input id="check-all" v-model="checkall" type="checkbox">
                    <label for="check-all"></label>
                </div>
            </template>

            <template v-slot:cell(no)="data">{{ ((page > 0 ? (page - 1) : 0) * filter.limit) + (data.index +1 ) }}</template>
            <template v-slot:cell(name)="data">
                <div class="description-block">
                    <h5 class="description-header">{{ data.item.name }}</h5>
                    <span :title="(data.item.loa_flows_count ? data.item.loa_flows_count : 0) + ' flows'" class="badge badge-warning">{{data.item.loa_flows_count ? data.item.loa_flows_count : 0}} step LoA</span>
                </div>
            </template>
            <template v-slot:cell(path)="data">
                <div class="description-block">
                    <h5 class="description-header">{{ data.item.code }}</h5>
                </div>
            </template>
            <template v-slot:cell(branch_id)="data">
                <div class="description-block">
                    <h5 class="description-header">{{ data.item.branch ? data.item.branch.name : '' }}</h5>
                </div>
            </template>
            <template v-slot:cell(organization_id)="data">
                <div class="description-block">
                    <h5 class="description-header">{{ data.item.organization ? data.item.organization.name : '' }}</h5>
                </div>
            </template>
            <template v-slot:cell(job_position_id)="data">
                <div class="description-block">
                    <h5 class="description-header">{{ data.item.job_position ? data.item.job_position.name : '' }}</h5>
                </div>
            </template>
            <template v-slot:cell(location_id)="data">
                <div class="description-block">
                    <h5 class="description-header">{{ data.item.location ? data.item.location.name : '' }}</h5>
                </div>
            </template>
            <template v-slot:cell(grade_id)="data">
                <div class="description-block">
                    <h5 class="description-header">{{ data.item.grade ? data.item.grade.name : '' }}</h5>
                </div>
            </template>
            <template v-slot:cell(job_level)="data">
                <div class="description-block">
                    <h5 class="description-header">{{ data.item.job_level ? data.item.job_level.label : '' }}</h5>
                </div>
            </template>
            <template v-slot:cell(limit)="data">
                <div class="description-block">
                    <h5 class="description-header">{{ data.item.limit_from | toCurrency }} - {{ data.item.limit_to | toCurrency }}</h5>
                </div>
            </template>
            <template v-slot:cell(active)="data">
                <ToggleButton v-if="$gate.hasAccess('loa.update')" class="mb-0" @change="(e) => setActive(e, data.item.id)" :value="data.value ? true : false" :width="80" :labels="{checked: 'Aktif', unchecked: 'Non-aktif'}"></ToggleButton>
                <span v-else>{{ data.item.active ? 'Active' : 'Inactive'}}</span>
            </template>
            
            <template v-slot:cell(action)="data">
                <div class="btn-group">
                    <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                <i class="fas fa-wrench"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" role="menu" x-placement="bottom-end" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-124px, 18px, 0px);">
                        <a class="dropdown-item" v-if="$gate.hasAccess('loa.update')">
                            <router-link  :to="{ name: 'loa.update', params: {id: data.item.id} }" style="box-sizing: content-box;height: 17px;"><i class="fas fa-edit"></i> Edit</router-link>
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" v-if="$gate.hasAccess('loa.create')"  @click="duplicateLoA(data.item)" variant="danger">
                            <i class="fas fa-copy"></i> Duplicate
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" v-if="$gate.hasAccess('loa.delete')"  @click="removeDocument(data.item.id)" variant="danger">
                            <i class="fas fa-times"></i> Delete
                        </a>
                    </div>
                </div>  
            </template>

            <template v-slot:cell(check)="data">
                <div class="checkbox">
                    <input :id="`checkbox-${data.item.id}`" :value="data.item.id" v-model="checked" type="checkbox">
                    <label :for="`checkbox-${data.item.id}`"></label>
                </div>
            </template>
        </b-table>

        <b-row>
            <b-col lg="6">
                <div class="form form--small form-inline justify-content-center justify-content-lg-start">
                    <b-form-group label-class="text-primary mr-1" label="GO TO " label-for="page">
                        <b-form-input type="number" v-model="page" id="page" class="form-control"></b-form-input>
                    </b-form-group>
                    <b-form-group label-class="text-primary ml-2 mr-2" label="VIEW " label-for="filter_limit">
                        <select2 id="filter_limit" v-model="filter.limit" :options="[10, 20, 30]" :settings="{minimumResultsForSearch: -1}" />
                    </b-form-group>
                </div>
            </b-col>
            <b-col lg="6">
                <b-pagination
                    align="right"
                    v-model="page"
                    :total-rows="alldocuments.total"
                    :per-page="filter.limit"
                    aria-controls="document-table">
                </b-pagination>
            </b-col>
        </b-row>   
    </div>      
    <div v-else>
        <form-loader></form-loader>
    </div>
</template>
<script>
import moment from 'moment';
export default {
    data() {
        return {
            document : new Form({
                id:'',
                name: '',
                code:'',
                branch_id:'',
                organization_id:'',
                job_position_id:'',
                grade_id:'',
                location_id:'',
                job_level:'',
                limit_from:0,
                limit_to:0,
                active :'',
                flows:[],
            }),
            preparation: true,
            isLoading:true,
            alldocuments:[],
            mpage:1,
            checkall: false,
            checked: [],
            selectionindex : -1,
            filter: {
                q: '',
                limit: 30
            },
            tableFields: [
                { key: 'no', label: 'No.', tdClass: 'text-right', thAttr: { width: '50' } },
                { key: 'name', label: 'Name' , thAttr: { width: '160' } },
                { key: 'code', label: 'Code', thAttr: { width: '160' } },
                { key: 'branch_id', label: 'Branch', thAttr: { width: '160' } },
                { key: 'organization_id', label: 'Dept', thAttr: { width: '160' } },
                { key: 'job_position_id', label : 'Position', thAttr: { width: '160' } },
                { key: 'grade_id', label: 'Grade', thAttr: { width: '160' } },
                { key: 'location_id', label: 'Location', thAttr: { width: '160' } },
                { key: 'job_level', label: 'Job Level', thAttr: { width: '160' } },
                { key: 'limit', label: 'Limit', thAttr: { width: '160' } },
                { key: 'active', label: 'Active', thAttr: { width: '100' } },
                { key: 'action', tdClass: 'text-center', thAttr: { width: '30' }},
                { key: 'check', thAttr: { width: '30' } , tdClass: 'text-center stick-end', thClass: 'stick-end' }
            ]
        }
    },
    created(){
        this.getDocument();
    },
    computed: {
        page: {
            get() {
                return this.mpage;
            },
            set(val) {
                this.mpage = val;
            }
        }
    },
    methods: {
        getDocument(){
            this.preparation = true;
            this.$Progress.start();

            const getDocumentData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    params: this.filter,
                    url: `/api/loa/fetch?page=${this.page}`
                }).then((data) => {
                    this.alldocuments = data.data.data;
                    resolve(data.data.data);
                });
            })
            Promise.all([getDocumentData]).then(() => {
                this.preparation = false;
                this.selectionindex = -1
            });
            this.$Progress.finish();
        },       
        setActive(data, id) {
            let formData = {
                id: id,
                is_active: data.value ? 1 : 0
            }
            
            return new Promise((resolve, reject) => {
                axios.post(`/api/loa/setactive`, formData)
                .then((response) => {
                     Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    resolve(response.data)
                })
            });
        },
        rowHighlight(item, type) {
            if (!item || type !== 'row') return
            if (item.id === this.$route.params.row_id) return 'highlight'
        },
        bulkDelete() {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    const DeleteData = new Promise((resolve, reject) => {
                        axios.post('/api/loa/bulk-delete', {ids: this.checked})
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            resolve(error.response.data)
                        })
                    })
                    Promise.all([DeleteData]).then((response) => {
                        this.getDocument()
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    });
                }
            })
        },
        removeDocument(id) {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    const DeleteRoles = new Promise((resolve, reject) => {
                        axios.post('/api/loa/delete', {id: id})
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            resolve(error.data)
                        })
                    });
                    Promise.all([DeleteRoles]).then((response) => {
                        this.getDocument()
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    });
                }
            });
        },
        duplicateLoA(item){
            const vm = this
            Swal.fire({
            title: 'Are you sure to duplicate this LoA '+ item.name+ ' ?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, duplicate!'
            }).then((result) => {
                if (result.value) {
                    this.document.id = null
                    this.document.name += item.name + '_copy'
                    this.document.code += item.code + '_copy'
                    this.document.branch_id = item.branch_id
                    this.document.organization_id = item.organization_id
                    this.document.job_position_id = item.job_position_id
                    this.document.job_level = item.job_level
                    this.document.grade_id = item.grade_id
                    this.document.location_id = item.location_id
                    this.document.limit_from = item.limit_from
                    this.document.limit_to = item.limit_to
                    this.document.active = item.active

                    this.document.flows = item.loa_flows.map((flow,index) => {
                        return {  
                            id: '',
                            name: flow.name+'_copy',
                            code: flow.code+'_copy',
                            no_urut : flow.no_urut,
                            status : flow.status,
                            from : flow.from,
                            to:flow.to,
                            employee_id : flow.employee_id,
                            delegations : flow.delegations.map((delegate,index) => {
                                return {
                                    id : '',
                                    employee_id: delegate.employee_id
                                }
                            }),
                        };
                    });

                    this.document.submit('post', '/api/loa/store', {
                    transformRequest: [function (data, headers) {
                            let mdata = new FormData();

                            for ( var key in data ) {
                                if (data[key] instanceof File){                                                                     //name
                                    mdata.append(`${key}`, data[key]);
                                }else if (Array.isArray(data[key])) {                                                               //flows
                                    data[key].forEach((element, index) => {
                                        const tempFormKey = `${key}[${index}]`;                                                     //flows[0]
                                        for ( var key1 in element ) {                           
                                            if (element[key1] instanceof File){
                                                mdata.append(`${tempFormKey}[${key1}]`, element[key1]);                               //flows[0]['files']
                                            }else if (Array.isArray(element[key1])) {                                               //flowsitems
                                                element[key1].forEach((element1, index1) => {
                                                    const tempFormKey1 = `${tempFormKey}[${key1}][${index1}]`;                      //flows[0]['items'][0]
                                                    for ( var key2 in element1 ) {
                                                        if (!Array.isArray(element1[key2]) && typeof element1[key2]!== 'object'){
                                                            if (element1[key2] instanceof File){
                                                                mdata.append(`${tempFormKey1}[${key2}]`,element1[key2]);              ////flows[0]['items'][0]['user_id']
                                                            }else{
                                                                mdata.set(`${tempFormKey1}[${key2}]`,element1[key2]);                 ////flows[0]['items'][0]['user_id']
                                                            }
                                                        }
                                                    }
                                                });
                                            }else if (typeof element[key1]  === 'object') {
                                                const tempFormKey1 = `${tempFormKey}[${key1}]`;
                                                for ( var key2 in element[key1]) {
                                                    if (!Array.isArray(element[key1][key2]) && typeof element[key1][key2]!== 'object'){
                                                        if (element[key1][key2] instanceof File){
                                                            mdata.append(`${tempFormKey1}[${key2}]`,element[key1][key2]);             //flows[0]['objdata']
                                                        }else{
                                                            mdata.set(`${tempFormKey1}[${key2}]`,element[key1][key2]);                //flows[0]['objdata']
                                                        }
                                                    }
                                                }
                                            }else{
                                                mdata.set(`${tempFormKey}[${key1}]`, element[key1]); 
                                            }
                                        }
                                    });
                                }else if (typeof data[key] === 'object') {
                                    const tempFormKey = `[${key}]`;
                                    for ( var key1 in data[key] ) {
                                        if (!Array.isArray(data[key][key1]) && typeof data[key][key1] !== 'object'){
                                            if (data[key][key1] instanceof File){
                                                mdata.append(`${tempFormKey}[${key1}]`, data[key][key1]);
                                            }else{
                                                mdata.set(`${tempFormKey}[${key1}]`,data[key][key1]);
                                            }
                                        }
                                    }
                                }else{
                                    mdata.set(`${key}`,data[key]);
                                }
                            }
                            return mdata;
                    }],
                    }).then((response) => {
                        this.getDocument()
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    }).catch((e) => {
                        Toast.fire({
                            icon: 'error',
                            title: e.message
                        });
                    });
                }
            });
        }
    },
    watch: {
        'filter.limit': function(id) {
            this.getDocument()
        },
        page() {
            this.checked = []
            this.getDocument()
        },
        checkall(checkall) {
            if ( checkall ) {
                this.alldocuments.items.forEach(role => {
                    this.checked.push(role.id)
                });
            } else {
                this.checked = []
            }
        }
    }
}
</script>