
<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header p-2">
                            <ul class="nav nav-pills">
                                <li class="nav-item" v-if="$gate.hasAccess('polling.data')"><a class="nav-link" href="#training" data-toggle="tab">Training Record</a></li>
                                <li class="nav-item"><a class="nav-link active show" href="#settings" data-toggle="tab">Settings</a></li>
                                <li class="nav-item"><a class="nav-link" href="#change-password" data-toggle="tab">Change Password</a></li>
                            </ul>
                        </div><!-- /.card-header -->
                        <div class="card-body">
                            <div class="tab-content">
                                <!-- Activity Tab -->
                                <div class="tab-pane" id="training">
                                    <div class="col-12" v-if="!loadingitem">
                                        <vue-good-table
                                            class="table--middle table--primary" show-empty responsive
                                            :columns="columns"
                                            :rows="rows"
                                            :group-options="{
                                                    enabled: true,
                                                    collapsable: true
                                            }"
                                            :search-options="{
                                                    enabled: true,
                                                    trigger: 'enter',
                                                    placeholder: 'nama training',
                                                    searchFn: searchData,
                                            }"
                                            :pagination-options="{
                                                enabled: true,
                                                mode: 'pages'
                                            }">
                                            <template slot="table-header-row" slot-scope="props">
                                                <span>
                                                    {{props.formattedRow[props.column.field]}}
                                                </span>
                                            </template>
                                            <template slot="table-row" slot-scope="props">
                                                <span>
                                                    {{props.formattedRow[props.column.field]}}
                                                </span>
                                            </template>
                                        
                                        </vue-good-table>        

                                        <!--
                                        <b-table
                                            id="training-table"
                                            style = "margin-top:-10px;"
                                            show-empty responsive
                                            :items="trainings.items"
                                            :fields="tableFields"
                                            :tbody-tr-class="rowHighlight"
                                            :busy.sync="loadingitem">
                                            <template v-slot:table-busy>
                                                <div class="text-center text-primary my-2">
                                                    <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
                                                </div>
                                            </template>
                                            <template v-slot:empty><div class="text-center text-muted">Data not found</div></template>

                                            <template v-slot:cell(no)="data">{{ ((page > 0 ? (page - 1) : 0) * filter.limit) + (data.index +1 ) }}</template>
                                        
                                            <template v-slot:cell(polling.name)="data">
                                                <span>{{ (data.item.polling ? data.item.polling.name : '') }}</span>
                                            </template>
                                            <template v-slot:cell(polling.trainer.name)="data">
                                                <span>{{ (data.item.polling.trainer ? data.item.polling.trainer.name : '') }}</span>
                                            </template>
                                            <template v-slot:cell(created_at)="data">
                                                <span>{{ moment(data.item.created_at).format('YYYY-MM-DD HH:MM:SS') }}</span>
                                            </template>
                                        </b-table>

                                        <b-row>
                                            <b-col lg="8">
                                                <div class="form form--small form-inline justify-content-lg-start">
                                                    <b-form-group label-class="text-primary mr-1" label="GO TO " label-for="page">
                                                        <b-form-input type="number" v-model="page" id="page" class="form-control"></b-form-input>
                                                    </b-form-group>
                                                    <b-form-group label-class="text-primary ml-2 mr-2" label="VIEW " label-for="filter_limit">
                                                        <select2 id="filter_limit" v-model="filter.limit" :options="[10, 20, 30]" :settings="{minimumResultsForSearch: -1}" />
                                                    </b-form-group>
                                                </div>
                                            </b-col>
                                            <b-col  lg="4">
                                                <b-pagination
                                                    align="right"
                                                    v-model="page"
                                                    :total-rows="trainings.total"
                                                    :per-page="filter.limit"
                                                    aria-controls="training-table">
                                                </b-pagination>
                                            </b-col>
                                        </b-row>
                                    -->
                                    </div>
                                    <div class="overlay" v-else>
                                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                                    </div>
                                </div>
                                <!-- Setting Tab -->
                                <div class="tab-pane active show" id="settings">
                                    <form @click.prevent="updateInfo" class="form-horizontal">
                                        <div class="form-group">
                                            <label for="inputName" class="col-sm-2 control-label">Name</label>

                                            <div class="col-sm-12">
                                            <input type="" :disabled="form.email.slice(form.email.indexOf('@') + 1 ).toLowerCase() == 'agrinesia.co.id' ? true :false" v-model="form.name" class="form-control" id="inputName" placeholder="Name" :class="{ 'is-invalid': form.errors.has('name') }">
                                            <has-error :form="form" field="name"></has-error>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="inputEmail" class="col-sm-2 control-label">Email</label>
                                            <div class="col-sm-12">
                                                <input type="email" :disabled="form.email.slice(form.email.indexOf('@') + 1 ).toLowerCase() == 'agrinesia.co.id' ? true :false" v-model="form.email" class="form-control" id="inputEmail" placeholder="Email"  :class="{ 'is-invalid': form.errors.has('email') }">
                                                <has-error :form="form" field="email"></has-error>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="col-md-12">
                                                <button type="submit" class="btn btn-success">Update Profile</button>
                                                <div class="float-right d-sm-inline text-muted">Member since: <span>{{form.created_at|myDate}}</span></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <!-- Setting Tab -->
                                <div class="tab-pane" id="change-password">
                                    <form class="form-horizontal">
                                    <div class="form-group">
                                        <label for="current_password" class="col-sm-2 control-label">Current Password</label>

                                        <div class="col-sm-12">
                                            <input type="password"
                                                v-model="form.current_password"
                                                class="form-control"
                                                id="current_password"
                                                placeholder="Current Password"
                                                :class="{ 'is-invalid': form.errors.has('current_password') }"
                                            >
                                            <has-error :form="form" field="current_password"></has-error>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="new_password" class="col-sm-2 control-label">New Password</label>

                                        <div class="col-sm-12">
                                            <input type="password"
                                                v-model="form.new_password"
                                                class="form-control"
                                                id="new_password"
                                                placeholder="New Password"
                                                :class="{ 'is-invalid': form.errors.has('new_password') }"
                                            >
                                            <has-error :form="form" field="new_password"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label for="confirm_password" class="col-sm-2 control-label">Confirm Password</label>

                                        <div class="col-sm-12">
                                            <input type="password"
                                                v-model="form.confirm_password"
                                                class="form-control"
                                                id="confirm_password"
                                                placeholder="Confirm Password"
                                                :class="{ 'is-invalid': form.errors.has('confirm_password') }"
                                            >
                                            <has-error :form="form" field="confirm_password"></has-error>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="col-sm-offset-2 col-sm-12">
                                        <button @click.prevent="updatePassword" type="submit" class="btn btn-success">Update Password</button>
                                        </div>
                                    </div>
                                    </form>
                                </div>
                            <!-- /.tab-pane -->
                            </div>
                            <!-- /.tab-content -->
                        </div><!-- /.card-body -->
                    </div>
                    <!-- /.nav-tabs-custom -->
            </div>
            <!-- end tabs -->
            </div>
        </div>
    </section>
</template>

<script>
    import moment from 'moment'
    import 'vue-good-table/dist/vue-good-table.css'
    import { VueGoodTable } from 'vue-good-table';

    export default {
        data(){
            return {
                form: new Form({
                    id:'',
                    name : '',
                    email: '',
                    password: '',
                    created_at: ''
                }),
                filter : {
                    limit : 10,
                    q : ''
                },
                loadingitem:true,
                columns: [
                        {
                            label: 'Name',
                            field: 'name',
                            width: '200px',
                        },
                        {
                            label: 'Description',
                            field: 'description',
                        },
                        {
                            label: 'Trainer',
                            field: 'trainer',
                            width: '200px',
                        },
                        {
                            label: 'Start',
                            field: 'from',
                            width: '200px',
                        },
                        {
                            label: 'Durasi',
                            field: 'durasi',
                            width: '200px',
                        },
                        {
                            label: 'Assessment',
                            field: 'tgl',
                            width: '200px',
                        },
                        {
                            label: 'Nilai',
                            field: 'nilai',
                            width: '200px',
                        }
                    ],
                rows: [],
            }
        },
        components: {
            VueGoodTable,
        },
        mounted() {
            this.loadData();
        },
        methods:{
            updateInfo(){
                this.$Progress.start();
                if(this.form.password == ''){
                    this.form.password = undefined;
                }
                this.form.put('api/profile')
                .then((data)=>{
                    this.$Progress.finish();
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                })
                .catch((data) => {
                    this.$Progress.fail();

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                });
            },

            updatePassword(){
                this.$Progress.start();
                this.form.post('api/change-password')
                .then((data)=>{
                    //  Fire.$emit('AfterCreate');
                    this.$Progress.finish();
                    this.form.current_password = '';
                    this.form.new_password = '';
                    this.form.confirm_password = '';

                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                })
                .catch(() => {
                    this.$Progress.fail();

                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                });
            },
            searchData(row, col, cellValue, searchTerm){
                this.filter.q = searchTerm.toLowerCase();
                return cellValue.indexOf(this.filter.q);
            },
            onSearch(){
                this.loadData();
            },
            loadData(){
                this.loadingitem = true
                this.$Progress.start();
                const getTrainings = new Promise((resolve) => {
                    axios({
                        method: 'get',
                        params: this.filter,
                        url: `/api/emplpolling/fetchTree`
                    }).then((data) => {
                        this.rows = data.data.data.items;
                        resolve(data);
                    });
                })

                const getProfile = new Promise((resolve) => {
                    axios.get("api/profile")
                    .then(({ data }) => {
                        this.form.fill(data.data);
                        resolve(data);
                    });
                })

                Promise.all([getTrainings,getProfile]).then(() => {
                    this.$Progress.finish();
                    this.loadingitem = false
                });
            }
        },
        watch: {
            'filter.q': function() {
                this.loadData()
            }
        }
    }
</script>
