<template>
    <div class="panel__body pt-4 ml-2 mr-2">
        <div class="form">
            <Calendar/>
        </div>
    </div>
</template>
<script>

import Calendar from './Calendar'
export default {
    components: {
        Calendar
    }
}
</script>
