<template>
    <div class="panel__body pt-4 ml-4">
        <div class="form">
            <BranchForm :preparation="preparation" :grade="grade" :state="true"></BranchForm>

            <div class="row mb-6">
                <div class="col-12 mb-2">
                    <button @click="submit" :disabled="preparation" class="btn btn-secondary btn-lg mr-2">Save</button>
                    <router-link :to="{ name : 'grade.data'}" class="btn btn-outline-primary btn-lg">Back</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BranchForm from './Form'

export default {
    data(){
        return {
            preparation: true,
            grade: new Form({
                id:'',
                name: '',
            })
        }
    },
    created() {
        const vm = this
        this.grade.reset();
        const getGradeData = new Promise((resolve) => {
            axios.get(`/api/grade/fetch/${this.$route.params.id}`).then((data) => {
                resolve(data.data.data);
                this.grade.fill(data.data.data);
            });
            resolve()
        })
        Promise.all([getGradeData]).then(() => {
            vm.preparation = false
        })
    },
    methods: {
        submit() {
            this.preparation = true;
            this.grade.submit('post', '/api/grade/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'grade.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                this.preparation = false;
            });
        }
    },
    components: {
        BranchForm
    }
}
</script>