<template>
    <div class="container-fluid" v-if="!preparation">
        <b-form class="form" @submit.prevent="$emit('submit',datauser)">
        <b-row class="align-items-center pb-1">
            <b-col  lg="3" class="mr-5">
                <h3 class="login-box__title text-primary mb-6 mb-lg-15">{{ time }}</h3>
                <div class="separator mb-6 mt-2"></div>
                <video v-show="false"
                    ref="video"
                    id="video"
                    width="320"
                    height="247"
                    autoplay
                />
                <canvas
                    class="border border-primary"
                    ref="canvas"
                    id="canvas"
                    width="320"
                    height="247"
                />
                <b-form-group v-show="!has_absen">
                    <b-button class="ml-3 btn btn-block bg-gradient-primary" @click.prevent="absen" type="button" variant="primary" block>Absensi</b-button>
                </b-form-group>
            </b-col>
            <b-col  lg="6" class="mt-2">
                <b-form-group label="Latitude" label-for="lat">
                    <b-form-input :disabled="true" :value="shifting.latitude" readonly id="lat"></b-form-input>
                </b-form-group>
                <b-form-group label="Longitude" label-for="long">
                    <b-form-input :disabled="true" :value="shifting.longitude" readonly id="long"></b-form-input>
                </b-form-group>
                <b-form-group :label="`Jarak(m) dari ${shifting.branch ? shifting.branch.name : curuser.employee.branch.name}`" label-for="jarak">
                    <b-form-input :disabled="true" :value="shifting.jarak" readonly  id="long"></b-form-input>
                </b-form-group>
                <b-form-group label="Address" label-for="address">
                    <b-form-textarea id="address" :disabled="true" :value="shifting.address" readonly placeholder="Alamat ketika absensi." />
                </b-form-group>
                
            </b-col>
            
        </b-row>    
        </b-form>
        <div class="timeline">
            <div class="time-label">
                <span class="bg-green">{{ shifting.employee ? shifting.employee.name + '(' + shifting.employee.nik + ')' : curuser.employee.name + '(' + curuser.employee.nik + ')' }}</span>
            </div>
            <div>
                <i class="fas fa-envelope bg-blue"></i>
                <div class="timeline-item">
                    <span class="time"><i class="fas fa-clock"></i> {{ shifting.employee ? shifting.shift : 'Unscheduled' }}</span>
                    <h3 class="timeline-header"><a href="#">{{ !shifting.employee ? 'Doesnt has schedule at' : 'Schedule at' }}  {{ shifting.tanggal }}</a></h3>
                    <div class="timeline-body">
                        Base : {{ shifting.employee ? shifting.employee.branch.name : curuser.employee.branch.name  }} <br/>
                        {{ shifting.employee ? `Assigned to work in : ${shifting.branch.name}` : '' }} <br/>
                        {{ shifting.employee ? 'On shift : ' + shifting.shift : '' }}
                    </div>
                </div>
            </div>
            <div>
                <i class="fas fa-user bg-green"></i>
                <div class="timeline-item">
                    <span class="time"><i class="fas fa-clock"></i> {{ shifting.actual ?? 'absen' }}</span>
                    <h3 class="timeline-header"><a href="#">Attendant</a></h3>
                    <div class="timeline-body">
                        Clock in : {{ shifting.actual ?? '' }} <br/>
                        Address : {{ shifting.actual ? shifting.address : '' }} <br/>
                        Position : {{  shifting.actual ? '('+ shifting.latitude + ', ' + shifting.longitude + ')' : '' }} <br/>
                        Distance from point assigned : {{  shifting.actual ? shifting.jarak : '-' }} meters
                    </div>
                </div>
            </div>
            <div>
                <i class="fas fa-clock bg-gray"></i>
            </div>
        </div>
    </div>     
    <div v-else>
        <form-loader></form-loader>
    </div>    
</template>
<script>
import axios from 'axios'
import moment from 'moment';
import haversine from 'haversine-distance'
import { eventBus } from '../../../app.js'

export default {
    data() {
        return {
            videostream:null,
            preparation: true,
            has_absen : true,
            interval: null,
            timeinterval: null,
            time: null,
            curuser : window.user,
            shifting : new Form({
                id:'',
                employee_id: window.user.employee.id,
                branch_id: window.user.employee.branch_id,
                tanggal: moment().format("YYYY-MM-DD"),
                shift: 'shift_1',
                tahun: moment().format("YYYY"),
                bulan: moment().format("M"),
                latitude : '',
                longitude : '',
                address : '',
                jarak : '',
                files : ''
            }),
            geolocationSupported: 'geolocation' in navigator,
            fps: 60,
            withOptions: [0, 1, 2, 3]
        }
    },
    mounted () {
        const vm = this
        vm.$nextTick(() => {
            if (!vm.preparation) vm.takePhoto()
        });
    },
    created() {
        const vm = this
        vm.timeinterval = setInterval(() => {
            this.time = Intl.DateTimeFormat(navigator.language, {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            }).format()
        }, 1000)

        vm.preparation = true;
        vm.$Progress.start();
        vm.shifting.reset();
        const getAbsenData = new Promise((resolve,reject) => {
            axios({
                method: 'get',
                params: { nik : window.user.employee.nik,
                          tanggal : moment().format("YYYY-MM-DD"),
                },
                url: `/api/shifting/freefetch`
            }).then((data) => {
                if (data.data.code == 200){
                    vm.shifting.fill(data.data.data)
                    vm.shifting.id = data.data.data.id;
                    vm.shifting.actual = data.data.data.actual;
                    vm.shifting.employee_id  = data.data.data.employee_id
                    vm.shifting.branch_id  = data.data.data.branch_id
                    vm.shifting.tanggal  = data.data.data.tanggal
                    vm.shifting.shift  = data.data.data.shift
                    vm.shifting.tahun  = data.data.data.tahun
                    vm.shifting.bulan  = data.data.data.bulan
                    vm.shifting.branch  = data.data.data.branch
                    vm.shifting.employee  = data.data.data.employee
                    
                    if (!vm.shifting.actual){
                        vm.shifting.latitude  = data.data.data.latitude
                        vm.shifting.longitude  = data.data.data.longitude
                        vm.shifting.address  = data.data.data.address
                        vm.shifting.jarak  = data.data.data.jarak
                    }
                    
                    vm.has_absen = vm.shifting.actual ? true : false;
                    
                    
                    if (vm.geolocationSupported){
                        let options = {
                            timeout: 10000
                        };
                    
                        // browser prompts for permission
                        navigator.geolocation.getCurrentPosition(
                        getPositionCallBack,
                        reject,
                        options
                        );
                    
                        function getPositionCallBack(position) {
                            vm.shifting.latitude = position.coords.latitude
                            vm.shifting.longitude = position.coords.longitude
                            try {
                                const FORMAT = 'jsonv2';
                                let token = document.head.querySelector('meta[name="csrf-token"]');

                                axios({
                                    baseURL: 'https://nominatim.openstreetmap.org',
                                    headers: {
                                            'X-CSRF-TOKEN': token.content
                                    },
                                    url: '/reverse',
                                    params: {
                                        format: FORMAT,
                                        lat: position.coords.latitude,
                                        lon: position.coords.longitude
                                        }
                                }).then((response) => {
                                    vm.shifting.address = response.data.display_name
                                    const a = { lat: position.coords.latitude, lng: position.coords.longitude }
                                    const b = { lat: vm.shifting.id ? vm.shifting.branch.latitude : position.coords.latitude, lon: vm.shifting.id ? vm.shifting.branch.longitude : position.coords.longitude }
                                    vm.shifting.jarak = haversine(a, b).toFixed(2); //in meter
                                })
                            } catch (err) {
                                reject
                                throw new Error(err);
                            }
                        }
                    }
                }else{
                    Toast.fire({
                        icon: 'error',
                        title: data.data.message
                    });
                }

                resolve(data.data.data);
            }).catch((error) => {
                eventBus.$emit('read_notification',{message : 'Tidak ada schedule untuk anda pada hari ini'});
                    
                if (vm.geolocationSupported){
                    let options = {
                        timeout: 10000
                    };
                
                    // browser prompts for permission
                    navigator.geolocation.getCurrentPosition(
                    getPositionCallBack,
                    reject,
                    options
                    );
                
                    function getPositionCallBack(position) {
                        vm.shifting.latitude = position.coords.latitude
                        vm.shifting.longitude = position.coords.longitude
                        try {
                            const FORMAT = 'jsonv2';
                            let token = document.head.querySelector('meta[name="csrf-token"]');

                            axios({
                                baseURL: 'https://nominatim.openstreetmap.org',
                                headers: {
                                        'X-CSRF-TOKEN': token.content
                                },
                                url: '/reverse',
                                params: {
                                    format: FORMAT,
                                    lat: position.coords.latitude,
                                    lon: position.coords.longitude
                                    }
                            }).then((response) => {
                                vm.shifting.address = response.data.display_name
                                const a = { lat: position.coords.latitude, lng: position.coords.longitude }
                                const b = { lat: vm.shifting.id ? vm.shifting.branch.latitude : position.coords.latitude, lon: vm.shifting.id ? vm.shifting.branch.longitude : position.coords.longitude }
                                vm.shifting.jarak = haversine(a, b).toFixed(2); //in meter
                            })
                        } catch (err) {
                            reject
                            throw new Error(err);
                        }
                    }
                }
                resolve(false)
            });
        })

        Promise.all([getAbsenData]).then(() => {
            this.preparation = false;
            vm.takePhoto();
        });

        this.$Progress.finish();
    },
    beforeDestroy () {
        if (this.interval) clearInterval(this.interval)
        if (this.timeinterval) clearInterval(this.timeinterval)
    },
    methods: {
        loadlocation(){
            
        },
        rowHighlight(item, type) {
            if (!item || type !== 'row') return 'highlight'
        },
        async startCamera () {
            if (!this.videostream &&
                navigator &&
                navigator.mediaDevices &&
                navigator.mediaDevices.getUserMedia) {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true })
                .catch((e) => {
                    throw new Error(e)
                })
                this.videostream = stream;
                return stream
            } else {
                throw new Error('This browser doesn\'t support WebRTC')
            }
        },

        stopCamera () {
            if (this.videostream) {
                this.videostream.getTracks().forEach(track => track.stop())
                this.videostream = null
            }
        },

        async takePhoto() {
            const vm = this
            if (vm.videostream) vm.stopCamera();

            await vm.startCamera().then(stream => {
                const video = document.getElementById("video")
                const canvas = document.getElementById("canvas")
                const canvasCtx = canvas.getContext("2d")
                video.srcObject = stream
                vm.interval = setInterval(async () => {
                    canvasCtx.drawImage(video, 0, 0, 320, 247)
                }, vm.fps / 1000)
            })
        },

        absen() {
            const vm = this
            vm.$Progress.start();
            //const download = document.getElementById("downloadPhoto"); 
            const canvas = document.getElementById("canvas");
            const content = canvas.toDataURL("image/jpeg"); //.replace("image/jpeg", "image/octet-stream");
            if (content){
                //download.setAttribute("href", content);
                let block = content.split(";");
                let contentType = block[0].split(":")[1];// In this case "image/gif"
                let realData = block[1].split(",")[1];
                vm.shifting.files = vm.b64toBlob(realData, contentType);
                vm.shifting.actual = moment().format('YYYY-MM-DD HH:mm:ss')
                vm.shifting.submit('post', '/api/shifting/store', {
                transformRequest: [function (data, headers) {
                        let mdata = new FormData();

                        for ( var key in data ) {
                            if (key == 'files'){
                                mdata.append(`${key}`, data[key]);
                            }else{
                                mdata.set(`${key}`,data[key]);
                            }
                        }
                        return mdata;
                }],
                }).then((response) => {
                    vm.$router.push({name: 'absensi'}, function() {
                        Toast.fire({
                            icon: 'success',
                            title: response.data.message
                        });
                    })
                    this.$Progress.finish();
                }).catch((e) => {
                    Toast.fire({
                        icon: 'error',
                        title: e.message
                    });
                    this.$Progress.finish();
                });
            }
        },

        b64toBlob(b64Data, contentType, sliceSize) {
            contentType = contentType || '';
            sliceSize = sliceSize || 512;

            var byteCharacters = atob(b64Data); // window.atob(b64Data)
            var byteArrays = [];

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            var blob = new Blob(byteArrays, {type: contentType});
            return blob;
        },
    }
}
</script>