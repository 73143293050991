<template>
    <div class="container-fluid" v-if="!preparation">
        <b-row class="align-items-center pb-1">
            <b-col  lg="8">
                <router-link v-if="$gate.hasAccess('polling.create')" :to="{ name: 'polling.create'}" class="btn btn-primary btm-sm mt-1">Create New</router-link>
            </b-col>
            <b-col  lg="4">
                <b-form class="form form-inline form--rounded justify-content-end btn-sm" @submit.prevent="getBranch()">
                    <b-form-group v-if="checked.length && $gate.hasAccess('polling.delete')">
                        <b-button @click="bulkDelete" class="btn btn-danger btm-sm" size="sm"><i class="fa fa-trash red"></i>Bulk Delete</b-button>
                    </b-form-group>
                    
                    <b-form-group class="form-inline ml-3" >
                        <b-input-group size="sm">
                            <b-form-input type="search" class="form-control form-control-navbar" v-model="filter.q" placeholder="Search" aria-label="Search" />
                            <div class="input-group-append">
                                <button class="btn btn-navbar" type="submit" >
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                            
                        </b-input-group>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>

        <b-table
            id="polling-table"
            class="table--middle table--primary mt-2" show-empty responsive
            :items="pollings.items"
            :fields="tableFields"
            :tbody-tr-class="rowHighlight"
            :busy.sync="preparation">
            <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
                </div>
            </template>
            <template v-slot:empty><div class="text-center text-muted">Data not found</div></template>

            <template v-slot:head(check)>
                <div class="checkbox checkbox--all">
                    <input id="check-all" v-model="checkall" type="checkbox">
                    <label for="check-all"></label>
                </div>
            </template>

            <template v-slot:cell(no)="data">{{ ((page > 0 ? (page - 1) : 0) * filter.limit) + (data.index +1 ) }}</template>
            <template v-slot:cell(periode_id)="data">
                <router-link v-if="data.item.periode_id && $gate.hasAccess('periode.update')" :to="{ name: 'periode.update', params: {id: data.item.periode_id} }" class="font-weight-medium text-primary" style="box-sizing: content-box;height: 17px;">
                    {{ data.item.periode.name }}
                </router-link>
                <span v-else>{{ data.item.periode.name }}</span>
            </template>
            <template v-slot:cell(peserta)="data">
                <div class="description-block">
                    <h5 class="description-header">{{ data.item.employees_count }}</h5>
                </div>
            </template>
            <template v-slot:cell(type)="data">
                <div class="description-block">
                    <h5 class="description-header">{{ data.item.type }}</h5>
                </div>
            </template>
            <template v-slot:cell(waktu)="data">
                <div class="description-block">
                    <h5 class="description-header">{{ data.item.from }}</h5>
                    <span class="description-text">{{ calc_time(data.item.from,data.item.to) }}</span>
                </div>
            </template>
            <template v-slot:cell(is_active)="data">
                <ToggleButton v-if="$gate.hasAccess('polling.update')" class="mb-0" @change="(e) => setActive(e, data.item.id)" :value="data.value ? true : false" :width="80" :labels="{checked: 'Aktif', unchecked: 'Non-aktif'}"></ToggleButton>
                <span v-else>{{ data.item.is_active ? 'Active' : 'Inactive'}}</span>
            </template>
            <template v-slot:cell(action)="data">
                <div class="btn-group">
                    <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                <i class="fas fa-wrench"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" role="menu" x-placement="bottom-end" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-124px, 18px, 0px);">
                        <a class="dropdown-item" v-if="$gate.hasAccess('polling.question')" @click="OpenPolling(data.item.id, data.item.name,'question')">
                            <i class="nav-icon fas fa-list orange"></i> Materi
                        </a>
                        <a class="dropdown-item" v-if="$gate.hasAccess('polling.participant')" @click="OpenPolling(data.item.id, data.item.name,'participant')">
                            <i class="nav-icon fas fa-users orange"></i> Participant
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" v-if="$gate.hasAccess('polling.update')">
                            <router-link  :to="{ name: 'polling.update', params: {id: data.item.id} }" style="box-sizing: content-box;height: 17px;"><i class="fas fa-edit"></i> Edit</router-link>
                        </a>
                        <a class="dropdown-item" v-if="$gate.hasAccess('emplpolling.update')" @click="uploadPolling(data.item.id, data.item.name)">
                            <i class="nav-icon fas fa-list orange"></i> Penilaian
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" v-if="$gate.hasAccess('polling.delete')"  @click="removePolling(data.item.id)" variant="danger">
                            <i class="fas fa-times"></i> Delete
                        </a>
                    </div>
                </div>  
            </template>

            <template v-slot:cell(check)="data">
                <div class="checkbox">
                    <input :id="`checkbox-${data.item.id}`" :value="data.item.id" v-model="checked" type="checkbox">
                    <label :for="`checkbox-${data.item.id}`"></label>
                </div>
            </template>
        </b-table>

        <b-row>
            <b-col lg="6">
                <div class="form form--small form-inline justify-content-center justify-content-lg-start">
                    <b-form-group label-class="text-primary mr-1" label="GO TO " label-for="page">
                        <b-form-input type="number" v-model="page" id="page" class="form-control"></b-form-input>
                    </b-form-group>
                    <b-form-group label-class="text-primary ml-2 mr-2" label="VIEW " label-for="filter_limit">
                        <select2 id="filter_limit" v-model="filter.limit" :options="[10, 20, 30]" :settings="{minimumResultsForSearch: -1}" />
                    </b-form-group>
                </div>
            </b-col>
            <b-col lg="6">
                <b-pagination
                    align="right"
                    v-model="page"
                    :total-rows="pollings.total"
                    :per-page="filter.limit"
                    aria-controls="polling-table">
                </b-pagination>
            </b-col>
        </b-row>   
        
        <BModal id="polling_modal" body-class="pt-4 pb-0" size="lg" centered :title="`Penilaian ${name}`">
            <b-row class="align-items-center pb-1 ml-1 mr-1">
                <b-col lg="12">
                    <Detail :pollingid="id"></Detail>
                </b-col>
            </b-row>
            <!--
            <form enctype="multipart/form-data" @submit.prevent="editmode ? updateProduct() : createProduct()">
                <div class="modal-body">
                    <b-row>
                        <b-col>
                            <div class="form-group">
                                <label>File Penilaian</label>
                                <uploader name="upload_file" description="Maks 2MB dengan ekstensi (xls, xlsx)." accept=".xls,.xlsx" v-bind:files.sync="upload_fields.files" id="upload_file" :class="{ 'is-invalid': upload_fields.errors.has('files') }"/>
                                <has-error :form="upload_fields" field="files"></has-error>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <b-button @click.prevent="uploadNilai" class="btn btn-success">Upload</b-button>
                    <export-excel  class="btn btn-primary" 
                        :data  = "[{ name : 'diisi dengan nama product',
                                        code : 'diisi dengan code product, pastikan code sama dengan POS',
                                        description : 'diisi dengan deskripsi produk',
                                        price : 'diisi dengan harga product example : 32500.50',
                                        category_id : 'diisi dengan ID category atau code category yang terdapat dalam aplikasi',
                                        tags : 'disi dengan code tags yang terdapat dalam aplikasi, untuk multiple tags, gunakan tanda koma sebagai pemisah. example: LNR,BOX,AGRI'
                                }]">
                        <i class="fa fa-download red"></i> Template
                    </export-excel>
                </div>
                </form>
                -->
        </BModal>

        <BModal id="item_modal" body-class="pt-4 pb-0" size="lg" centered :title="`${type} for ${name}`">
            <b-row class="align-items-center pb-1">
                <b-col  lg="8">
                    <treeselect 
                        :options="type == 'question' ? currquestions : curremployees"
                        :show-count="true"
                        :default-expand-level="1"
                        v-model="multivalue"
                        :disable-branch-nodes="true"
                        search-nested
                        :value-consists-of="'LEAF_PRIORITY'"
                        :valueFormat="'object'"
                        placeholder="Choose"
                        :multiple="true"
                    />
                </b-col>
                <b-col lg="2">
                    <b-button class="text-right btn btn-primary btm-sm" size="sm" @click="InsertObject()"><i class="fa fa-plus red"></i>Add Item</b-button>
                </b-col>
                <b-col lg="2" v-if="checkeditem.length">
                    <b-button @click="bulkDeleteitem" class="text-right btn btn-danger btm-sm" size="sm"><i class="fa fa-trash red"></i>Bulk Delete</b-button>
                </b-col>
            </b-row>

            <div v-if="! isLoading">
                <b-table
                    id="question-table"
                    class="table--middle table--primary mt-2" show-empty responsive
                    :items="type == 'question' ? allquestion : allemployee"
                    :fields="QuestionFields"
                    :tbody-tr-class="SelectionRowHighlight"
                    :busy.sync="isLoading"
                    style="overflow: visible !important">
                    <template v-slot:table-busy>
                        <div class="text-center text-primary my-2">
                            <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
                        </div>
                    </template>
                    <template v-slot:head(check)>
                        <div class="checkbox checkbox--all">
                            <input id="check-all" v-model="checkitem" type="checkbox">
                            <label for="check-all"></label>
                        </div>
                    </template>
                    <template v-slot:empty><div class="text-center text-muted">Data not found</div></template>
                    <template v-slot:cell(no)="data">{{ (data.index +1 ) }}</template>
                    
                    <template v-slot:cell(question_id)="data">
                        <treeselect v-if="selectionindex == data.index" 
                            :options="type == 'question' ? currquestions : curremployees"
                            :show-count="true"
                            :default-expand-level="1"
                            v-model="value"
                            :disable-branch-nodes="true"
                            search-nested
                            :valueFormat="'object'"
                            @input="updateValue"
                            placeholder="Choose"
                        />
                        <span v-else>{{ type == 'question' ? (data.item.question ? data.item.question.question : '') : (data.item.employee ? data.item.employee.name : '') }}</span>
                    </template>

                    <template v-slot:cell(action)="data">
                        <b-button v-if="data.index !== selectionindex" @click="edit(data.index)" variant="outline-primary" size="xs">Edit</b-button>
                        <b-button v-if="data.index == selectionindex" @click="cancel()" variant="outline-primary" size="xs">Cancel</b-button>
                        <b-button @click="remove(data.index)" variant="outline-danger" size="xs">Delete</b-button>
                    </template>

                    <template v-slot:cell(check)="data">
                        <div class="checkbox">
                            <input :id="`checkbox-${data.item.id}`" :value="data.index" v-model="checkeditem" type="checkbox">
                            <label :for="`checkbox-${data.item.id}`"></label>
                        </div>
                    </template>
                </b-table>
            </div>
            
            <p v-else>Loading...</p>

            <template slot="modal-footer" class="mt-6">
                <b-button variant="secondary" v-if="type == 'question' ? $gate.hasAccess('polling.question'): $gate.hasAccess('polling.participant')" @click="save()">Save</b-button>
            </template>
        </BModal>

    </div>      
    <div v-else>
        <form-loader></form-loader>
    </div>
</template>
<script>
import Detail from './Detail'
import moment from 'moment';
export default {
    components: {
        Detail
    },
    data() {
        return {
            preparation: true,
            isLoading:true,
            pollings:{},
            multivalue : [],
            value:{
                id:'',
                label:''
            },
            mpage:1,
            id:'',
            name:'',
            type:'',
            checkall: false,
            checkitem:false,
            checked: [],
            checkeditem: [],
            selectionindex : -1,
            allemployee:[],
            allquestion:[],
            employees:[],
            questions:[],
            nilaipolling:[],
            curremployees:[],
            currquestions:[],
            employee_pollings:[],
            question_pollings:[],
            filter: {
                q: '',
                limit: 30
            },
            upload_fields: new Form({
                  files: ''
            }),
            tableFields: [
                { key: 'no', label: 'No.', tdClass: 'text-right', thAttr: { width: '50' } },
                { key: 'name', label: 'Name' , thAttr: { width: '160' } },
                { key: 'description', label: 'Description', thAttr: { width: '160' } },
                { key: 'trainer.name', label: 'Trainer', thAttr: { width: '160' } },
                { key: 'peserta', label: 'Peserta', thAttr: { width: '90' } },
                { key: 'waktu', label: 'Waktu', thAttr: { width: '160' } },
                { key: 'type', label: 'Type', thAttr: { width: '100' } },
                { key: 'is_active', label: 'Active', thAttr: { width: '100' } },
                { key: 'periode_id', label: 'Periode', thAttr: { width: '100' } },
                { key: 'action', tdClass: 'text-center', thAttr: { width: '30' }},
                { key: 'check', thAttr: { width: '30' } , tdClass: 'text-center stick-end', thClass: 'stick-end' }
            ],
            QuestionFields: [
                { key: 'no', label: 'No.', tdClass: 'text-right', thAttr: { width: '50' } },
                { key: 'question_id', label: 'Element of Polling' },
                { key: 'action', label: 'Action', tdClass: 'text-center', thAttr: { width: '160' } },
                { key: 'check', thAttr: { width: '30' } , tdClass: 'text-center stick-end', thClass: 'stick-end' }
            ]
        }
    },
    created(){
        const vm = this;

        const getAllEmployeeData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/branch/fetch/all`,
                params: {groupby : 'employees'}
            }).then((data) => {
                vm.employees = data.data.data.items.map((branch) => {
                                    return {
                                        id: 'branch'+branch.id,
                                        label: branch.name,
                                        children : this.prosesarray(branch.employees)
                                    }
                                });
                resolve(data);
            });
        })

        const getAllQuestionData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/questiongroup/fetch/all`
            }).then((data) => {
                vm.questions = data.data.data.items.map((question) => {
                                    return {
                                        id: 'group'+question.id,
                                        label: question.name,
                                        children : this.prosesarrayQuestion(question.children)
                                    }
                                });
                                resolve(data);
            });
        })
        Promise.all([getAllEmployeeData,getAllQuestionData]).then(() => {
            this.getPolling();
        });
    },
    computed: {
        page: {
            get() {
                return this.mpage;
            },
            set(val) {
                this.mpage = val;
            }
        }
    },
    methods: {
        setActive(data, id) {
            let formData = {
                id: id,
                is_active: data.value ? 1 : 0
            }
            
            return new Promise((resolve, reject) => {
                axios.post(`/api/polling/setactive`, formData)
                .then((response) => {
                     Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    resolve(response.data)
                })
            });
        },
        InsertObject(){
            if (this.multivalue){
                this.selectionindex = -1;
                this.multivalue.forEach(emplorquest => {
                    if (this.type == 'question'){
                        this.allquestion.unshift({id:'',question_id:emplorquest.id,polling_id:this.id, question : {id : emplorquest.id, question : emplorquest.label}});
                    }else{
                        this.allemployee.unshift({id:'',employee_id:emplorquest.id,polling_id:this.id, employee : {id : emplorquest.id, name : emplorquest.label}});
                    }
                });
                this.reloaditem()
            }
        },
        calc_time(from,to){
            var res = '';
            if (moment(from).isBefore(moment(to))){
                var matchDate = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
                var firstDateParsed = matchDate.exec(moment(from).format('YYYY-MM-DD HH:mm:ss'));
                var secondDateParsed = matchDate.exec(moment(to).format('YYYY-MM-DD HH:mm:ss'));

                var b = new Date(firstDateParsed[1], firstDateParsed[2], firstDateParsed[3], firstDateParsed[4], firstDateParsed[5], firstDateParsed[6], 0);
                var a = new Date(secondDateParsed[1], secondDateParsed[2], secondDateParsed[3], secondDateParsed[4], secondDateParsed[5], secondDateParsed[6], 0);
                var differenceInMilliseconds = a.getTime() - b.getTime();
                
                const days = Math.floor(differenceInMilliseconds / (24*60*60*1000));
                const daysms = differenceInMilliseconds % (24*60*60*1000);
                const hours = Math.floor(daysms / (60*60*1000));
                const hoursms = differenceInMilliseconds % (60*60*1000);
                const minutes = Math.floor(hoursms / (60*1000));
                const minutesms = differenceInMilliseconds % (60*1000);
                const sec = Math.floor(minutesms / 1000);
                res += (days ? days + " Day : " : "");
                res += (hours ? hours + " Hour : " : ""); 
                res += (minutes ? minutes + " Minute : " : "");
                res += (sec ? sec + " Second" : "") ; 
            }
            return res;
        },
        updateValue(val){
            Toast.fire({
                icon: 'success' ,
                title: 'This Item will remove from your next choices'
            });

            if (this.type == 'question'){
                if (val){
                    this.allquestion[this.selectionindex].question_id = val.id
                    this.allquestion[this.selectionindex].question = {id : val.id, question : val.label}
                    this.selectionindex = -1
                }
            }else{
                if (val){
                    this.allemployee[this.selectionindex].employee_id = val.id
                    this.allemployee[this.selectionindex].employee = {id : val.id, name : val.label}
                    this.selectionindex = -1
                }
            }
            this.reloaditem()
        },
        prosesarray(array){
            return array.map(element => {
                return {
                        id: element.id,
                        label: element.first_name + ' ' + element.last_name
                    }
            });
        },
        reloaditem(){
            const vm = this
            vm.curremployees = vm.employees.map((branch) => {
                let cek = branch.children.filter((employee) => {
                    return vm.allemployee.findIndex((empl) => empl.employee_id === employee.id) ? false : true
                });

                if (cek){
                    return {
                        id : branch.id,
                        label : branch.label,
                        children : cek
                    }
                }
            })
            
            vm.currquestions = vm.questions.map((questiongroup) => {
                let cek = questiongroup.children.filter((question) => {
                    return this.allquestion.findIndex((quest) => quest.question_id === question.id) ? false : true
                })
                
                if (cek){
                    return {
                        id : questiongroup.id,
                        label : questiongroup.label,
                        children : cek
                    }
                }
            })
            vm.currquestions.filter(function (el) {
                return el != null;
            });
            vm.curremployees.filter(function (el) {
                return el != null;
            });
        },
        prosesarrayQuestion(array){
            return array.map(element => {
                return {
                        id: element.id,
                        label: element.question
                    }
            });
        },
        uploadPolling(id,name){
            const vm = this;
            this.id = id;
            this.name = name;
            this.$bvModal.show('polling_modal')
        },
        /*
        uploadNilai(){
            const vm = this
            this.upload_fields.submit('post', '/api/emplpolling/upload', {
                transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                  Toast.fire({
                      icon: 'success',
                      title: response.data.message
                  });
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
            });
        },
        */
        OpenPolling(id,name,type){
            const vm = this;

            this.id = id;
            this.name = name;
            this.type = type;
            this.isLoading = true;
            this.$bvModal.show('item_modal')

            const getPollingData = new Promise((resolve) => {
                axios.get(`/api/polling/fetch/${this.id}`).then((data) => {
                    this.allemployee = data.data.data.employee_pollings ;
                    this.allquestion = data.data.data.question_pollings;
                    vm.reloaditem()
                    resolve(data.data.data);
                });
                resolve()
            })
            Promise.all([getPollingData]).then(() => {
                vm.isLoading = false;
                this.selectionindex = -1
            });
        },
        edit(index){
            this.selectionindex = index
        },
        cancel(){
            this.selectionindex = -1
        },
        remove(index){
            Swal.fire({
            title: 'Are you sure?',
            text: "Click Save to finalize deletion!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, Temporary delete it!'
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    if (this.type == 'question'){
                        this.allquestion.splice(index, 1);
                    }else{
                        this.allemployee.splice(index, 1);
                    }
                    this.reloaditem()
                    this.selectionindex = -1
                }
            });
        },
        save(){
            const vm = this;

            let formData = {
                polling_id: this.id,
                type : this.type,
                items: this.type == 'question' ? this.allquestion : this.allemployee
            }
            
            return new Promise((resolve, reject) => {
                axios.post(`/api/polling/update-${this.type}`, formData)
                .then((response) => {
                    this.selectionindex = -1
                    vm.$bvModal.hide('item_modal')
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    resolve(response.data)
                })
            })
        },
        SelectionRowHighlight(item, type) {
            if (!item || type !== 'row') return
            if (item.index === this.selectionindex) return 'highlight'
        },
        rowHighlight(item, type) {
            if (!item || type !== 'row') return
            if (item.id === this.$route.params.row_id) return 'highlight'
        },
        getPolling(){
            this.preparation = true;
            this.$Progress.start();

            const getPollingData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    params: this.filter,
                    url: `/api/polling/fetch?page=${this.page}`
                }).then((data) => {
                    this.pollings = data.data.data;
                    resolve(data.data.data);
                });
            })
            Promise.all([getPollingData]).then(() => {
                this.preparation = false;
                this.selectionindex = -1
            });
            this.$Progress.finish();
        },       
        bulkDelete() {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    const DeleteData = new Promise((resolve, reject) => {
                        axios.post('/api/polling/bulk-delete', {ids: this.checked})
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            resolve(error.response.data)
                        })
                    })
                    Promise.all([DeleteData]).then((response) => {
                        this.getPollingData()
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    });
                }
            })
        },
        bulkDeleteitem() {
            Swal.fire({
            title: 'Are you sure?',
            text: "Click Save to finalize deletion!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, Temporary delete it!'
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    this.checkeditem.sort().reverse().forEach(index =>{
                        if (this.type == 'question'){
                            this.allquestion.splice(index,1)
                        }else{
                            this.allemployee.splice(index,1)
                        }
                    });
                    this.reloaditem()
                    this.selectionindex = -1
                }
            });
        },
        removePolling(id) {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    const DeleteRoles = new Promise((resolve, reject) => {
                        axios.post('/api/polling/delete', {id: id})
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            resolve(error.data)
                        })
                    });
                    Promise.all([DeleteRoles]).then((response) => {
                        this.getPollingData()
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    });
                }
            });
        }
    },
    watch: {
        'filter.limit': function(id) {
            this.getPolling()
        },
        page() {
            this.checked = []
            this.getPolling()
        },
        checkall(checkall) {
            if ( checkall ) {
                this.pollings.items.forEach(role => {
                    this.checked.push(role.id)
                });
            } else {
                this.checked = []
            }
        },
        checkitem(checkitem) {
            let x = 0
            if ( checkitem ) {
                if (this.type == 'question'){
                    if (this.allquestion){
                        this.allquestion.forEach(role => {
                            this.checkeditem.push(x)
                            x++;
                        });
                    }else{
                        this.checkeditem = []
                    }
                }else{
                    if (this.allemployee){
                        this.allemployee.forEach(role => {
                            this.checkeditem.push(x)
                            x++;
                        });
                    }else{
                        this.checkeditem = []
                    }
                }
            } else {
                this.checkeditem = []
            }
        }
    }
}
</script>