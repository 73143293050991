import Vue from 'vue'
import {remove_tags} from './string.js'
import {eventBus} from '../app.js'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import Toast from '../components/control/Toast'
import icon from '../components/control/Toast'
Vue.use(Toast)

export default class helper {
    prosesarray(array){
        return array.map(element => {
            if (element.children){
                return {
                        id: element.id,
                        label: element.name,
                        children : element.children ? this.prosesarray(element.children) : []
                    }
            }else{
                return {
                    id: element.id,
                    label: element.name
                }
            }
        });
    }
    prosesarraypolling(array,basearr){
        return array.map(element => {
            let cek = basearr.filter((item) => item.parent_polling_id == element.id);
            let hasil = {};
            if (element.children){
                hasil = this.prosesarraypolling(element.children,basearr);
            }else{
                hasil = this.prosesarraypolling(cek,basearr);
            }

            if (cek && hasil.length){
                return {
                        id: element.id,
                        label: element.name,
                        children : hasil
                    }
            }else{
                return {
                    id: element.id,
                    label: element.name
                }
            }
        });
    }
    prosesarrayorg(array,basearr){
        return array.map(element => {
            let cek = basearr.filter((item) => item.parent_organization_id == element.id);
            let hasil = {};
            if (element.children){
                hasil = this.prosesarrayorg(element.children,basearr);
            }else{
                hasil = this.prosesarrayorg(cek,basearr);
            }

            if (cek && hasil.length){
                return {
                        id: element.id,
                        label: element.name,
                        children : hasil
                    }
            }else{
                return {
                    id: element.id,
                    label: element.name
                }
            }
        });
    }
    prosesarrayjob(array,basearr){
        return array.map(element => {
            let cek = basearr.filter((item) => item.parent_job_id == element.id);
            let hasil = {};
            if (element.children){
                hasil = this.prosesarrayjob(element.children,basearr);
            }else{
                hasil = this.prosesarrayjob(cek,basearr);
            }

            if (cek && hasil.length){
                return {
                    id: element.id,
                    label: element.name,
                    children : hasil
                }
            }else{
                return {
                    id: element.id,
                    label: element.name
                }
            }
        });
    }
    prosesarraybranchdisabled(array,basearr,cek){
        return array.map(element => {
            let cek1 = basearr.filter((item) => item.parent_branch_id == element.id);
            let hasil = {};
            if (element.children){
                hasil = this.prosesarraybranchdisabled(element.children,basearr,cek);
            }else{
                hasil = this.prosesarraybranchdisabled(cek1,basearr,cek);
            }

            if (cek1 && hasil.length){
                return {
                        id: element.id,
                        label: element.name,
                        children : hasil
                }
            }else{
                return {
                    id: element.id,
                    label: element.name,
                    isDisabled :cek.find((lst) => lst.branch_id == element.id) ? false : true,
                }
            }
        });
    }
    prosesarraybranchcodedisabled(array,basearr,cek){
        return array.map(element => {
            let cek1 = basearr.filter((item) => item.parent_branch_id == element.id);
            let hasil = {};
            if (element.children){
                hasil = this.prosesarraybranchcodedisabled(element.children,basearr,cek);
            }else{
                hasil = this.prosesarraybranchcodedisabled(cek1,basearr,cek);
            }

            if (cek1 && hasil.length){
                return {
                        id: element.code,
                        label: element.name,
                        children : hasil
                }
            }else{
                return {
                    id: element.code,
                    label: element.name,
                    isDisabled :cek.find((lst) => lst.branch_id == element.id) ? false : true,
                }
            }
        });
    }
    prosesarraybranch(array,basearr){
        return array.map(element => {
            let cek = basearr.filter((item) => item.parent_branch_id == element.id);
            let hasil = {};
            if (element.children){
                hasil = this.prosesarraybranch(element.children,basearr);
            }else{
                hasil = this.prosesarraybranch(cek,basearr);
            }
            
            if (cek && hasil.length){
                return {
                        id: element.id,
                        label: element.name,
                        children : hasil
                }
            }else{
                return {
                    id: element.id,
                    label: element.name
                }
            }
        });
    }       
    prosesarraylocation(array,basearr){
        return array.map(element => {
            let cek = basearr.filter((item) => item.parent_location_id == element.id);
            let hasil = {};
            if (element.children){
                hasil = this.prosesarraylocation(element.children,basearr);
            }else{
                hasil = this.prosesarraylocation(cek,basearr);
            }
            
            if (cek && hasil.length){
                return {
                        id: element.id,
                        label: element.name,
                        children : hasil
                }
            }else{
                return {
                    id: element.id,
                    label: element.name
                }
            }
        });
    }
    prosesarraychanel(array,basearr){
        return array.map(element => {
            let cek = basearr.filter((item) => item.parent_chanel_id == element.id);
            let hasil = {};
            if (element.children){
                hasil = this.prosesarraychanel(element.children,basearr);
            }else{
                hasil = this.prosesarraychanel(cek,basearr);
            }
            
            if (cek && hasil.length){
                return {
                        id: element.id,
                        label: element.name,
                        children : hasil
                }
            }else{
                return {
                    id: element.id,
                    label: element.name
                }
            }
        });
    }
    prosesarraychanelcode(array,basearr){
        return array.map(element => {
            let cek = basearr.filter((item) => item.parent_chanel_id == element.id);
            let hasil = {};
            if (element.children){
                hasil = this.prosesarraychanelcode(element.children,basearr);
            }else{
                hasil = this.prosesarraychanelcode(cek,basearr);
            }
            
            if (cek && hasil.length){
                return {
                        id: element.code,
                        label: element.name,
                        children : hasil
                }
            }else{
                return {
                    id: element.code,
                    label: element.name
                }
            }
        });
    }            
    grantPermission() {
        if (!('Notification' in window)) {
          alert('This browser does not support system notifications');
          return;
        }
    
        if (Notification.permission === 'granted') {
            new Notification('You are already subscribed to web notifications on agrinesia raya');
            return;
        }
    
        if ( Notification.permission !== 'denied' || Notification.permission === 'default' ) {
          Notification.requestPermission().then(result => {
          if (result === 'granted') {
            new Notification(
                  'You will start receiving notifications from Agrinesia Raya'
              );
            }
          });
        }
    }

    startEcho(id,token) {
        const laravelEcho = new Echo({
          broadcaster: 'pusher',
          key: process.env.MIX_PUSHER_APP_KEY,
          cluster: process.env.MIX_PUSHER_APP_CLUSTER,
          encrypted: true,
          authEndpoint: `/broadcasting/auth`,
          logToConsole: true,
          forceTLS: false,
          csrfToken: `${token}`,
          auth:{
              headers: {
                  //'Authorization': `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiZWU0NDNkOWFhZmRkNzgxMTNmOGM0MTQ1MTRiYWJlMjY3NzA1YTE2YjJmYzQxYjUwZmM0ZmE4YjBjMzk4ZDQyN2Y4OTFjZTgyYmYzYjljZjgiLCJpYXQiOjE2NzA4MzkxMzkuNzkxMTQzLCJuYmYiOjE2NzA4MzkxMzkuNzkxMTUsImV4cCI6MTcwMjM3NTEzOS4yNDczMzYsInN1YiI6IjIiLCJzY29wZXMiOltdfQ.PnjF25pavjvYdnLxy4NH7cfBsz9UEt3EBKVdPtWKWvsMV38jhnNP5ocuETMvgtWEAlN0wkhw8gNT73d5RfPyCGBLskS9rhbKv1NJmlK83qKDa8pzMGarIvn2GvKpUWui8JdJP6yr6pvokBvNxzKYR6z2-TLCDvXDdvSsBTXlVuGCApCfJnrKSbHLJm8XGbD8l1z2hefdCqtqcsf4gTz3pGPBWuuB4Pr4PWwPBxf2E0sJ5KjLoaOFS6ul8AMWwbM_QkO7MPVv7nMEKp21-s_77aXGpEfHR7bbFEEsq7DOeqJxFl_CZM4Omu6ysBQyKoJMmE9tnoiX9dfBXkqvnqSCmUieu5HnKoi0a6S-RRVqjnHEBCkpch3YDqRvSXrKC8F4AQjzb0CJbCa0KdC4uJ51BhV38Im0u8zgKurBrEGcIwPRg1DyKdLr1BYvOAYy2aK-bVpNqIcSJ_8zaXe7H24smR8baP8-Af1OLJqAA02GcZqa13_ZASvCm8qKAAiSbIGcXfUh5vkEM11EULiwZha4xlGLBid4bROLJLsxoBP1Ndz7IRnja0vcivLX-w1n-4vLhQVJU5iEK3dxYrghdlqz45REHNqVlSAjmygHiUK9QjRLCGIb6yaVEP4uOOt7obVa35fEEF4KboQHfrKEpA39Vs-igh50rku9u_n5Tu6r5gs`,
                    'X-CSRF-TOKEN' : `${token}`
                }
          }
        });
        laravelEcho.connector.options.auth.headers['X-CSRF-TOKEN'] = token

        laravelEcho.private('notification.' + id)
          .listen('NotificationEvent', (e) => { // New notification received
            //if (e.notification.to_id === window.user.id){
                this.playSound('/assets/sound/bell.mp3');

                Vue.$toast({
                component: Toast,
                props: {
                    data: e.notification,
                },
                },
                {
                toastClassName: "notification-toast",
                position: "top-left",
                timeout: 15000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.15,
                showCloseButtonOnHover: true,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
                });
                eventBus.$emit('new_notification',e.notification.to_id)
                this.showNotification(e.notification);
            //}
        });
    }

    showNotification(payload) {
        const message = remove_tags(payload.message)
        new Notification('New Notification', {body: message, icon: icon});
        eventBus.$emit('read_notification', {message : message});
        
        if (payload.slug === 'article.detail'){
            eventBus.$emit('load_comment', payload);
        }
        if (payload.slug === 'cekliststore.update'){
            eventBus.$emit('load_checklist', payload);
        }
        //eventBus.$emit('reload_dashboard', payload);
    }

    playSound (payload) {
        if (payload) {
            var audio = new Audio(payload);
            audio.play();
        }
    }
}