<template>
    <div class="container-fluid" v-if="!preparation">
        <b-row class="align-items-center pb-1">
            <b-col lg="6">
                <router-link v-if="$gate.hasAccess('doclist.create')" :to="{ name: 'doclist.create', query:{src:ProcessDocument('new',null)}}" class="btn btn-primary btm-sm mt-1">Create New Document</router-link>
            </b-col>
            <b-col lg="6">
                <b-form class="form form-inline form--rounded justify-content-end btn-sm" @submit.prevent="getDocuments()">
                    <b-form-group class="form-inline" >
                        <b-input-group>
                            <b-form-group v-if="checked.length && $gate.hasAccess('doclist.delete')">
                                <b-button @click="bulkDelete" class="btn btn-danger btm-sm" size="sm"><i class="fa fa-trash red"></i>Bulk Delete</b-button>
                            </b-form-group>
                            <b-form-group>
                                <treeselect
                                    id="action"
                                    :options="[{ id : 'all',label : 'All'},
                                            {id : 'onproses', label : 'On Proses'},
                                            {id : 'approval', label : 'On Approval'},
                                            {id : 'final', label : 'Final'}]"
                                    v-model="filter.action"
                                    :searchable="true"
                                    placeholder="Document status?"
                                    />
                            </b-form-group>
                            <b-form-group >
                                <treeselect
                                    id="expiry"
                                    :options="[{ id : 'all',label : 'All'},
                                            {id : 'expired', label : 'Expire Document'},
                                            {id : 'legal', label : 'Legal Document'}]"
                                    v-model="filter.expiry"
                                    :searchable="true"
                                    placeholder="Expiry state?"
                                    />
                            </b-form-group>
                            <date-range-picker class="justify-content-start" style="min-width:300px;inline-size: fit-content;"
                                :max-date="moment().format('YYYY-MM-DD')"
                                append-to-body
                                auto-apply
                                opens="right"
                                :locale-data="{
                                    monthNames: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'Nopember', 'Desember'],
                                    daysOfWeek: ['Min','Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                                    firstDay: 0
                                }"
                                :ranges="{
                                    'Hari Ini': [moment()._d, moment()._d],
                                    'Minggu Ini': [moment().startOf('week')._d, moment()._d],
                                    'Bulan Ini': [moment().startOf('month')._d, moment()._d],
                                    'Tahun Ini': [moment().startOf('year')._d, moment()._d],
                                }"
                                @update="(date) => updateDate(date)"
                                v-model="dateRange">
                                <template v-slot:input="picker" style="min-width: 350px;">
                                    <div v-if="filter.from && filter.to">
                                        <span>
                                            {{ moment(picker.startDate).format('DD/MM/YYYY') }} - {{ moment(picker.endDate).format('DD/MM/YYYY') }}
                                        </span>
                                        <b-button size="xs" @click.stop="clearDate()" variant="outline-dark"
                                        style="position: absolute; right: 0px; bottom: 0px;">
                                            Clear
                                        </b-button>
                                    </div>
                                    <span v-else>-</span>
                                </template>
                            </date-range-picker>
                
                            <b-form-input type="search" class="form-control form-control-navbar" v-model="filter.q" placeholder="Search" aria-label="Search" />
                            <div class="input-group-append">
                                <button class="btn btn-navbar" type="submit" >
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                        </b-input-group>
                    </b-form-group>
                    
                        
                </b-form>
            </b-col>
        </b-row>

        <b-table
            id="document-table"
            class="table--middle table--primary mt-2" show-empty responsive
            :items="documents.items"
            :fields="tableFields"
            :tbody-tr-class="rowHighlight"
            :busy.sync="preparation">
            <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
                </div>
            </template>
            <template v-slot:empty><div class="text-center text-muted">Data not found</div></template>

            <template v-slot:head(check)>
                <div class="checkbox checkbox--all">
                    <input id="check-all" v-model="checkall" type="checkbox">
                    <label for="check-all"></label>
                </div>
            </template>

            <template v-slot:cell(no)="data">{{ ((page > 0 ? (page - 1) : 0) * filter.limit) + (data.index +1 ) }}</template>

            <template v-slot:cell(action)="data">
                <div class="btn-group" v-if="$gate.hasAccess('doclist.update') && data.item.doc_approvals.filter((item) => filter.employee_id === item.employee_id && item.status == 'waiting')">
                    <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-wrench"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" role="menu" x-placement="bottom-end" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-124px, 18px, 0px);">
                        <router-link v-if="$gate.hasAccess('doclist.update')" :to="{ name: 'doclist.update', query:{src:ProcessDocument('draft',data.item)}}" class="dropdown-item btn btn-primary btm-sm mt-1">
                            <i class="fas fa-edit"></i>Draft
                        </router-link>
                        <a class="dropdown-divider" ></a>
                        <router-link v-if="$gate.hasAccess('doclist.update')" :to="{ name: 'doclist.update', query:{src:ProcessDocument('approve',data.item)}}" class="dropdown-item btn btn-primary btm-sm mt-1">
                            <i class="fas fa-edit"></i>Approve
                        </router-link>
                        <a class="dropdown-divider"></a>
                        <router-link v-if="$gate.hasAccess('doclist.update')" :to="{ name: 'doclist.update', query:{src:ProcessDocument('cancel',data.item)}}" class="dropdown-item btn btn-primary btm-sm mt-1">
                            <i class="fas fa-edit"></i>Cancel
                        </router-link>
                    </div>
                </div>
                <div class="btn-group" v-if="$gate.hasAccess('doclist.delete')">
                    <b-button @click="removeGroup(data.item.id)" class="btn btn-danger btm-sm" size="sm"><i class="fa fa-trash red"></i>Delete</b-button>
                </div>
            </template>
            <template v-slot:cell(no_doc)="data">
                <span class="text-primary font-weight-medium">
                {{ data.item.no_doc  }} <span class="badge badge-warning">{{data.item.doc_version}}</span></span>
            </template>
            <template v-slot:cell(doc_date)="data">
                <span class="text-primary font-weight-medium">{{ moment(parseInt(data.item.doc_date.$date.$numberLong)).format("YYYY-MM-DD")   }}</span>
            </template>
            <template v-slot:cell(status)="data">
                <span class="text-primary font-weight-medium">{{ data.item.status  }}</span>
            </template>
            <template v-slot:cell(progress)="data">
                <span class="text-primary font-weight-medium">{{ data.item.progress  }}</span>
            </template>
            <template v-slot:cell(expired_status)="data">
                <span class="text-primary font-weight-medium">{{ data.item.expired_status  }}</span>
            </template>
            <template v-slot:cell(creator)="data">
                <span class="text-primary font-weight-medium">{{ data.item.creator.name  }}</span><br>
                <span><small>{{ data.item.creator.branch.name + ' ' + data.item.creator.organization.name }}</cite></small></span>
            </template>
            <template v-slot:cell(reviewer)="data">
                <span class="text-primary font-weight-medium">{{ data.item.reviewer.name  }}</span><br>
                <span><small>{{ data.item.reviewer.branch.name + ' ' + data.item.reviewer.organization.name }}</small></span>
            </template>
            <template v-slot:cell(check)="data">
                <div class="checkbox">
                    <input :id="`checkbox-${data.item.id}`" :value="data.item.id" v-model="checked" type="checkbox">
                    <label :for="`checkbox-${data.item.id}`"></label>
                </div>
            </template>
        </b-table>

        <b-row>
            <b-col lg="6">
                <div class="form form--small form-inline justify-content-center justify-content-lg-start">
                    <b-form-group label-class="text-primary mr-1" label="GO TO " label-for="page">
                        <b-form-input type="number" v-model="page" id="page" class="form-control"></b-form-input>
                    </b-form-group>
                    <b-form-group label-class="text-primary ml-2 mr-2" label="VIEW " label-for="filter_limit">
                        <select2 id="filter_limit" v-model="filter.limit" :options="[10, 20, 30]" :settings="{minimumResultsForSearch: -1}" />
                    </b-form-group>
                </div>
            </b-col>
            <b-col lg="6">
                <b-pagination
                    align="right"
                    v-model="page"
                    :total-rows="documents.total"
                    :per-page="filter.limit"
                    aria-controls="document-table">
                </b-pagination>
            </b-col>
        </b-row>
    </div>      
    <div v-else>
        <form-loader></form-loader>
    </div>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'
export default {
    data() {
        return {
            dateRange: {
                startDate: new Date(),
                endDate: new Date()
            },
            doc_type : {},
            name:'',
            selected_id : '',
            preparation: true,
            documents:{},
            mpage:1,
            checkall: false,
            checked: [],
            filter: {
                q: '',
                from : '',
                to : '',
                limit: 30,
                employee_id : window.user.employee_id,
                doc_type_id : this.$route.params.id || 0,
                action : this.$route.query.action ? this.$route.query.action : 'all',
                expiry : this.$route.query.expiry ? this.$route.query.expiry : 'all'
            },
            tableFields: [
                { key: 'no', label: 'No.', tdClass: 'text-right', thAttr: { width: '50' } },
                { key: 'no_doc', label: 'No. Doc' },
                { key: 'doc_date', label: 'Doc. Date' },
                { key: 'status', label: 'Status' },
                { key: 'expired_status', label: 'Expired State' },
                { key: 'progress', label: 'Approval State' },
                { key: 'creator', label: 'Document Creator' },
                { key: 'reviewer', label: 'Document Reviewer' },
                { key: 'action', label: 'Action', tdClass: 'text-center', thAttr: { width: '260' } },
                { key: 'check', thAttr: { width: '30' } , tdClass: 'text-center stick-end', thClass: 'stick-end' }
            ]
        }
    },
    created(){
        const vm = this;
        if (!this.$route.params.id){
            Toast.fire({
                icon: 'error',
                title: 'Please Open from dashboard on link on type document'
            });
        }else{
            const getDocData = new Promise((resolve) => {
                axios.get(`/api/doc/fetch/${id}`).then((data) => {
                    this.doc_type = data.data.data;
                    resolve(data.data.data);
                })
            })
            Promise.all([getDocData]).then(() => {
                this.getDocuments();
            });
        }
    },
    computed: {
        page: {
            get() {
                return this.mpage;
            },
            set(val) {
                this.mpage = val;
            }
        }
    },
    methods: {
        ProcessDocument(status,item){
            if (!item){
                item = {
                    'doc_type_id' : this.filter.doc_type_id,
                    'employee_id' : this.filter.employee_id,
                    'state' : 'draft',
                    'no_doc' : '---DOC NUMBER---'
                };
            }else{
                item.state = status;
            }
            const params = new URLSearchParams(item);
            console.log(this.doc_type.path + '?' + params.toString())
            return (this.doc_type.path + '?' + params.toString());
        },
        clearDate() {
            const vm = this
            vm.filter.from= '';
            vm.filter.to= '';
        },
        updateDate({endDate, startDate}) {
            const vm = this
            vm.filter.from = moment(startDate).format('YYYY-MM-DD');
            vm.filter.to = moment(endDate).format('YYYY-MM-DD');
        },
        rowHighlight(item, type) {
            if (!item || type !== 'row') return
            if (item.id === this.selected_id) return 'highlight'
        },
        getDocuments(){
            this.preparation = true;
            this.$Progress.start();

            const getDocumentData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    params: this.filter,
                    url: `/api/document/fetch?page=${this.page}`
                }).then((data) => {
                    this.documents = data.data.data;
                    resolve(data.data.data);
                });
            })
            Promise.all([getDocumentData]).then(() => {
                this.preparation = false;
            });
            this.$Progress.finish();
        },       
        bulkDelete() {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    const DeleteData = new Promise((resolve, reject) => {
                        axios.post('/api/document/bulk-delete', {ids: this.checked})
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            resolve(error.response.data)
                        })
                    })
                    Promise.all([DeleteData]).then((response) => {
                        this.getDocuments()
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    });
                }
            })
        },
        removeGroup(id) {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                // Send request to the server
                if (result.value) {
        
                    const DeleteRoles = new Promise((resolve, reject) => {
                        axios.post('/api/document/delete', {id: id})
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            resolve(error.data)
                        })
                    });
                    Promise.all([DeleteRoles]).then((response) => {
                        this.getDocuments()
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    });
                }
            });
        }
    },
    watch: {
        'filter.limit': function() {
            this.getDocuments()
        },
        page() {
            this.checked = []
            this.getDocuments()
        },
        checkall(checkall) {
            if ( checkall ) {
                this.documents.items.forEach(role => {
                    this.checked.push(role.id)
                });
            } else {
                this.checked = []
            }
        }
    },
    components: {
        DateRangePicker
    },
}
</script>
<style>
.vue-treeselect__control {
    width: auto !important ;
}
.vue-treeselect__label{
    justify-content : left !important;
}
</style>