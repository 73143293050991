<template>
    <div>
        <FullCalendar
            ref="fullCalendar"
            :options="calendarOptions"
            defaultView="timeGridDay"
        />
    
        <b-modal id="item_modal" body-class="pt-4 pb-0" size="lg" centered :title="`Schedule ${moment(new_event_details.start).format('YYYY-MM-DD HH:mm:ss')}`">
          <AddAppointmentModal
            :show="new_event_modal_open"
            :date="new_event_details"
            @close="resetNewEventData"
            @event-created="newEventCreated"
          />
          <template slot="modal-footer">
            <div></div>
          </template>
        </b-modal>
    
        <!--   
        <show-appointment-modal
          :show="show_event_details_modal"
          :event="current_event"
          @close="show_event_details_modal = false"
          @event-deleted="rerenderCalendar"
          @event-updated="rerenderCalendar"
        />
            -->
  </div>
</template>
    
<script>
    import moment from 'moment'
    import { Datetime } from 'vue-datetime'

    import FullCalendar from "@fullcalendar/vue"
    import dayGridPlugin from "@fullcalendar/daygrid"
    import timeGridPlugin from "@fullcalendar/timegrid"
    import interactionPlugin from "@fullcalendar/interaction"
    import AddAppointmentModal from './AddAppointmentModal'
    import esLocale from '@fullcalendar/core/locales/es'
    import { INITIAL_EVENTS, createEventId } from './event-utils'
    //import ShowAppointmentModal from './ShowAppointmentModal'
    
    import "fullcalendar/main.css"
    //import "@fullcalendar/daygrid/main.css"
    //import "@fullcalendar/timegrid/main.css"
    
    export default {
        name: 'Calendar',
        components: {
            FullCalendar,
            Datetime,
            AddAppointmentModal
        },
        data: function() {
          return {
            new_event_modal_open: false,
            event_detail_modal_open : false,
            new_event_details: {
              start: null,
              end: null,
            },
            current_event: null,
            show_event_details_modal: false,
    
            /* Full Calendar Options Start */      
            locale: esLocale,
            calendarOptions: {
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin,
                    interactionPlugin // needed for dateClick
                ],
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'   //,listWeek
                },
                views: {
                    dayGridMonth: { buttonText: "month" },
                    timeGridWeek: { buttonText: "week" },
                    timeGridDay: { buttonText: "day" }
                },
                editable : true,
                initialView: 'dayGridMonth',
                selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
                weekends: true,
                select : this.handleDateClick,
                eventClick: this.handleEventClick,
                eventDrop : this.handleEventDrop,
                events: function(info, successCallback, failureCallback) {
                          console.log(info)
                          axios({
                              method: 'get',
                              params: {
                                  start : info.startStr,
                                  end : info.endStr,
                              },
                              url: `/api/event/fetch/all`
                          }).then((data) => {
                            successCallback( data.data.data.items);
                          }).catch((error) => {
                            failureCallback(error.response.data);
                          });
                    }, 
                
                //eventDrop : this.handleEventDrop,
                //eventAdd:
                //eventChange:
                //eventRemove:
            },
          }
            /* Full Calendar Options End */
        },
        methods: {
            handleDateClick(info) {
              this.new_event_modal_open = true
              this.new_event_details.start = info.startStr
              this.new_event_details.end = info.endStr

              this.$bvModal.show('item_modal');
              console.log ('date clicked');
            },
    
            handleEventDrop(info) {
              console.log(info)
              console.log ('event drop')

              let updatedEventData = {
                start: info.event.startStr,
                end: info.event.endStr
              }
              /*
              this.$api.appointments.update(e.event.id, updatedEventData)
                .then( ({data}) => {
                  new Noty({
                    text: `Event has been updated.`,
                    timeout: 700,
                    type: 'success'
                  }).show()
                })
                .catch( error => {
                  e.revert()
                  new Noty({
                    text: `Oops, something bad happened while updating your event.`,
                    timeout: 1000,
                    type: 'error'
                  }).show()
                })
              */
            },
            handleEventClick(info) {
              console.log(info)
              this.current_event = info.event
              this.show_event_details_modal = true
              console.log ('event clicked')
            },
    
            formatDate(date) {
              return moment.utc(date).format('DD/MM/YY HH:mm')
            },
    
            resetNewEventData() {
              this.new_event_details.start = null
              this.new_event_details.end = null
              this.new_event_details.title = null
              this.new_event_modal_open = false
            },
    
            newEventCreated() {
              this.rerenderCalendar()
              this.new_event_modal_open = false
              this.resetNewEventData()
              Toast.fire({
                    icon: 'success' ,
                    title: `Appointment has been created.`
              });
            },
    
            eventResize(info) {
              console.log ('event resize')
              let updatedEventData = {
                start: info.event.start,
                end: info.event.end
              }
              /*
              this.$api.appointments.update(e.event.id, updatedEventData)
                .then( ({data}) => {
                  new Noty({
                    text: `Appointment duration updated.`,
                    timeout: 1000,
                    type: 'success'
                  }).show()
                })
                .catch( error => {
                  e.revert()
                  new Noty({
                    text: `Oooops, couldn't update appointment duration. Sorry.`,
                    timeout: 1000,
                    type: 'error'
                  }).show()
                })
              */
            },
    
            rerenderCalendar() {
              this.$refs.fullCalendar.getApi().refetchEvents()
            }
        },
    };
    </script>
    
    <style>
        .fc {
          background-color: white;
        }
    </style>