<template>
    <div class="panel__body pt-4 ml-4">
        <BranchForm :src="src"></BranchForm>

        <div class="row mb-6">
            <div class="col-12 mb-2 mt-3">
                <router-link :to="{ name : 'doclist.data'}" class="btn btn-outline-primary btn-lg">Back</router-link>
            </div>
        </div>
    </div>
</template>
<script>

import BranchForm from './Form'

export default {
    data(){
        return {
            src : this.$route.query.src,
            preparation : true
        }
    },
    mounted(){
        this.preparation = false
    },
    created() {
        const vm = this;

        if (!this.$route.query.src){
            this.$router.push({name: 'doclist.dashboard'}, function() {
                Toast.fire({
                    icon: 'error',
                    title: 'Invalid parameter!!!'
                });
            })
        }
    },
    components: {
        BranchForm
    }
}
</script>