<template>
    <b-form v-if="!preparation" class="form" enctype="multipart/form-data">
        <b-row>
            <b-col lg="4" class="mr-1">
                <b-form-group label="First name *" label-for="first_name">
                    <the-mask class="form-control" mask="WWWWWWWWWWWWWWWWWWWWWWWWW" :tokens="{W:{pattern: /^[a-zA-Z\s]+/}}" :class="{ 'is-invalid': empl.errors.has('first_name') }" v-model="empl.first_name" id="first_name"></the-mask>
                    <has-error :form="empl" field="first_name"></has-error>
                </b-form-group>
                <b-form-group label="Last name *" label-for="last_name">
                    <the-mask class="form-control" mask="WWWWWWWWWWWWWWWWWWWWWWWWW" :tokens="{W:{pattern: /^[a-zA-Z\s]+/}}" :class="{ 'is-invalid': empl.errors.has('last_name') }"  v-model="empl.last_name" id="first_name"></the-mask>
                    <has-error :form="empl" field="last_name"></has-error>
                </b-form-group>
                <b-form-group label="Email *" label-for="email">
                    <b-form-input type="email" v-model="empl.email" id="email" required placeholder="Email"  :class="{ 'is-invalid': empl.errors.has('email') }"></b-form-input>
                    <has-error :form="empl" field="email"></has-error>
                </b-form-group>
                <b-form-group label="Birth *" label-for="date_of_birth">
                    <Datetime  
                        value-zone="UTC+7"
                        input-id="date_of_birth" v-on:input="empl.date_of_birth ? empl.date_of_birth = moment(empl.date_of_birth).format('YYYY-MM-DD') : null" input-class="form-control" class="input-group" v-model.lazy="empl.date_of_birth">
                        <div class="input-group-append" slot="after">
                            <div class="input-group-text">
                                <i class="fas fa-calendar" @click="empl.date_of_birth = null"></i>
                            </div>
                        </div>
                    </Datetime>
                    <has-error :form="empl" field="date_of_birth"></has-error>
                </b-form-group>
                <b-form-group label="Jenis Kelamin" label-for="gender">
                    <b-form-radio-group
                        id="gender"
                        v-model="empl.gender"
                        :options="[{value:'1', text:'Laki-Laki'},{value:'2', text:'Perempuan'}]"
                        :class="{ 'is-invalid': empl.errors.has('gender') }"
                    ></b-form-radio-group>
                    <has-error :form="empl" field="gender"></has-error>
                </b-form-group>
            </b-col>
            <b-col lg="4" class="mr-1">
                <b-form-group label="Status Pernikahan" label-for="marital_status">
                    <b-form-radio-group
                        size="sm"
                        id="marital_status"
                        v-model="empl.marital_status"
                        :options="[{value:'1', text:'Belum Menikah'},{value:'2', text:'Menikah'},{value: '3', text: 'Janda'},{value: '4', text: 'Duda'}, {value: '5', text: 'Lainnya'}]"
                        buttons
                        button-variant="outline-primary"
                    ></b-form-radio-group>
                    <has-error :form="empl" field="marital_status"></has-error>
                </b-form-group>
                <b-form-group label="Kota lahir" label-for="place_of_birth">
                    <b-form-input  v-model="empl.place_of_birth" id="place_of_birth" placeholder="Kota Lahir" :class="{ 'is-invalid': empl.errors.has('place_of_birth') }"></b-form-input>
                    <has-error :form="empl" field="place_of_birth"></has-error>
                </b-form-group>
                <b-form-group label="Mobile Phone" label-for="mobile_phone_number">
                    <the-mask class="form-control" mask="WWWWWWWWWWWWWWWWWWWWWWWWW" :tokens="{W:{pattern: /\d/}}" :class="{ 'is-invalid': empl.errors.has('mobile_phone_number') }" v-model="empl.mobile_phone_number" id="mobile_phone_number"></the-mask>
                    <has-error :form="empl" field="mobile_phone_number"></has-error>
                </b-form-group>
                <b-form-group label="Home Phone" label-for="home_phone_number">
                    <the-mask class="form-control" mask="WWWWWWWWWWWWWWWWWWWWWWWWW" :tokens="{W:{pattern: /\d/}}" :class="{ 'is-invalid': empl.errors.has('home_phone_number') }" v-model="empl.home_phone_number" id="home_phone_number"></the-mask>
                    <has-error :form="empl" field="home_phone_number"></has-error>
                </b-form-group>
                <b-form-group label="Agama *" label-for="religion">
                    <treeselect
                        id="religion"
                        :options="[{ id : '1',label : 'Katolik'},
                                   {id : '2', label : 'Islam'},
                                   {id : '3', label : 'Kristen'},
                                   {id : '4', label : 'Buddha'},
                                   {id : '5', label : 'Hindu'},
                                   {id : '6', label : 'Confucius'},
                                   {id : '7', label : 'Others'},
                                   {id : '8', label : 'Orthodox'}]"
                        v-model="empl.religion"
                        :searchable="true"
                        placeholder="Religion?"
                        />
                    <has-error :form="empl" field="religion"></has-error>
                </b-form-group>
            </b-col>
            <b-col lg="3" class="mr-1">
                <b-form-group label="Blood Type *" label-for="blood_type">
                    <treeselect
                        id="blood_type"
                        :options="[{ id : '1',label : 'A'},
                                    {id : '2', label : 'B'},
                                    {id : '3', label : 'AB'},
                                    {id : '4', label : 'O'}]"
                        v-model="empl.blood_type"
                        :searchable="true"
                        placeholder="Blood Type?"
                        />
                    <has-error :form="empl" field="blood_type"></has-error>
                </b-form-group>
                <b-form-group label="Branch *" label-for="branch_id">
                    <treeselect
                        id="branch_id"
                        :options="allbranch"
                        :show-count="true"
                        :default-expand-level="1"
                        v-model="empl.branch_id"
                        :searchable="true"
                        @input="updateValueBranch"
                        placeholder="Branch?"
                        />
                    <has-error :form="empl" field="branch_id"></has-error>
                </b-form-group>
                
                <b-form-group label="Department *" label-for="organization_id">
                    <treeselect
                        id="organization_id"
                        :options="allorganization"
                        :show-count="true"
                        :default-expand-level="1"
                        :searchable="true"
                        v-model="empl.organization_id"
                        @input="updateValueOrg"
                        placeholder="Department?"
                        />
                    <has-error :form="empl" field="organization_id"></has-error>
                </b-form-group>
                <b-form-group label="Job Position *" label-for="job_position_id">
                    <treeselect
                        id="job_position_id"
                        :options="alljob"
                        :show-count="true"
                        :default-expand-level="1"
                        :searchable="true"
                        v-model="empl.job_position_id"
                        @input="updateValueJob"
                        placeholder="Job Position?"
                        />
                    <has-error :form="empl" field="job_position_id"></has-error>
                </b-form-group>
                <b-form-group label="Job Level *" label-for="job_level">
                    <treeselect
                        id = "job_level"
                        :options="[{ id : '1',label : 'CEO'}, 
                                    {id : '2', label : 'Manager'},
                                    {id : '3', label : 'Supervisor'},
                                    {id : '4', label : 'Staff'},
                                    {id : '5', label : 'Other'}]"
                        v-model="empl.job_level"
                        :searchable="true"
                        placeholder="Job Level?"
                        />
                    <has-error :form="empl" field="job_level"></has-error>
                </b-form-group>
            </b-col>
        </b-row>

        <div class="dropdown-divider mt-2 mb-6"></div>

        <b-row>
            <b-col lg="4" class="mr-1">
                <b-form-group label="Employment Status *" label-for="employment_status">
                    <treeselect
                        id="employment_status"
                        :options="[{ id : '1',label : 'FullTime'},  
                                    {id : '2', label : 'Contract'},
                                    {id : '3', label : 'Probation'},
                                    {id : '4', label : 'Days'},
                                    {id : '5', label : 'Other'}]"
                        :searchable="true"
                        v-model="empl.employment_status"
                        placeholder="Employment Status"
                        />
                    <has-error :form="empl" field="employment_status"></has-error>
                </b-form-group>
                <b-form-group label="Join Date *" label-for="join_date">
                    <Datetime  
                        value-zone="UTC+7"
                        input-id="join_date" v-on:input="empl.join_date ? empl.join_date = moment(empl.join_date).format('YYYY-MM-DD') : null" input-class="form-control" class="input-group" v-model.lazy="empl.join_date">
                        <div class="input-group-append" slot="after">
                            <div class="input-group-text">
                                <i class="fas fa-calendar" @click="empl.join_date = null"></i>
                            </div>
                        </div>
                    </Datetime>
                    <has-error :form="empl" field="join_date"></has-error>
                </b-form-group>
                <b-form-group label="End Employment Date" label-for="end_employment_status_date">
                    <Datetime  
                        value-zone="UTC+7"
                        input-id="end_employment_status_date" v-on:input="empl.end_employment_status_date ? empl.end_employment_status_date = moment(empl.end_employment_status_date).format('YYYY-MM-DD') : null" input-class="form-control" class="input-group" v-model.lazy="empl.end_employment_status_date">
                        <div class="input-group-append" slot="after">
                            <div class="input-group-text">
                                <i class="fas fa-calendar" @click="empl.end_employment_status_date = null"></i>
                            </div>
                        </div>
                    </Datetime>
                    <has-error :form="empl" field="end_employment_status_date"></has-error>
                </b-form-group>
                <b-form-group label="ID Mekari" label-for="schedule">
                    <b-form-input  v-model="empl.schedule" id="schedule" placeholder="ID Mekari" :class="{ 'is-invalid': empl.errors.has('schedule') }"></b-form-input>
                    <has-error :form="empl" field="schedule"></has-error>
                </b-form-group>
            </b-col>
            <b-col lg="4" class="mr-1">
                <b-form-group label="Jenis Pengenal *">
                    <treeselect
                        :options="[{ id : '1',label : 'KTP'},  
                                    {id : '2', label : 'Passport'},
                                    {id : '3', label : 'SIM'},
                                    {id : '4', label : 'Other'}]"
                        v-model="empl.citizen_id_type"
                        :searchable="true"
                        placeholder="Jenis Kartu Pengenal"
                        />
                    <has-error :form="empl" field="citizen_id_type"></has-error>
                </b-form-group>
                <b-form-group label="No Pengenal *" label-for="citizen_id">
                    <the-mask class="form-control" mask="WWWWWWWWWWWWWWWWWWWWWWWWW" :tokens="{W:{pattern: /\d/}}" :class="{ 'is-invalid': empl.errors.has('citizen_id') }" v-model="empl.citizen_id" id="citizen_id"></the-mask>
                    <has-error :form="empl" field="citizen_id"></has-error>
                </b-form-group>
                <b-form-group label="Alamat Pengenal" label-for="citizen_address">
                    <b-form-textarea
                        id="citizen_address"
                        v-model.trim="empl.citizen_address"
                        placeholder="Tulis Alamat jika perlu." :class="{ 'is-invalid': empl.errors.has('citizen_address') }"/>
                        <has-error :form="empl" field="citizen_address" ></has-error>
                </b-form-group>
                <b-form-group label="Alamat Tinggal" label-for="residential_address">
                    <b-form-textarea
                        id="residential_address"
                        v-model.trim="empl.residential_address"
                        placeholder="Tulis Alamat jika perlu." :class="{ 'is-invalid': empl.errors.has('residential_address') }"/>
                        <has-error :form="empl" field="residential_address" ></has-error>
                </b-form-group>
            </b-col>
            <b-col lg="3" class="mr-1">
                <b-form-group label="Post Code" label-for="postal_code">
                    <b-form-input  v-model="empl.postal_code" id="postal_code" placeholder="Post Code" :class="{ 'is-invalid': empl.errors.has('postal_code') }"></b-form-input>
                    <has-error :form="empl" field="postal_code"></has-error>
                </b-form-group>
                <b-form-group label="Grade *">
                    <treeselect
                        :options="allgrade"
                        :show-count="true"
                        :default-expand-level="1"
                        v-model="empl.grade_id"
                        @input="updateValueGrade"
                        placeholder="Grade?"
                        :searchable="true"
                        />
                    <has-error :form="empl" field="grade_id"></has-error>
                </b-form-group>
                <b-form-group label="Class" label-for="class">
                    <b-form-input  v-model="empl.class" id="class" placeholder="Class Empl" :class="{ 'is-invalid': empl.errors.has('class') }"></b-form-input>
                    <has-error :form="empl" field="class"></has-error>
                </b-form-group>
                <b-form-group label="Approval Line" label-for="approval_line">
                    <b-form-input  v-model="empl.approval_line" id="approval_line" placeholder="Approval Line" :class="{ 'is-invalid': empl.errors.has('approval_line') }"></b-form-input>
                    <has-error :form="empl" field="approval_line"></has-error>
                </b-form-group>
            </b-col>
        </b-row>

        <div class="dropdown-divider mt-2 mb-6"></div>

        <b-row>
            <b-col lg="4" class="mr-1">
                <b-form-group label="Empl Number" label-for="nik">
                    <b-form-input  v-model="empl.nik" id="nik" placeholder="Employee Number" :class="{ 'is-invalid': empl.errors.has('nik') }"></b-form-input>
                    <has-error :form="empl" field="nik"></has-error>
                </b-form-group>
                <b-form-group label="Basic Salary" label-for="basic_salary">
                    <money v-money v-model.number="empl.basic_salary" :class="{ 'is-invalid': empl.errors.has('basic_salary') }"> </money>
                    <b-input-group-prepend style="display: inline-flex !important;margin-left:-3px;">
                        <div class="input-group-text">
                            <i class="fas fa-dollar-sign"></i>
                        </div>
                    </b-input-group-prepend>
                    <has-error :form="empl" field="basic_salary"></has-error>
                </b-form-group>
                <b-form-group label="Type Salary *" label-for="type_salary"> 
                    <treeselect
                        id="type_salary"
                        :options="[{ id : '1',label : 'Monthly'}, {id : '2', label : 'Daily'}]"
                        v-model="empl.type_salary"
                        placeholder="Type Salary" 
                        :class="{ 'is-invalid': empl.errors.has('type_salary') }"
                        :searchable="true"
                        />
                    <has-error :form="empl" field="type_salary"></has-error>
                </b-form-group>
                <b-form-group label="Salary Configuration*" label-for="salary_configuration"> 
                    <treeselect
                        id="salary_configuration"
                        :options="[{ id : '1',label : 'Taxable'}, {id : '2', label : 'Non-Taxable'}]"
                        v-model="empl.salary_configuration"
                        placeholder="Salary Configuration" 
                        :class="{ 'is-invalid': empl.errors.has('salary_configuration') }"
                        :searchable="true"
                        />
                    <has-error :form="empl" field="salary_configuration"></has-error>
                </b-form-group>
                <b-form-group label="PTKP Status *" label-for="ptkp_status">
                    <treeselect
                        id="ptkp_status"
                        :options="[{ id : '1',label : 'TK0'}, {id : '2', label : 'TK1'},{id : '3', label : 'TK2'},{id : '4', label : 'TK3'},
                                    { id : '5',label : 'K0'}, {id : '6', label : 'K1'},{id : '7', label : 'K2'},{id : '8', label : 'K3'}]"
                        v-model="empl.ptkp_status"
                        placeholder="Status PTKP" 
                        :searchable="true"
                        :class="{ 'is-invalid': empl.errors.has('ptkp_status') }"
                        />
                    <has-error :form="empl" field="ptkp_status"></has-error>
                </b-form-group>
                <b-form-group label="Tax Configuration *" label-for="tax_configuration"> 
                    <treeselect
                        id="tax_configuration"
                        :options="[{ id : '1',label : 'Gross'}, {id : '2', label : 'Gross Up'},{id : '3', label : 'Netto'}]"
                        v-model="empl.tax_configuration"
                        placeholder="Tax Configuration" 
                        :searchable="true"
                        :class="{ 'is-invalid': empl.errors.has('tax_configuration') }"
                        />
                    <has-error :form="empl" field="tax_configuration"></has-error>
                </b-form-group>
            </b-col>  
            <b-col lg="4" class="mr-1">
                <b-form-group label="JHT Configuration *" label-for="jht_configuration">
                    <treeselect
                        id="jht_configuration"
                        :options="[{ id : '0',label : 'Not Paid'},{ id : '1',label : 'Paid By Company'}, {id : '2', label : 'Paid By Employee'},{id : '3', label : 'Default'}]"
                        v-model="empl.jht_configuration"
                        placeholder="JHT Configuration" 
                        :searchable="true"
                        :class="{ 'is-invalid': empl.errors.has('jht_configuration') }"
                        />
                    <has-error :form="empl" field="jht_configuration"></has-error>
                </b-form-group>
                <b-form-group label="Employee Tax Status *" label-for="employee_tax_status">
                    <treeselect 
                        id="employee_tax_status"
                        :options="[{ id : '0',label : 'Pegawai Tetap'},{ id : '1',label : 'Pegawai Tidak Tetap'}, 
                                    {id : '2', label : 'Bukan Pegawai yang Bersifat Berkesinambungan'},{id : '3', label : 'Bukan Pegawai yang tidak Bersifat Berkesinambungan'},
                                    {id : '4',label : 'Ekspatriat'},{ id : '5',label : 'Ekspatriat Dalam Negeri'}, 
                                    {id : '6',label : 'Tenaga Ahli yang Bersifat Berkesinambungan'},{ id : '7',label : 'Tenaga Ahli yang Tidak Bersifat Berkesinambungan'}, 
                                    {id : '8',label : 'Dewan Komisaris'}]"
                        v-model="empl.employee_tax_status"
                        :searchable="true"
                        placeholder="Employee Tax Status" 
                        :class="{ 'is-invalid': empl.errors.has('employee_tax_status') }"
                        />
                    <has-error :form="empl" field="employee_tax_status"></has-error>
                </b-form-group>
                <b-form-group label="JP Configuration *" label-for="jp_configuration">
                    <treeselect 
                        id="jp_configuration"
                        :options="[{ id : '0',label : 'Not Paid'},{ id : '1',label : 'Paid By Company'}, {id : '2', label : 'Paid By Employee'},{id : '3', label : 'Default'}]"
                        v-model="empl.jp_configuration"
                        placeholder="JP Configuration" 
                        :searchable="true"
                        :class="{ 'is-invalid': empl.errors.has('jp_configuration') }"
                        />
                    <has-error :form="empl" field="jp_configuration"></has-error>
                </b-form-group>
                <b-form-group label="NPP BPJS Tenaga Kerja" label-for="npp_bpjs_ketenagakerjaan">
                    <the-mask class="form-control" mask="WWWWWWWWWWWWWWWWWWWWWWWWW" :tokens="{W:{pattern: /\d/}}" :class="{ 'is-invalid': empl.errors.has('npp_bpjs_ketenagakerjaan') }" v-model="empl.npp_bpjs_ketenagakerjaan" id="npp_bpjs_ketenagakerjaan"></the-mask>
                    <has-error :form="empl" field="npp_bpjs_ketenagakerjaan"></has-error>
                </b-form-group>
                <b-form-group label="Overtime Status *" label-for="overtime_status">
                    <treeselect 
                        id="overtime_status"
                        :options="[{ id : '1',label : 'Eligible'}, {id : '2', label : 'Non-Eligible'}]"
                        v-model="empl.overtime_status"
                        placeholder="Overtime Status" 
                        :searchable="true"
                        :class="{ 'is-invalid': empl.errors.has('overtime_status') }"
                        />
                    <has-error :form="empl" field="overtime_status"></has-error>
                </b-form-group>
            </b-col>
            <b-col lg="3" class="mr-1">
                <b-form-group label="BPJSKES Configuration *" label-for="bpjs_kesehatan_config">
                    <treeselect 
                        id="bpjs_kesehatan_config"
                        :options="[{ id : '1',label : 'Paid By Company'}, {id : '2', label : 'Paid By Employee'},{id : '3', label : 'Default'}]"
                        v-model="empl.bpjs_kesehatan_config"
                        placeholder="BPJSKES Configuration" 
                        :searchable="true"
                        :class="{ 'is-invalid': empl.errors.has('bpjs_kesehatan_config') }"
                        />
                    <has-error :form="empl" field="bpjs_kesehatan_config"></has-error>
                </b-form-group>
                <b-form-group label="Payment Schedule" label-for="payment_schedule">
                    <b-form-input  v-model="empl.payment_schedule" id="payment_schedule" placeholder="Payment Schedule" :class="{ 'is-invalid': empl.errors.has('payment_schedule') }"></b-form-input>
                    <has-error :form="empl" field="payment_schedule"></has-error>
                </b-form-group>
                <b-form-group label="NPWP" label-for="npwp">
                    <the-mask class="form-control" mask="WW.WWW.WWW.W-WWW.WWW" :tokens="{W:{pattern: /\d/}}" :class="{ 'is-invalid': empl.errors.has('npwp') }" v-model="empl.npwp" id="npwp"></the-mask>
                    <has-error :form="empl" field="npwp"></has-error>
                </b-form-group>
                <b-form-group label="Bank Name" label-for="bank_name">
                    <b-form-input  v-model="empl.bank_name" id="bank_name" placeholder="Bank Name" :class="{ 'is-invalid': empl.errors.has('bank_name') }"></b-form-input>
                    <has-error :form="empl" field="bank_name"></has-error>
                </b-form-group>
                <b-form-group label="Bank Account No" label-for="bank_account">
                    <the-mask class="form-control" mask="WWWWWWWWWWWWWWWWWWWWWWWWW" :tokens="{W:{pattern: /\d/}}" :class="{ 'is-invalid': empl.errors.has('bank_account') }" v-model="empl.bank_account" id="bank_account"></the-mask>
                    <has-error :form="empl" field="bank_account"></has-error>
                </b-form-group>
                <b-form-group label="Bank AccountHolder" label-for="bank_account_holder">
                    <b-form-input  v-model="empl.bank_account_holder" id="bank_account_holder" placeholder="Pemilik Rekening" :class="{ 'is-invalid': empl.errors.has('bank_account_holder') }"></b-form-input>
                    <has-error :form="empl" field="bank_account_holder"></has-error>
                </b-form-group>
            </b-col>
        </b-row>
        <div class="dropdown-divider mt-2 mb-6"></div>

        <b-row>
            <b-col lg="4" class="mr-1">
                <b-form-group label="BPJS Ketenagakerjaan" label-for="bpjs_ketenagakerjaan">
                    <b-form-input  v-model="empl.bpjs_ketenagakerjaan" id="bpjs_ketenagakerjaan" placeholder="BPJS Ketenagakerjaan" :class="{ 'is-invalid': empl.errors.has('bpjs_ketenagakerjaan') }"></b-form-input>
                    <has-error :form="empl" field="bpjs_ketenagakerjaan"></has-error>
                </b-form-group>
                <b-form-group label="BPJS Kesehatan" label-for="bpjs_kesehatan">
                    <b-form-input  v-model="empl.bpjs_kesehatan" id="bpjs_kesehatan" placeholder="BPJS Kesehatan" :class="{ 'is-invalid': empl.errors.has('bpjs_kesehatan') }"></b-form-input>
                    <has-error :form="empl" field="bpjs_kesehatan"></has-error>
                </b-form-group>
                <b-form-group label="Keluarga BPJS Kesehatan" label-for="bpjs_kesehatan_family">
                    <the-mask class="form-control" mask="W" :tokens="{W:{pattern: /\d/}}" :class="{ 'is-invalid': empl.errors.has('bpjs_kesehatan_family') }" v-model="empl.bpjs_kesehatan_family" id="bpjs_kesehatan_family"></the-mask>
                    <has-error :form="empl" field="bpjs_kesehatan_family"></has-error>
                </b-form-group>
                <b-form-group label="BPJSTK Join date *" label-for="bpjs_ketenagakerjaan_date">
                    <Datetime  
                        value-zone="UTC+7"
                        input-id="bpjs_ketenagakerjaan_date" v-on:input="empl.bpjs_ketenagakerjaan_date ? empl.bpjs_ketenagakerjaan_date = moment(empl.bpjs_ketenagakerjaan_date).format('YYYY-MM-DD') : null" input-class="form-control" class="input-group" v-model.lazy="empl.bpjs_ketenagakerjaan_date">
                        <div class="input-group-append" slot="after">
                            <div class="input-group-text">
                                <i class="fas fa-calendar" @click="empl.bpjs_ketenagakerjaan_date = null"></i>
                            </div>
                        </div>
                    </Datetime>
                    <has-error :form="empl" field="bpjs_ketenagakerjaan_date"></has-error>
                </b-form-group>
                <b-form-group label="BPJSKes Join date *" label-for="bpjs_kesehatan_date">
                    <Datetime  
                        value-zone="UTC+7"
                        input-id="bpjs_kesehatan_date" v-on:input="empl.bpjs_kesehatan_date ? empl.bpjs_kesehatan_date = moment(empl.bpjs_kesehatan_date).format('YYYY-MM-DD') : null" input-class="form-control" class="input-group" v-model.lazy="empl.bpjs_kesehatan_date">
                        <div class="input-group-append" slot="after">
                            <div class="input-group-text">
                                <i class="fas fa-calendar" @click="empl.bpjs_kesehatan_date = null"></i>
                            </div>
                        </div>
                    </Datetime>
                    <has-error :form="empl" field="bpjs_kesehatan_date"></has-error>
                </b-form-group>

            </b-col>
            <b-col lg="4" class="mr-1">
                <b-form-group label="Overtime Working Day" label-for="overtime_working_day_default">
                    <money v-money v-model.number="empl.overtime_working_day_default" :class="{ 'is-invalid': empl.errors.has('overtime_working_day_default') }"></money>
                    <b-input-group-prepend style="display: inline-flex !important;margin-left:-3px;">
                        <div class="input-group-text">
                            <i class="fas fa-dollar-sign"></i>
                        </div>
                    </b-input-group-prepend>
                    <has-error :form="empl" field="overtime_working_day_default"></has-error>
                </b-form-group>
                <b-form-group label="Overtime Day Off" label-for="overtime_day_off_default">
                    <money v-money v-model.number="empl.overtime_day_off_default" :class="{ 'is-invalid': empl.errors.has('overtime_day_off_default') }"></money>
                    <b-input-group-prepend style="display: inline-flex !important;margin-left:-3px;">
                        <div class="input-group-text">
                            <i class="fas fa-dollar-sign"></i>
                        </div>
                    </b-input-group-prepend>
                    <has-error :form="empl" field="overtime_day_off_default"></has-error>
                </b-form-group>
                <b-form-group label="Overtime National Holiday" label-for="overtime_national_holiday_default">
                    <money v-money v-model.number="empl.overtime_national_holiday_default" :class="{ 'is-invalid': empl.errors.has('overtime_national_holiday_default') }"></money>
                    <b-input-group-prepend style="display: inline-flex !important;margin-left:-3px;">
                        <div class="input-group-text">
                            <i class="fas fa-dollar-sign"></i>
                        </div>
                    </b-input-group-prepend>
                    <has-error :form="empl" field="overtime_national_holiday_default"></has-error>
                </b-form-group>
                <b-form-group label="Beginning Netto" label-for="beginning_netto">
                    <money v-money v-model.number="empl.beginning_netto" :class="{ 'is-invalid': empl.errors.has('beginning_netto') }"></money>
                    <b-input-group-prepend style="display: inline-flex !important;margin-left:-3px;">
                        <div class="input-group-text">
                            <i class="fas fa-dollar-sign"></i>
                        </div>
                    </b-input-group-prepend>
                    <has-error :form="empl" field="beginning_netto"></has-error>
                </b-form-group>
                <b-form-group label="PPH21 Paid" label-for="pph21_paid">
                    <money v-money v-model.number="empl.pph21_paid" :class="{ 'is-invalid': empl.errors.has('pph21_paid') }"></money>
                    <b-input-group-prepend style="display: inline-flex !important;margin-left:-3px;">
                        <div class="input-group-text">
                            <i class="fas fa-dollar-sign"></i>
                        </div>
                    </b-input-group-prepend>
                    <has-error :form="empl" field="pph21_paid"></has-error>
                </b-form-group>
            </b-col>
            <b-col lg="3" class="mr-1">
                <b-form-group label="Taxable date *" label-for="taxable_date">
                    <Datetime  
                        value-zone="UTC+7"
                        input-id="taxable_date" v-on:input="empl.taxable_date ? empl.taxable_date = moment(empl.taxable_date).format('YYYY-MM-DD') : null" input-class="form-control" class="input-group" v-model.lazy="empl.taxable_date">
                        <div class="input-group-append" slot="after">
                            <div class="input-group-text">
                                <i class="fas fa-calendar" @click="empl.taxable_date = null"></i>
                            </div>
                        </div>
                    </Datetime>
                    <has-error :form="empl" field="taxable_date"></has-error>
                </b-form-group>
                <b-form-group label="Jaminan Pensiun date *" label-for="jp_date">
                    <Datetime  
                        value-zone="UTC+7"
                        input-id="jp_date" v-on:input="empl.jp_date ? empl.jp_date = moment(empl.jp_date).format('YYYY-MM-DD') : null" input-class="form-control" class="input-group" v-model.lazy="empl.jp_date">
                        <div class="input-group-append" slot="after">
                            <div class="input-group-text">
                                <i class="fas fa-calendar" @click="empl.jp_date = null"></i>
                            </div>
                        </div>
                    </Datetime>
                    <has-error :form="empl" field="jp_date"></has-error>
                </b-form-group>
                <b-form-group label="Prorate Type" label-for="prorate_type">
                    <b-form-input  v-model="empl.prorate_type" id="prorate_type" placeholder="Prorate Type" :class="{ 'is-invalid': empl.errors.has('prorate_type') }"></b-form-input>
                    <has-error :form="empl" field="prorate_type"></has-error>
                </b-form-group>
                <b-form-group label="Invite ESS" label-for="invite_ess" >
                    <b-form-radio-group
                        id="invite_ess"
                        v-model="empl.invite_ess"
                        :options="[{value:'0', text:'No'},{value:'1', text:'Yes'}]"
                    ></b-form-radio-group>
                </b-form-group>
            </b-col>
        </b-row>
    </b-form>
    <div v-else class="text-center text-primary py-14">
        <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
    </div>
<!--   COMPONENT NOT FOUND
<div>
    <not-found></not-found>
</div>
-->   
</template>
<script>
import { Datetime } from 'vue-datetime'
export default {
    props: ['preparation','empl','allbranch','allgrade','alljob','allorganization','state'],
    methods: {
        updateValueBranch(val){
            if (val) this.empl.branch_id = val;
            else this.empl.branch_id = ''
        },
        updateValueJob(val){
            if (val) this.empl.job_position_id = val;
            else this.empl.job_position_id = ''
        },
        updateValueOrg(val){
            if (val) this.empl.organization_id = val;
            else this.empl.organization_id = ''
        },
        updateValueGrade(val){
            if (val) this.empl.grade_id = val;
            else this.empl.grade_id = ''
        }
    },
    components: {
        Datetime
    }
}
</script>