<template>
    <div class="panel__body pt-4 ml-4" >
        <div class="container-fluid" style="height:700px;">
            <vue-iframe v-if="!preparation"
                :src="src"
                allow="camera *; geolocation *; microphone *; autoplay *"
                frame-id="my-iframe"
                name="my-iframe"
                @load="adjustheight"
                width="100%"
                :height="height"
                :scrolling="true"
            />
        </div>
    </div>
</template>
<script>

export default {
    data () {
      return {
        height : '100%',
      }
    },
    props: ['src','preparation'],
    methods : {
        adjustheight(){
            document.getElementById('my-iframe').style.height = document.getElementById('my-iframe').contentWindow.document.body.offsetHeight + 'px';
        }
    }
}
</script>