<template>
    <div class="container-fluid" v-if="!preparation">
        <b-row class="align-items-center pb-1">
            <b-col  lg="8">
                <router-link v-if="$gate.hasAccess('questiongroup.create')" :to="{ name: 'questiongroup.create'}" class="btn btn-primary btm-sm mt-1">Create New</router-link>
            </b-col>
            <b-col  lg="4">
                <b-form class="form form-inline form--rounded justify-content-end btn-sm" @submit.prevent="getGroup()">
                    <b-form-group v-if="checked.length && $gate.hasAccess('questiongroup.delete')">
                        <b-button @click="bulkDelete" class="btn btn-danger btm-sm" size="sm"><i class="fa fa-trash red"></i>Bulk Delete</b-button>
                    </b-form-group>
                    
                    <b-form-group class="form-inline ml-3" >
                        <b-input-group size="sm">
                            <b-form-input type="search" class="form-control form-control-navbar" v-model="filter.q" placeholder="Search" aria-label="Search" />
                            <div class="input-group-append">
                                <button class="btn btn-navbar" type="submit" >
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                            
                        </b-input-group>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>

        <b-table
            id="grade-table"
            class="table--middle table--primary mt-2" show-empty responsive
            :items="questiongroups.items"
            :fields="tableFields"
            :tbody-tr-class="rowHighlight"
            :busy.sync="preparation">
            <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
                </div>
            </template>
            <template v-slot:empty><div class="text-center text-muted">Data not found</div></template>

            <template v-slot:head(check)>
                <div class="checkbox checkbox--all">
                    <input id="check-all" v-model="checkall" type="checkbox">
                    <label for="check-all"></label>
                </div>
            </template>

            <template v-slot:cell(no)="data">{{ ((page > 0 ? (page - 1) : 0) * filter.limit) + (data.index +1 ) }}</template>

            <template v-slot:cell(action)="data">
                <div class="btn-group">
                    <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-wrench"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" role="menu" x-placement="bottom-end" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-124px, 18px, 0px);">
                        <a class="dropdown-item" v-if="$gate.hasAccess('question.create')" @click="addQuestion(data.item.id,data.item.name)" variant="primary">
                            <i class="fas fa-plus"></i>Add Question
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" v-if="$gate.hasAccess('questiongroup.update')">
                            <router-link :to="{ name: 'questiongroup.update', params: {id: data.item.id} }" style="box-sizing: content-box;height: 17px;"><i class="fas fa-edit"></i>Edit</router-link>
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" v-if="$gate.hasAccess('questiongroup.delete')" @click="removeGroup(data.item.id)" variant="danger">
                            <i class="fas fa-times"></i> Delete
                        </a>
                    </div>
                </div>
            </template>
            <template v-slot:cell(name)="data">
                <span class="text-primary font-weight-medium">{{ data.item.name + ' (' +  (data.item.questions ? data.item.questions.length : '0') + ' Question)' }}</span>
            </template>
            <template v-slot:cell(description)="data">
                <span class="text-secondary font-weight-medium">{{ data.item.description }}</span>
            </template>
            <template v-slot:cell(check)="data">
                <div class="checkbox">
                    <input :id="`checkbox-${data.item.id}`" :value="data.item.id" v-model="checked" type="checkbox">
                    <label :for="`checkbox-${data.item.id}`"></label>
                </div>
            </template>
        </b-table>

        <b-row>
            <b-col lg="6">
                <div class="form form--small form-inline justify-content-center justify-content-lg-start">
                    <b-form-group label-class="text-primary mr-1" label="GO TO " label-for="page">
                        <b-form-input type="number" v-model="page" id="page" class="form-control"></b-form-input>
                    </b-form-group>
                    <b-form-group label-class="text-primary ml-2 mr-2" label="VIEW " label-for="filter_limit">
                        <select2 id="filter_limit" v-model="filter.limit" :options="[10, 20, 30]" :settings="{minimumResultsForSearch: -1}" />
                    </b-form-group>
                </div>
            </b-col>
            <b-col lg="6">
                <b-pagination
                    align="right"
                    v-model="page"
                    :total-rows="questiongroups.total"
                    :per-page="filter.limit"
                    aria-controls="grade-table">
                </b-pagination>
            </b-col>
        </b-row>
        
        <BModal id="item_modal" body-class="pt-4 pb-0" size="lg" centered :title="`Add Question for ${name}`">
            <BranchForm :preparation="preparation" :question="question" :question_groups="allquestiongroups" :state="false"></BranchForm>

            <template slot="modal-footer" class="mt-6">
                <div class="row mb-6">
                    <div class="col-12 mb-2">
                        <button type="submit" v-if="$gate.hasAccess('question.create')" :disabled="preparation" @click="submit" class="btn btn-secondary btn-lg mr-2">Save</button>
                        <button :disabled="preparation" @click="exit" class="btn btn-secondary btn-lg mr-2">Save</button>
                    </div>
                </div>
            </template>
        </BModal>
    </div>      
    <div v-else>
        <form-loader></form-loader>
    </div>
</template>
<script>

import BranchForm from '../question/Form'

export default {
    data() {
        return {
            name:'',
            preparation: true,
            questiongroups:{},
            allquestiongroups : [],
            mpage:1,
            checkall: false,
            checked: [],
            filter: {
                q: '',
                limit: 30
            },
            question: new Form({
                id:'',
                question: '',
                question_group_id: '',
                question_type: '',
                is_active: '',
                jawaban: '',
                pil_a: '',
                pil_b: '',
                pil_c: '',
                pil_d: '',
                pil_e: '',
            }),
            tableFields: [
                { key: 'no', label: 'No.', tdClass: 'text-right', thAttr: { width: '50' } },
                { key: 'name', label: 'Name' },
                { key: 'description', label: 'Deskripsi' },
                { key: 'action', label: 'Action', tdClass: 'text-center', thAttr: { width: '160' } },
                { key: 'check', thAttr: { width: '30' } , tdClass: 'text-center stick-end', thClass: 'stick-end' }
            ]
        }
    },
    created(){
        this.getGroup();
    },
    computed: {
        page: {
            get() {
                return this.mpage;
            },
            set(val) {
                this.mpage = val;
            }
        }
    },
    methods: {
        exit(){
            this.$bvModal.hide('item_modal')
        },
        submit() {
            this.preparation = true;

            this.question.submit('post', '/api/question/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$bvModal.hide('item_modal')
                this.getGroup()
                Toast.fire({
                        icon: 'success',
                        title: response.data.message
                });
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
            });
            this.preparation = false;
        },
        addQuestion(id,name){
            this.name = name
            this.question.question_group_id = id;
            this.$bvModal.show('item_modal')
        },
        rowHighlight(item, type) {
            if (!item || type !== 'row') return
            if (item.id === this.$route.params.row_id) return 'highlight'
        },
        getGroup(){
            this.preparation = true;
            this.$Progress.start();

            const geGroupData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    params: this.filter,
                    url: `/api/questiongroup/fetch?page=${this.page}`
                }).then((data) => {
                    this.questiongroups = data.data.data;
                    resolve(data.data.data);
                });
            })
            const getAllGroupData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    url: `/api/questiongroup/fetch/all`
                }).then((data) => {
                    this.allquestiongroups = data.data.data.items.map((group) => {
                                        return {
                                            id: group.id,
                                            label: group.name
                                        }
                                    });
                    resolve(data);
                });
            })
            Promise.all([geGroupData,getAllGroupData]).then(() => {
                this.preparation = false;
            });
            this.$Progress.finish();
        },       
        bulkDelete() {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    const DeleteData = new Promise((resolve, reject) => {
                        axios.post('/api/questiongroup/bulk-delete', {ids: this.checked})
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            resolve(error.response.data)
                        })
                    })
                    Promise.all([DeleteData]).then((response) => {
                        this.getGroup()
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    });
                }
            })
        },
        removeGroup(id) {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                // Send request to the server
                if (result.value) {
        
                    const DeleteRoles = new Promise((resolve, reject) => {
                        axios.post('/api/questiongroup/delete', {id: id})
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            resolve(error.data)
                        })
                    });
                    Promise.all([DeleteRoles]).then((response) => {
                        this.getGroup()
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    });
                }
            });
        }
    },
    watch: {
        'filter.limit': function() {
            this.getGroup()
        },
        page() {
            this.checked = []
            this.getGroup()
        },
        checkall(checkall) {
            if ( checkall ) {
                this.questiongroups.items.forEach(role => {
                    this.checked.push(role.id)
                });
            } else {
                this.checked = []
            }
        }
    },
    components: {
        BranchForm
    }
}
</script>