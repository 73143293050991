<template>
    <div class="container-fluid" v-if="!preparation">
        <b-row class="align-items-center pb-1">
            <b-col  lg="8">
                <router-link :to="{ name: 'article.create'}" class="btn btn-primary btm-sm mt-1">Create New</router-link>
            </b-col>
            <b-col  lg="4">
                <b-form class="form form-inline form--rounded justify-content-end btn-sm" @submit.prevent="getArticle()">
                    <b-form-group v-if="checked.length">
                        <b-button @click="bulkDelete" class="btn btn-danger btm-sm" size="sm"><i class="fa fa-trash red"></i>Bulk Delete</b-button>
                    </b-form-group>
                    
                    <b-form-group class="form-inline ml-3" >
                        <b-input-group size="sm">
                            <b-form-input type="search" class="form-control form-control-navbar" v-model="filter.q" placeholder="Search" aria-label="Search" />
                            <div class="input-group-append">
                                <button class="btn btn-navbar" type="submit" >
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                            
                        </b-input-group>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>

        <b-table
            id="grade-table"
            class="table--middle table--primary mt-2" show-empty responsive
            :items="articles.items"
            :fields="tableFields"
            :tbody-tr-class="rowHighlight"
            :busy.sync="preparation">
            <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
                </div>
            </template>
            <template v-slot:empty><div class="text-center text-muted">Data not found</div></template>

            <template v-slot:head(check)>
                <div class="checkbox checkbox--all">
                    <input id="check-all" v-model="checkall" type="checkbox">
                    <label for="check-all"></label>
                </div>
            </template>

            <template v-slot:cell(no)="data">{{ ((page > 0 ? (page - 1) : 0) * filter.limit) + (data.index +1 ) }}</template>

            <template v-slot:cell(subject)="data">
                <router-link :to="{ name: 'article.detail', params: {id: data.item.id} }">
                    <span class="text-primary font-weight-medium">{{ data.item.subject }}</span>
                </router-link>
            </template>
            
            <template v-slot:cell(action)="data">
                <div class="btn-group">
                    <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-wrench"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" role="menu" x-placement="bottom-end" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-124px, 18px, 0px);">
                        <a class="dropdown-item" v-if="$gate.hasAccess('article.update')">
                            <router-link :to="{ name: 'article.update', params: {id: data.item.id} }" style="box-sizing: content-box;height: 17px;"><i class="fas fa-edit"></i>Edit</router-link>
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" @click="removeRole(data.item.id)" variant="danger">
                            <i class="fas fa-times"></i> Delete
                        </a>
                    </div>
                </div>
            </template>
            <template v-slot:cell(media_type)="data">
                <b-form-radio-group
                    size="sm"
                    :options="[{value:'video', text:'Video Url'},{value:'image', text:'Image'}]"
                    buttons
                    v-model="data.value"
                    button-variant="outline-primary">
                </b-form-radio-group>
            </template>
            <template v-slot:cell(type)="data">
                <b-form-radio-group
                    size="sm"
                    :options="[{value:'public', text:'Public'},{value:'private', text: data.value == 'private' ? 'Assigned ' + data.item.employees_count +' employee' : 'Private'}]"
                    buttons
                    v-model="data.value"
                    button-variant="outline-primary">
                </b-form-radio-group>
            </template>
            <template v-slot:cell(media)="data">
                <a v-if="data.item.media_type === 'image'" :data-caption="data.item.subject" data-fancybox :href="base_url + '/' + data.value">
                    <img  width="300px" height="300px" class="img-fluid" :src="base_url + '/' + data.value" alt="Media profile picture"> <!--class="profile-user-img img-fluid img-circle"-->
                </a>            
                <a v-else :data-caption="data.item.subject" data-fancybox :href="data.value" target="_blank">
                    <LazyYoutubeVideo :src="data.value" preview-image-size="sddefault" aspect-ratio="1:1" style="position: relative;"/>
                </a> 
                    <!--<VueVideoThumbnail v-if="data.value.slice((data.value.lastIndexOf('.') - 1 >>> 0) + 2) == ''" 
                        :video-src="data.value"
                        show-play-button
                        :snapshot-at-duration-percent="1"
                        :cors = "true"
                        :crossOrigin="token"
                        :width="100"
                        :height="100"
                        :chunks-quantity="10"
                        @snapshotCreated="snapshotCreated"
                        @snapshotsArrayCreated="snapshotsArrayCreated">
                        <template #snapshot="{snapshot}">
                            <img
                            v-if="snapshot"
                            :src="snapshot"
                            alt="snapshot"
                            >
                        </template>
                    </VueVideoThumbnail>-->
                
            </template>
            <template v-slot:cell(check)="data">
                <div class="checkbox">
                    <input :id="`checkbox-${data.item.id}`" :value="data.item.id" v-model="checked" type="checkbox">
                    <label :for="`checkbox-${data.item.id}`"></label>
                </div>
            </template>
        </b-table>

        <b-row>
            <b-col lg="6">
                <div class="form form--small form-inline justify-content-center justify-content-lg-start">
                    <b-form-group label-class="text-primary mr-1" label="GO TO " label-for="page">
                        <b-form-input type="number" v-model="page" id="page" class="form-control"></b-form-input>
                    </b-form-group>
                    <b-form-group label-class="text-primary ml-2 mr-2" label="VIEW " label-for="filter_limit">
                        <select2 id="filter_limit" v-model="filter.limit" :options="[10, 20, 30]" :settings="{minimumResultsForSearch: -1}" />
                    </b-form-group>
                </div>
            </b-col>
            <b-col lg="6">
                <b-pagination
                    align="right"
                    v-model="page"
                    :total-rows="articles.total"
                    :per-page="filter.limit"
                    aria-controls="grade-table">
                </b-pagination>
            </b-col>
        </b-row>  
              
    </div>      
    <div v-else>
        <form-loader></form-loader>
    </div>
    
</template>

<script>
import VueVideoThumbnail from 'vue-video-thumbnail'
import LazyYoutubeVideo from 'vue-lazy-youtube-video'
import fancybox from '@fancyapps/fancybox'
import '@fancyapps/fancybox/dist/jquery.fancybox.css'
export default {
    data() {
        return {
            base_url : window.location.origin,
            preparation: true,
            grades:{},
            mpage:1,
            checkall: false,
            checked: [],
            filter: {
                q: '',
                limit: 30
            },
            tableFields: [
                { key: 'no', label: 'No.', tdClass: 'text-right', thAttr: { width: '50' } },
                { key: 'media', label: 'Media' },
                { key: 'media_type', label: 'Type' },
                { key: 'subject', label: 'Subject' },
                { key: 'tanggal', label: 'Pub. date' },               
                { key: 'type', label: 'Publish Type' },
                { key: 'action', label: 'Action', tdClass: 'text-center', thAttr: { width: '160' } },
                { key: 'check', thAttr: { width: '30' } , tdClass: 'text-center stick-end', thClass: 'stick-end' }
            ]
        }
    },
    created(){
        this.getArticle();
    },
    computed: {
        page: {
            get() {
                return this.mpage;
            },
            set(val) {
                this.mpage = val;
            }
        },
        token:{
            get() {
                document.head.querySelector('meta[name="csrf-token"]');
            },
        }
    },
    methods: {
        getVideo: async function(videoUrl) { 
            const requestUrl= `http://youtube.com/oembed?url=${videoUrl}&format=json`;
            const result = await axios.get(requestUrl);
            console.log(result.data);
            return result.data;
        },
        snapshotCreated(){

        },
        snapshotsArrayCreated(){

        },
        rowHighlight(item, type) {
            if (!item || type !== 'row') return
            if (item.id === this.$route.params.row_id) return 'highlight'
        },
        getArticle(){
            this.preparation = true;
            this.$Progress.start();

            const getArticlesData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    params: this.filter,
                    url: `/api/article/fetch?page=${this.page}`
                }).then((data) => {
                    this.articles = data.data.data;
                    resolve(data.data.data);
                });
            })
            Promise.all([getArticlesData]).then(() => {
                this.preparation = false;
            });
            this.$Progress.finish();
        },       
        bulkDelete() {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    const DeleteData = new Promise((resolve, reject) => {
                        axios.post('/api/article/bulk-delete', {ids: this.checked})
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            resolve(error.response.data)
                        })
                    })
                    Promise.all([DeleteData]).then((response) => {
                        this.getArticle()
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    });
                }
            })
        },
        removeRole(id) {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                // Send request to the server
                if (result.value) {
        
                    const DeleteRoles = new Promise((resolve, reject) => {
                        axios.post('/api/article/delete', {id: id})
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            resolve(error.data)
                        })
                    });
                    Promise.all([DeleteRoles]).then((response) => {
                        this.getArticle()
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    });
                }
            });
        }
    },
    watch: {
        'filter.limit': function() {
            this.getArticle()
        },
        page() {
            this.checked = []
            this.getArticle()
        },
        checkall(checkall) {
            if ( checkall ) {
                this.articles.items.forEach(role => {
                    this.checked.push(role.id)
                });
            } else {
                this.checked = []
            }
        }
    },
    components: { VueVideoThumbnail,
        LazyYoutubeVideo }
}
</script>
<style>
.y-video__media {
    position: absolute !important;
    top: 10px ;
    left: 40px ;
}
</style>
