<template>
    <div class="panel__body pt-4 ml-4">
        <BranchForm :grade="grade" :state="false"></BranchForm>

        <div class="row mb-6">
            <div class="col-12 mb-2">
                <button type="submit" :disabled="isLoading" @click="submit" class="btn btn-secondary btn-lg mr-2">Save</button>
                <router-link :to="{ name : 'grade.data'}" class="btn btn-outline-primary btn-lg">Back</router-link>
            </div>
        </div>
    </div>
</template>
<script>

import BranchForm from './Form'

export default {
    data(){
        return {
            isLoading : false,
            grade : new Form({
                id:'',
                name: '',
            })
        }
    },
    created() {
        this.isLoading = false
    },
    methods: {
        submit() {
            this.isLoading = true;

            this.grade.submit('post', '/api/grade/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'grade.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                
            });
            this.isLoading = false;
        }
    },
    components: {
        BranchForm
    }
}
</script>