<template>
    <b-form v-if="!preparation" class="form" enctype="multipart/form-data">
        <b-row>
            <b-col lg="5" class="mr-2">
               <b-form-group label="Materi" label-for="question">
                    <b-form-textarea
                        id="question"
                        v-model.trim="question.question"
                        placeholder="Tulis Materi." :class="{ 'is-invalid': question.errors.has('question') }"/>
                        <has-error :form="question" field="question" ></has-error>
                </b-form-group>
                <b-form-group label="Group *" label-for="question_group_id">
                    <treeselect
                        id="question_group_id"
                        :options="question_groups"
                        v-model="question.question_group_id"
                        :searchable="true"
                        placeholder="Group?"
                        :class="{ 'is-invalid': question.errors.has('question_group_id') }"
                        />
                    <has-error :form="question" field="question_group_id"></has-error>
                </b-form-group>
                <b-form-group label="Type" label-for="question_type">
                    <b-form-radio-group
                        size="sm"
                        id="question_type"
                        v-model="question.question_type"
                        :options="[{value:'polling', text:'Polling'},{value:'pilihan', text:'PIlihan Ganda'},{value: 'essay', text: 'Essay'}]"
                        buttons
                        button-variant="outline-primary"
                        :class="{ 'is-invalid': question.errors.has('question_type') }">
                        <has-error :form="question" field="question_type"></has-error>
                </b-form-radio-group>
                </b-form-group>
                <b-form-group label="Active" label-for="is_active">
                    <ToggleButton :class="{ 'is-invalid': question.errors.has('is_active') }" v-model="question.is_active" @change="(e) => setActive(e)" class="mb-0" :width="80" :labels="{checked: 'Aktif', unchecked: 'Non-aktif'}"></ToggleButton>
                    <has-error :form="question" field="is_active"></has-error>
                </b-form-group>
                <b-form-group label="Ilustration" label-for="ilustration">
                    <uploader id="upload_ilustration"  name="upload_ilustration" description="Maks 2MB dengan ekstensi (jpg, png, gif, pdf)." accept=".jpg,.jpeg,.png,.gif,.pdf" v-bind:files.sync="question.ilustration" :class="{ 'is-invalid': question.errors.has('ilustration') }"/>
                    <has-error :form="question" field="ilustration"></has-error>
                </b-form-group>
            </b-col>    
            <b-col lg="5" class="mr-2" v-if="question.question_type != 'polling'"> 
                <b-form-group label="Option A" label-for="pil_a" v-if="question.question_type == 'pilihan'">
                    <b-form-textarea
                        id="pil_a"
                        v-model.trim="question.pil_a"
                        placeholder="Tulis Pertanyaan." :class="{ 'is-invalid': question.errors.has('pil_a') }"/>
                        <has-error :form="question" field="pil_a" ></has-error>
                </b-form-group>
                <b-form-group label="Option B" label-for="pil_b" v-if="question.question_type == 'pilihan'">
                    <b-form-textarea
                        id="pil_b"
                        v-model.trim="question.pil_b"
                        placeholder="Tulis Pertanyaan." :class="{ 'is-invalid': question.errors.has('pil_b') }"/>
                        <has-error :form="question" field="pil_b" ></has-error>
                </b-form-group>
                <b-form-group label="Option C" label-for="pil_c" v-if="question.question_type == 'pilihan'">
                    <b-form-textarea
                        id="pil_c"
                        v-model.trim="question.pil_c"
                        placeholder="Tulis Pertanyaan." :class="{ 'is-invalid': question.errors.has('pil_c') }"/>
                        <has-error :form="question" field="pil_c" ></has-error>
                </b-form-group>
                <b-form-group label="Option D" label-for="pil_d" v-if="question.question_type == 'pilihan'">
                    <b-form-textarea
                        id="pil_d"
                        v-model.trim="question.pil_d"
                        placeholder="Tulis Pertanyaan." :class="{ 'is-invalid': question.errors.has('pil_d') }"/>
                        <has-error :form="question" field="pil_d" ></has-error>
                </b-form-group>
                <b-form-group label="Option E" label-for="pil_e" v-if="question.question_type == 'pilihan'">
                    <b-form-textarea
                        id="pil_e"
                        v-model.trim="question.pil_e"
                        placeholder="Tulis Pertanyaan." :class="{ 'is-invalid': question.errors.has('pil_e') }"/>
                        <has-error :form="question" field="pil_e" ></has-error>
                </b-form-group>
                <b-form-group label="Jawaban">
                    <b-form-textarea v-if="question.question_type == 'essay'"
                        v-model.trim="question.jawaban"
                        placeholder="Tulis Jawaban Essay." :class="{ 'is-invalid': question.errors.has('jawaban') }"/>
                    <b-form-radio-group v-if="question.question_type == 'pilihan'" 
                            size="sm"
                            v-model="question.jawaban"
                            :options="[{value:'a', text:'A'},{value:'b', text:'B'},{value: 'c', text: 'C'},{value: 'd', text: 'D'},{value: 'e', text: 'E'}]"
                            buttons
                            button-variant="outline-primary"
                            :class="{ 'is-invalid': question.errors.has('jawaban') }"/>
                    <has-error :form="question" field="jawaban"></has-error>
                </b-form-group>
            </b-col>
        </b-row>
        <div class="separator mt-2 mb-6"></div>
    </b-form>
    <div v-else class="text-center text-primary py-14">
        <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
<!--   COMPONENT NOT FOUND
<div>
    <not-found></not-found>
</div>
-->   
    </div>
</template>
<script>

export default {
    props: ['preparation','question','question_groups','state'],
    methods: {
        setActive(data) {
            this.question.is_active = data.value ? 1 : 0
        }
    }
}
</script>