/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from 'vue'
window.Vue = require('vue');

if (process.env.APP_ENV === 'production') {
    console.log = function () {};
}

require('./bootstrap');
import { BootstrapVue } from 'bootstrap-vue'

import moment from 'moment';
Vue.prototype.moment = moment
import './functions/table';

import { Form, HasError, AlertError } from 'vform';
window.Form = Form;
window.Form.axios = window.axios;

import Gate from "./Gate";
Vue.prototype.$gate = new Gate(window.user);

import VueIframe from 'vue-iframes'
Vue.use(VueIframe)

import Swal from 'sweetalert2';
const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
window.Swal = Swal;
window.Toast = Toast;

import VueToast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
Vue.use(VueToast, {
    transition: "Vue-Toastification__fade",
    maxToasts: 3
})

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

import KnobControl from 'vue-knob-control'
Vue.use(KnobControl)

import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
    color: 'rgb(143, 255, 199)',
    failedColor: 'red',
    height: '3px'
});

import excel from 'vue-excel-export'
Vue.use(excel)

Vue.use(BootstrapVue)

import { ContentLoader } from 'vue-content-loader'
Vue.component('ContentLoader', ContentLoader)

import money from 'v-money'
Vue.use(money, {
    allowDecimal: true,
    decimal: '.',
    thousands: ',',
    precision: 2,
    masked: false
})

import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
Vue.component('datetime', Datetime)

import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin);

import VueFlashMessage from 'vue-flash-message'
Vue.use(VueFlashMessage, {
    messageOptions: {
        timeout: 6000,
        pauseOnInteract: true
    }
})

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)

import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
Vue.use(MonthPicker)
Vue.use(MonthPickerInput)

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters)

Vue.component(HasError.name, HasError)
Vue.component(AlertError.name, AlertError)

import { CoolSelectPlugin } from "vue-cool-select";
import("vue-cool-select/dist/themes/bootstrap.css");
//import("vue-cool-select/dist/themes/material-design.css"); jika menggunakan theme material
Vue.use(CoolSelectPlugin)

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
Vue.use(Treeselect)
Vue.component('treeselect', Treeselect )
/**
 * Routes imports and assigning
 */
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import routes from './routes';

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach( (to, from, next) => {
    const nearestWithTitle = to.meta.title;
    const appName = document.querySelector("meta[name='app-name']").getAttribute('content')

    if ( typeof nearestWithTitle != 'undefined' && nearestWithTitle != 'Not Found' ) {
        document.title = `${nearestWithTitle} | ${appName}`
    } else {
        document.title = appName
    }
        
    if(to.matched.some(record => record.meta.requiresAuth)) {
        const gateService = new Gate(window.user);
        if (window.user){
            if (gateService.hasAccess(to.meta.action)) {
                next()
            }else{
                //if ( typeof action === 'undefined') {
                //    next()
                //}else{
                    next()
                //}
            }
        }else{
            next({path: '/'})
        }
    }else{
        next()
    }
})
// Routes End

Vue.filter('toCurrency', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: 0
    });
    return formatter.format(value);
});
Vue.filter('toCurrencyUnlabel', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 0
    });
    return formatter.format(value);
    //return value.toFixed(0).replace(/\d(?=(\d{3})+\.)/g, '$&,');
});

Vue.filter('toNumber', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 0
    });
    return formatter.format(value);
});

Vue.filter('myDate',function(created){
    return moment(created).format('MMMM Do YYYY');
});

Vue.filter('yesno', value => (value ? '<i class="fas fa-check green"></i>' : '<i class="fas fa-times red"></i>'));

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

 
// Components
import notification from './components/control/Notification.vue';

Vue.component('notification', require('./components/control/Notification.vue')).default;
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('dashboard', require('./components/pages/Dashboard.vue'));

Vue.component(
    'uploader',
    require('./components/control/Uploader').default
);

Vue.component(
    'select2',
    require('./components/control/Select2').default
);

Vue.component(
    'omni',
    require('./components/control/Omni').default
);

Vue.component(
    'form-loader',
    require('./components/loading/FormLoader.vue').default
);

Vue.component(
    'passport-clients',
    require('./components/passport/Clients.vue').default
);

Vue.component(
    'passport-authorized-clients',
    require('./components/passport/AuthorizedClients.vue').default
);

Vue.component(
    'passport-personal-access-tokens',
    require('./components/passport/PersonalAccessTokens.vue').default
);

Vue.component(
    'not-found',
    require('./components/NotFound.vue').default
);

Vue.component(
    'va-chart',
    require('./components/control/Chart.vue').default
);

Vue.component('example-component', require('./components/ExampleComponent.vue'));

export const eventBus = new Vue();

const app = new Vue({
    el: '#app',
    router,
    components: {
        notification
    }
}).$mount('#app');
