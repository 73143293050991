<template>
    <div class="panel__body pt-4 ml-4">
        <div class="form">
            <BranchForm :preparation="preparation" :trainer="trainer" :state="true" v-on:updatetrainer="updateTrainer($event)"></BranchForm>

            <div class="row mb-6">
                <div class="col-12 mb-2">
                    <button @click="submit" :disabled="preparation" class="btn btn-secondary btn-lg mr-2">Save</button>
                    <router-link :to="{ name : 'trainer.data'}" class="btn btn-outline-primary btn-lg">Back</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BranchForm from './Form'

export default {
    data(){
        return {
            preparation: true,
            trainer: new Form({
                id:'',
                name: '',
                phone : '',
                email : '',
                address : '',
                type : ''
            })
        }
    },
    created() {
        const vm = this
        this.trainer.reset();
        const getTrainerData = new Promise((resolve) => {
            axios.get(`/api/trainer/fetch/${this.$route.params.id}`).then((data) => {
                resolve(data.data.data);
                this.trainer.fill(data.data.data);
            });
            resolve()
        })

        Promise.all([getTrainerData]).then(() => {
            vm.preparation = false
        })
    },
    methods: {
        updateTrainer(mempl){
            this.trainer.name = mempl.name;
            this.trainer.phone = mempl.mobile_phone_number;
            this.trainer.email = mempl.email;
            this.trainer.address = mempl.residential_address;
        },
        submit() {
            this.preparation = true;
            this.trainer.submit('post', '/api/trainer/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'trainer.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                this.preparation = false;
            });
        }
    },
    components: {
        BranchForm
    }
}
</script>