<template>
    <div class="panel__body pt-4 ml-4" v-if="!isLoading">
        <b-tabs content-class="mt-3">
            <b-tab title="Manual Entry" active>
                <BranchForm :preparation="isLoading" :empl="empl" :allbranch="allbranch" :allgrade="allgrade" :alljob="alljob" :allorganization="allorganization" :state="false"></BranchForm>
        
                <div class="row mb-6">
                    <div class="col-12 mb-2">
                        <button v-if="$gate.hasAccess('empl.create')" type="submit" :disabled="isLoading" @click="submit" class="btn btn-secondary btn-lg mr-2">Save</button>
                        <router-link :to="{ name : 'empl.data'}" class="btn btn-outline-primary btn-lg">Back</router-link>
                    </div>
                </div>
            </b-tab>
            <b-tab title="Upload">
                <b-form class="form"  enctype="multipart/form-data">
                    <b-row>
                        <b-col>
                            <div class="form-group">
                                <label>File Empl</label>
                                <uploader name="upload_file" description="Maks 2MB dengan ekstensi (xls, xlsx)." accept=".xls,.xlsx" v-bind:files.sync="upload_fields.files" id="upload_file" :class="{ 'is-invalid': upload_fields.errors.has('files') }"/>
                                <has-error :form="upload_fields" field="files"></has-error>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class = "mb-6">
                        <b-col class = "mb-2">
                            <b-button v-if="$gate.hasAccess('empl.update') || $gate.hasAccess('empl.create')" @click.prevent="uploadFiles" class="btn btn-outline-primary btn-lg mr-2">Upload</b-button>
                            <router-link :to="{ name : 'empl.data'}" class="btn btn-outline-primary btn-lg mr-2">Back</router-link>
                            <export-excel  class="btn btn-outline-primary btn-lg" 
                                :data  = "template">
                                <i class="fa fa-download red"></i> Template
                            </export-excel>
                        </b-col>
                    </b-row>
                </b-form>
            </b-tab>
        </b-tabs>
    </div>
    <div v-else>
        <form-loader></form-loader>
    </div>
</template>

<script>

import BranchForm from './Form'

export default {
    data(){
        return {
            isLoading : true,
            empl : new Form({
                 employee_id : '', created_by : '', first_name : '', last_name : '', email  : '', date_of_birth  : '1900-00-00', 
                 gender : '1', marital_status : '1', place_of_birth : '', mobile_phone_number : '', home_phone_number : '', 
                 blood_type : '1', religion  : '2', branch_id : '', organization_id : '', job_position_id : '', job_level : '5',
                 employment_status : '1', join_date : '1900-00-00', end_employment_status_date  : '1900-00-00', schedule  : '', citizen_id_type : '', 
                 citizen_id : '', citizen_address : '', residential_address : '', postal_code : '', grade_id  : '', class : '', approval_line  : '', basic_salary  : '0', 
                 ptkp_status  : '1', tax_configuration   : '1', type_salary  : '1', salary_configuration  : '1', jht_configuration  : '0', 
                 employee_tax_status  : '0', jp_configuration  : '0', npp_bpjs_ketenagakerjaan  : '', overtime_status  : '1', 
                 bpjs_kesehatan_config  : '', payment_schedule  : '', npwp  : '', bank_name  : '', bank_account  : '', bank_account_holder  : '',
                 bpjs_ketenagakerjaan  : '', bpjs_kesehatan  : '', bpjs_kesehatan_family  : '0', taxable_date  : '1900-00-00', overtime_working_day_default  : '0',
                 overtime_day_off_default  : '0', overtime_national_holiday_default  : '0', beginning_netto  : '0', pph21_paid  : '0', bpjs_ketenagakerjaan_date  : '1900-00-00',
                 bpjs_kesehatan_date  : '1900-00-00', jp_date  : '1900-00-00', prorate_type  : '', invite_ess : '',nik:''
            }),
            template : [{
                employee_id  : `'Employee ID data dari mekari, default 0'`,
                created_by : `'Diisi dengan ID pembuat di aplikasi mekari, default 0'`,
                nik: `'isi dengan NIK karyawan'`,
                first_name : `'isi dengan nama depan'`,
                last_name : `'isi dengan nama akhir'`,
                email : `'isi dengan email'`,
                date_of_birth : `'isi dengan tanggal lahir (yyyy-mm-dd : 2020-12-31)'`,
                gender : `'isi dengan jenis kelamin 1 => Male,2 => Female'`,
                marital_status : `"isi dengan status perkawinan 1 => Single,2 => Married,3 => Widow,4 => Widower"`,
                place_of_birth : `"isi dengan kota lahir"`,
                mobile_phone_number : `"isi dengan noHP"`,
                home_phone_number : `"isi dengan nomor telephone rumah"`,
                blood_type : `"isi dengan golongan darah 1 => A,2 => B,3 => AB,4 => O"`,
                religion : `"isi dengan agama 1 => Katolik,2 => Islam,3 => Kristen,4 => Buddha,5 => Hindu,6 => Confucius,7 => Others,8 => Orthodox"`,
                branch_id : `"isi dengan kode branch yang terdaftar di aplikasi (JB, SS, CB)"`,
                organization_id : `"isi dengan nama organization yang terdaftar di aplikasi"`,
                job_position_id : `"isi dengan nama posisi yang terdaftar di aplikasi"`,
                job_level : `"isi dengan job level, default 1"`,
                employment_status : `"isi dengan status kepegawaian 1 => Fulltime,2 => Contract,3 => Probation,4 => Days,5 => Other"`,
                join_date  : `'isi dengan tanggal bergabung (yyyy-mm-dd : 2020-12-31)'`,
                end_employment_status_date : `'isi dengan tanggal non aktif/resign (yyyy-mm-dd : 2020-12-31), default 1900-00-00 '`,
                schedule : `'isi dengan schedule name, default kosong'`,
                citizen_id_type : `'isi dengan jenis pengenal kewarganegaraan 1 => KTP,2 => Passport,3 => SIM,4 => Other'`,
                citizen_id : `'isi dengan nomor pengenal'`,
                citizen_address : `'isi dengan alamat di pengenal'`,
                residential_address : `'isi dengan tempat tinggal dipengenal'`,
                postal_code : `'isi dengan kode pos'`,
                grade_id : `'isi dengan nama grade'`,
                class : `'isi dengan nama class, default kosongkan'`,
                approval_line : `'isi dengan nama atasan'`,
                basic_salary : `'isi dengan jumlah gaji, default 0'`,
                ptkp_status : `'isi dengan status PTKP 1 => TK0,2 => TK1,3 => TK2,4 => TK3,5 => K0,6 => K1,7 => K2,8 => K3'`,
                tax_configuration : `'isi dengan Tax config 1 => Gross,2 => Gross Up,3 => Netto'`,
                type_salary           : `'isi dengan Jenis gaji 1 => Monthly, 2 => Daily'`,
                salary_configuration  : `"isi dengan konfigurasi gaji '1' => 'Taxable',  '2' => 'Non-Taxable'"`,
                jht_configuration     : `"isi dengan jht konfigurasi 0 => Not Paid,1 => Paid By Company,2 => Paid By Employee,3 => Default"`,
                employee_tax_status   : `"isi dengan tax status 0 => Pegawai Tetap,1 => Pegawai Tidak Tetap,2 => Bukan Pegawai yang Bersifat Berkesinambungan,3 => Bukan Pegawai yang tidak Bersifat Berkesinambungan,4 => Ekspatriat,5 => Ekspatriat Dalam Negeri,6 => Tenaga Ahli yang Bersifat Berkesinambungan,7 => Tenaga Ahli yang Tidak Bersifat Berkesinambungan,8 => Dewan Komisaris"`,
                jp_configuration      : `"isi dengan konfigurasi JP '0' => 'Not Paid','1' => 'Paid by Company','2' => 'Paid by Employee', '3' => 'Default'"`,
                npp_bpjs_ketenagakerjaan : `"isi dengan NPP bpjs tenaga kerja"`,
                overtime_status      : `"isi dengan overtime status '1' => 'Eligible','2' => 'Non-Eligible'"`,
                bpjs_kesehatan_config : `"isi dengan bpjs kesehatan konfigurasi '1' => 'By Company','2' => 'By Employee','3' => 'Default'"`,
                payment_schedule     : `'isi dengan schedule pembayaran'`,
                npwp                 : `'isi dengan nomor npwp'`,
                bank_name            : `'isi dengan nama bank'`,
                bank_account         : `'isi dengan nomor rekening'`,
                bank_account_holder   : `'isi dengan nama di rekening'`,
                bpjs_ketenagakerjaan  : `'isi dengan nomor bpjs ketenagakerjaan'`,
                bpjs_kesehatan       : `'isi dengan nomor bpjs kesehatan'`,
                bpjs_kesehatan_family : `'isi jumlah keluarga bpjs kesehatan, default 1'`,
                taxable_date          : `'isi dengan tanggal Pajak (yyyy-mm-dd), default 1900-00-00 '`,
                overtime_working_day_default : `'isi dengan tarif kerja lembur hari kerja, default 0'`,
                overtime_day_off_default : `'isi dengan tarif lembur hari libur, default 0 '`,
                overtime_national_holiday_default : `'isi dengan tarif lembur hari libur nasional, default 0'`,
                beginning_netto       : `'isi dengan nominal beginning saldo netto pph, default 0'`,
                pph21_paid           : `'isi dengan pph 21 yang telah dibayar, default 0 '`,
                bpjs_ketenagakerjaan_date : `'isi dengan tanggal join pbjs ketenagakerjaan  (yyyy-mm-dd), default 1900-00-00 '`,
                bpjs_kesehatan_date   : `'isi dengan tanggal join bpjs kesehatan (yyyy-mm-dd), default 1900-00-00 '`,
                jp_date               : `'isi dengan tanggal JP (yyyy-mm-dd), default 1900-00-00 '`,
                prorate_type          : `'isi dengan prorate type '`,
                invite_ess            : `'isi dengan 1 : true, 0 : false'`
            }],
            allbranch : [],
            allgrade : [],
            allorganization : [],
            alljob : [],
            upload_fields: new Form({
                files: ''
            }),
        }
    },
    created() {
        const vm = this
        const getAllBranchData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/branch/fetch/all`
            }).then((data) => {
                let tmp = data.data.data.items.filter((branch) => !branch.parent_branch_id );
                vm.allbranch = tmp.map((branch) => {
                                    return {
                                        id: branch.id,
                                        label: branch.name,
                                        children : branch.children ? this.$helper.prosesarraybranch(branch.children,data.data.data.items) : []
                                    }
                                });
                                resolve(data);
            });
        })
        const getAllJobsData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/jobposition/fetch/all`
            }).then((data) => {
                let tmp = data.data.data.items.filter((job) => !job.parent_job_id );
                vm.alljob = tmp.map((job) => {
                    return {
                        id: job.id,
                        label: job.name,
                        children : job.children ? this.$helper.prosesarrayjob(job.children,data.data.data.items) : []
                    }    
                });
                resolve(data);
            });
        })
        const getAllGradeData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/grade/fetch/all`
            }).then((data) => {
                vm.allgrade = data.data.data.items.map((grade) => {
                    return {
                        id: grade.id,
                        label: grade.name,
                    }    
                });
                resolve(data);
            });
        })
        const getAllOrgsData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/org/fetch/all`
            }).then((data) => {
                let tmp = data.data.data.items.filter((org) => !org.parent_organization_id );
                vm.allorganization = tmp.map((org) => {
                                    return {
                                        id: org.id,
                                        label: org.name,
                                        children : org.children ? this.$helper.prosesarrayorg(org.children,data.data.data.items) : []
                                    }
                                });
                resolve(data);
            });
        })
        
        Promise.all([getAllBranchData,getAllJobsData,getAllGradeData,getAllOrgsData]).then(() => {
            vm.isLoading = false
        })
    },
    methods: {
        uploadFiles(){
            const vm = this
            vm.isLoading = true;
            this.upload_fields.submit('post', '/api/empl/upload', {
                transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'empl.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
            });
        },
        submit() {
            this.isLoading = true;

            this.empl.submit('post', '/api/empl/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'empl.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                
            });
            this.isLoading = false;
        }
    },
    components: {
        BranchForm
    }
}
</script>