<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title mr-2">Periode List</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>

                <form class="form-inline ml-3" @submit.prevent="loadPeriodes()">
                    <div class="input-group input-group-sm">
                        <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" v-model="filter.q"   > <!--@keypress.enter="loadUsers()"-->
                        <div class="input-group-append">
                        <button class="btn btn-navbar" type="submit">
                            <i class="fa fa-search"></i>
                        </button>
                        </div>
                    </div>
                </form>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover table-responsive table--middle table--primary mt-2">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Active</th>
                      <th>Description</th>
                      <th>Created</th>
                      <th class="stick-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="periode in periodes.data" :key="periode.id">
                      <td>{{periode.id}}</td>
                      <td class="text-capitalize">{{periode.name}}</td>
                      <td class="text-capitalize">{{periode.is_active ? 'Active' : 'Inactive'}}</td>
                      <td>{{periode.description}}</td>
                      <td>{{periode.created_at}}</td>
                      <td>

                        <a href="#" @click="editModal(periode)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="periodes" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New Periode</h5>
                    <h5 class="modal-title" v-show="editmode">Update Periode</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updatePeriode() : createPeriode()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Name</label>
                            <input v-model="form.name" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <b-form-group label="Active" label-for="is_active">
                            <b-form-radio-group
                                size="sm"
                                id="is_active"
                                v-model="form.is_active"
                                :options="[{value:'1', text:'Active'},{value:'0', text:'Inactive'}]"
                                buttons
                                button-variant="outline-primary"
                            ></b-form-radio-group>
                            <has-error :form="form" field="is_active"></has-error>
                        </b-form-group>
                        <div class="form-group">
                            <label>Description</label>
                            <input v-model="form.description" type="text" name="description"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                            <has-error :form="form" field="description"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                periodes : {},
                form: new Form({
                    id : '',
                    name: '',
                    is_active: false,
                    description: '',
                }),
                filter: {
                    q: '',
                    limit: 10
                },
            }
        },
        methods: {

            getResults(page = 1) {
                  this.$Progress.start();
                  
                  axios.get('/api/periode?page=' + page).then(({ data }) => (
                    this.periodes = data.data
                  ));
                  this.$Progress.finish();
            },
            updatePeriode(){
                this.$Progress.start();
                this.form.put('/api/periode/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');

                    this.loadPeriodes();
                })
                .catch(() => {
                    this.$Progress.fail();
                });

            },
            editModal(periode){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(periode);
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },

            loadPeriodes(){
                const getUsersData = new Promise((resolve) => {
                    axios({
                        method: 'get',
                        params: this.filter,
                        url: `/api/periode`
                    }).then(({ data }) => {
                        this.periodes = data.data;
                        resolve(data);
                    });
                })
                Promise.all([getUsersData]).then(() => {
                    this.editmode = false;
                });
            },
            
            createPeriode(){

                this.form.post('/api/periode')
                .then((response)=>{
                    $('#addNew').modal('hide');

                    Toast.fire({
                            icon: 'success',
                            title: response.data.message
                    });

                    this.$Progress.finish();
                    this.loadPeriodes();
                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            }

        },
        mounted() {
            //console.log('Component mounted.')
        },
        created() {
            this.$Progress.start();
            this.loadPeriodes();
            this.$Progress.finish();
        }
    }
</script>
