<template>
    <div class="panel__body pt-4 ml-4">
        <BranchForm :chanel="chanel" :allchanels="allchanels" :state="false"></BranchForm>

        <div class="row mb-6">
            <div class="col-12 mb-2">
                <button type="submit" :disabled="isLoading" @click="submit" class="btn btn-secondary btn-lg mr-2">Save</button>
                <router-link :to="{ name : 'chanel.data'}" class="btn btn-outline-primary btn-lg">Back</router-link>
            </div>
        </div>
    </div>
</template>
<script>

import BranchForm from './Form'

export default {
    data(){
        return {
            isLoading : true,
            allchanels : [],
            chanel : new Form({
                id:'',
                name: '',
                code :'',
                is_active :''
            })
        }
    },
    created() {
        const vm = this;

        const getAllChanelData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/chanel/fetch/all`
            }).then((data) => {
                let tmp = data.data.data.items.filter((job) => !job.parent_chanel_id );
                vm.allchanels =tmp.map((branch) => {
                                    return {
                                        id: branch.id,
                                        label: branch.name,
                                        children : branch.children ? this.$helper.prosesarraychanel(branch.children,data.data.data.items) : []
                                    }
                                });
                                resolve(data);
            });
        })
        Promise.all([getAllChanelData]).then(() => {
            vm.isLoading = false
        })
    },
    methods: {
        submit() {
            this.isLoading = true;

            this.chanel.submit('post', '/api/chanel/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'chanel.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                
            });
            this.isLoading = false;
        }
    },
    components: {
        BranchForm
    }
}
</script>