<template>
    <b-form v-if="!preparation" class="form" enctype="multipart/form-data">
        <b-row>
            <b-col lg="5" class="mr-2">
               <b-form-group label="Name *" label-for="name">
                    <b-form-textarea
                        id="name"
                        v-model.trim="grade.name"
                        placeholder="Ceklist" :class="{ 'is-invalid': grade.errors.has('name') }"/>
                    <has-error :form="grade" field="name"></has-error>
                </b-form-group>
                <b-form-group label="Group *" label-for="ceklist_group_id">
                    <treeselect
                        id="ceklist_group_id"
                        :options="groups"
                        v-model="grade.ceklist_group_id"
                        :searchable="true"
                        :placeholder="loading ? 'Reloading ceklist group' : 'Choose a group'"
                        :class="{ 'is-invalid': grade.errors.has('ceklist_group_id') }"
                        />
                    <has-error :form="grade" field="ceklist_group_id"></has-error>
                </b-form-group>
            </b-col>    
        </b-row>
        <div class="separator mt-2 mb-6"></div>
    </b-form>
    <div v-else class="text-center text-primary py-14">
        <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
    </div>
</template>
<script>

export default {
    props: ['preparation','grade','state'],
    data(){
        return {
            loading:true,
            groups: []
        }
    },
    created() {
        const vm = this;
        const getGroupData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/ceklistgroup/fetch/all`
            }).then((data) => {
                vm.groups = data.data.data.items.map((group) => {
                                    return {
                                        id: group.id,
                                        label: group.name
                                    }
                                });
                resolve(data);
            });
        })
        Promise.all([getGroupData]).then(() => {
            vm.loading = false
        })
    }
}
</script>