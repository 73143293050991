<template>
    <div class="panel__body pt-4 ml-4">
        <div class="form">
            <BranchForm :preparation="preparation" :polling="polling" :allperiode="allperiode" :state="true" :alltrainer="alltrainers"></BranchForm>

            <div class="row mb-6">
                <div class="col-12 mb-2">
                    <button @click="submit" :disabled="preparation" class="btn btn-secondary btn-lg mr-2">Save</button>
                    <router-link :to="{ name : 'polling.data'}" class="btn btn-outline-primary btn-lg">Back</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BranchForm from './Form'
import moment from 'moment';
export default {
    data(){
        return {
            preparation: true,
            allperiode : [],
            alltrainers : [],
            polling: new Form({
                id:'',
                name: '',
                description:'',
                is_active:'',
                periode_id:'',
                from:'',
                to:'',
                trainer_id:'',
                time_limit:0,
                type:'',
                parent_polling_id:0
            })
        }
    },
    created() {
        const vm = this
        this.polling.reset();
        const getPollingData = new Promise((resolve) => {
            axios.get(`/api/polling/fetch/${this.$route.params.id}`).then((data) => {
                this.polling.fill(data.data.data);
                this.polling.from = moment(this.polling.from).format()
                this.polling.to = moment(this.polling.to).format()
                resolve(data.data.data);
            });
            resolve()
        })
        const getAllPeriodeData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/periode/list`
            }).then(data => {
                vm.allperiode = data.data.data.map(a => {
                      return { label: a.name + ' (' + a.description + ')', id: a.id };
                  });
                resolve(data);
            });
        })
        const getAllTrainerData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/trainer/fetch/all`
            }).then(data => {
                if (data.data.data.items){
                    vm.alltrainers = data.data.data.items.map(a => {
                      return { label: a.name , id: a.id };
                    });
                }

                resolve(data);
            });
        })
        Promise.all([getPollingData,getAllPeriodeData,getAllTrainerData]).then(() => {
            vm.preparation = false
        })
    },
    methods: {
        submit() {
            this.preparation = true;
            
            this.polling.submit('post', '/api/polling/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            if (key == 'from' || key == 'to'){
                                mdata.set(`${key}`,moment(data[key]).format('YYYY-MM-DD HH:mm:ss'));
                            }else  mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'polling.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                this.preparation = false;
            });
        }
    },
    components: {
        BranchForm
    }
}
</script>