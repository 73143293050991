<template>
    <b-form v-if="!preparation" class="form" enctype="multipart/form-data">
        <b-row>
            <b-col lg="5" class="mr-2">
                <b-form-group label="Name *" label-for="name">
                    <b-form-input  v-model="chanel.name" id="name" required placeholder="Chanel Name"  :class="{ 'is-invalid': chanel.errors.has('name') }"></b-form-input>
                    <has-error :form="chanel" field="name"></has-error>
                </b-form-group>
                <b-form-group label="Code *" label-for="code">
                    <b-form-input  v-model="chanel.code" id="code" required placeholder="Chanel Code"  :class="{ 'is-invalid': chanel.errors.has('code') }"></b-form-input>
                    <has-error :form="chanel" field="code"></has-error>
                </b-form-group>
                <b-form-group label="Active" label-for="active">
                    <b-form-radio-group
                        size="sm"
                        id="active"
                        v-model="chanel.is_active"
                        :options="[{value:'1', text:'Active'},{value:'0', text:'InActive'}]"
                        buttons
                        button-variant="outline-primary"
                        :class="{ 'is-invalid': chanel.errors.has('is_active') }"
                    ></b-form-radio-group>
                    <has-error :form="chanel" field="is_active"></has-error>
                </b-form-group>
                <b-form-group label="Parent *">
                    <treeselect
                        :options="allchanels"
                        :show-count="true"
                        :default-expand-level="1"
                        v-model="chanel.parent_chanel_id"
                        @input="updateValue"
                        placeholder="Parent Chanel?"
                        :class="{ 'is-invalid': chanel.errors.has('parent_chanel_id') }"
                        />
                    <has-error :form="chanel" field="parent_chanel_id"></has-error>
                </b-form-group>
            </b-col>    

        </b-row>
        <div class="separator mt-2 mb-6"></div>
    </b-form>
    <div v-else class="text-center text-primary py-14">
        <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
<!--   COMPONENT NOT FOUND
<div>
    <not-found></not-found>
</div>
-->   
    </div>
</template>
<script>

export default {
    props: ['preparation','chanel','allchanels','state'],
    methods: {
        updateValue(val){
            if (val) this.chanel.parent_chanel_id = val;
            else this.chanel.parent_chanel_id = ''
            Toast.fire({
                      icon: 'success',
                      title:val
                    });
        }
    }
}
</script>