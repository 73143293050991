<template>
    <div class="container-fluid" v-if="!preparation">
        <b-row class="align-items-center pb-1">
            <b-col  lg="8">
                <router-link :to="{ name: 'role.create'}" class="btn btn-primary btm-sm mt-1">Create New</router-link>
            </b-col>
            <b-col  lg="4">
                <b-form class="form form-inline form--rounded justify-content-end btn-sm" @submit.prevent="getRoles()">
                    <b-form-group v-if="checked.length">
                        <b-button @click="bulkDelete" class="btn btn-danger btm-sm" size="sm"><i class="fa fa-trash red"></i>Bulk Delete</b-button>
                    </b-form-group>
                    
                    <b-form-group class="form-inline ml-3" >
                        <b-input-group size="sm">
                            <b-form-input type="search" class="form-control form-control-navbar" v-model="filter.q" placeholder="Search" aria-label="Search" />
                            <div class="input-group-append">
                                <button class="btn btn-navbar" type="submit" >
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                            
                        </b-input-group>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>

        <b-table
            id="role-table"
            class="table--middle table--primary mt-2" show-empty responsive
            :items="roles.items"
            :fields="tableFields"
            :tbody-tr-class="rowHighlight"
            :busy.sync="preparation">
            <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
                </div>
            </template>
            <template v-slot:empty><div class="text-center text-muted">Data not found</div></template>

            <template v-slot:head(check)>
                <div class="checkbox checkbox--all">
                    <input id="check-all" v-model="checkall" type="checkbox">
                    <label for="check-all"></label>
                </div>
            </template>

            <template v-slot:cell(no)="data">{{ ((page > 0 ? (page - 1) : 0) * filter.limit) + (data.index +1 ) }}</template>

            <template v-slot:cell(action)="data">
                <div class="btn-group">
                    <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-wrench"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" role="menu" x-placement="bottom-end" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-124px, 18px, 0px);">
                        <a class="dropdown-item" @click="accessRole(data.item.id, data.item.name)" variant="primary">
                            <i class="nav-icon fas fa-list orange"></i>Access
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" v-if="$gate.hasAccess('role.update')">
                            <router-link :to="{ name: 'role.update', params: {id: data.item.id} }" style="box-sizing: content-box;height: 17px;"><i class="fas fa-edit"></i>Edit</router-link>
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" @click="removeRole(data.item.id)" variant="danger">
                            <i class="fas fa-times"></i> Delete
                        </a>
                    </div>
                </div>
            </template>

            <template v-slot:cell(check)="data">
                <div class="checkbox">
                    <input :id="`checkbox-${data.item.id}`" :value="data.item.id" v-model="checked" type="checkbox">
                    <label :for="`checkbox-${data.item.id}`"></label>
                </div>
            </template>
        </b-table>

        <b-row>
            <b-col lg="6">
                <div class="form form--small form-inline justify-content-center justify-content-lg-start">
                    <b-form-group label-class="text-primary mr-1" label="GO TO " label-for="page">
                        <b-form-input type="number" v-model="page" id="page" class="form-control"></b-form-input>
                    </b-form-group>
                    <b-form-group label-class="text-primary ml-2 mr-2" label="VIEW " label-for="filter_limit">
                        <select2 id="filter_limit" v-model="filter.limit" :options="[10, 20, 30]" :settings="{minimumResultsForSearch: -1}" />
                    </b-form-group>
                </div>
            </b-col>
            <b-col lg="6">
                <b-pagination
                    align="right"
                    v-model="page"
                    :total-rows="roles.total"
                    :per-page="filter.limit"
                    aria-controls="role-table">
                </b-pagination>
            </b-col>
        </b-row>        
        
        <BModal id="access_role" body-class="pt-4 pb-0" size="lg" centered :title="`Access for ${name}`"   >
            <b-form class="form form-inline form--rounded justify-content-end btn-sm" @submit.prevent="findAccess(q)">
                <b-form-group class="form-inline ml-3" >
                    <b-input-group size="sm">
                        <b-form-input type="search" class="form-control form-control-navbar" v-model="q" placeholder="Search" aria-label="Search" />
                        <div class="input-group-append">
                            <button class="btn btn-navbar" type="submit" >
                                <i class="fa fa-search"></i>
                            </button>
                        </div>
                    </b-input-group>
                </b-form-group>
            </b-form>

            <div v-if="tmpaccess.items && ! loadingAccess">
                <div v-for="(item, i) in tmpaccess.items" class="mb-6" :key="i">
                    <h3 class="display-0 mb-2 font-weight-bold text-primary">{{  item.group_name.charAt(0).toUpperCase()  +   item.group_name.slice(1) }}</h3>

                    <div class="px-4 mb-2" v-if="item.accesses">
                        <div v-for="(access, j) in item.accesses" class="checkbox checkbox--with-label mr-3" :key="j" style="display: inline-block;">
                            <input :id="`access_code_${i}-${j}`" :value="access.code" v-model="access.is_enable" type="checkbox">
                            <label :for="`access_code_${i}-${j}`">{{ access.name.charAt(0).toUpperCase()  +  access.name.slice(1)  }}</label>
                        </div>
                    </div>
                </div>
            </div>
            
            <p v-else>Loading...</p>

            <template slot="modal-footer">
                <b-button variant="secondary" @click="saveAccess(role_id, access.items)">Save</b-button>
            </template>
        </BModal>
    </div>      
    <div v-else>
        <form-loader></form-loader>
    </div>
</template>
<script>
import { BModal } from 'bootstrap-vue'

export default {
    data() {
        return {
            preparation: true,
            loadingAccess: true,
            roles:{},
            access:{},
            tmpaccess:{},
            mpage:1,
            role_id: 0,
            name: '',
            checkall: false,
            checked: [],
            filter: {
                q: '',
                limit: 30
            },
            q:'',
            tableFields: [
                { key: 'no', label: 'No.', tdClass: 'text-right', thAttr: { width: '50' } },
                { key: 'name', label: 'Name' },
                { key: 'code', label: 'Code', thAttr: { width: '140' } },
                { key: 'description', label: 'Description', thAttr: { width: '240' } },
                { key: 'action', label: 'Action', tdClass: 'text-center', thAttr: { width: '160' } },
                { key: 'check', thAttr: { width: '30' } , tdClass: 'text-center stick-end', thClass: 'stick-end' }
            ]
        }
    },
    created() {
        this.getRoles()
    },
    computed: {
        page: {
            get() {
                return this.mpage;
            },
            set(val) {
                this.mpage = val;
            }
        }
    },
    methods: {
        rowHighlight(item, type) {
            if (!item || type !== 'row') return
            if (item.id === this.$route.params.row_id) return 'highlight'
        },
        getRoles(){
            this.preparation = true;
            this.$Progress.start();

            const getRolesData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    params: this.filter,
                    url: `/api/role/fetch?page=${this.page}`
                }).then((data) => {
                    this.roles = data.data.data;
                    resolve(data.data.data);
                });
            })
            Promise.all([getRolesData]).then(() => {
                this.preparation = false;
            });

            this.$Progress.finish();
        },
        findAccess(q){
            this.loadingAccess = true
            const getAccessData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    params: {q:q},
                    url: `/api/role/access/fetch-by-role/${this.role_id}`
                }).then((data) => {
                    this.tmpaccess = data.data.data
                    resolve(data.data.data);
                });
            })
            Promise.all([getAccessData]).then(() => {
                this.tmpaccess.items.map( (item) => {
                    var foundIndex = this.access.items.findIndex(x => x.group_name == item.group_name);
                    if (foundIndex){
                        item.accesses.map( (itemaccess) => {
                            var foundsubindex = this.access.items[foundIndex].accesses.findIndex(x => x.code == itemaccess.code);
                            if (foundsubindex){
                                itemaccess = this.access.items[foundIndex].accesses[foundsubindex]
                            }
                        })
                    }
                })
                this.loadingAccess = false
            });
        },
        accessRole(id, name) {
            this.role_id = id
            this.name = name
            this.$bvModal.show('access_role')
            this.loadingAccess = true

            const getAccessData = new Promise((resolve) => {

                axios.get(`/api/role/access/fetch-by-role/${id}`).then((data) => {
                    this.access = data.data.data;
                    this.tmpaccess = data.data.data
                    resolve(data.data.data);
                });
            })
            Promise.all([getAccessData]).then(() => {
                this.loadingAccess = false
            });
        },        
        async saveAccess(role_id, access) {
            var vm = this
            let access_codes = []

            await Promise.all(access.map(async (role) => {
                await Promise.all(role.accesses.map( (access) => {
                    if ( access.is_enable ) {
                        access_codes.push(access.code)
                    }
                } ))
            })).then(() => {
                let formData = {
                    role_id: role_id,
                    access_codes: access_codes
                }
                
                return new Promise((resolve, reject) => {
                    axios.post(`/api/role/access/update`, formData)
                    .then((response) => {
                        vm.$bvModal.hide('access_role')
                        Toast.fire({
                            icon: 'success',
                            title: response.data.message
                        });
                        resolve(response.data)
                    })
                })
            })
        },
        bulkDelete() {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    const DeleteData = new Promise((resolve, reject) => {
                        axios.post('/api/role/bulk-delete', {ids: this.checked})
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            resolve(error.response.data)
                        })
                    })
                    Promise.all([DeleteData]).then((response) => {
                        this.getRoles()
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    });
                }
            })
        },
        removeRole(id) {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                // Send request to the server
                if (result.value) {
        
                    const DeleteRoles = new Promise((resolve, reject) => {
                        axios.post('/api/role/delete', {id: id})
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            resolve(error.data)
                        })
                    });
                    Promise.all([DeleteRoles]).then((response) => {
                        this.getRoles()
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    });
                }
            });
        }
    },
    components: {
        'b-modal' : BModal
    },
    watch: {
       'q': function() {
            if (this.q == ''){
                this.tmpaccess = this.access
            }
        },
        tmpaccess(){
            this.tmpaccess.items.map( (item) => {
                var foundIndex = this.access.items.findIndex(x => x.group_name == item.group_name);
                if (foundIndex){
                    item.accesses.map( (itemaccess) => {
                        var foundsubindex = this.access.items[foundIndex].accesses.findIndex(x => x.code == itemaccess.code);
                        if (foundsubindex){
                            this.access.items[foundIndex].accesses[foundsubindex] = itemaccess 
                        }
                    })
                }
            })
        },
        'filter.limit': function() {
            this.getRoles()
        },
        page() {
            this.checked = []
            this.getRoles()
        },
        checkall(checkall) {
            if ( checkall ) {
                this.roles.items.forEach(role => {
                    this.checked.push(role.id)
                });
            } else {
                this.checked = []
            }
        }
    }
}
</script>