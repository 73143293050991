<template>
    <div class="panel__body pt-4 ml-4">
        <b-tabs content-class="mt-3">
            <b-tab title="Manual Entry" active>
                <BranchForm :polling="polling" :allperiode="periodes" :state="false" :alltrainer="trainers"></BranchForm>
        
                <div class="row mb-6">
                    <div class="col-12 mb-2">
                        <button type="submit" :disabled="isLoading" @click="submit" class="btn btn-secondary btn-lg mr-2">Save</button>
                        <router-link :to="{ name : 'polling.data'}" class="btn btn-outline-primary btn-lg">Back</router-link>
                    </div>
                </div>
            </b-tab>
            <b-tab title="Upload">
                <b-form class="form"  enctype="multipart/form-data">
                    <b-row>
                        <b-col>
                            <div class="form-group">
                                <label>File Polling</label>
                                <uploader name="upload_file" description="Maks 2MB dengan ekstensi (xls, xlsx)." accept=".xls,.xlsx" v-bind:files.sync="upload_fields.files" id="upload_file" :class="{ 'is-invalid': upload_fields.errors.has('files') }"/>
                                <has-error :form="upload_fields" field="files"></has-error>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class = "mb-6">
                        <b-col class = "mb-2">
                            <b-button @click.prevent="uploadFiles" class="btn btn-outline-secondary btn-lg mr-2">Upload</b-button>
                            <router-link :to="{ name : 'polling.data'}" class="btn btn-outline-primary btn-lg mr-2">Back</router-link>
                        </b-col>
                    </b-row>
                </b-form>
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>

import BranchForm from './Form'
import moment from 'moment';

export default {
    data(){
        return {
            isLoading : false,
            polling : new Form({
                id:'',
                name: '',
                description:'',
                periode_id:'',
                is_active :'',
                from:'',
                to:'',
                trainer_id : '',
                time_limit:0,
                type:'learning',
                parent_polling_id:0
            }),
            periodes : [],
            trainers : [],
            upload_fields: new Form({
                files: ''
            }),
        }
    },
    created() {
        const vm = this
        const getAllPeriodeData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/periode/list`
            }).then(data => {
                vm.periodes = data.data.data.map(a => {
                      return { label: a.name + ' (' + a.description + ')', id: a.id };
                  });

                resolve(data);
            });
        })
        const getAllTrainerData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/trainer/fetch/all`
            }).then(data => {
                if (data.data.data.items){
                    vm.trainers = data.data.data.items.map(a => {
                        return { label: a.name , id: a.id };
                    });
                }

                resolve(data);
            });
        })
        Promise.all([getAllPeriodeData,getAllTrainerData]).then(() => {
            vm.isLoading = false
        })
    },
    methods: {
        uploadFiles(){
            const vm = this
            vm.isLoading = true;
            this.upload_fields.submit('post', '/api/polling/upload', {
                transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            if (key == 'from' || key == 'to'){
                                mdata.set(`${key}`,moment(data[key]).format('YYYY-MM-DD HH:mm:ss'));
                            }else  mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
                }],
            }).then((response) => {
                this.$router.push({name: 'polling.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
            });
        },
        submit() {
            this.isLoading = true;
            this.polling.from = moment(this.polling.from).format('YYYY-MM-DD HH:mm:ss');
            this.polling.to = moment(this.polling.to).format('YYYY-MM-DD HH:mm:ss');
            
            this.polling.submit('post', '/api/polling/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'polling.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                
            });
            this.isLoading = false;
        }
    },
    components: {
        BranchForm
    }
}
</script>