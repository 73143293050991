<template>
    <div class="panel__body pt-4 ml-4">
        <div class="form">
            <BranchForm :preparation="preparation" :question="question" :question_groups="question_groups" :state="true"></BranchForm>

            <div class="row mb-6">
                <div class="col-12 mb-2">
                    <button v-if="$gate.hasAccess('question.update')" @click="submit" :disabled="preparation" class="btn btn-secondary btn-lg mr-2">Save</button>
                    <router-link :to="{ name : 'question.data'}" class="btn btn-outline-primary btn-lg">Back</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BranchForm from './Form'

export default {
    data(){
        return {
            preparation: true,
            question: new Form({
                id:'',
                question: '',
                question_group_id: '',
                question_type: '',
                is_active: '',
                jawaban: '',
                pil_a: '',
                pil_b: '',
                pil_c: '',
                pil_d: '',
                pil_e: '',
                ilustration:''
            }),
            question_groups : []
        }
    },
    created() {
        const vm = this
        this.question.reset();
        const getQuestionData = new Promise((resolve) => {
            axios.get(`/api/question/fetch/${this.$route.params.id}`).then((data) => {
                resolve(data.data.data);
                this.question.fill(data.data.data);
            });
            resolve()
        })
        const getGroupData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/questiongroup/fetch/all`
            }).then((data) => {
                vm.question_groups = data.data.data.items.map((group) => {
                                    return {
                                        id: group.id,
                                        label: group.name
                                    }
                                });
                resolve(data);
            });
        })
        Promise.all([getGroupData,getQuestionData]).then(() => {
            vm.preparation = false
        })
    },
    methods: {
        submit() {
            this.preparation = true;
            this.question.submit('post', '/api/question/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'ilustration'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'question.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                this.preparation = false;
            });
        }
    },
    components: {
        BranchForm
    }
}
</script>