<template>
    <b-form v-if="!preparation" class="form" enctype="multipart/form-data">
        <b-row>
            <b-col lg="4">
                <b-form-group label="Name *" label-for="name">
                    <b-form-input  v-model="role.name" id="name" required placeholder="Role Name"  :class="{ 'is-invalid': role.errors.has('code') }"></b-form-input>
                    <has-error :form="role" field="name"></has-error>
                </b-form-group>
                <b-form-group label="Code *" label-for="code">
                    <b-form-input  v-model="role.code" id="code" required placeholder="Role Code" :class="{ 'is-invalid': role.errors.has('code') }"></b-form-input>
                    <has-error :form="role" field="code"></has-error>
                </b-form-group>
                <b-form-group label="Description" label-for="desc">
                    <b-form-textarea
                        id="desc"
                        v-model.trim="role.description"
                        placeholder="Tulis deskripsi jika perlu." :class="{ 'is-invalid': role.errors.has('description') }"/>
                        <has-error :form="role" field="description" ></has-error>
                </b-form-group>
            </b-col>
        </b-row>

        <div class="separator mt-2 mb-6"></div>
    </b-form>
    <div v-else class="text-center text-primary py-14">
        <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
<!--   COMPONENT NOT FOUND
<div>
    <not-found></not-found>
</div>
-->   
    </div>
</template>
<script>

export default {
    props: ['preparation','role'],
}
</script>