<template>
    <div class="panel__body pt-4 ml-2 mr-2">
        <ReportForm
            v-if="!preparation"
            :hasUi = "true"
            :type="'report'"
            :tableFields="tableFields"
            :form-fields="formFields"
            :category="category"
            :report-type="report_type"
            :report-types="[
                {
                    id: 'penjualanstore',
                    text: 'Penjualan Store '
                },
            ]" />
        <div v-else class="text-center text-primary py-14">
            <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
        </div>
    </div>
</template>
<script>
import ReportForm from '../../control/Form'

export default {
    data() {
        return {
            preparation: true,
            category: 'reportstore',
            report_type: 'penjualanstore',
            branches : [],
            lstbranch : [],
            allbranches : [],
            tableFields: [
                { key: 'code', label: 'Kode Branch' },
                { key: 'name', label: 'Nama Branch' },
                { key: 'address', label: 'Alamat Branch' },
                { key: 'shift1', label: 'Total Shift 1', tdClass: 'text-center', thAttr: { width: '250' } },
                { key: 'shift2', label: 'Total Shift 2', tdClass: 'text-center', thAttr: { width: '250' } },
                { key: 'total', label: 'Total Penjualan', thAttr: { width: '150' } , tdClass: 'text-center stick-end', thClass: 'stick-end' }
            ]
        }
    },
    created() {
        const vm = this
        this.lstbranch = window.user.branches

        const getAllBranchData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/branch/fetch/all`
            }).then((data) => {
                vm.branches = data.data.data.items;
                resolve(data);
            });
        })
        
        Promise.all([getAllBranchData]).then(() => {
            vm.allbranches = vm.branches.filter((branch) => !branch.parent_branch_id );
            vm.allbranches = vm.allbranches.map((branch) => {
                            if (branch.children){
                                return {
                                    id: branch.id,
                                    label: branch.name,
                                    children : branch.children ? this.$helper.prosesarraybranchdisabled(branch.children,vm.branches,this.lstbranch) : []
                                }
                            }else{
                                return {
                                    id: branch.id,
                                    label: branch.name,
                                    isDisabled : this.lstbranch.find((lst) => lst.branch_id == branch.id) ? false : true
                                }
                            }
                        });  
            this.preparation = false;
        });
    },
    computed: {
		formFields: {
            get() {
				const vm = this
                return [
                    { 
                        reportTypes: ['penjualanstore'],
                        fields: [
                            {
                                id: 'branch_id',
                                label: 'Branch',
                                placeholder: 'Pilih Branch',
                                type: 'tree',
                                multiple: false,
                                size: 4,
                                options: vm.allbranches
                            },
                            { 
                                id: 'sales_date',
                                label: 'Tanggal Penjualan',
                                type: 'date-range',
                                from: 'sales_start',
                                to: 'sales_end',
                                size: 4,
                            },
                        ]
                    }
                ]
            }
        }
    },
    components: {
        ReportForm
    },
}
</script>