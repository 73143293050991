<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Cashier List</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0 ">
                <table class="table table-hover table--middle table--primary mt-2 text-nowrap text-center">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Name</th>
                      <th>Code</th>
                      <th>Description</th>
                      <th>Branch</th>
                      <th>Activer</th>
                      <th class="stick-end">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(cashier, index)  in cashiers.data" :key="cashier.id">
                      <td>{{ index + 1 }}&nbsp;</td>
                      <td>{{cashier.name}}</td>
                      <td>{{cashier.code}}</td>
                      <td>{{cashier.description | truncate(30, '...')}}</td>
                      <td>{{cashier.branch.name}}</td>
                      <td>
                          <ToggleButton class="mb-0" @change="(e) => setActive(e, cashier.id)" :value="cashier.active ? true : false" :width="80" :labels="{checked: 'Aktif', unchecked: 'Non-aktif'}"></ToggleButton>
                      </td>
                      <td class="stick-end">
                        <a href="#" @click="editModal(cashier)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteProduct(cashier.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="cashiers" @pagination-change-page="getResults" :limit="10"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title" v-show="!editmode">Create New Cashier</h5>
                      <h5 class="modal-title" v-show="editmode">Edit Cashier</h5>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>

                  <form enctype="multipart/form-data" @submit.prevent="editmode ? updateProduct() : createProduct()">
                    <div class="modal-body">
                      <b-tabs content-class="mt-3">
                        <b-tab title="Manual Entry" active @click="setType('manual')">
                          <div class="form-group">
                              <label>Name</label>
                              <input v-model="form.name" type="text" name="name"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                              <has-error :form="form" field="name"></has-error>
                          </div>
                          <div class="form-group">
                              <label>Code</label>
                              <input v-model="form.code" type="text" name="code"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('code') }">
                              <has-error :form="form" field="code"></has-error>
                          </div>
                          <div class="form-group">
                              <label>Description</label>
                              <input v-model="form.description" type="text" name="description"
                                  class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                              <has-error :form="form" field="description"></has-error>
                          </div>
                          <b-form-group label="Is Active" label-for="is_active" >
                            <b-form-radio-group
                                size="sm"
                                id="is_active"
                                v-model="form.active"
                                :options="[{value:'1', text:'Active'},{value:'0', text:'Inactive'}]"
                                required 
                                buttons
                                button-variant="outline-primary"
                                :class="{ 'is-invalid': form.errors.has('active') }"
                            ></b-form-radio-group>
                            <has-error :form="form" field="price"></has-error>
                          </b-form-group>
                          <b-form-group label="Branch" label-for="branch">
                            <treeselect
                                id="branch_id"
                                :options="branches"
                                :show-count="true"
                                :default-expand-level="1"
                                :searchable="true"
                                v-model="form.branch_id"
                                placeholder="Branch?"
                                />
                            <has-error :form="form" field="branch_id"></has-error>
                          </b-form-group>
                        </b-tab>
                        <b-tab title="Upload" @click="setType('upload')">
                            <b-row>
                                <b-col>
                                    <div class="form-group">
                                        <label>File Cashier</label>
                                        <uploader name="upload_file" description="Maks 2MB dengan ekstensi (xls, xlsx)." accept=".xls,.xlsx" v-bind:files.sync="upload_fields.files" id="upload_file" :class="{ 'is-invalid': upload_fields.errors.has('files') }"/>
                                        <has-error :form="upload_fields" field="files"></has-error>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-tab>
                      </b-tabs>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode && type == 'manual'" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode && type == 'manual'" type="submit" class="btn btn-primary">Create</button>
                        <b-button v-show="type == 'upload'" @click.prevent="uploadFiles" class="btn btn-success">Upload</b-button>
                        <export-excel  class="btn btn-primary" 
                            :data  = "[{ name : 'diisi dengan nama cashier',
                                         code : 'diisi dengan code cashier, pastikan code sama dengan POS',
                                         description : 'diisi dengan deskripsi cashier',
                                         active : 'diisi dengan harga cashier example : 1 active, 0 sebaliknya',
                                         category_id : 'diisi dengan ID Branch atau code Branch yang terdapat dalam aplikasi'
                                    }]">
                            <i class="fa fa-download red"></i> Template
                        </export-excel>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                cashiers : {},
                form: new Form({
                    id : '',
                    branch : '',
                    name: '',
                    code : '',
                    description: '',
                    branch_id: '',
                    active: '1',
                }),
                upload_fields: new Form({
                  files: ''
                }),
                type:'manual',
                branches: [],
            }
        },
        methods: {
          setActive(data, id) {
            let formData = {
                id: id,
                is_active: data.value ? 1 : 0
            }
            
            return new Promise((resolve, reject) => {
                axios.post(`/api/cashier/setactive`, formData)
                .then((response) => {
                     Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    resolve(response.data)
                })
            });
          },
          setType(type){
            this.type = type;
          },
          uploadFiles(){
            const vm = this
            this.upload_fields.submit('post', '/api/product/upload', {
                transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                  Toast.fire({
                      icon: 'success',
                      title: response.data.message
                  });
                  this.loadProducts()
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
            });
          },
          getResults(page = 1) {
              this.$Progress.start();
              axios.get('api/cashier?page=' + page).then(({ data }) => (this.cashiers = data.data));
              this.$Progress.finish();
          },
          loadProducts(){
              axios.get("api/cashier").then(({ data }) => (this.cashiers = data.data));
          },
          editModal(product){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(product);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createProduct(){
              this.$Progress.start();

              this.form.post('api/cashier')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadProducts();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateProduct(){
              this.$Progress.start();
              this.form.put('api/cashier/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadProducts();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deleteProduct(id){
              Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Yes, delete it!'
                  }).then((result) => {
                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/cashier/'+id).then(()=>{
                                      Swal.fire(
                                      'Deleted!',
                                      'Your file has been deleted.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadProducts();
                              }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                  })
          },
        },
        created() {
            this.$Progress.start();
            const getAllBranchData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    url: `/api/branch/fetch/all`
                }).then((data) => {
                    let tmp = data.data.data.items.filter((branch) => !branch.parent_branch_id );
                    this.branches = tmp.map((branch) => {
                                        return {
                                            id: branch.id,
                                            label: branch.name,
                                            children : branch.children ? this.$helper.prosesarraybranch(branch.children,data.data.data.items) : []
                                        }
                                    });
                    resolve(data);
                });
            })
            Promise.all([getAllBranchData]).then(() => {
                this.loadProducts()
                this.$Progress.finish();
            })
        },
        filters: {
            truncate: function (text, length, suffix) {
                return text.substring(0, length) + suffix;
            },
        },
        
    }
</script>
