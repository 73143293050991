window.jQuery = require('jquery');
window.$ = window.jQuery;
/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */
try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
    require('admin-lte');
} catch (e) {}

window._ = require('lodash');
import helper from './functions/helper';
let mhelper = new helper();
Vue.prototype.$helper = mhelper;
let token = document.head.querySelector('meta[name="csrf-token"]');

if (token && window.user){
    mhelper.grantPermission();
    mhelper.startEcho(window.user.id,token.content);
}

import axios from 'axios'

if (token) {
    const $axios = axios.create({
        //baseURL: '/api',
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With' : 'XMLHttpRequest',
            'X-CSRF-TOKEN': token.content
        }
    });

    $axios.interceptors.response.use(
    (data) => {
        switch(data.status) {
            case 401 : // Unauthorized
                let reftoken = '';
                const getRefreshToken = new Promise((resolve, reject) => {
                    return $axios({
                        method: 'post',
                        url: `/refreshCsrf`,
                    }).then((response) => {
                        console.log("=====Refresh token=====")
                        reftoken = response.data.csrf_token
                        resolve(response.data)
                    }).catch((error) => {
                        console.log(error)
                        reject(error)
                    })
                })    
                Promise.all([getRefreshToken]).then(() => {
                    if ( reftoken && reftoken !== token) {
                        document.head.querySelector('meta[name="csrf-token"]').setAttribute("content", reftoken);
                        const originalRequest = data.config;
                        originalRequest._retry = true;
                        $axios.defaults.headers.common['X-CSRF-TOKEN'] = `${reftoken}`;
                        return $axios(originalRequest);
                    }else{
                        this.$router.push({path: '/'}).then(() => {
                            Toast.fire({
                                icon: 'error',
                                title: `Error ${data.status}: ${data.data.message}`
                            });
                        })
                        return Promise.reject(data);
                    }
                })
                break;
            default :
                return data;
        }
    }, (error) => {
        switch(error.status) {
            case 401 : // Unauthorized
                let reftoken = '';
                const getRefreshToken = new Promise((resolve, reject) => {
                    return $axios({
                        method: 'post',
                        url: `/refreshCsrf`,
                    }).then((response) => {
                        console.log("=====Refresh token=====")
                        reftoken = response.data.csrf_token
                        resolve(response.data)
                    }).catch((error) => {
                        console.log(error)
                        reject(error)
                    })
                })    
                Promise.all([getRefreshToken]).then(() => {
                    if ( reftoken && reftoken !== token) {
                        document.head.querySelector('meta[name="csrf-token"]').setAttribute("content", reftoken);
                        const originalRequest = error.config;
                        originalRequest._retry = true;
                        $axios.defaults.headers.common['X-CSRF-TOKEN'] = `${reftoken}`;
                        return $axios(originalRequest);
                    }else{
                        this.$router.push({path: '/'}).then(() => {
                            Toast.fire({
                                icon: 'error',
                                title: `Error ${error.response.status}: ${error.response.data.message}`
                            });
                        })
                        return Promise.reject(error);
                    }
                })
            break;
            default:
                Toast.fire({
                    icon: 'error',
                    title: `Error ${error.status}: ${error.message}`
                });
                return Promise.reject(error)
        }
    })

    window.axios = $axios;
}else{
    Toast.fire({
        icon: 'error',
        title: `Error failed generate and getting csrf token, please reload the application`
    });
}
/** ERROR, DIGUNAKAN UNTUK MENISI TOKEN SETIAP REQUEST SECARA OTOMATIS
 * 
 * 
 * 
$axios.interceptors.request.use(
(config) => {
    //let token = document.head.querySelector('meta[name="csrf-token"]');
    //if (token) {
        config.headers.common['X-CSRF-TOKEN'] = token.content;
    //}
    return config
},
(error) => {
    return Promise.reject(error)
})
 * 
 * 
 * 
 */


/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

//window.axios = require('axios');
//window.axios.defaults.baseURL = '/api';
//window.axios.defaults.headers.common['Content-Type'] = 'application/json';
//window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

 /*let token = document.head.querySelector('meta[name="csrf-token"]');

 if (token) {
     window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
     mhelper.grantPermission();
     mhelper.startEcho(window.user.id,token.content);

     console.log(window.user.id+token.content)
 } else {
    app.$router.push({path: '/'}).then(() => {
        Toast.fire({
            icon: 'error',
            title: 'Session Anda telah berakhir, silakan Login ulang.'
        });
    })
 }
*/