<template>
    <b-form v-if="show" class="form" enctype="multipart/form-data">
        <b-row>
            <b-col lg="12">
                <b-form-group label="Event name *" label-for="event_name">
                    <input type="text" class="form-control w-100 modal-title" v-model="event.title" placeholder="Add title ..." ref="eventTitle" autofocus>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="4">
                <b-form-group label="Start" label-for="start">
                    <Datetime
                        value-zone="UTC+7"
                        type="datetime"
                        input-id="start"
                        v-model="event.start"
                        format="yyyy-MM-dd HH:mm:ss"
                        input-class="form-control" class="input-group">
                        <div class="input-group-append" slot="after">
                            <div class="input-group-text">
                                <i class="fas fa-calendar" @click="event.start = '1900-00-00 00:00:00'"></i>
                            </div>
                        </div>
                    </Datetime>
                </b-form-group>
            </b-col>
            <b-col lg="4">
                <b-form-group label="End" label-for="end">
                    <Datetime
                    value-zone="UTC+7"
                        type="datetime"
                        input-id="end"
                        v-model="event.end"
                        format="yyyy-MM-dd HH:mm:ss"
                        input-class="form-control" class="input-group">
                        <div class="input-group-append" slot="after">
                            <div class="input-group-text">
                                <i class="fas fa-calendar" @click="event.end = '1900-00-00 00:00:00'"></i>
                            </div>
                        </div>
                    </Datetime>
                </b-form-group>
            </b-col>
            <b-col lg="4">
                <b-form-group label="All Day event*">
                    <b-form-radio-group
                        size="sm"
                        id="allday"
                        v-model="event.allday"
                        :options="[{value:'0', text:'Specified'},{value:'1', text:'All day'}]"
                        buttons
                        button-variant="outline-primary"
                    ></b-form-radio-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="12">
                <b-form-group label="Description*">
                    <textarea class="form-control" id="appointmentNote" rows="3" v-model="event.description" placeholder="Description ...">
                    </textarea>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="align-items-center pb-1">
            <b-col  lg="8">
                <treeselect 
                    :options="employees"
                    :show-count="true"
                    :default-expand-level="1"
                    v-model="multivalue"
                    :disable-branch-nodes="true"
                    search-nested
                    :valueFormat="'object'"
                    placeholder="Choose"
                    :multiple="true"
                />
            </b-col>
            <b-col lg="2">
                <b-button class="text-right btn btn-primary btm-sm" size="sm" @click="InsertObject"><i class="fa fa-plus red"></i>Add</b-button>
            </b-col>
            <b-col lg="2" v-if="checkeditem.length">
                <b-button @click="bulkDeleteitem" class="text-right btn btn-danger btm-sm" size="sm"><i class="fa fa-trash red"></i>Delete</b-button>
            </b-col>
        </b-row>

        <div v-if="! isloading">
            <b-table
                id="question-table"
                class="table--middle table--primary mt-2" show-empty responsive
                :items="event.participants"
                :fields="ParticipantFields"
                :tbody-tr-class="SelectionRowHighlight"
                :busy.sync="isloading"
                style="overflow: visible !important">
                <template v-slot:table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
                    </div>
                </template>
                <template v-slot:head(check)>
                    <div class="checkbox checkbox--all">
                        <input id="check-all" v-model="checkitem" type="checkbox">
                        <label for="check-all"></label>
                    </div>
                </template>
                <template v-slot:empty><div class="text-center text-muted">Data not found</div></template>
                <template v-slot:cell(no)="data">{{ (data.index +1 ) }}</template>
                
                <template v-slot:cell(employee_id)="data">
                    <treeselect v-if="selectedindex == data.index" 
                        :options="employees"
                        :show-count="true"
                        :default-expand-level="1"
                        v-model="value"
                        :disable-branch-nodes="true"
                        search-nested
                        :valueFormat="'object'"
                        @input="updateValue"
                        placeholder="Choose"
                    />
                    <span v-else>{{ (data.item.employee ? data.item.employee.name : '') }}</span>
                </template>
                <template v-slot:cell(description)="data">
                    <b-form-group v-if="selectedindex == data.index" >
                        <b-form-input required placeholder="Description" :value="data.item.description" @input="updateDescription($event)"></b-form-input>
                    </b-form-group>
                    <span v-else>{{ (data.item.description ? data.item.description : '') }}</span>
                </template>
                    
                <template v-slot:cell(action)="data">
                    <b-button v-if="data.index !== selectedindex" @click="edit(data.index)" variant="outline-primary" size="xs">Edit</b-button>
                    <b-button v-if="data.index == selectedindex" @click="cancel()" variant="outline-primary" size="xs">Cancel</b-button>
                    <b-button @click="remove(data.index)" variant="outline-danger" size="xs">Delete</b-button>
                </template>

                <template v-slot:cell(check)="data">
                    <div class="checkbox">
                        <input :id="`checkbox-${data.index}`" :value="data.index" v-model="checkeditem" type="checkbox">
                        <label :for="`checkbox-${data.index}`"></label>
                    </div>
                </template>
            </b-table>
        </div>
        <p v-else>Loading...</p>

        <div class="separator mt-2 mb-6"></div>

        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal" data-dismiss="modal">
                Close
            </button>
            <button v-if=" $gate.hasAccess('event.create')"  type="button" class="btn btn-primary" @click="saveEvent()" :disabled="!validEventData">
                Save
            </button>
        </div>
    </b-form>

    <div v-else class="text-center text-primary py-14">
        <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>  
    </div>
</template>

<script>
    import moment from 'moment'
    import { Datetime } from 'vue-datetime'

    export default{
        props: ['show', 'date'],
        components: {
            Datetime,
        },
        data: function() {
          return {
                isloading : true,
                selectedindex: -1,
                selbranchid : '',
                emplbranch : [],
                checkitem:false,
                checkeditem : [],
                event: {
                    start: this.date.start,
                    end: this.date.end,
                    allday:1,
                    title: null,
                    branch_id : null,
                    description : '',
                    created_by : window.user.id,
                    participants:[]
                },
                ParticipantFields: [
                    { key: 'no', label: 'No.', tdClass: 'text-right', thAttr: { width: '50' } },
                    { key: 'employee_id', label: 'Employee' },
                    { key: 'description', label: 'Description' },
                    { key: 'action', label: 'Action', tdClass: 'text-center', thAttr: { width: '160' } },
                    { key: 'check', thAttr: { width: '30' } , tdClass: 'text-center stick-end', thClass: 'stick-end' }
                ],
                employees: [],
                multivalue : [],
                value:{
                    id:'',
                    label:''
                },
            }
        },
        methods: {
            edit(index){
                this.selectedindex = index
            },
            cancel(){
                this.selectedindex = -1
            },
            bulkDeleteitem() {
                Swal.fire({
                title: 'Are you sure?',
                text: "Click Save to finalize deletion!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, Temporary delete it!'
                }).then((result) => {
                    // Send request to the server
                    if (result.value) {
                        this.checkeditem.sort().reverse().forEach(index =>{
                            this.event.participants.splice(index,1)
                        });
                        this.reloaditem()
                        this.selectedindex = -1
                    }
                });
            },
            reloaditem(){
                const vm = this
                if (this.event.participants){
                    vm.employees = vm.emplbranch.map((branch) => {
                        let cek = branch.children.filter((employee) => {
                            return this.event.participants.find((empl) => empl.employee_id == employee.id) ? false : true
                        });

                        if (cek){
                            return {
                                id : branch.id,
                                label : branch.label,
                                children : cek
                            }
                        }
                    })
                }else{
                    vm.employees = vm.emplbranch
                }
                
            },
            InsertObject(){
                console.log (this.multivalue)
                if (this.multivalue.length){
                    this.selectedindex = -1;
                    this.multivalue.forEach(emplorquest => {
                        this.event.participants.unshift({state:'invite',
                                                        description:'',
                                                        employee_id:this.id, 
                                                        employee : {id : emplorquest.id, name : emplorquest.label}});
                    });
                    this.reloaditem()
                }
            },
            updateDescription(val){
                this.event.participants[this.selectedindex].description = val ? val :'';
            },
            updateValue(val){
                if (val){
                    this.event.participants[this.selectedindex].employee_id = val.id
                    this.event.participants[this.selectedindex].employee = {id : val.id, name : val.label}

                    Toast.fire({
                        icon: 'success' ,
                        title: 'This Item will remove from your next choices'
                    });
                    this.reloaditem()
                    this.selectedindex = -1
                }
            },
            prosesarray(array){
                    return array.map(element => {
                        return {
                                id: element.id,
                                label: element.first_name + ' ' + element.last_name
                            }
                    });
            },
            SelectionRowHighlight(item, type) {
                if (!item || type !== 'row') return
                if (item.index === this.selectedindex) return 'highlight'
            },
            closeModal() {
                this.$emit('close')
            },
            saveEvent() {
                /*
                this.$api.appointments.create(newEventData)
                    .then(({
                        data
                    }) => {
                        this.closeModal()
                        this.$emit('event-created')
                    })
                    .catch(error => {
                        this.$emit('error')
                    })
                    */
            },
            getEmployee(){
                const vm = this
                const getAllEmployeeData = new Promise((resolve) => {
                    axios({
                        method: 'get',
                        url: `/api/branch/fetch/all`,
                        params: {groupby : 'employees',
                                    filterbyuser : 1
                                }
                    }).then((data) => {
                        vm.emplbranch = data.data.data.items.map((branch) => {
                                            return {
                                                id: 'branch'+branch.id,
                                                label: branch.name,
                                                children : this.prosesarray(branch.employees)
                                            }
                                        });

                        resolve(data);
                    });
                })

                Promise.all([getAllEmployeeData]).then(() => {
                    this.isloading = false
                    this.reloaditem();
                })
            }
        },
        mounted(){
            this.getEmployee();
            this.event.start = this.date.start;
            this.event.end = this.date.end; 
            console.log(this.date)
        },
        computed: {
            validEventData() {
                return !!(this.event.title && this.event.participant != [])
            }
        },
        watch: {
            checkitem(checkitem) {
                let x = 0
                if ( checkitem ) {
                    if (this.event.participants){
                        this.event.participants.forEach(employee => {
                            this.checkeditem.push(x)
                            x++;
                        });
                    }else{
                        this.checkeditem = []
                    }
                } else {
                    this.checkeditem = []
                }
            },
            'event.start': function(val) {
                console.log(val);
            },
        }
    }
    </script>