<template>
    <div class="uploader">
        <b-row v-if="!disabled" class="align-items-center mb-2">
            <b-col lg="5">
                <b-button @click.prevent="pickFiles"
                    :variant="variant"
                    :size="size"
                    class="btn-file btn-normal"
                    block>{{ label }} <i v-if="icon" :class="icon"></i></b-button>
            </b-col>
            <b-col lg="7" class="pl-lg-0 pr-lg-0">
                <small class="form-text text-muted">{{ description }}</small>
            </b-col>
        </b-row>
        <b-row v-if="newFiles.length || (newFile && newFile !== null)">
            <b-col>
                <div v-if="type === 'image'" class="uploader__preview">
                    <div v-if="multiple === false" class="uploader__preview-image">
                        <img :src="newFile.url || readURL(newFile)">
                        <a v-if="!disabled" class="text-red uploader__preview-image__remove" href="javascript:;" @click="removeFileSingle(newFile.id)" ><i class="fas fa-times orange"></i></a>
                    </div>
                    <div v-else-if="newFiles.length" class="uploader__preview-image" v-for="(file, i) in files" :key="i">
                        <img :src="readURL(file)">
                        <a v-if="!disabled" class="text-red uploader__preview-image__remove" href="javascript:;" @click="removeFile(i, file.id)" ><i class="fas fa-times orange"></i></a>
                    </div>
                </div>
                <div v-else class="uploader__preview">
                    <div v-if="multiple === false">
                        <a v-if="!disabled" class="text-red" href="javascript:;" @click="removeFileSingle(newFile.id)" ><i class="fas fa-times orange"></i></a>
                        <span class="text-primary">{{ newFile.name || newFile.filename || newFile.file_name}}</span>
                    </div>
                    <div v-else>
                        <p class="mb-0" v-for="(file, i) in files" :key="i">
                            <a v-if="!disabled" class="text-red" href="javascript:;" @click="removeFile(i, file.id)" ><i class="fas fa-times orange"></i></a>
                            <span class="text-primary">{{ file.name || file.filename || file.file_name}}</span>
                        </p>
                    </div>
                </div>
            </b-col>
        </b-row>
        <input class="uploader__picker" :accept="accept" type="file" @change="addFiles" :multiple="multiple">
    </div>
</template>
<script>
export default {
    props: {
        name: {
            type: String,
            default: ''
        },
        deleted_files: {
            type: Array,
            default: () => []
        },
        files: {
        },
        limit: {
            type: Number,
            default: 3
        },
        accept: {
            type: String,
            default: '.jpg,.jpeg,.pdf,.doc,.docx,.ppt,.pptx,.xls,.xlsx'
        },
        label: {
            type: String,
            default: 'Upload Lampiran'
        },
        description: {
            type: String,
            default: 'Maks. 3 lampiran, masing-masing dengan ukuran hingga 2 MB (jpg, doc, xls, ppt, atau pdf).'
        },
        type: {
            type: String,
            default: 'file'
        },
        multiple: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        icon: {
            type: [String, Boolean],
            default: 'fas fa-list orange'
        },
        variant: {
            type: String,
            default: 'outline-dark'
        },
        size: {
            type: String,
            default: 'md'
        }
    },
    data() {
        return {
            newFiles: [],
            newFile: ''
        }
    },
    created() {
        if ( this.files && (this.files.length || Object.keys(this.files).length) ) {
            if ( this.multiple ) {
                this.newFiles = this.files
            } else {
                this.newFile = this.files
            }
        }
    },
    methods: {
        readURL(file) {
            return URL.createObjectURL(file)
        },
        pickFiles() {
            $(this.$el).find('.uploader__picker').click()
        },
        removeFile(index, id){
            this.newFiles.splice(index, 1)

            if ( id ) {
                this.deleted_files.push(id)
            }
            this.$emit('update:files', this.newFiles)
        },
        removeFileSingle(id){
            this.newFile = ''
            this.$emit('update:files', this.newFile)
        },
        addFiles: async function(e) {
            let selectedFiles = e.target.files
            if ( this.multiple ) {
                let filesCount = this.files.length || 0
                let limit = Number(this.limit)
                this.newFiles = this.files
                await Promise.all(Array.from(selectedFiles).map((file, i) => {
                    if ( (filesCount + i) < limit ) {
                        this.newFiles.push(file)
                    }
                }))
                this.$emit('update:files', this.newFiles)
            } else {
                this.newFile = selectedFiles[0]
                this.$emit('update:files', this.newFile)
            }
        }
    },
    watch: {
        files: function(files) {
            if ( this.multiple === false ) {
                this.newFile = files
            } else {
                this.newFiles = files
            }
        }
    }
}
</script>