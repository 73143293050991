<template>
    <b-form v-if="!preparation && !isloading" class="form" enctype="multipart/form-data">
        <b-row>
            <b-col lg="5" class="mr-2">
                <b-form-group label="Type" label-for="type">
                    <b-form-radio-group
                        id="type"
                        v-model="trainer.type"
                        :options="[{value:'internal', text:'Internal Trainer'},{value:'external', text:'External Trainer'}]"
                        :class="{ 'is-invalid': trainer.errors.has('type') }"
                    ></b-form-radio-group>
                    <has-error :form="trainer" field="type"></has-error>
                </b-form-group>
            </b-col>
            <b-col lg="5" class="mr-2" v-if="trainer.type === 'internal'">
                <b-form-group label="Employee *">
                    <treeselect
                        :options="allemployees"
                        :show-count="true"
                        :default-expand-level="1"
                        @input="updateValue"
                        placeholder="choose employee?"
                        />
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col lg="5" class="mr-2">
               <b-form-group label="Name *" label-for="name">
                    <b-form-input  v-model="trainer.name" id="name" required placeholder="Trainer Name"  :class="{ 'is-invalid': trainer.errors.has('name') }"></b-form-input>
                    <has-error :form="trainer" field="name"></has-error>
                </b-form-group>
            </b-col>
            <b-col lg="5" class="mr-2">
               <b-form-group label="Phone *" label-for="phone">
                    <b-form-input  v-model="trainer.phone" id="name" required placeholder="Trainer Phone"  :class="{ 'is-invalid': trainer.errors.has('phone') }"></b-form-input>
                    <has-error :form="trainer" field="phone"></has-error>
                </b-form-group>
            </b-col>      
        </b-row>
        <b-row>
            <b-col lg="5" class="mr-2">
               <b-form-group label="Email *" label-for="email">
                    <b-form-input  v-model="trainer.email" id="email" required placeholder="Trainer Email"  :class="{ 'is-invalid': trainer.errors.has('email') }"></b-form-input>
                    <has-error :form="trainer" field="email"></has-error>
                </b-form-group>
            </b-col>
            <b-col lg="5" class="mr-2">
               <b-form-group label="Address *" label-for="address">
                    <b-form-input  v-model="trainer.address" id="address" required placeholder="Trainer Address"  :class="{ 'is-invalid': trainer.errors.has('address') }"></b-form-input>
                    <has-error :form="trainer" field="address"></has-error>
                </b-form-group>
            </b-col>      
        </b-row>
        <div class="separator mt-2 mb-6"></div>
    </b-form>
    <div v-else class="text-center text-primary py-14">
        <b-spinner type="grow" small label="Loading..." class="align-middle" @click="isloading = false"></b-spinner>
<!--   COMPONENT NOT FOUND
<div>
    <not-found></not-found>
</div>
-->   
    </div>
</template>
<script>

export default {
    props: ['preparation','trainer','state'],
    data(){
        return {
            isloading:false,
            allemployees : [],
        }
    },
    created() {
        const vm = this
        const getAllEmployeeData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/branch/fetch/all`,
                params: {groupby : 'employees'}
            }).then((data) => {
                vm.allemployees = data.data.data.items.map((branch) => {
                                    return {
                                        id: 'branch'+branch.id,
                                        label: branch.name,
                                        children : this.prosesarray(branch.employees)
                                    }
                                });
                resolve(data);
            });
        })
    },
    methods: {
        prosesarray(array){
            return array.map(element => {
                return {
                        id: element.id,
                        label: element.first_name + ' ' + element.last_name
                    }
            });
        },
        updateValue(val){
            if (val){
                this.isloading = true;
                const getEmplData = new Promise((resolve) => {
                    axios.get(`/api/empl/fetch/${val}`).then((data) => {
                        this.$emit("updatetrainer", data.data.data);
                        resolve(data);
                    });
                })
                Promise.all([getEmplData]).then(() => {
                    this.isloading = false;
                    Toast.fire({
                        icon: 'success',
                        title:val
                    });
                })
            }
        }
    }
}
</script>