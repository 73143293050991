<template>
    <div class="container-fluid">
        <b-form v-if="!isLoading" class="form" enctype="multipart/form-data">
            <b-row class="align-items-center pb-1 mt-2">
                <b-col lg="4" class="mr-2">
                    <b-form-group label="Periode *" label-for="periode">
                        <div class="form-group form-inline">                            
                            <month-picker-input id="periode" lang = "id" :no-default="true" :show-year="true" :editable-year="true"
                            @change="showDate"
                            @clear="showClearText"
                            style ="z-index : 99 !important"
                            :class="{ 'is-invalid': shifting.errors.has('tahun') || shifting.errors.has('bulan') }"
                            ></month-picker-input>
                            <span class="ml-2 inline text-primary font-weight-medium">{{ (date.year ? date.year : '') + ' ' + (date.month ? date.month : '')  }}</span>
                        </div>
                        <has-error :form="shifting" field="tahun"></has-error>
                    </b-form-group>   
                    <b-form-group label="Branch *" label-for="branch_id">
                        <treeselect
                            :options="allbranches"
                            :show-count="true"
                            :default-expand-level="1"
                            v-model="filter.branch_id"
                            :disable-branch-nodes="true"
                            placeholder="Pilih Branch?" 
                            :class="{ 'is-invalid': shifting.errors.has('branch_id') }"
                            />
                            <has-error :form="shifting" field="branch_id"></has-error>
                    </b-form-group>                    
                </b-col>
                <b-col lg="3">
                    <b-form-group label="Shift" label-for="shift">
                        <b-form-radio-group
                            size="sm"
                            id="shift"
                            v-model="filter.shift"
                            :options="[{value:'', text:'Non Shift'},{value:'shift_1', text:'Shift 1'},{value:'shift_2', text:'Shift 2'},{value: 'shift_3', text: 'Shift 3'}]"
                            buttons
                            button-variant="outline-primary"
                            :class="{ 'is-invalid': shifting.errors.has('shift') }"
                        ></b-form-radio-group>
                        <has-error :form="shifting" field="shift"></has-error>
                    </b-form-group>
                    <div class="mt-4">
                        <div class="dropdown-divider"></div>
                        <button @click.prevent="getShifting()" :disabled="isLoading" class="btn btn-primary btn-lg mr-2">Preview Data</button>
                    </div>
                </b-col>
                <b-col lg="4" v-if="$gate.hasAccess('shifting.update')" >    
                    <b-form-group label="Upload*" label-for="upload_file">
                        <uploader name="upload_file" description="Maks 2MB dengan ekstensi (xls, xlsx)." accept=".xls,.xlsx" v-bind:files.sync="shifting.files" id="upload_file" :class="{ 'is-invalid': shifting.errors.has('files') }"/>
                        <has-error :form="shifting" field="files"></has-error>
                    </b-form-group>
                    <div class="mt-4">
                        <div class="dropdown-divider"></div>
                        <b-button @click.prevent="uploadFiles" :disabled="isLoading"  class="btn btn-outline-primary btn-lg mr-2">Upload</b-button>
                        <export-excel  class="btn btn-outline-primary btn-lg" 
                            :data  = "[{ branch_id : 'diisi dengan ID branch / kode branch',
                                            employee_id : 'diisi dengan ID Employee, hapus kolom ini jika anda menggunakan kolom nik',
                                            nik : 'diisi dengan NIK Employee, pastikan kolom employee_id dihapus jika anda menggunakan ini',
                                            tahun : 'diisi dengan Tahun example : 2020',
                                            bulan : 'diisi dengan Nama Bulan dalam bahasa indonesia example : (Januari, Februari, dlsb)',
                                            tanggal : 'diisi dengan tanggal (Format : 2020-12-31)',
                                            shift : 'diisi dengan Shift (Format : shift_1, shift_2)'
                                    }]">
                            <i class="fa fa-download red"></i> Template
                        </export-excel>
                    </div>
                </b-col>
            </b-row>
        </b-form>

        <b-row class="align-items-center pb-1">
            <b-col  lg="4" >
                <export-excel v-if="shiftings.items"  class="btn btn-primary btm-sm" size="lg"
                    :data  = "shiftings.items.map((item) => {
                            return { branch : item.branch.name,
                                    branch_id : item.branch_id,
                                    employee : item.employee.name,
                                    employee_id : item.employee_id,
                                    nik : item.employee.nik,
                                    tahun : item.tahun,
                                    bulan : item.bulan,
                                    tanggal : item.tanggal,
                                    shift : item.shift
                    }})">
                    <i class="fa fa-download red"></i> Download
                </export-excel>
            </b-col>
            <b-col lg="8">
                <b-form class="form form-inline form--rounded justify-content-end btn-sm" @submit.prevent="getShifting()">
                    <b-form-group v-if="checked.length && $gate.hasAccess('shifting.update')">
                        <b-button @click="bulkDelete" class="btn btn-danger btm-sm" size="sm"><i class="fa fa-trash red"></i>Bulk Delete</b-button>
                    </b-form-group>
                    
                    <b-form-group class="form-inline ml-3" >
                        <b-input-group size="sm">
                            <b-form-input type="search" class="form-control form-control-navbar" v-model="filter.q" placeholder="Search" aria-label="Search" />
                            <div class="input-group-append">
                                <button class="btn btn-navbar" type="submit" >
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                            
                        </b-input-group>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        
        <b-table
            id="grade-table"
            class="table--middle table--primary mt-2" show-empty responsive
            :items="shiftings.items"
            :fields="tableFields"
            :tbody-tr-class="rowHighlight"
            :busy.sync="isLoading"
            style="overflow: visible !important">
            <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
                </div>
            </template>
            <template v-slot:empty><div class="text-center text-muted">Data not found</div></template>

            <template v-slot:head(check)>
                <div class="checkbox checkbox--all">
                    <input id="check-all" v-model="checkall" type="checkbox">
                    <label for="check-all"></label>
                </div>
            </template>
            
            <template v-slot:cell(no)="data">{{ ((page > 0 ? (page - 1) : 0) * filter.limit) + (data.index +1 ) }}</template>
            
            <template v-slot:cell(periode)="data">
                <span class="text-primary font-weight-medium">{{ data.item.tahun + '/' + data.item.bulan  }}</span>
            </template>
            <template v-slot:cell(branch_id)="data">
                <treeselect v-if="selectedindex == data.index"
                    id="branch_id"
                    :options="allbranches"
                    :searchable="true"
                    :disable-branch-nodes="true"
                    v-model="shift.branch_id"
                    placeholder="Branch"
                    />
                <span class="text-primary font-weight-medium" v-else>{{ data.item.branch.name  }}</span>
            </template>
            <template v-slot:cell(employee_id)="data">
                <treeselect v-if="selectedindex == data.index"
                    id="employee_id"
                    :options="allemployee"
                    :searchable="true"
                    v-model="shift.employee_id"
                    placeholder="Nama Karyawan"
                    />
                <span class="text-primary font-weight-medium" v-else>{{ data.item.employee.name  }}</span>
            </template>
            <template v-slot:cell(shift)="data">
                <treeselect v-if="selectedindex == data.index"
                    id="shift"
                    :options="[{ id : '',label : 'Non Shift'},  
                                {id : 'shift_1', label : 'Shift 1'},
                                {id : 'shift_2', label : 'Shift 2'},
                                {id : 'shift_3', label : 'Shift 3'}]"
                    :searchable="true"
                    v-model="shift.shift"
                    placeholder="Shift"
                    />
                <span class="text-primary font-weight-medium" v-else>{{ data.item.shift}}</span>
            </template>
            <template v-slot:cell(tanggal)="data">
                <Datetime v-if="selectedindex == data.index"
                    type = "date"
                    value-zone="UTC+7"
                    format="yyyy-MM-dd"
                    v-model="shift.tanggal"
                    input-class="form-control" class="input-group">
                </Datetime>
                <span class="text-primary font-weight-medium" v-else>{{  moment(data.item.tanggal).format('YYYY-MM-DD')  }}</span>
            </template>

            <template v-slot:cell(action)="data" v-if="$gate.hasAccess('shifting.update')">
                <div class="btn-group">
                    <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-wrench"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" role="menu" x-placement="bottom-end" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-124px, 18px, 0px);">
                        <a class="dropdown-item" @click="edit(data.item, data.index)" v-if="selectedindex != data.index" variant="danger">
                            <i class="fas fa-times"></i>Edit
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" @click="save()" v-if="selectedindex != -1" variant="danger">
                           <i class="fas fa-times"></i>Save
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" v-if="$gate.hasAccess('question.create')" @click="addQuestion(data.item.id,data.item.name)" variant="primary">
                            <i class="fas fa-plus"></i>Add Question
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" @click="cancel()" v-if="selectedindex != -1" variant="danger">
                            <i class="fas fa-times"></i>Cancel
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" @click="remove(data.item.id)" variant="danger">
                            <i class="fas fa-times"></i> Delete
                        </a>
                    </div>
                </div>
            </template>
            <template v-slot:cell(check)="data">
                <div class="checkbox">
                    <input :id="`checkbox-${data.item.id}`" :value="data.item.id" v-model="checked" type="checkbox">
                    <label :for="`checkbox-${data.item.id}`"></label>
                </div>
            </template>
        </b-table>
    
        <b-row>
            <b-col lg="6">
                <div class="form form--small form-inline justify-content-center justify-content-lg-start">
                    <b-form-group label-class="text-primary mr-1" label="GO TO " label-for="page">
                        <b-form-input type="number" v-model="page" id="page" class="form-control"></b-form-input>
                    </b-form-group>
                    <b-form-group label-class="text-primary ml-2 mr-2" label="VIEW " label-for="filter_limit">
                        <select2 id="filter_limit" v-model="filter.limit" :options="[10, 20, 30]" :settings="{minimumResultsForSearch: -1}" />
                    </b-form-group>
                </div>
            </b-col>
            <b-col lg="6">
                <b-pagination
                    align="right"
                    v-model="page"
                    :total-rows="shiftings.total"
                    :per-page="filter.limit"
                    aria-controls="grade-table">
                </b-pagination>
            </b-col>
        </b-row>   
    </div>
    
</template>
<script>
import { MonthPickerInput } from 'vue-month-picker'
import { Datetime } from 'vue-datetime'
import moment from 'moment'
export default {
    name: 'Shifting',
    data(){
        return {
            selectedindex : -1,
            isLoading : true,
            clearEmittedText: null,
            shift : new Form({
                id : '',
                branch_id : '',
                tahun : '',
                bulan : '',
                shift : '',
                tanggal : '',
                employee_id : ''
            }),
            shifting : new Form({
                branch_id : '',
                tahun : '',
                bulan : '',
                shift : '',
                files : '',
            }),
            filter : {    
                branch_id : '',
                tahun : '',
                bulan : '',
                shift : '',
                q : '',
                limit : 30
            },
            date: {
                from: null,
                to: null,
                month: null,
                year: null,
            },
            branches: [],
            lstbranch : [],
            shiftings : [],
            allbranches : [],
            allemployee : [],
            tableFields: [
                { key: 'no', label: 'No.', tdClass: 'text-right', thAttr: { width: '50' } },
                { key: 'periode', label: 'Periode' },
                { key: 'tanggal', label: 'Tanggal' , tdClass: 'text-center text-wrap', thAttr: { width: '100' }  },
                { key: 'employee_id', label: 'Employee' },
                { key: 'branch_id', label: 'Empl. Branch' },
                { key: 'shift', label: 'Shift', tdClass: 'text-center' },
                { key: 'action', label: 'Action', tdClass: 'text-center', thAttr: { width: '160' } },
                { key: 'check', thAttr: { width: '30' } , tdClass: 'text-center stick-end', thClass: 'stick-end' }
            ],
            mpage:1,
            checkall: false,
            checked: [],
        }
    },
    created() {
        const vm = this;
        vm.isLoading = true
        this.lstbranch = window.user.branches
        const getAllBranchData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/branch/fetch/all`
            }).then((data) => {
                vm.branches = data.data.data.items;
                resolve(data);
            });
        })
                    
        Promise.all([getAllBranchData]).then(() => {
            vm.allbranches = vm.branches.filter((branch) => !branch.parent_branch_id );
            vm.allbranches = vm.allbranches.map((branch) => {
                            if (branch.children){
                                return {
                                    id: branch.id,
                                    label: branch.name,
                                    children : branch.children ? this.$helper.prosesarraybranchdisabled(branch.children,vm.branches,this.lstbranch) : []
                                }
                            }else{
                                return {
                                    id: branch.id,
                                    label: branch.name,
                                    isDisabled : this.lstbranch.find((lst) => lst.branch_id == branch.id) ? false : true
                                }
                            }
                        });             
            vm.isLoading = false
        })
    },
    computed: {
        page: {
            get() {
                return this.mpage;
            },
            set(val) {
                this.mpage = val;
            }
        }
    },
    components: {
		MonthPickerInput,
        Datetime
	},
    methods: {
        getShifting(){
            this.isLoading = true;
            if (!this.filter.branch_id){
                Toast.fire({
                    icon: 'error' ,
                    title: 'Please choose one of branch!!'
                });
            }
            const getShiftingData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    params: this.filter,
                    url: `/api/shifting/fetch?page=${this.page}`
                }).then((data) => {
                    this.shiftings = data.data.data;
                    resolve(data.data.data);
                });
            })
            Promise.all([getShiftingData]).then(() => {
                this.isLoading = false;
                this.shift.reset();
            });
        },
        edit(data,index){
            this.shift.fill(data);
            this.selectedindex = index
        },
        save(){
            if (!this.shift.id){
                Toast.fire({
                    icon: 'error' ,
                    title: 'Please choose one of branch!!'
                });
            }else{
                this.isLoading = true;
                this.shift.submit('post', '/api/shifting/store', {
                transformRequest: [function (data, headers) {
                        let mdata = new FormData();
                        for ( var key in data ) {
                            if (key == 'files'){
                                mdata.append(`${key}`, data[key]);
                            }else{
                                mdata.set(`${key}`,data[key]);
                            }
                        }
                        return mdata;
                }],
                }).then((response) => {
                    Toast.fire({
                        icon: 'success',
                        title: response.message
                    });
                    this.getShifting();
                    this.isLoading = false;
                    this.selectedindex = -1;
                }).catch((e) => {
                    Toast.fire({
                        icon: 'error',
                        title: e.message
                    });
                    this.isLoading = false;
                    this.selectedindex = -1;
                });
            }
        },
        cancel(){
            this.shift.reset();
            this.selectedindex = -1
        },
        remove(id){
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    const DeleteRoles = new Promise((resolve, reject) => {
                        axios.post('/api/shifting/delete', {id: id})
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            resolve(error.data)
                        })
                    });
                    Promise.all([DeleteRoles]).then((response) => {
                        this.getShifting()
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    });
                }
            });
        },
        rowHighlight(item, type) {
            if (!item || type !== 'row') return
        },
        updateValue(val){
            Toast.fire({
                icon: 'success' ,
                title: 'Selecting item of '.val
            });
        },
        uploadFiles(){
            const vm = this
            vm.isLoading = true;
            this.shifting.submit('post', '/api/shifting/upload', {
                transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            if (key == 'from' || key == 'to'){
                                mdata.set(`${key}`,moment(data[key]).format('YYYY-MM-DD HH:mm:ss'));
                            }else  mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
            }],
            }).then((response) => {
                this.getShifting();
                this.$router.push({name: 'shifting.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
                this.isLoading = false
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                this.isLoading = false
            });
        },
        showClearText() {
            this.clearEmittedText = 'emitted'
            window.setTimeout(() => {
                this.clearEmittedText = null
            }, 1000)
        },
        showDate (date) {
			this.date = date
            this.filter.tahun = date.year
            this.filter.bulan = date.month
		}
    },
    watch: {
        'filter.tahun': function(tahun) {
            this.shifting.tahun = tahun;
        },
        'filter.bulan': function(bulan) {
            this.shifting.bulan = bulan;
        },
        'filter.shift': function(shift) {
            this.shifting.shift = shift;
        },
        'filter.branch_id' : function (branch_id){
            this.shifting.branch_id = branch_id;
            let allbrch ='';
            if (branch_id){
                if (this.lstbranch.find((lst) => lst.branch_id == branch_id)){
                    allbrch = branch_id
                }else{
                    Toast.fire({
                        icon: 'error',
                        title: 'Cant not select area that not in coverage'
                    });
                    this.isLoading = false;
                    return;
                }
            }else{
                allbrch = this.lstbranch.map((item) => {return item.id});
            }

            this.isLoading = true;
            axios({
                method: 'get',
                url: `/api/empl/fetch/all`,
                params : {branch_id : allbrch}
            }).then((data) => {
                this.allemployee = data.data.data.items.map((empl) => {
                    return {
                        id: empl.id,
                        label: empl.name,
                    }
                }) ;
                this.isLoading = false;
            });
        },
        'filter.limit': function() {
            this.getShifting()
        },
        page() {
            this.shift.reset();
            this.checked = []
            this.getShifting()
            this.selectedindex = -1;
        },
        checkall(checkall) {
            if ( checkall ) {
                this.shiftings.items.forEach(role => {
                    this.checked.push(role.id)
                });
            } else {
                this.checked = []
            }
        }
    }
}
</script>