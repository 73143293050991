<template>
    <div class="panel__body pt-4 ml-4">
        <div class="form">
            <BranchForm :preparation="preparation" :article="article" :multivalue="multivalue" v-on:updateEmployee="setEmployee($event)"></BranchForm>
            <!--<Detail :preparation="preparation" :article_id="$route.params.id"></Detail>-->

            <div class="row mb-6">
                <div class="col-12 mb-2">
                    <button @click="submit" :disabled="preparation" class="btn btn-secondary btn-lg mr-2">Save</button>
                    <router-link :to="{ name : 'article.data'}" class="btn btn-outline-primary btn-lg">Back</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import BranchForm from './Form'

import moment from 'moment'
export default {
    data(){
        return {
            preparation: true,
            multivalue : [],
            article : new Form({
                id:'',
                subject: '',
                berita: '',
                media: '',
                media_type: 'image',
                tanggal: moment().format('YYYY-MM-DD'),
                type: 'public',
                created_by : window.user.id
            })
        }
    },
    created() {
        const vm = this
        this.article.reset()
        this.article.tanggal = moment().format('YYYY-MM-DD')
        this.article.created_by = window.user.id
        const getArticleData = new Promise((resolve) => {
            axios.get(`/api/article/fetch/${this.$route.params.id}`).then((data) => {
                resolve(data.data.data);
                this.article.fill(data.data.data);
                if (data.data.data.employees){
                    this.multivalue = data.data.data.employees.map((item) => {
                        return {
                            id : item.id,
                            label : item.name
                        }
                    })
                }
                
            });
            resolve()
        })
        Promise.all([getArticleData]).then(() => {
            vm.preparation = false
        })
    },
    methods: {
        setEmployee(multivalue) {
            console.log(multivalue);
            this.multivalue = multivalue;
        },
        submit() {
            console.log(this.multivalue);
            this.preparation = true;
            this.article.listemployee = this.multivalue;
            this.article.submit('post', '/api/article/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'media' && data['media_type'] == 'image'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            if (key == 'listemployee'){
                                data.listemployee.map((obj, j) => {
                                    mdata.append(`${'employees'}[${j}][id]`, obj.id);
                                })
                            }else{
                                mdata.set(`${key}`,data[key]);
                            }
                        }
                    }
                 
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'article.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                this.preparation = false;
            });
        }
    },
    components: {
        BranchForm
    }
}
</script>
