<template>
    <div class="panel__body pt-4 ml-4">
        <div class="form">
            <BranchForm :preparation="preparation" :allchanels="allchanels" :chanel="chanel" :state="true"></BranchForm>

            <div class="row mb-6">
                <div class="col-12 mb-2">
                    <button @click="submit" :disabled="preparation" class="btn btn-secondary btn-lg mr-2">Save</button>
                    <router-link :to="{ name : 'chanel.data'}" class="btn btn-outline-primary btn-lg">Back</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BranchForm from './Form'

export default {
    data(){
        return {
            preparation: true,
            allchanels : [],
            chanel: new Form({
                id:'',
                name: '',
                code : '',
                is_active: '',
            })
        }
    },
    created() {
        const vm = this
        this.chanel.reset();
        const getGroupData = new Promise((resolve) => {
            axios.get(`/api/chanel/fetch/${this.$route.params.id}`).then((data) => {
                this.chanel.fill(data.data.data);
                resolve(data.data.data);
            });
            resolve()
        })
        const getAllChanelData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/chanel/fetch/all`
            }).then((data) => {
                let tmp = data.data.data.items.filter((job) => !job.parent_chanel_id );
                vm.allchanels = tmp.map((branch) => {
                                    return {
                                        id: branch.id,
                                        label: branch.name,
                                        children : branch.children ? this.$helper.prosesarraychanel(branch.children,data.data.data.items) : []
                                    }
                                });
                                resolve(data);
            });
        })
        Promise.all([getGroupData,getAllChanelData]).then(() => {
            vm.preparation = false
        })
    },
    methods: {
        submit() {
            this.preparation = true;
            this.chanel.submit('post', '/api/chanel/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'chanel.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                this.preparation = false;
            });
        }
    },
    components: {
        BranchForm
    }
}
</script>