<template>
    <div class="panel__body pt-4 ml-4" v-if="!isLoading">
        <b-tabs content-class="mt-3">
            <b-tab title="Manual Entry" active>
                <BranchForm :preparation="isLoading" :access_group="access_group" :allgroups="allgroups" :state="false"></BranchForm>
        
                <div class="row mb-6">
                    <div class="col-12 mb-2">
                        <button v-if="$gate.hasAccess('access') || $gate.hasAccess('developer')" type="submit" :disabled="isLoading" @click="submit" class="btn btn-secondary btn-lg mr-2">Save</button>
                        <router-link :to="{ name : 'access'}" class="btn btn-outline-primary btn-lg">Back</router-link>
                    </div>
                </div>
            </b-tab>
            <b-tab title="Upload">
                <b-form class="form"  enctype="multipart/form-data">
                    <b-row>
                        <b-col>
                            <div class="form-group">
                                <label>File Access Groups</label>
                                <uploader name="upload_file" description="Maks 2MB dengan ekstensi (xls, xlsx)." accept=".xls,.xlsx" v-bind:files.sync="upload_fields.files" id="upload_file" :class="{ 'is-invalid': upload_fields.errors.has('files') }"/>
                                <has-error :form="upload_fields" field="files"></has-error>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class = "mb-6">
                        <b-col class = "mb-2">
                            <b-button v-if="$gate.hasAccess('access') || $gate.hasAccess('developer')" @click.prevent="uploadFiles" class="btn btn-outline-primary btn-lg mr-2">Upload</b-button>
                            <router-link :to="{ name : 'empl.data'}" class="btn btn-outline-primary btn-lg mr-2">Back</router-link>
                            <export-excel  class="btn btn-outline-primary btn-lg" 
                                :data  = "template">
                                <i class="fa fa-download red"></i> Template
                            </export-excel>
                        </b-col>
                    </b-row>
                </b-form>
            </b-tab>
        </b-tabs>
    </div>
    <div v-else>
        <form-loader></form-loader>
    </div>
</template>

<script>

import BranchForm from './Form'

export default {
    data(){
        return {
            isLoading : true,
            access_group : new Form({
                 id : '', name : '', code : '', icon : '',  module_group_id  : '2', nourut : '1'
            }),
            template : [{
                id  : `'Kode dikosonglan diisi hanya jika untuk update, default 0'`,
                name : `'Nama Access Group, default empty'`,
                code: `'Code Access Group, default empty'`,
                icon : `'isi dengan kode icon'`,
                module_group_id : `'isi dengan nama Aplikasi'`,
                nourut : `'isi dengan index untuk tampilan menu'`
            }],
            allgroups : [],
            upload_fields: new Form({
                files: ''
            }),
        }
    },
    created() {
        const vm = this
        const getAllGroups = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/access_group/client/all`
            }).then((data) => {
                vm.allgroups = data.data.data.items.map((apps) => {
                    return {
                        id: apps.id,
                        label: apps.name,
                    }    
                });
                resolve(data);
            });
        })
        
        Promise.all([getAllGroups]).then(() => {
            vm.isLoading = false
        })
    },
    methods: {
        uploadFiles(){
            const vm = this
            vm.isLoading = true;
            this.upload_fields.submit('post', '/api/access_group/upload', {
                transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'access'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
            });
        },
        submit() {
            this.isLoading = true;

            this.access_group.submit('post', '/api/access_group/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'access'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                
            });
            this.isLoading = false;
        }
    },
    components: {
        BranchForm
    }
}
</script>