<template>
  <section class="content">
    <div class="container-fluid" v-if="!preparation">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h2 class="card-title mr-3">Search </h2>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>

                <form class="form-inline ml-3" @submit.prevent="loadUsers()">
                    <div class="input-group input-group-sm">
                        <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" v-model="filter.q"   > <!--@keypress.enter="loadUsers()"-->
                        <div class="input-group-append">
                        <button class="btn btn-navbar" type="submit">
                            <i class="fa fa-search"></i>
                        </button>
                        </div>
                    </div>
                </form>
              </div>
              <!-- /.card-header -->
              
              <div class="card-body p-0">
                    <table class="table table-hover table--middle table--primary mt-2 text-nowrap text-center">
                        <thead>
                            <tr>
                            <th>ID</th>
                            <th>Role</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Email Verified?</th>
                            <th>Employee</th>
                            <th>Branch</th>
                            <th>Grade</th>
                            <th>Job Position</th>
                            <th>Department</th>
                            <th >Action</th>
                            <th class="stick-end">Created</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="user in users.data" :key="user.id">

                            <td>{{user.id}}</td>
                            <td class="text-capitalize"><div class="d-flex float-left">{{ user.role ? user.role.name : 'Unknown' }}</div></td>
                            <td class="text-capitalize"> 
                                <div class="d-flex justify-content-between">
                                    <div>
                                        <i class='fas fa-user-slash' v-if="user.disabled"></i><i class='fas fa-users' v-else></i>
                                    </div>
                                    <div>
                                        {{  user.name }} 
                                    </div>
                                </div>
                            </td>
                            <td><div class="d-flex float-left">{{user.email}}</div></td>
                            <td :inner-html.prop="user.email_verified_at | yesno"></td>
                            <td><div class="d-flex float-left">{{user.employee ? user.employee.name : ''}}</div></td>
                            <td><div class="d-flex float-right">{{user.employee ? (user.employee.branch ? user.employee.branch.name : '') : ''}}</div></td>
                            <td>{{user.employee ? (user.employee.grade ? user.employee.grade.name : '') : ''}}</td>
                            <td>{{user.employee ? (user.employee.job_position ? user.employee.job_position.name : '') : ''}}</td>
                            <td>{{user.employee ? (user.employee.organization ? user.employee.organization.name : '') : ''}}</td>
                            <td >
                                <div class="btn-group">
                                    <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                        <i class="fas fa-wrench"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right" role="menu" x-placement="bottom-end" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-124px, 18px, 0px);">
                                        <a class="dropdown-item" @click="disableduser(user)">
                                            <i class="fa fa-users blue"></i>{{ !user.disabled ? 'Disable' : 'Enabled' }} User
                                        </a>
                                        <a class="dropdown-divider"></a>
                                        <a class="dropdown-item" v-if="$gate.hasAccess('empl.update') && user.employee">
                                            <router-link :to="{ name: 'empl.update', params: {id: user.employee.id} }" style="box-sizing: content-box;height: 17px;"><i class="fas fa-users"></i>Employee Data</router-link>
                                        </a>
                                        <a class="dropdown-divider"></a>
                                        <a class="dropdown-item" @click="editModal(user)">
                                            <i class="fa fa-edit blue"></i>Edit
                                        </a>
                                        <a class="dropdown-divider"></a>
                                        <a class="dropdown-item" @click="deleteUser(user.id)" variant="danger" v-if="$gate.hasAccess('developer')>
                                            <i class="fa fa-trash red"></i>Delete
                                        </a>
                                    </div>
                                </div>
                            </td>
                            <td class="stick-end">{{user.created_at}}</td>
                            </tr>
                        </tbody>
                    </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="users" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Create New User</h5>
                    <h5 class="modal-title" v-show="editmode">Update User's Info</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->

                <form @submit.prevent="editmode ? updateUser() : createUser()" enctype="multipart/form-data">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Name</label>
                            <input v-model="form.name" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label >Email</label>
                            <input v-model="form.email" type="text" name="email" :disabled="editmode" 
                                class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                            <has-error :form="form" field="email"></has-error>
                        </div>
                        <div class="form-group">
                            <label>{{real_ad && editmode ? 'AD Account': 'Create Window Account' }}</label> &nbsp;
                            <ToggleButton :disabled="real_ad && editmode" :sync="true" class="mb-0" @change="(e) => setActive(e)" :value="real_ad ? true : (form.is_ad ? true : false)" :width="80" :labels="{checked: 'Yes', unchecked: 'No'}"></ToggleButton>
                        </div>
                        
                        <div class="form-group">
                            <label>Password</label>
                            <input v-model="form.password" type="password" name="password"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('password') }" autocomplete="false">
                            <has-error :form="form" field="password"></has-error>
                        </div>
                    
                        <div class="form-group">
                            <label>User Role</label>
                            <treeselect
                                :options="roles"
                                :disable-branch-nodes="true"
                                :show-count="true"
                                v-model="form.role_id"
                                @input="updateRole"
                                placeholder="Choose Role"
                                :class="{ 'is-invalid': form.errors.has('role_id') }"
                                />
                            <!--
                            <select2 v-model="form.role_id"
                                id="role_id"
                                placeholder="Pilih Kategori"
                                :searchable="true" 
                                :settings="{minimumResultsForSearch: -1,allowClear: true}"
                                :options="[{id: '1', text: 'Admin'}, {id: '2', text: 'Standar'}]" />
                                -->
                            <has-error :form="form" field="role_id"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Employee</label>
                            <treeselect
                                :options="employees"
                                :disable-branch-nodes="true"
                                :show-count="true"
                                v-model="form.employee_id"
                                @input="updateEmployee"
                                placeholder="Employee Account"
                                :class="{ 'is-invalid': form.errors.has('employee_id') }"
                                />
                            <has-error :form="form" field="employee_id"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Photo</label>
                            <uploader name="upload_file" description="Maks 2MB dengan ekstensi (jpg, png, gif)." accept=".jpg,.jpeg,.png, .gif" v-bind:files.sync="form.files" id="upload_file" :class="{ 'is-invalid': form.errors.has('files') }"/>
                            <has-error :form="form" field="files"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <form-loader></form-loader>
    </div>
  </section>
</template>

<script>
    export default {
        data () {
            return {
                filter: {
                    q: '',
                    limit: 10
                },
                preparation: true,
                editmode: false,
                users : {},
                real_ad : false,
                form: new Form({
                    id : '',
                    role_id : '',
                    name: '',
                    email: '',
                    password: '',
                    email_verified_at: '',
                    files:'',
                    employee_id:'',
                    is_ad:0
                }),
                employees: [],
                roles:[]
            }
        },
        methods: {
            setActive(data) {
                console.log(data.value)
                this.form.is_ad = data.value ? 1 : 0;
            },
            disableduser(user){
                Swal.fire({
                    title: 'Are you sure to '+ (user.disabled ? 'enabled' : 'disabled') + ' user and wokspace email?',
                    text: "Note : if you disabled, when user deleted, workspace email that integrated will be deleted !!!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, i understand it!'
                    }).then((result) => {
                        if (result.value) {
                            axios.post('/api/ldap/disable', {id : user.id})
                            .then((data) => {
                                if (data.data.data) this.getResults(1);
                                Toast.fire({
                                    icon: data.data.data ? 'success' : 'error',
                                    title: data.data.message
                                });
                            });
                        }
                    });
            },
            updateRole(id){
                this.form.role_id = id;
            },
            updateEmployee(id){
                this.form.employee_id = id;
            },
            prosesarray(array){
                return array.map(element => {
                    return {
                            id: element.id,
                            label: element.name
                        }
                });
            },
            getResults(page = 1) {
                this.$Progress.start();
                
                const getUsersData = new Promise((resolve) => {
                    axios({
                        method: 'get',
                        params: this.filter,
                        url: `/api/user?page=${page}`
                    }).then((data) => {
                        this.users = data.data.data;
                        resolve(data.data.data);
                    });
                });
                
                Promise.all([getUsersData]).then(() => {
                    this.preparation = false;
                    this.$Progress.finish();
                });
            },
            updateUser(){
                this.$Progress.start();
                // console.log('Editing data');
                this.form.submit('post', 'api/user', {
                transformRequest: [function (data, headers) {
                        let mdata = new FormData();

                        for ( var key in data ) {
                            if (key == 'files'){
                                mdata.append(`${key}`, data[key]);
                            }else{
                                mdata.set(`${key}`,data[key]);
                            }
                        }
                        return mdata;
                }],
                }).then((response) => {
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                    this.loadUsers();
                }).catch((e) => {
                    this.$Progress.fail();
                });
            },
            editModal(user){
                this.editmode = true;
                this.form.reset();
                this.form.fill(user);
                this.real_ad = user.is_ad;
                $('#addNew').modal('show');
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                this.real_ad = false;
                $('#addNew').modal('show');
            },
            deleteUser(id){
                Swal.fire({
                    title: 'Are you sure to delete?',
                    text: "You won't be able to revert this action, please note if user is status disabled then workspace email will be delete also !!!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, i know it!'
                    }).then((result) => {
                        // Send request to the server
                        if (result.value) {
                                this.form.delete('api/user/'+id).then(()=>{
                                        Swal.fire(
                                        'Deleted!',
                                        'Your Account has been deleted.',
                                        'success'
                                        );
                                    // Fire.$emit('AfterCreate');
                                    this.loadUsers();
                                }).catch((data)=> {
                                  Swal.fire("Failed!", data.message, "warning");
                              });
                        }
                    })
            },
          loadUsers(){
            this.$Progress.start();

            const getUsersData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    params: this.filter,
                    url: `/api/user`
                }).then((data) => {
                    this.users = data.data.data;
                    resolve(data.data.data);
                });
            })
            Promise.all([getUsersData]).then(() => {
                this.preparation = false;
            });
            
            this.$Progress.finish();
          },
          
          createUser(){
            this.form.submit('post', 'api/user', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                $('#addNew').modal('hide');
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                });

                this.$Progress.finish();
                this.loadUsers();
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });

                this.$Progress.finish();
            });
          }
        },
        mounted() {
            //console.log('User Component mounted.')
        },
        created() {
            const vm = this
            this.$Progress.start();

            const getAllEmployeeData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    url: `/api/branch/fetch/all`,
                    params: {groupby : 'employees'}
                }).then((data) => {
                    vm.employees = data.data.data.items.map((branch) => {
                                        return {
                                            id: 'branch'+branch.id,
                                            label: branch.name,
                                            children : this.prosesarray(branch.employees)
                                        }
                                    });
                    resolve(data);
                });
            })
            const getAllRoleData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    url: `/api/role/fetch/all`
                }).then((data) => {
                    vm.roles = data.data.data.items.map((role) => {
                                        return {
                                            id: role.id,
                                            label: role.name
                                        }
                                    });
                    resolve(data);
                });
            })

            Promise.all([getAllEmployeeData,getAllRoleData]).then(() => {
                this.loadUsers();
                //this.$gate.printuser();
                this.$Progress.finish();
            });
        }
    }
</script>
