<template>
    <b-form v-if="!preparation" class="form" enctype="multipart/form-data">
        <b-row>
            <b-col lg="5" class="mr-2">
                <b-form-group label="Name *" label-for="name">
                    <b-form-input  v-model="branch.name" id="name" required placeholder="Branch Name"  :class="{ 'is-invalid': branch.errors.has('name') }"></b-form-input>
                    <has-error :form="branch" field="name"></has-error>
                </b-form-group>
                <b-form-group label="Code *" label-for="code">
                    <b-form-input  v-model="branch.code" id="code" required placeholder="Code"  :class="{ 'is-invalid': branch.errors.has('code') }"></b-form-input>
                    <has-error :form="branch" field="code"></has-error>
                </b-form-group>
                <b-form-group label="Parent *">
                    <treeselect
                        :options="allbranch"
                        :show-count="true"
                        :default-expand-level="1"
                        v-model="branch.parent_branch_id"
                        @input="updateValue"
                        placeholder="Parent Branch?"
                        />
<!--
                    <select2
                        id="parent_branch_id"
                        v-model="branch.parent_branch_id"
                        placeholder="Pilih Branch Parent"
                        :searchable="true" 
                        :settings="{minimumResultsForSearch: -1,allowClear: true}"
                        :options="allbranch"/>  -->
                    <has-error :form="branch" field="parent_branch_id"></has-error>
                </b-form-group>
                <b-form-group label="City" label-for="city">
                    <b-form-input  v-model="branch.city" id="city" placeholder="Branch City" :class="{ 'is-invalid': branch.errors.has('city') }"></b-form-input>
                    <has-error :form="branch" field="city"></has-error>
                </b-form-group>
                <b-form-group label="Province" label-for="province_name">
                    <b-form-input  v-model="branch.province_name" id="province_name" placeholder="Province Name"  :class="{ 'is-invalid': branch.errors.has('province_name') }"></b-form-input>
                    <has-error :form="branch" field="province_name"></has-error>
                </b-form-group>
            </b-col>  
            <b-col lg="5" class="mr-2">
                <b-form-group label="Post Code" label-for="postcode">
                    <b-form-input  v-model="branch.postcode" id="postcode" required placeholder="Post Code"  :class="{ 'is-invalid': branch.errors.has('postcode') }"></b-form-input>
                    <has-error :form="branch" field="name"></has-error>
                </b-form-group>
                <b-form-group label="Address" label-for="address">
                    <b-form-textarea
                        id="address"
                        v-model.trim="branch.address"
                        placeholder="Tulis Alamat jika perlu." :class="{ 'is-invalid': branch.errors.has('address') }"/>
                        <has-error :form="branch" field="address" ></has-error>
                </b-form-group>
                <b-form-group label="Phone" label-for="phone">
                    <b-form-input  v-model="branch.phone" id="phone" required placeholder="Phone"  :class="{ 'is-invalid': branch.errors.has('phone') }"></b-form-input>
                    <has-error :form="branch" field="phone"></has-error>
                </b-form-group>
                <b-form-group label="Latitude" label-for="lat">
                    <b-form-input  v-model="branch.latitude" id="lat" required placeholder="Latitude"  :class="{ 'is-invalid': branch.errors.has('latitude') }"></b-form-input>
                    <has-error :form="branch" field="latitude"></has-error>
                </b-form-group>
                <b-form-group label="Longitude" label-for="lon">
                    <b-form-input  v-model="branch.longitude" id="lon" required placeholder="Longitude"  :class="{ 'is-invalid': branch.errors.has('longitude') }"></b-form-input>
                    <has-error :form="branch" field="longitude"></has-error>
                </b-form-group>
            </b-col>  
        </b-row>
        <div class="separator mt-2 mb-6"></div>
    </b-form>
    <div v-else class="text-center text-primary py-14">
        <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
<!--   COMPONENT NOT FOUND
<div>
    <not-found></not-found>
</div>
-->   
    </div>
</template>

<script>

export default {
    props: ['preparation','branch','allbranch','state'],
    methods: {
        updateValue(val){
            if (val) this.branch.parent_branch_id = val;
            else this.branch.parent_branch_id = ''
            Toast.fire({
                      icon: 'success',
                      title:val
                    });
        }
    }
}
</script>