<template>
    <div class="container-fluid" v-if="!preparation">
        <b-row class="align-items-center pb-1">
            <b-col  lg="8">
                <router-link :to="{ name: 'access.create'}" class="btn btn-primary btm-sm mt-1">Create New</router-link>
            </b-col>
            <b-col  lg="4">
                <b-form class="form form-inline form--rounded justify-content-end btn-sm" @submit.prevent="getaccessgroups()">
                    <b-form-group v-if="checked.length">
                        <b-button @click="bulkDelete" class="btn btn-danger btm-sm" size="sm"><i class="fa fa-trash red"></i>Bulk Delete</b-button>
                    </b-form-group>
                    
                    <b-form-group class="form-inline ml-3" >
                        <b-input-group size="sm">
                            <b-form-input type="search" class="form-control form-control-navbar" v-model="filter.q" placeholder="Search" aria-label="Search" />
                            <div class="input-group-append">
                                <button class="btn btn-navbar" type="submit" >
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                            
                        </b-input-group>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>

        <b-table
            id="empl-table"
            class="table--middle table--primary mt-2" show-empty responsive
            :items="access_groups.items"
            :fields="tableFields"
            :tbody-tr-class="rowHighlight"
            :busy.sync="preparation">
            <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
                </div>
            </template>
            <template v-slot:empty><div class="text-center text-muted">Data not found</div></template>

            <template v-slot:head(check)>
                <div class="checkbox checkbox--all">
                    <input id="check-all" v-model="checkall" type="checkbox">
                    <label for="check-all"></label>
                </div>
            </template>

            <template v-slot:cell(no)="data">{{ ((page > 0 ? (page - 1) : 0) * filter.limit) + (data.index +1 ) }}</template>
            <template v-slot:cell(code)="data">
                <span class="text-secondary font-weight-medium">{{ data.item.code }}</span>
            </template>
            <template v-slot:cell(name)="data">
                <span class="text-secondary font-weight-medium">{{ data.item.name }}</span>
            </template>
            <template v-slot:cell(icon)="data">
                <span class="text-secondary font-weight-medium"><i :class="'fa ' + data.item.icon"></i> {{ data.item.icon }}</span>
            </template>
            <template v-slot:cell(module_group_id)="data">
                <span class="text-secondary font-weight-medium">{{ data.item.module_name }}</span>
            </template>
            <template v-slot:cell(nourut)="data">
                <span class="text-secondary font-weight-medium">{{ data.item.nourut }}</span>
            </template>
           
            <template v-slot:cell(action)="data">
                <div class="btn-group">
                    <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-wrench"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" role="menu" x-placement="bottom-end" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-124px, 18px, 0px);">
                        <a class="dropdown-item" v-if="$gate.hasAccess('access') || $gate.hasAccess('developer')" @click="OpenGroupAccess(data.item)">
                            <i class="nav-icon fas fa-list orange"></i> Access
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" v-if="$gate.hasAccess('access') || $gate.hasAccess('developer')">
                            <router-link :to="{ name: 'access.update', params: {id: data.item.id} }" style="box-sizing: content-box;height: 17px;"><i class="fas fa-edit"></i>Edit</router-link>
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" @click="removeAccessGroup(data.item.id)" variant="danger">
                            <i class="fas fa-times"></i> Delete
                        </a>
                    </div>
                </div>
            </template>

            <template v-slot:cell(check)="data">
                <div class="checkbox">
                    <input :id="`checkbox-${data.item.id}`" :value="data.item.id" v-model="checked" type="checkbox">
                    <label :for="`checkbox-${data.item.id}`"></label>
                </div>
            </template>
        </b-table>

        <b-row>
            <b-col lg="6">
                <div class="form form--small form-inline justify-content-center justify-content-lg-start">
                    <b-form-group label-class="text-primary mr-1" label="GO TO " label-for="page">
                        <b-form-input type="number" v-model="page" id="page" class="form-control"></b-form-input>
                    </b-form-group>
                    <b-form-group label-class="text-primary ml-2 mr-2" label="VIEW " label-for="filter_limit">
                        <select2 id="filter_limit" v-model="filter.limit" :options="[10, 20, 30]" :settings="{minimumResultsForSearch: -1}" />
                    </b-form-group>
                </div>
            </b-col>
            <b-col lg="6">
                <b-pagination
                    align="right"
                    v-model="page"
                    :total-rows="access_groups.total"
                    :per-page="filter.limit"
                    aria-controls="empl-table">
                </b-pagination>
            </b-col>
        </b-row>
        
        <BModal id="item_modal" body-class="pt-4 pb-0" size="lg" centered :title="`Access Group of ${access_group ? access_group.name : '' }`">
            <b-row>
                <b-col lg="6">
                    <b-form-group label="Name *" label-for="name">
                        <the-mask class="form-control" mask="WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW" :tokens="{W:{pattern: /^[0-9a-zA-Z\s]+/}}" v-model="access.name" id="name"></the-mask>
                    </b-form-group>
                    <b-form-group label="Icon *" label-for="icon">
                        <treeselect 
                            id = "icon"
                            :options="allicons"
                            :show-count="true"
                            :default-expand-level="1"
                            v-model="value"
                            :disable-branch-nodes="true"
                            search-nested
                            :valueFormat="'object'"
                            @input="updateValue"
                            placeholder="Choose">
                            <div slot="option-label" slot-scope="{ node }"><i :class="'fa ' + node.raw.customLabel +' red'"></i>&nbsp;{{ node.raw.label }}</div>
                        </treeselect>
                    </b-form-group>
                    <b-form-group label="Path *" label-for="path">
                        <b-form-input  v-model="access.object_path" id="path" required placeholder="path"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="6">
                    <b-form-group label="Code *" label-for="code">
                        <the-mask class="form-control" mask="WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW" :tokens="{W:{pattern: /^[0-9a-zA-Z\s]+/}}" v-model="access.code" id="code"></the-mask>
                    </b-form-group>
                    <b-form-group label="Is Menu *" label-for="menu">
                        <b-form-radio-group
                            size="sm"
                            :options="[{value:'0', text:'Hide'},{value:'1', text: 'As Menu'}]"
                            buttons
                            v-model="access.is_menu"
                            button-variant="outline-primary">
                        </b-form-radio-group>
                    </b-form-group>
                    
                </b-col>    
            </b-row>
            
            <div v-if="!isLoading">
                <b-col lg="6">
                    <div class="form form--small form-inline justify-content-center justify-content-lg-start">
                        <b-button class="text-right btn btn-secondary btm-sm" size="sm" @click="InsertObject(access)" variant="outline-primary"><i class="fa fa-plus red"></i>&nbsp; {{ selectionindex != -1 ? 'Update' : 'Add Item' }}</b-button>
                        <b-button class="text-right btn btn-secondary btm-sm" @click="cancel()" variant="outline-primary" size="sm"><i class="fa fa-cog red"></i>&nbsp;{{ selectionindex != -1 ? 'Cancel' : 'Clear' }}</b-button>
                        <b-button v-if="checkeditem.length" @click="bulkDeleteitem" class="text-right btn btn-danger btm-sm" size="sm"><i class="fa fa-trash red"></i>Bulk Delete</b-button>
                    </div>
                </b-col>
                <b-table
                    id="question-table"
                    class="table--middle table--primary mt-2" show-empty responsive
                    :items="detail_groups"
                    :fields="emplbranchfield"
                    :tbody-tr-class="SelectionRowHighlight"
                    :busy.sync="isLoading"
                    style="overflow: visible !important">
                    <template v-slot:table-busy>
                        <div class="text-center text-primary my-2">
                            <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
                        </div>
                    </template>
                    <template v-slot:head(check)>
                        <div class="checkbox checkbox--all">
                            <input id="check-all" v-model="checkitem" type="checkbox">
                            <label for="check-all"></label>
                        </div>
                    </template>
                    <template v-slot:empty><div class="text-center text-muted">Data not found</div></template>
                    <template v-slot:cell(no)="data">{{ (data.index +1 ) }}</template>
                    
                    <template v-slot:cell(code)="data">
                        <span class="text-secondary font-weight-medium">{{ data.item.code }}</span>
                    </template>
                    <template v-slot:cell(name)="data">
                        <span class="text-secondary font-weight-medium">{{ data.item.name }}</span>
                    </template>
                    <template v-slot:cell(icon)="data">
                        <span class="text-secondary font-weight-medium"><i :class="'fa ' + data.item.icon"></i> {{ data.item.icon }}</span>
                    </template>
                    <template v-slot:cell(is_menu)="data">
                        <b-form-radio-group
                            size="sm"
                            :options="[{value:'0', text:'Hide'},{value:'1', text: 'as Menu'}]"
                            buttons
                            v-model="data.value"
                            button-variant="outline-primary">
                        </b-form-radio-group>
                    </template>
                    <template v-slot:cell(action)="data">
                        <b-button v-if="data.index !== selectionindex" @click="edititem(data.index)" variant="outline-primary" size="xs">Edit</b-button>
                        <b-button @click="remove(data.index)" variant="outline-danger" size="xs">Delete</b-button>
                    </template>

                    <template v-slot:cell(check)="data">
                        <div class="checkbox">
                            <input :id="`checkbox-${data.item.id}`" :value="data.index" v-model="checkeditem" type="checkbox">
                            <label :for="`checkbox-${data.item.id}`"></label>
                        </div>
                    </template>
                </b-table>
            </div>
            
            <p v-else>Loading...</p>

            <template slot="modal-footer" class="mt-6">
                <b-button variant="secondary" v-if="$gate.hasAccess('access') || $gate.hasAccess('developer')" @click="save()">Save</b-button>
            </template>
        </BModal>

    </div>      
    <div v-else>
        <form-loader></form-loader>
    </div>
</template>
<script>
export default {
    data() {
        return {
            allicons : this.$gate.iconlist(),
            isLoading : false,
            access_group:{},
            access_groups:{},
            preparation: true,
            detail_groups: [],
            listapps:[],
            mpage:1,
            checkall: false,
            checked: [],
            checkitem: false,
            checkeditem: [],
            selectionindex : -1,
            multivalue : [],
            value:{
                id:'',
                label:'',
                customLabel:''
            },
            access: {
                id:'',
                code:'',
                name:'',
                icon:'',
                object_path:'',
                is_menu:0,
                group_code:''
            },
            filter: {
                q: '',
                limit: 30
            },
            tableFields: [
                { key: 'nourut', label: 'index', thAttr: { width: '100' } },
                { key: 'module_group_id', label: 'Module_ID', thAttr: { width: '100' } },
                { key: 'code', label: 'Code.', tdClass: 'text-right', thAttr: { width: '50' } },
                { key: 'name', label: 'Name' },
                { key: 'icon', label: 'Icon' },
                { key: 'action', label: 'Action', tdClass: 'text-center', thAttr: { width: '160' } },
                { key: 'check', thAttr: { width: '30' } , tdClass: 'text-center stick-end', thClass: 'stick-end' }
            ],
            emplbranchfield: [
                { key: 'no', label: 'No.', tdClass: 'text-right', thAttr: { width: '50' } },
                { key: 'name', label: 'Name' },
                { key: 'code', label: 'Code' },
                { key: 'icon', label: 'Icon' },
                { key: 'is_menu', label: 'Menu' },
                { key: 'action', label: 'Action', tdClass: 'text-center', thAttr: { width: '160' } },
                { key: 'check', thAttr: { width: '30' } , tdClass: 'text-center stick-end', thClass: 'stick-end' }
            ]
        }
    },
    created(){
        const getallapps = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/access_group/client/all`
            }).then((data) => {
                this.listapps = data.data.data.items.map((apps) => {
                    return {
                        id: apps.id,
                        label: apps.name,
                    }    
                });
                resolve(data);
            });
        });
        Promise.all([getallapps]).then(() => {
            this.getaccessgroups();
        });
    },
    computed: {
        page: {
            get() {
                return this.mpage;
            },
            set(val) {
                this.mpage = val;
            }
        }
    },
    methods: {
        updateValue(val){
            if (val){
                this.value = val;
                this.access.icon = val.customLabel
            }
        },
        InsertObject(access){
            let cek = this.detail_groups.find((itemaccess) => this.access.code == itemaccess.code);
            if (!cek || !this.detail_groups.length){
                console.log('exist '+this.access.name)
                this.detail_groups.unshift(this.access);
            }else{
                console.log('not exist '+this.access.name)
                this.detail_groups = this.detail_groups.map((itemaccess) => {
                    if (itemaccess.code == this.access.code){
                        return { id : this.access.id || 0,
                                 code : this.access.code,
                                 name : this.access.name,
                                 group_code : this.access_group.code,
                                 icon : this.access.icon,
                                 object_path : this.access.object_path,
                                 is_menu : this.access.is_menu
                        };
                    }else{ 
                        return {id : itemaccess.id || 0,
                                 code : itemaccess.code,
                                 name : itemaccess.name,
                                 group_code : this.access_group.code,
                                 icon : itemaccess.icon,
                                 object_path : itemaccess.object_path,
                                 is_menu : itemaccess.is_menu };
                    }
                });
            }
            this.cancel()
        },
        OpenGroupAccess(group){
            const vm = this;

            this.access_group = group;
            this.access.group_code = group.code
            this.isLoading = true;
            this.$bvModal.show('item_modal')

            const getDetailData = new Promise((resolve) => {
                axios.get(`/api/access_group/fetch/${this.access_group.id}`).then((data) => {
                    this.detail_groups = data.data.data.accesses ;
                    resolve(data.data.data);
                });
            })
            Promise.all([getDetailData]).then(() => {
                vm.isLoading = false;
                this.selectionindex = -1;
            });
        },
        SelectionRowHighlight(item, type) {
            if (!item || type !== 'row') return
            if (item.index === this.selectionindex) return 'highlight'
        },
        rowHighlight(item, type) {
            if (!item || type !== 'row') return
            if (item.id === this.$route.params.row_id) return 'highlight'
        },
        getaccessgroups(){
            this.preparation = true;
            this.$Progress.start();

            const getAccessData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    params: this.filter,
                    url: `/api/access_group/fetch?page=${this.page}`
                }).then((data) => {
                    this.access_groups = data.data.data;
                    resolve(data.data.data);
                });
            })
            Promise.all([getAccessData]).then(() => {
                this.preparation = false;
            });
            this.$Progress.finish();
        },       
        bulkDelete() {
            Swal.fire({
            title: 'Are you sure, delete groups access?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    const DeleteData = new Promise((resolve, reject) => {
                        axios.post('/api/access_group/bulk-delete', {ids: this.checked})
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            resolve(error.response.data)
                        })
                    })
                    Promise.all([DeleteData]).then((response) => {
                        this.getaccessgroups()
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    });
                }
            })
        },
        cancel(){
            this.selectionindex = -1
            this.access = {
                id:'',
                code:'',
                name:'',
                icon:'',
                group_code:this.access_group.code,
                object_path:'',
                is_menu:0
            };
        },
        edititem(index){
            this.selectionindex = index
            this.access = this.detail_groups[this.selectionindex];
        },
        remove(index){
            Swal.fire({
            title: 'Are you sure?',
            text: "Click Save to finalize deletion!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, Temporary delete it!'
            }).then((result) => {
                if (result.value) {
                    this.detail_groups.splice(index, 1);
                    this.cancel()
                }
            });
        },
        save(){
            const vm = this;

            let formData = {
                id: this.access_group.id,
                code : this.access_group.code,
                data: this.detail_groups
            }
            
            return new Promise((resolve, reject) => {
                axios.post(`/api/access_group/saveitems`, formData)
                .then((response) => {
                    this.cancel()
                    vm.$bvModal.hide('item_modal')
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    resolve(response.data)
                })
            })
        },
        bulkDeleteitem() {
            Swal.fire({
            title: 'Are you sure?',
            text: "Click Save to finalize deletion!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, Temporary delete it!'
            }).then((result) => {
                if (result.value) {
                    this.checkeditem.sort().reverse().forEach(index =>{
                        this.detail_groups.splice(index,1)
                    });
                    this.cancel()
                }
            });
        },
        removeAccessGroup(id) {
            Swal.fire({
            title: 'Are you sure, delete access group?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    const DeleteRoles = new Promise((resolve, reject) => {
                        axios.post('/api/access_group/delete', {id: id})
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            resolve(error.data)
                        })
                    });
                    Promise.all([DeleteRoles]).then((response) => {
                        this.getaccessgroups()
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    });
                }
            });
        }
    },
    watch: {
        'filter.limit': function() {
            this.getaccessgroups()
        },
        page() {
            this.checked = []
            this.getaccessgroups()
        },
        checkall(checkall) {
            if ( checkall ) {
                this.access_groups.items.forEach(role => {
                    this.checked.push(role.id)
                });
            } else {
                this.checked = []
            }
        },
        checkitem(checkitem) {
            let x = 0
            if ( checkitem  && this.detail_groups){
                this.detail_groups.forEach(role => {
                    this.checkeditem.push(x)
                    x++;
                });
            }else{
                this.checkeditem = []
            }
        }
    }
}
</script>