<template>
    <b-form v-if="!preparation" class="form" enctype="multipart/form-data">
        <b-row class="align-items-center pb-1">
            <b-col  lg="12" class="mt-2 text-right">
                <b-form-group v-if="checked.length && $gate.hasAccess('cekliststore.update')">
                    <b-button @click="Save" class="btn btn-primary btm-lg mr-2" size="lg"><i class="fa fa-save blue"></i>&nbsp;Save</b-button>
                </b-form-group>
            </b-col>
        </b-row>

        <vue-good-table
            class="table--middle table--primary mt-2" show-empty responsive
            :columns="columns"
            :rows="rows"
            :group-options="{
                    enabled: true,
                    collapsable: true
            }"
            :search-options="{
                    enabled: true,
                    trigger: 'enter',
                    placeholder: 'nama ceklist, nama karyawan',
                    searchFn: searchData
            }"
        > 
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'cek1'">
                    <div class="checkbox" v-if="props.row.cek1 !== 1 && moment().hour() >= 5 && moment().hour() < 13 && $gate.hasAccess('cekliststore.update')">
                        <input :id="`checkbox-${props.row.id1 + '-' + props.row.id + '-' + props.row.idshift1 + '-shift_1'}`" :value="props.row.idshift1 + '#'+ props.row.id1 + '#'+ props.row.id + '#shift_1'" type="checkbox" @change="check($event)">
                        <label :for="`checkbox-${props.row.id1 + '-' + props.row.id + '-' + props.row.idshift1 + '-shift_1'}`"></label>
                    </div>
                    <span v-else-if="props.row.cek1 === 1">{{ props.row.usr1.employee.name }} &nbsp;<i class="fa fa-user-check"></i></span>
                    <span v-else><i class="fa fa-user-slash"></i></span>
                </span>
                <span v-else-if="props.column.field == 'cek2'">
                    <div class="checkbox" v-if="props.row.cek2 !== 1 && moment().hour() >= 13 && moment().hour() <= 23 && $gate.hasAccess('cekliststore.update')">
                        <input :id="`checkbox-${props.row.id1 + '-' + props.row.id + '-' + props.row.idshift2 + '-shift_2'}`" :value="props.row.idshift2 + '#'+ props.row.id1 + '#'+ props.row.id + '#shift_2'" type="checkbox" @change="check($event)">
                        <label :for="`checkbox-${props.row.id1 + '-' + props.row.id + '-' + props.row.idshift2 + '-shift_2'}`"></label>
                    </div>
                    <span v-else-if="props.row.cek2 === 1">{{ props.row.usr2.employee.name }} &nbsp;<i class="fa fa-user-check"></i></span>
                    <span v-else><i class="fa fa-user-slash"></i></span>
                </span>
                <span v-else-if="props.column.field == 'cek3' ">
                    <div class="checkbox" v-if="props.row.cek3 !== 1 && moment().hour() > 23 && moment().hour() <= 5 && $gate.hasAccess('cekliststore.update')">
                        <input :id="`checkbox-${props.row.id1 + '-' + props.row.id + '-' + props.row.idshift3 + '-shift_3'}`" :value="props.row.idshift3 + '#'+ props.row.id1 + '#'+ props.row.id + '#shift_3'" type="checkbox" @change="check($event)">
                        <label :for="`checkbox-${props.row.id1 + '-' + props.row.id + '-' + props.row.idshift3 + '-shift_3'}`"></label>
                    </div>
                    <span v-else-if="props.row.cek3 === 1">{{ props.row.usr3.employee.name }} &nbsp;<i class="fa fa-user-check"></i></span>
                    <span v-else><i class="fa fa-user-slash"></i></span>
                </span>

                <span v-else>
                    {{props.formattedRow[props.column.field]}}
                </span>
            </template>
        </vue-good-table>        
    </b-form>
    <div v-else class="text-center text-primary py-14">
        <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
    </div>
</template>
<script>

import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import moment from 'moment'
import { eventBus } from '../../../../app.js'

export default {
    props: ['branch_id','tanggal'],
    data(){
        return {
            preparation : false,
            checked: [],
            filter:{
                q : '',
                branch_id : this.branch_id,
                tanggal : this.tanggal
            },
            columns: [
                        {
                            label: 'Group',
                            field: 'group',
                        },
                        {
                            label: 'Name',
                            field: 'name',
                        },
                        {
                            label: 'Shift 1',
                            field: 'cek1',
                            type: 'percentage',
                        },
                        {
                            label: 'Shift 2',
                            field: 'cek2',
                            type: 'percentage',
                        },
                        {
                            label: 'Percent',
                            field: 'percent',
                            type: 'percentage',
                        },
                    ],
            rows: [],
        }
    },
    created() {
        this.getChecklist();
        
        eventBus.$on('load_checklist', function(payload) {
            this.getChecklist();
        });
    },
    methods: {
        Save(){
            Swal.fire({
            title: 'Are you sure, please recheck again!!',
            text: "You won't be able to revert this!!!",
            showCancelButton: true,
            confirmButtonColor: '#3334dd',
            cancelButtonColor: '#30d63b',
            confirmButtonText: 'Yes, save it!'
            }).then((result) => {
                if (result.value) {
                    const DeleteRoles = new Promise((resolve) => {
                        axios.post('/api/cekliststore/store', {data: this.checked})
                        .then((response) => {
                            Toast.fire({
                                icon: 'success',
                                title: response.message
                            });
                            
                            resolve(response)
                        });
                    });
                    Promise.all([DeleteRoles]).then((response) => {
                        this.getChecklist()
                    });
                }
            });
        },
        searchData(row, col, cellValue, searchTerm){
            this.filter.q = searchTerm.toLowerCase();
            return row.name.includes(this.filter.q);
        },
        onSearch(){
            this.getChecklist();
        },
        check (e) {
            this.$nextTick(() => {
                let val = e.target.value.split('#');
                let today = moment().format('YYYY-MM-DD');
                let find = -1;
                if(e.target.checked){
                    if (this.checked){
                        if (val[0]){
                            find  = this.checked.findIndex((lst) => lst.id == val[0]) ;
                        }else{
                            find  = this.checked.findIndex((lst) => lst.branch_id == val[2] &&
                                                                    lst.ceklist_id == val[1] && 
                                                                    lst.shift == val[3]) ;
                        }
                    }else{
                        find = -1;
                    }

                    if (find >= 0){
                        this.checked[find].id = val[0];
                        this.checked[find].ceklist_id = val[1];
                        this.checked[find].branch_id = val[2];
                        this.checked[find].shift = val[3];
                        this.checked[find].cek = 1;
                        this.checked[find].tanggal = today;
                        this.checked[find].created_by = window.user.id;
                    }else{
                        this.checked.push({
                            id : val[0],
                            ceklist_id : val[1],
                            branch_id : val[2],
                            shift : val[3],
                            cek : 1,
                            tanggal : today,
                            created_by : window.user.id
                        });
                    }
                }else{
                    if (this.checked.length){
                        if (val[0]){
                            find  = this.checked.findIndex((lst) => lst.id == val[0]) ;
                        }else{
                            find  = this.checked.findIndex((lst) => lst.branch_id == val[2] &&
                                                                    lst.ceklist_id == val[1] && 
                                                                    lst.shift == val[3]) ;
                        }

                        if (find >= 0)  this.checked.splice(find,1);
                    }
                }
            })
        },
        getChecklist(){
            this.preparation = true;
            this.$Progress.start();

            const geChecklistData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    params: this.filter,
                    url: `/api/cekliststore/fetch/all`
                }).then((data) => {
                    this.rows = data.data.data.items;  
                    resolve(data);
                });
            })
            Promise.all([geChecklistData]).then(() => {
                this.preparation = false;
            });
            this.$Progress.finish();
        },       
    },
    watch: {
        'filter.q': function() {
            this.getChecklist()
        },
    },
    components: {
        VueGoodTable,
    }
}
</script>