<template>
  <div>
    <h3 v-bind:class="{timeout : timeout}">{{time}}</h3>
  </div>
</template>
    
<script>
    export default {
      name: 'Timer',
      props: ['waktu','isend'],
      data(){
        return{
          timelapse : 0,
          time : '00:00:00',
          timeout : false,
          timer : null
        }
      },
      mounted(){
        this.timer = setInterval(this.Timeline, 1000);
      },
      beforeDestroy(){
        if (this.timer) clearInterval(this.timer);
      },
      methods: {
        Timeline(){
          setTimeout(() => {
                this.timelapse++;
                var hours   = Math.floor(this.timelapse / 3600);
                var minutes = Math.floor((this.timelapse - (hours * 3600)) / 60);
                var seconds = this.timelapse - (hours * 3600) - (minutes * 60);

                if (hours   < 10) {hours   = "0"+hours;}
                if (minutes < 10) {minutes = "0"+minutes;}
                if (seconds < 10) {seconds = "0"+seconds;}
                this.time =  hours+':'+minutes+':'+seconds;
                
                if (this.waktu){
                    if (Math.floor(this.timelapse / 60) >= this.waktu){
                        this.timeout = true
                        clearInterval(this.timer);
                        this.$emit('timeEnd');
                    } 
                }
          } , 1000);  
        }
      },
      watch: {
        isend(val) {
          if (val){
            clearInterval(this.timer);
          }
        }
      }
    }
    </script>

<style scoped>
h3{
  color: white;
  background-color: green;
  text-align: center;
  padding: 15px;
  width: auto;
  margin-left: 1%;
  float: left;
  margin-right: 2%;
}
        /* mediA query*/
        @media(min-width: 768px){
          h3{
            margin-left: 4%;
            margin-right: 0;
        }
        }
.timeout{
  background-color: #ff0037;
}
</style>