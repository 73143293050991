<template>
    <div class="panel__body pt-4 ml-4">
        <b-tabs content-class="mt-3">
            <b-tab title="Manual Entry" active>
                <JobForm :job="org" :allbranch="allbranches" :alljobs = "allorganization" :state="false"></JobForm>
        
                <div class="row mb-2">
                    <div class="col-12 mb-2">
                        <button type="submit" :disabled="isLoading" @click="submit" class="btn btn-secondary btn-lg mr-2">Save</button>
                        <router-link :to="{ name : 'location.data'}" class="btn btn-outline-primary btn-lg">Back</router-link>
                    </div>
                </div>
            </b-tab>
            <b-tab title="Upload">
                <b-form class="form"  enctype="multipart/form-data">
                    <b-row>
                        <b-col>
                            <div class="form-group">
                                <label>File Branch</label>
                                <uploader name="upload_file" description="Maks 2MB dengan ekstensi (xls, xlsx)." accept=".xls,.xlsx" v-bind:files.sync="upload_fields.files" id="upload_file" :class="{ 'is-invalid': upload_fields.errors.has('files') }"/>
                                <has-error :form="upload_fields" field="files"></has-error>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class = "mb-6">
                        <b-col class = "mb-2">
                            <b-button @click.prevent="uploadFiles" class="btn btn-outline-secondary btn-lg mr-2">Upload</b-button>
                            <router-link :to="{ name : 'locatios.data'}" class="btn btn-outline-primary btn-lg mr-2">Back</router-link>
                        </b-col>
                    </b-row>
                </b-form>
            </b-tab>
        </b-tabs>
    </div>
</template>
<script>

import JobForm from './Form'

export default {
    data(){
        return {
            isLoading : false,
            org : new Form({
                id:'',
                name: '',
                branch_id:'',
                parent_location_id:'',
                code : ''
            }),
            allbranches : [],
            allorganization : [],
            upload_fields: new Form({
                files: ''
            }),
        }
    },
    created() {
        const vm = this
        const getAllBranchData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/branch/fetch/all`
            }).then((data) => {
                let tmp = data.data.data.items.filter((branch) => !branch.parent_branch_id );
                vm.allbranches = tmp.map((branch) => {
                                    return {
                                        id: branch.id,
                                        label: branch.name,
                                        children : branch.children ? this.$helper.prosesarraybranch(branch.children,data.data.data.items) : []
                                    }
                                });
                resolve(data);
                
            });
        })
        const getAllOrgsData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/location/fetch/all`
            }).then((data) => {
                let tmp = data.data.data.items.filter((org) => !org.parent_location_id );
                vm.allorganization = tmp.map((org) => {
                                    return {
                                        id: org.id,
                                        label: org.name,
                                        children : org.children ? this.$helper.prosesarrayorg(org.children,data.data.data.items) : []
                                    }
                                });
                resolve(data);
            });
        })
        Promise.all([getAllBranchData,getAllOrgsData]).then(() => {
            vm.isLoading = false
        })
    },
    methods: {
        uploadFiles(){
            const vm = this
            vm.isLoading = true;
            this.upload_fields.submit('post', '/api/location/upload', {
                transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'location.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
            });
        },
        submit() {
            this.isLoading = true;
            if (!this.org.branch_id) this.org.pbranch_id = '0';
            if (!this.org.parent_location_id) this.org.parent_location_id = '0';
            this.org.submit('post', '/api/location/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'location.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                
            });
            this.isLoading = false;
        }
    },
    components: {
        JobForm
    }
}
</script>
