<template>
    <div class="panel__body pt-4 ml-4">
        <div class="form">
            <JobForm :preparation="preparation" :job="org" :allbranch="allbranches"  :alljobs = "allorganization" :state="true"></JobForm>

            <div class="row mb-6">
                <div class="col-12 mb-2">
                    <button @click="submit" :disabled="preparation" class="btn btn-secondary btn-lg mr-2">Save</button>
                    <router-link :to="{ name : 'org.data'}" class="btn btn-outline-primary btn-lg">Back</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import JobForm from './Form'

export default {
    data(){
        return {
            preparation: true,
            allbranches : [],
            allorganization : [],
            org: new Form({
                id: '',
                name: '',
                branch_id:'',
                parent_organization_id:'',
                ou_address : '',
                code : ''
            })
        }
    },
    created() {
        const vm = this
        this.org.reset();
        const getOrganizationData = new Promise((resolve) => {
            axios.get(`/api/org/fetch/${this.$route.params.id}`).then((data) => {
                this.org.fill(data.data.data);
                if (!this.org.parent_branch_id) this.org.parent_branch_id = '';
                if (!this.org.parent_organization_id) this.org.parent_organization_id = '';
                resolve(data.data.data);
            });
            resolve()
        })
        const getAllBranchData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/branch/fetch/all`
            }).then((data) => {
                vm.allbranches = data.data.data.items.filter((branch) => !branch.parent_branch_id );
                vm.allbranches = vm.allbranches.map((branch) => {
                                    return {
                                        id: branch.id,
                                        label: branch.name,
                                        children : branch.children ? this.$helper.prosesarraybranch(branch.children,data.data.data.items) : []
                                    }
                                }
                );
                resolve(data);
                
            });
        })
        const getAllOrgsData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/org/fetch/all`
            }).then((data) => {
                vm.allorganization = data.data.data.items.filter((org) => !org.parent_organization_id );
                vm.allorganization = vm.allorganization.map((org) => {
                            return {
                                id: org.id,
                                label: org.name,
                                children : org.children ? this.$helper.prosesarrayorg(org.children,data.data.data.items) : []
                            }
                        }
                );
                resolve(data);
            });
        })
        Promise.all([getOrganizationData, getAllBranchData, getAllOrgsData]).then(() => {
            vm.preparation = false
        })
    },
    methods: {
        submit() {
            this.preparation = true;
            if (!this.org.parent_branch_id) this.org.parent_branch_id = 0;
            if (!this.org.parent_organization_id) this.org.parent_organization_id = 0;
            this.org.submit('post', '/api/org/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'org.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                this.preparation = false;
            });
        }
    },
    components: {
        JobForm
    }
}
</script>