<template>
    <div class="container-fluid" v-if="!preparation">
        <Loading :visible.sync="inproses" v-show="inproses"></Loading>
        <b-row class="align-items-center pb-1">
            <b-col  lg="8">
                <router-link :to="{ name: 'empl.create'}" class="btn btn-primary btm-sm mt-1">Create New</router-link>
                 <b-button v-if="$gate.hasAccess('empl.update')" @click="synch" class="btn btn-warning btm-sm mt-1"><i class="fa fa-sync red"></i>&nbsp;Synch</b-button>
            </b-col>
            <b-col  lg="4">
                <b-form class="form form-inline form--rounded justify-content-end btn-sm" @submit.prevent="getempls()">
                    <b-form-group v-if="checked.length">
                        <b-button @click="bulkDelete" class="btn btn-danger btm-sm" size="sm"><i class="fa fa-trash red"></i>Bulk Delete</b-button>
                    </b-form-group>
                    
                    <b-form-group class="form-inline ml-3" >
                        <b-input-group size="sm">
                            <b-form-input type="search" class="form-control form-control-navbar" v-model="filter.q" placeholder="Search" aria-label="Search" />
                            <div class="input-group-append">
                                <button class="btn btn-navbar" type="submit" >
                                    <i class="fa fa-search"></i>
                                </button>
                            </div>
                            
                        </b-input-group>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>

        <b-table
            id="empl-table"
            class="table--middle table--primary mt-2" show-empty responsive
            :items="empls.items"
            :fields="tableFields"
            :tbody-tr-class="rowHighlight"
            :busy.sync="preparation">
            <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
                </div>
            </template>
            <template v-slot:empty><div class="text-center text-muted">Data not found</div></template>

            <template v-slot:head(check)>
                <div class="checkbox checkbox--all">
                    <input id="check-all" v-model="checkall" type="checkbox">
                    <label for="check-all"></label>
                </div>
            </template>

            <template v-slot:cell(no)="data">{{ ((page > 0 ? (page - 1) : 0) * filter.limit) + (data.index +1 ) }}</template>
            <template v-slot:cell(branch_id)="data">
                <router-link v-if="data.item.branch_id" :to="{ name: 'branch.update', params: {id: data.item.branch_id} }" class="font-weight-medium text-primary" style="box-sizing: content-box;height: 17px;">
                    {{ data.item.branch ? data.item.branch.name : ''}}
                </router-link>
            </template>
            <template v-slot:cell(job_position_id)="data">
                <router-link v-if="data.item.job_position_id" :to="{ name: 'jobposition.update', params: {id: data.item.job_position_id} }" class="font-weight-medium text-primary" style="box-sizing: content-box;height: 17px;">
                    {{ data.item.job_position ? data.item.job_position.name : '' }}
                </router-link>
            </template>
            <template v-slot:cell(organization_id)="data">
                <router-link v-if="data.item.organization_id" :to="{ name: 'org.update', params: {id: data.item.organization_id} }" class="font-weight-medium text-primary" style="box-sizing: content-box;height: 17px;">
                    {{ data.item.organization ? data.item.organization.name : '' }}
                </router-link>
            </template>
            <template v-slot:cell(nik)="data">
                <span class="text-secondary font-weight-medium">{{ data.item.nik }}</span>
            </template>
            <template v-slot:cell(gender)="data">
                <span class="text-secondary font-weight-medium">{{ data.item.gender.label }}</span>
            </template>
            <template v-slot:cell(job_level)="data">
                <span class="text-secondary font-weight-medium">{{ data.item.job_level }}</span>
            </template>
            <template v-slot:cell(employment_status)="data">
                <span class="text-secondary font-weight-medium">{{ data.item.employment_status.label }}</span>
            </template>
            <template v-slot:cell(grade_id)="data">
                <router-link v-if="data.item.grade_id" :to="{ name: 'grade.update', params: {id: data.item.grade_id} }" class="font-weight-medium text-primary" style="box-sizing: content-box;height: 17px;">
                    {{ data.item.grade ? data.item.grade.name : '' }}
                </router-link>
            </template>

            <template v-slot:cell(action)="data">
                <div class="btn-group">
                    <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-wrench"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" role="menu" x-placement="bottom-end" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-124px, 18px, 0px);">
                        <a class="dropdown-item" v-if="$gate.hasAccess('branch.update')" @click="OpenEmplBranch(data.item)">
                            <i class="nav-icon fas fa-list orange"></i> Branches
                        </a>
                        <a class="dropdown-divider" v-if="$gate.hasAccess('user') && !data.item.user" ></a>
                        <a class="dropdown-item" v-if="$gate.hasAccess('user') && !data.item.user" @click="editModal(data.item)">
                            <i class="nav-icon fas fa-users blue"></i> {{data.item.user ? "Update " : "Create "}} User
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" v-if="$gate.hasAccess('empl.update')">
                            <router-link :to="{ name: 'empl.update', params: {id: data.item.id} }" style="box-sizing: content-box;height: 17px;"><i class="fas fa-edit"></i>Edit</router-link>
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" @click="removeRole(data.item.id)" variant="danger">
                            <i class="fas fa-times"></i> Delete
                        </a>
                    </div>
                </div>
            </template>

            <template v-slot:cell(check)="data">
                <div class="checkbox">
                    <input :id="`checkbox-${data.item.id}`" :value="data.item.id" v-model="checked" type="checkbox">
                    <label :for="`checkbox-${data.item.id}`"></label>
                </div>
            </template>
        </b-table>

        <b-row>
            <b-col lg="6">
                <div class="form form--small form-inline justify-content-center justify-content-lg-start">
                    <b-form-group label-class="text-primary mr-1" label="GO TO " label-for="page">
                        <b-form-input type="number" v-model="page" id="page" class="form-control"></b-form-input>
                    </b-form-group>
                    <b-form-group label-class="text-primary ml-2 mr-2" label="VIEW " label-for="filter_limit">
                        <select2 id="filter_limit" v-model="filter.limit" :options="[10, 20, 30]" :settings="{minimumResultsForSearch: -1}" />
                    </b-form-group>
                </div>
            </b-col>
            <b-col lg="6">
                <b-pagination
                    align="right"
                    v-model="page"
                    :total-rows="empls.total"
                    :per-page="filter.limit"
                    aria-controls="empl-table">
                </b-pagination>
            </b-col>
        </b-row>
        
        <BModal id="item_modal" body-class="pt-4 pb-0" size="lg" centered :title="`Branch of ${employee ? employee.name : '' }`">
            <b-row class="align-items-center pb-1">
                <treeselect 
                    class="ml-2"
                    id="type"
                    :options="[{ id : 'branch',label : 'Branch'}, 
                                {id : 'org', label : 'Department'},
                                {id : 'location', label : 'Location'}]"
                    v-model="type"
                    placeholder="Pilih Jenis" 
                    :searchable="true"
                    />
                <b-col lg="4">
                    <treeselect 
                        :multiple="true"
                        :options="allbranches"
                        :flat="true"
                        sort-value-by="ORDER_SELECTED"
                        :default-expand-level="1"
                        placeholder="Try selecting some options."
                        v-model="multivalue"
                        :valueFormat="'object'"
                    />
                </b-col>
                <b-button v-if="multivalue.length" class="text-right btn btn-primary btm-sm" size="sm" @click="InsertObject()"><i class="fa fa-plus red"></i>Add Item</b-button>
                <b-button v-if="checkeditem.length"@click="bulkDeleteitem" class="text-right btn btn-danger btm-sm" size="sm"><i class="fa fa-trash red"></i>Bulk Delete</b-button>
            </b-row>

            <div v-if="! isLoading">
                <b-table
                    id="question-table"
                    class="table--middle table--primary mt-2" show-empty responsive
                    :items="emplbranches"
                    :fields="emplbranchfield"
                    :tbody-tr-class="SelectionRowHighlight"
                    :busy.sync="isLoading"
                    style="overflow: visible !important">
                    <template v-slot:table-busy>
                        <div class="text-center text-primary my-2">
                            <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
                        </div>
                    </template>
                    <template v-slot:head(check)>
                        <div class="checkbox checkbox--all">
                            <input id="check-all" v-model="checkitem" type="checkbox">
                            <label for="check-all"></label>
                        </div>
                    </template>
                    <template v-slot:empty><div class="text-center text-muted">Data not found</div></template>
                    <template v-slot:cell(no)="data">{{ (data.index +1 ) }}</template>
                    <template v-slot:cell(type)="data">
                        <span class="text-secondary font-weight-medium">{{ data.item.type }}</span>
                    </template>
                    <template v-slot:cell(branch_id)="data">
                        <treeselect v-if="selectionindex == data.index" 
                            :options="allbranches"
                            :show-count="true"
                            :default-expand-level="1"
                            v-model="value"
                            :disable-branch-nodes="true"
                            search-nested
                            :valueFormat="'object'"
                            @input="updateValue"
                            placeholder="Choose"
                        />
                        <span v-else>{{ (data.item.type == 'org' ? data.item.org.name :  (data.item.type == 'branch' ? data.item.branch.name : data.item.location.name)) }}</span>
                    </template>

                    <template v-slot:cell(action)="data">
                        <b-button v-if="data.index !== selectionindex" @click="edit(data.index)" variant="outline-primary" size="xs">Edit</b-button>
                        <b-button v-if="data.index == selectionindex" @click="cancel()" variant="outline-primary" size="xs">Cancel</b-button>
                        <b-button @click="remove(data.index)" variant="outline-danger" size="xs">Delete</b-button>
                    </template>

                    <template v-slot:cell(check)="data">
                        <div class="checkbox">
                            <input :id="`checkbox-${data.item.id}`" :value="data.index" v-model="checkeditem" type="checkbox">
                            <label :for="`checkbox-${data.item.id}`"></label>
                        </div>
                    </template>
                </b-table>
            </div>
            
            <p v-else>Loading...</p>

            <template slot="modal-footer" class="mt-6">
                <b-button variant="secondary" v-if="$gate.hasAccess('empl.create')" @click="save()">Save</b-button>
            </template>
        </BModal>

        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Create User</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form enctype="multipart/form-data" @submit.prevent="createUpdateUser()">
                        <input type="hidden" name="_method" value="PUT">
                        <div class="modal-body">
                            <div class="form-group">
                                <label>Name</label>
                                <input v-model="form.name" type="text" name="name"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                                <has-error :form="form" field="name"></has-error>
                            </div>
                            <div class="form-group">
                                <label >Email</label>
                                <input v-model="form.email" type="text" name="email" :disabled="true" 
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                                <has-error :form="form" field="email"></has-error>
                            </div>
                            <div class="form-group">
                                <label>Create Window Account</label> &nbsp;
                                <ToggleButton :disabled="user !== undefined && user !== null && user.is_ad" class="mb-0" @change="(e) => setActive(e)" :value="form.is_ad ? true : false" :sync="true" :width="80" :labels="{checked: 'Yes', unchecked: 'No'}"></ToggleButton>
                            </div>
                            
                            <div class="form-group">
                                <label>Password</label>
                                <input v-model="form.password" type="password" name="password"
                                    class="form-control" :class="{ 'is-invalid': form.errors.has('password') }" autocomplete="false">
                                <has-error :form="form" field="password"></has-error>
                            </div>
                        
                            <div class="form-group">
                                <label>User Role</label>
                                <treeselect
                                    :options="roles"
                                    :disable-branch-nodes="true"
                                    :show-count="true"
                                    v-model="form.role_id"
                                    placeholder="Choose Role"
                                    :class="{ 'is-invalid': form.errors.has('role_id') }"
                                    />
                                <has-error :form="form" field="role_id"></has-error>
                            </div>
                            <div class="form-group" v-if="user !== undefined && user !== null && !user.employee_id">
                                <label>Employee</label>
                                <treeselect
                                    :options="employees"
                                    :disable-branch-nodes="true"
                                    :show-count="true"
                                    v-model="form.employee_id"
                                    placeholder="Employee Account"
                                    :class="{ 'is-invalid': form.errors.has('employee_id') }"
                                    />
                                <has-error :form="form" field="employee_id"></has-error>
                            </div>
                            <div class="form-group">
                                <label>Photo</label>
                                <uploader name="upload_file" description="Maks 2MB dengan ekstensi (jpg, png, gif)." accept=".jpg,.jpeg,.png, .gif" v-bind:files.sync="form.files" id="upload_file" :class="{ 'is-invalid': form.errors.has('files') }"/>
                                <has-error :form="form" field="files"></has-error>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-success">{{ user === undefined || user === null ? "Update" : "Create"}}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>    

<!--
        <BModal id="addNew" body-class="pt-4 pb-0" size="lg" centered :title="`User Account ${user === undefined || user === null? 'Update' : 'Create' }`">
            <b-row class="align-items-center mr-1">
                <b-col lg="12">
                    <form enctype="multipart/form-data" @submit.prevent="createUpdateUser()">
                        <div class="form-group">
                            <label>Name</label>
                            <input v-model="form.name" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label >Email</label>
                            <input v-model="form.email" type="text" name="email" :disabled="true" 
                                class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                            <has-error :form="form" field="email"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Create Window Account</label> &nbsp;
                            <ToggleButton :disabled="user !== undefined && user !== null && user.is_ad" class="mb-0" @change="(e) => setActive(e)" :value="form.is_ad ? true : false" :width="80" :labels="{checked: 'Yes', unchecked: 'No'}"></ToggleButton>
                        </div>
                        
                        <div class="form-group">
                            <label>Password</label>
                            <input v-model="form.password" type="password" name="password"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('password') }" autocomplete="false">
                            <has-error :form="form" field="password"></has-error>
                        </div>
                    
                        <div class="form-group">
                            <label>User Role</label>
                            <treeselect
                                :options="roles"
                                :disable-branch-nodes="true"
                                :show-count="true"
                                v-model="form.role_id"
                                placeholder="Choose Role"
                                :class="{ 'is-invalid': form.errors.has('role_id') }"
                                />
                            <has-error :form="form" field="role_id"></has-error>
                        </div>
                        <div class="form-group" v-if="user !== undefined && user !== null && !user.employee_id">
                            <label>Employee</label>
                            <treeselect
                                :options="employees"
                                :disable-branch-nodes="true"
                                :show-count="true"
                                v-model="form.employee_id"
                                placeholder="Employee Account"
                                :class="{ 'is-invalid': form.errors.has('employee_id') }"
                                />
                            <has-error :form="form" field="employee_id"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Photo</label>
                            <uploader name="upload_file" description="Maks 2MB dengan ekstensi (jpg, png, gif)." accept=".jpg,.jpeg,.png, .gif" v-bind:files.sync="form.files" id="upload_file" :class="{ 'is-invalid': form.errors.has('files') }"/>
                            <has-error :form="form" field="files"></has-error>
                        </div>
                        
                    </form>
                </b-col>
            </b-row>
            <template slot="modal-footer" class="mt-6">
                <button type="submit" class="btn btn-success">{{ user === undefined || user === null ? "Update" : "Create"}}</button>
            </template>
        </BModal>
-->


    </div>      
    <div v-else>
        <form-loader></form-loader>
    </div>
</template>
<script>
import Loading from '../../loading/Loading'
import moment from 'moment'
export default {
    data() {
        return {
            inproses:false,
            user : {},
            employees:[],
            roles:[],
            isLoading : false,
            employee:{},
            preparation: true,
            empls:{},
            emplbranches:[],
            allbranches : [],
            listbranch : [],
            mpage:1,
            checkall: false,
            checked: [],
            checkitem: false,
            checkeditem: [],
            selectionindex : -1,
            multivalue : [],
            type: 'branch',
            form: new Form({
                id : '',
                role_id : '',
                name: '',
                email: '',
                password: '',
                email_verified_at: '',
                files:'',
                employee_id:'',
                is_ad:0
            }),
            value:{
                id:'',
                label:''
            },
            filter: {
                q: '',
                limit: 30
            },
            tableFields: [
                { key: 'no', label: 'No.', tdClass: 'text-right', thAttr: { width: '50' } },
                { key: 'nik', label: 'Empl.#' },
                { key: 'name', label: 'Full Name' },
                { key: 'gender', label: 'Gender' },
                { key: 'grade_id', label: 'Grade', thAttr: { width: '100' } },
                { key: 'branch_id', label: 'Branch', thAttr: { width: '100' } },
                { key: 'organization_id', label: 'Department', thAttr: { width: '100' } },
                { key: 'job_position_id', label: 'Job Position', thAttr: { width: '100' } },
                { key: 'job_level', label: 'Job Level', thAttr: { width: '100' } },
                { key: 'employment_status', label: 'Status', thAttr: { width: '100' } },
                { key: 'action', label: 'Action', tdClass: 'text-center', thAttr: { width: '160' } },
                { key: 'check', thAttr: { width: '30' } , tdClass: 'text-center stick-end', thClass: 'stick-end' }
            ],
            emplbranchfield: [
                { key: 'no', label: 'No.', tdClass: 'text-right', thAttr: { width: '50' } },
                { key: 'branch_id', label: 'Branch Name' },
                { key: 'type', label: 'Type', thAttr: { width: '120' } },
                { key: 'action', label: 'Action', tdClass: 'text-center', thAttr: { width: '160' } },
                { key: 'check', thAttr: { width: '30' } , tdClass: 'text-center stick-end', thClass: 'stick-end' }
            ]
        }
    },
    created(){
        this.$Progress.start();
        const getallBranchData = this.load_data();
        Promise.all([getallBranchData]).then(() => {
            this.getempls();
            this.getuserdata();
            this.$Progress.finish();
        });
    },
    computed: {
        page: {
            get() {
                return this.mpage;
            },
            set(val) {
                this.mpage = val;
            }
        }
    },
    methods: {
        load_data(){
            return new Promise((resolve) => {
                axios({
                    method: 'get',
                    url: `/api/${this.type}/fetch/all`
                }).then((data) => {
                    this.listbranch = data.data.data.items;
                    resolve(data);
                });
            });
        },
        prosesarray(array){
            return array.map(element => {
                return {
                        id: element.id,
                        label: element.name
                    }
            });
        },
        getuserdata(){
            const vm = this
            this.$Progress.start();
            const getAllEmployeeData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    url: `/api/branch/fetch/all`,
                    params: {groupby : 'employees'}
                }).then((data) => {
                    vm.employees = data.data.data.items.map((branch) => {
                                        return {
                                            id: 'branch'+branch.id,
                                            label: branch.name,
                                            children : this.prosesarray(branch.employees)
                                        }
                                    });
                    resolve(data);
                });
            })
            const getAllRoleData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    url: `/api/role/fetch/all`
                }).then((data) => {
                    vm.roles = data.data.data.items.map((role) => {
                                        return {
                                            id: role.id,
                                            label: role.name
                                        }
                                    });
                    resolve(data);
                });
            });
            Promise.all([getAllRoleData,getAllEmployeeData]).then(() => {
                this.$Progress.finish();
            });
        },
        getempls(){
            this.preparation = true;
            this.$Progress.start();

            const getEmplData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    params: this.filter,
                    url: `/api/empl/fetch?page=${this.page}`
                }).then((data) => {
                    this.empls = data.data.data;
                    resolve(data.data.data);
                });
            })
            Promise.all([getEmplData]).then(() => {
                this.preparation = false;
            });
            this.$Progress.finish();
        },     
        setActive(data) {
            this.form.is_ad = data.value ? 1 : 0;
        },
        createUpdateUser(){
            this.form.submit('post', '../api/user', {
            transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
            }],
            }).then((response) => {
                //this.$bvModal.hide('addNew');
                $('#addNew').modal('hide');
                Toast.fire({
                    icon: 'success',
                    title: response.data.message
                });

                this.$Progress.finish();
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });

                this.$Progress.finish();
            });
        },
        editModal(data){
            this.user = null;
            this.form.reset();
            if (data.user !== undefined && data.user !== null){
                this.user = data.user;
                this.form.fill(this.user);
            }else{
                this.form.name = data.name
                this.form.email = data.email
                this.form.email_verified_at = moment().format('YYYY-MM-DD')
                this.form.employee_id = data.id
                this.form.is_ad = 0
            }
            //this.$bvModal.show('addNew');
            $('#addNew').modal('show');
        },
        InsertObject(){
            if (this.multivalue){
                this.selectionindex = -1;
                this.multivalue.forEach(data => {
                    this.emplbranches.unshift(
                        {id:'',
                        employee_id:this.employee.id,
                        branch_id:data.id,
                        type:this.type, 
                        [this.type] : {id : data.id, name : data.label}
                    });
                });
                this.reloaditem()
            }
        },
        reloaditem(){
            const vm = this
            switch(this.type ) {
                case 'location':
                    vm.allbranches = vm.listbranch.filter((branch) => !branch.parent_location_id );
                    break;
                case 'org': 
                    vm.allbranches = vm.listbranch.filter((branch) => !branch.parent_organization_id );
                    break;
                default :
                    vm.allbranches = vm.listbranch.filter((branch) => !branch.parent_branch_id );
                    break;
            }

            vm.allbranches = vm.allbranches.map((branch) => {
                if (this.emplbranches){
                    let cek = this.emplbranches.find((empl) => empl.branch_id == branch.id && empl.type == this.type) ? false : true;

                    if (cek ){
                        return {
                            id : branch.id,
                            label : branch.name,
                            children : branch.children ? 
                            (this.type == 'org' ? this.$helper.prosesarrayorg(branch.children,this.listbranch.filter((branch) => this.emplbranches.find((empl) => empl.branch_id == branch.id && empl.type == this.type) ? false : true )) :
                            (this.type == 'location' ? this.$helper.prosesarraylocation(branch.children,this.listbranch.filter((branch) => this.emplbranches.find((empl) => empl.branch_id == branch.id && empl.type == this.type) ? false : true )) :
                            this.$helper.prosesarraybranch(branch.children,this.listbranch.filter((branch) => this.emplbranches.find((empl) => empl.branch_id == branch.id && empl.type == this.type) ? false : true )))) : []
                        }
                    }
                }else{
                    return {
                            id : branch.id,
                            label : branch.name,
                            children : branch.children ? 
                            (this.type == 'org' ? this.$helper.prosesarrayorg(branch.children,this.listbranch.filter((branch) => this.emplbranches.find((empl) => empl.branch_id == branch.id && empl.type == this.type) ? false : true )) :
                            (this.type == 'location' ? this.$helper.prosesarraylocation(branch.children,this.listbranch.filter((branch) => this.emplbranches.find((empl) => empl.branch_id == branch.id && empl.type == this.type) ? false : true )) :
                            this.$helper.prosesarraybranch(branch.children,this.listbranch.filter((branch) => this.emplbranches.find((empl) => empl.branch_id == branch.id && empl.type == this.type) ? false : true )))) : []
                    }
                }
            })
            
            vm.allbranches.filter(function (el) {
                return el != null;
            });
        },
        updateValue(val){
            Toast.fire({
                icon: 'success' ,
                title: 'This Item will remove from your next choices'
            });
            this.value = val;

            if (val){
                this.emplbranches[this.selectionindex].branch_id = val.id
                this.emplbranches[this.selectionindex].$this.type = {id : val.id, name : val.label}
                this.emplbranches[this.selectionindex].type = this.type
                this.selectionindex = -1
            }
            
            this.reloaditem()
        },
        OpenEmplBranch(empl){
            const vm = this;

            this.employee = empl;
            this.isLoading = true;
            this.$bvModal.show('item_modal')

            const getBranchData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    url: `/api/emplbranch/fetch/all`,
                    params : {employee_id : this.employee.id}
                }).then((data) => {
                    this.emplbranches = data.data.data.items ;
                    this.reloaditem();
                    resolve(data.data.data);
                });
                resolve()
            })
            Promise.all([getBranchData]).then(() => {
                vm.isLoading = false;
                this.selectionindex = -1;
            });
        },
        SelectionRowHighlight(item, type) {
            if (!item || type !== 'row') return
            if (item.index === this.selectionindex) return 'highlight'
        },
        rowHighlight(item, type) {
            if (!item || type !== 'row') return
            if (item.id === this.$route.params.row_id && item.type == this.type) return 'highlight'
        },
          
        bulkDelete() {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    const DeleteData = new Promise((resolve, reject) => {
                        axios.post('/api/empl/bulk-delete', {ids: this.checked})
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            resolve(error.response.data)
                        })
                    })
                    Promise.all([DeleteData]).then((response) => {
                        this.getempls()
                        this.getuserdata();
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    });
                }
            })
        },
        cancel(){
            this.selectionindex = -1
            this.value = null;
        },
        edit(index){
            this.selectionindex = index
            this.value = {id : this.emplbranches[this.selectionindex].branch_id,
                        val :  this.emplbranches[this.selectionindex].$[this.emplbranches[this.selectionindex].type].name}
        },
        remove(index){
            Swal.fire({
            title: 'Are you sure?',
            text: "Click Save to finalize deletion!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, Temporary delete it!'
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    this.emplbranches.splice(index, 1);
                    this.reloaditem()
                    this.selectionindex = -1
                    this.value = null;
                }
            });
        },
        synch(){
            Swal.fire({
            title: 'Synch data from talenta mekari?',
            text: "Click to synch data with talenta mekari!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, Synch it!'
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    this.inproses = true
                    const SynchRoles = new Promise((resolve, reject) => {
                        axios({
                            method: 'get',
                            url: `/api/synchEmployee`,
                            params: {method : 'summary'}
                        }).then((data) => {
                            resolve(data.data);
                        });
                    });
                    Promise.all([SynchRoles]).then((response) => {
                        this.inproses = false
                        this.getempls()
                        this.getuserdata();
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    });
                }
            });
        },
        save(){
            const vm = this;

            let formData = {
                employee_id: this.employee.id,
                data: this.emplbranches
            }
            
            return new Promise((resolve, reject) => {
                axios.post(`/api/emplbranch/saveitems`, formData)
                .then((response) => {
                    this.selectionindex = -1
                    vm.$bvModal.hide('item_modal')
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                    resolve(response.data)
                })
            })
        },
        bulkDeleteitem() {
            Swal.fire({
            title: 'Are you sure?',
            text: "Click Save to finalize deletion!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, Temporary delete it!'
            }).then((result) => {
                // Send request to the server
                if (result.value) {
                    this.checkeditem.sort().reverse().forEach(index =>{
                        this.emplbranches.splice(index,1)
                    });
                    this.reloaditem()
                    this.selectionindex = -1
                }
            });
        },
        removeRole(id) {
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                // Send request to the server
                if (result.value) {
        
                    const DeleteRoles = new Promise((resolve, reject) => {
                        axios.post('/api/empl/delete', {id: id})
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            resolve(error.data)
                        })
                    });
                    Promise.all([DeleteRoles]).then((response) => {
                        this.getempls()
                        this.getuserdata();
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    });
                }
            });
        }
    },
    watch: {
        'type': function() { 
            const vm=this;
            if (this.type){
                vm.inproses = true
                const getallBranchData = this.load_data();
                Promise.all([getallBranchData]).then(() => {
                    vm.inproses = false
                    vm.reloaditem();
                });
            }
        },
        'filter.limit': function() {
            this.getempls()
        },
        page() {
            this.checked = []
            this.getempls()
        },
        checkall(checkall) {
            if ( checkall ) {
                this.empls.items.forEach(role => {
                    this.checked.push(role.id)
                });
            } else {
                this.checked = []
            }
        },
        checkitem(checkitem) {
            let x = 0
            if ( checkitem  && this.emplbranches){
                this.emplbranches.forEach(role => {
                    this.checkeditem.push(x)
                    x++;
                });
            }else{
                this.checkeditem = []
            }
        }
    },
    components: {
        Loading
    }
}
</script>