<template>
    <div class="container-fluid" v-if="!preparation">
       <Loading :visible.sync="preparation"></Loading>
       <b-row class="align-items-center pb-1" v-if="branch_id && listcamera">
            <b-col  lg="6" class="mt-2">
                <h2 class="text-4xl font-bold" id="queue-container"></h2>
            </b-col>
            <b-col lg="6">
                <h3 class="login-box__title text-primary mb-6 mb-lg-15">{{ time }}</h3>
                <div class="separator mb-6 mt-2"></div>
                <video v-show="false"
                    ref="video"
                    id="video"
                    width="320"
                    height="247"
                    autoplay
                />
                <canvas v-show="!processing"
                    class="border border-primary"
                    ref="canvas"
                    id="canvas"
                    width="320"
                    height="247"
                />
                <div v-if="processing" class="absolute inset-0 bg-gray-700 opacity-75 flex">
                    <span class="spinner m-auto"></span>
                </div>
            </b-col>
        </b-row> 
        <BModal id="optionbranch" body-class="pt-4 pb-0" size="lg" centered :title="`Pilih branch`">
            <b-row class="align-items-center mr-1">
                <b-col lg="12">
                    <form enctype="multipart/form-data">
                        <div class="form-group">
                            <label>Branch</label>
                            <treeselect
                                :options="branches"
                                :disable-branch-nodes="true"
                                :show-count="true"
                                v-model="branch_id"
                                placeholder="Choose Branch"
                                />
                        </div>
                        <div class="form-group">
                            <label>Camera</label>
                            <treeselect
                                :options="listcamera"
                                :disable-branch-nodes="true"
                                :show-count="true"
                                v-model="idcamera"
                                placeholder="Choose Camera"
                                />
                        </div>
                    </form>
                </b-col>
            </b-row>
            <template slot="modal-footer" class="mt-6">
                <button type="submit" class="btn btn-success" @click="selectBranch()">Open Branch</button>
            </template>
        </BModal>
    </div>
</template>   
<script>
import Loading from '../../loading/Loading'
import axios from 'axios'
import vcf from 'vcf'

export default {
    data() {
        return {
            idcamera : 0,
            listcamera : [],
            preparation: true,
            processing:false,
            videostream:null,
            branch_id:this.$route.params.id || '',
            loading:false,
            branches:[],
            timeinterval: null,
            time: null,
            curuser : window.user,
        }
    },
    mounted() {
        this.branches = this.curuser.branches.map((item) => {
                                    return {
                                        id: item.id,
                                        label: item.name
                                    }
                                });
        this.preparation = false;
    },
    created() {
        const vm = this
        vm.listCamera();
        console.log(vm.listcamera)
        vm.timeinterval = setInterval(() => {
            this.time = Intl.DateTimeFormat(navigator.language, {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            }).format()
        }, 1000)

        vm.$nextTick(() => {
            if (!this.branch_id || !this.idcamera) {
                Swal.fire({
                title: 'Setting Branch dan kamera yang akan digunakan?',
                showCancelButton: true,
                text: "Mohon setting dahulu sebelum dilakukan proses antrian!!",
                confirmButtonColor: '#d33',
                confirmButtonText: 'Yes'
                }).then((result) => {
                    if (result.value) vm.$bvModal.show('optionbranch');
                    else{
                        vm.$router.push({name: 'antrian.data'}, function() {
                            Toast.fire({
                                icon: 'error',
                                title: "Cant continue without branch selection"
                            });
                        })
                    }
                });
            }else{
                vm.OpenCamera()
            }
        });

        this.preparation = false;
    },
    beforeDestroy () {
        if (this.timeinterval) clearInterval(this.timeinterval)
    },
    methods: {
        selectBranch(){
            const vm = this;
            vm.$bvModal.hide('optionbranch');
            vm.OpenCamera();
        },
        rowHighlight(item, type) {
            if (!item || type !== 'row') return 'highlight'
        },
        async listCamera(){
            if (navigator && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                await navigator.mediaDevices.enumerateDevices().then((devices) => {
                       devices.filter((element) => element.kind === 'videoinput').map((element,index) => {
                            this.listcamera.push({ id : element.deviceId,label : element.label || 'camera ' + (index + 1) });
                        });             
                }).catch((err) => {
                    console.error(`${err.name}: ${err.message}`);
                });
            } else {
                throw new Error('This browser doesn\'t support WebRTC')
            }
        },
        async startCamera () {
            if (!this.videostream &&
                navigator &&
                navigator.mediaDevices &&
                navigator.mediaDevices.getUserMedia) {
                const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: this.idcamera}, 
                                                                           audio :false });
                this.videostream = stream;
                return stream
            } else {
                throw new Error('This browser doesn\'t support WebRTC')
            }
        },
        stopCamera () {
            if (this.videostream) {
                this.videostream.getTracks().forEach(track => track.stop())
                this.videostream = null
            }
        },
        async OpenCamera() {
            const vm = this;
            let defaultCamera; 
            const scannedSound = new Audio("/assets/sound/scanned.mp3");
            const loadingSound = new Audio("/assets/sound/loading.mp3");
            loadingSound.addEventListener(
                "ended",
                function() {
                    if (vm.processing) {
                        this.play();
                    }
                },
                false
            );
            
            if (vm.videostream) vm.stopCamera();
            await vm.startCamera().then(stream => {
                const video = document.getElementById("video")
                const canvas = document.getElementById("canvas")
                const canvasCtx = canvas.getContext("2d")
                video.srcObject = stream
                vm.interval = setInterval(async () => {
                    canvasCtx.drawImage(video, 0, 0, 320, 247)
                }, vm.fps / 1000)

                Instascan.Camera.getCameras().then(function(cameras) {
                    defaultCamera = cameras[0];
                    scanner.start(defaultCamera);
                }).catch(function(e) {
                    Toast.fire({
                        icon: 'error',
                        title: "Error loading camera for scan QR Code"
                    });
                });

                let scanner = new Instascan.Scanner({
                    video: video,
                    mirror: false
                });

                scanner.addListener("scan", function(content) {
                    scanner.stop();
                    vm.processing = true
                    loadingSound.play();
                    var card = new vcf().parse( content )
                
                    axios.post("api/antrian/scan-register", {
                        branch_id : vm.branch_id,
                        vcard : card.toJSON().filter((element) => Array.isArray(element))
                    })
                    .then(result => {
                        scannedSound.play();
                        vm.processing = false;
                        const queue = 'Terimakasih ' + result.data.full_name + '<br>No antrian anda : ' + result.data.no_antrian
                        document.getElementById("queue-container").innerHTML = queue;
                    })
                    .catch(err => {
                        console.log("err :>> ", err);
                        vm.processing = false;
                    })
                    .then(() => {
                        vm.processing = false;
                        scanner.start(defaultCamera);
                    });
                });
            });
        },

        absen() {
            const vm = this
 /*
            vm.shifting.submit('post', '/api/shifting/store', {
            transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
            }],
            }).then((response) => {
                vm.$router.push({name: 'antrian.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
                this.$Progress.finish();
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                this.$Progress.finish();
            });
            */
        },
    },
    components: {
        Loading
    }
}
</script>