<template>
    <section class="content" style="margin-top:-20px">
        <div class="container-fluid" v-if="finishloading" :key="curindex">
            <b-row>
                <div class="col-lg-12">
                    <nav class="navbar navbar-expand">
                        <ul class="navbar-nav">
                            <li class="nav-item dropdown mr-2">
                                <a class="nav-link rounded dropdown-toggle" data-toggle="dropdown" href="#" style="font-size: 20px">
                                    <i class="fa fa-building"></i>
                                    &nbsp;Store&nbsp;{{ alldata.items[curindex].name }}
                                </a>
                                
                                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                    <!--<span class="dropdown-item dropdown-header"><h5>{{ alldata.items[curindex].name }}</h5></span>-->
                                        
                                    <span v-for="(branch, index)  in alldata.branches" :key="branch.id">
                                        <div class="dropdown-divider"></div>
                                        <a @click="loadBranch(branch.id,index);" class="dropdown-item style-items" href="#">
                                            {{ branch.name }}
                                        </a>
                                    </span>
                                </div>
                            </li>

                            <li class="nav-item dropdown ml-2">
                                <a class="nav-link rounded dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="font-size: 20px">
                                    {{ filter.method.charAt(0).toUpperCase() + filter.method.slice(1) }}
                                </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown2" style="margin-top:-10px;" >
                                    <a @click="loadmethod('daily');" class="dropdown-item" href="#">Today</a>
                                    <a @click="loadmethod('mtd');" class="dropdown-item" href="#">Month To Date</a>
                                    <a @click="loadmethod('monthly');" class="dropdown-item" href="#">Monthly</a>
                                    <a @click="loadmethod('ytd');" class="dropdown-item" href="#">Year To Date</a>
                                    <a @click="loadmethod('yearly');" class="dropdown-item" href="#">Yearly</a>
                                </div>
                            </li>
                        </ul>

                        <!--
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item">
                                <div class="description-block">
                                    <span class="description-text info-box-number" style="font-size: 20px">
                                        {{ moment().format('LL') }}
                                    </span>
                                </div>
                            </li>
                        </ul>
                        -->
                    </nav>

<!--
                    <div class="card mt-2"> 
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-4 border-right">
                                    <div class="description-block">
                                        <h5 class="description-header">Store</h5> 
                                        <span class="description-text">
                                            
                                        </span>
                                    </div>
                                </div> 
                                <div class="col-sm-4 border-right">
                                    <div class="description-block">
                                        <h5 class="description-header">Methode</h5> 
                                        <span class="description-text">
                                            <ul class="navbar-nav">
                                                
                                            </ul>
                                        </span>
                                    </div>
                                </div> 
                                <div class="col-sm-4">
                                    <div class="description-block">
                                        <h5 class="description-header">Tanggal</h5> 
                                        <span class="description-text">
                                            {{ moment().format('LL') }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                    -->
                </div>
            </b-row>

            <b-row >
                <b-col lg="9">
                    <b-row>
                        <b-col lg="4">
                            <b-row>
                                <b-col lg="12">
                                    <div class="card card-primary direct-chat direct-chat-primary" style="border-radius: 0.9rem;border-bottom-right-radius: 0.25rem !important;border-bottom-left-radius: 0.25rem !important;">
                                        <div class="card-header bg-info" style="border-radius: 0.9rem;border-bottom-right-radius: 0rem !important; border-bottom-left-radius: 0rem !important;">
                                            <h5 class="text-center">{{ filter.method == 'daily' ? ' Today Target, '+ moment().format('LL')  : filter.method == 'mtd' ? 'Month To Date Target ' + moment().format('MMMM') : filter.method == 'monthly' ? 'Monthly Target '+ moment().format('MMMM') : filter.method == 'ytd' ? 'Year To Date Target ' + moment().format('YYYY') : 'Yearly Target ' + moment().format('YYYY') }}</h5>
                                        </div>
                                        <!-- /.card-header -->
                                        <div class="card-body ml-3 mt-2 mr-2">
                                            <div class="row">
                                                <div class="col-md-9 ml-2" >
                                                    <h4><strong>IDR</strong> <br/>{{  target | toCurrencyUnlabel }}</h4>
                                                </div>
                                                <div class="col-md-3" v-bind:style="{ backgroundImage: 'url(/images/target.png)', backgroundSize: 'contain', backgroundRepeat:'no-repeat',backgroundPosition: 'top', marginLeft : '-20px'}">
                                                    <span></span>
                                                </div>
                                            </div>

                                            <b-row> 
                                                <b-col lg="6" >
                                                    <div :class="moment().hour() <= 14 ? 'info-box ml-2 bg-warning': 'info-box ml-2 bg-red'">
                                                        <div class="info-box-content" >
                                                            <span class="info-box-text">
                                                                <strong>Shift 1</strong>
                                                            </span> 
                                                            <span class="info-box-number">{{ alldata.items[curindex].sales_targets_sum_shift1 ? alldata.items[curindex].sales_targets_sum_shift1 : 0 | toCurrency }}</span>
                                                        </div>
                                                    </div>
                                                </b-col>
                                                <b-col lg="6">
                                                    <div style="margin-left:-15px;" :class="moment().hour() > 14 ? 'info-box bg-warning': 'info-box bg-red'">
                                                        <div class="info-box-content">
                                                            <span class="info-box-text">
                                                                <strong>Shift 2</strong>
                                                            </span>
                                                            <span class="info-box-number">{{ alldata.items[curindex].sales_targets_sum_shift2 ? alldata.items[curindex].sales_targets_sum_shift2 : 0 | toCurrency }}</span>
                                                        </div>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <div class="card-footer pt-2" style="border-bottom-right-radius: 0.8rem !important;border-bottom-left-radius: 0.8rem !important;">
                                            <a href="#" class="small-box-footer" style="border-bottom-right-radius: 0.8rem !important;border-bottom-left-radius: 0.8rem !important;">
                                            <i class="fas fa-arrow-circle-right"></i>&nbsp;<span>Target {{ alldata.items[curindex].name }} </span> 
                                            {{ (alldata.items[curindex].sales_targets_sum_bulanan ? alldata.items[curindex].sales_targets_sum_bulanan : 0) | toCurrency }}</a>
                                        </div>
                                    </div>





                                    <!--
                                    <div class="small-box bg-white"  style="border-radius: 0.9rem;">
                                        <div class="inner ml-3 pt-4">
                                            <div class="row">
                                                <div class="col-md-9" >
                                                    <h4><strong>IDR</strong> <br/>{{  target | toCurrencyUnlabel }}</h4><br/>
                                                    <p>{{ filter.method == 'daily' ? 'Daily Target'  : filter.method == 'mtd' ? 'Month To Date Target' : filter.method == 'monthly' ? 'Monthly Target' : filter.method == 'ytd' ? 'Year To Date Target' : 'Yearly Target' }}</p>
                                                </div>
                                                <div class="col-md-3" v-bind:style="{ backgroundImage: 'url(/images/target.png)', backgroundSize: 'contain', backgroundRepeat:'no-repeat',backgroundPosition: 'top', marginLeft : '-20px'}">
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <a href="#" class="small-box-footer" style="border-bottom-right-radius: 0.8rem !important;border-bottom-left-radius: 0.8rem !important;">
                                            <i class="fas fa-arrow-circle-right"></i>&nbsp;<span v-if="filter.method== 'daily' || filter.method == 'mtd' || filter.method== 'monthly'">Monthly </span> <span v-else>Yearly </span> 
                                            {{ (alldata.items[curindex].sales_targets_sum_bulanan ? alldata.items[curindex].sales_targets_sum_bulanan : 0) | toCurrency }}</a>
                                    </div>
                                -->

                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12">
                                    <div class="card card-primary direct-chat direct-chat-primary" style="border-radius: 0.9rem;border-bottom-right-radius: 0.25rem !important;border-bottom-left-radius: 0.25rem !important;">
                                        <div class="card-header bg-info" style="border-radius: 0.9rem;border-bottom-right-radius: 0rem !important; border-bottom-left-radius: 0rem !important;">
                                            <h5 class="text-center">Checklist Store {{ moment().format('LL') }}</h5>
                                        </div>
                                        <!-- /.card-header -->
                                        <div class="card-body ml-3 mt-2 mr-2">
                                            <div class="row">
                                                <div class="col-md-9 ml-2" >
                                                    <h4><strong>{{ alldata.items[curindex].name }}</strong> <br/>&nbsp;</h4>
                                                </div>
                                                <div class="col-md-3" v-bind:style="{ backgroundImage: 'url(/images/clean.png)', backgroundSize: 'contain', backgroundRepeat:'no-repeat',backgroundPosition: 'top', marginLeft : '-20px'}">
                                                    <span></span>
                                                </div>
                                            </div>

                                            <b-row >
                                                <b-col lg="6" >
                                                    <div style="opacity: 1;" :class="alldata.items[curindex].ceklist_shift1 == alldata.allceklist ? 'mt-3 ml-2 info-box rounded-lg shadow-lg bg-success': 'mt-3 ml-2 info-box rounded-lg shadow-lg bg-red'">
                                                        <span class="info-box-icon">
                                                            <a v-if="$gate.hasAccess('cekliststore.update')" @click="openCeklist" style="cursor:pointer">
                                                                <i :class="alldata.items[curindex].ceklist_shift1 == alldata.allceklist ? 'fas fa-thumbs-up fas-solid yellow' : 'fas fa-exclamation-triangle fas-solid yellow'"></i>
                                                            </a>
                                                        </span>
                                                        <div class="info-box-content" style="margin-left:-10px;">
                                                            <span class="info-box-text">Shift 1</span>
                                                            <span class="info-box-number">{{ alldata.items[curindex].ceklist_shift1 && alldata.allceklist ? (alldata.items[curindex].ceklist_shift1 / alldata.allceklist) * 100 : 0 | toNumber }}&nbsp;<sup style="font-size: 10px;top: -5px !important;">%</sup></span>
                                                        </div>
                                                    </div>
                                                </b-col>
                                                <b-col lg="6">
                                                    <div style="opacity: 1;margin-left:-10px;" :class="alldata.items[curindex].ceklist_shift2 == alldata.allceklist ? 'mt-3 info-box rounded-lg shadow-lg bg-success': 'mt-3 info-box rounded-lg shadow-lg bg-red'">
                                                        <span class="info-box-icon">
                                                            <a v-if="$gate.hasAccess('cekliststore.update')" @click="openCeklist" style="cursor:pointer">
                                                                <i :class="alldata.items[curindex].ceklist_shift2 == alldata.allceklist ? 'fas fa-thumbs-up fas-solid yellow' : 'fas fa-exclamation-triangle fas-solid yellow'"></i>
                                                            </a>
                                                        </span>
                                                        <div class="info-box-content" style="margin-left:-10px;">
                                                            <span class="info-box-text">Shift 2</span>
                                                            <span class="info-box-number">{{ alldata.items[curindex].ceklist_shift2 && alldata.allceklist ? (alldata.items[curindex].ceklist_shift2 / alldata.allceklist) * 100 : 0 | toNumber }}&nbsp;<sup style="font-size: 10px;top: -5px !important;">%</sup></span>
                                                        </div>
                                                    </div>
                                                    
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <div class="card-footer pt-2" style="border-bottom-right-radius: 0.8rem !important;border-bottom-left-radius: 0.8rem !important;">
                                            <a href="#" class="small-box-footer" style="border-bottom-right-radius: 0.8rem !important;border-bottom-left-radius: 0.8rem !important;">
                                            <i class="fas fa-arrow-circle-right"></i>&nbsp;<span>Ceklist Store {{ alldata.items[curindex].name }} </span> 
                                            </a>
                                        </div>
                                    </div>
<!--
                                    <div class="small-box bg-white" style="border-radius: 0.9rem;">
                                        <div class="inner">
                                            <div class="row">
                                                <div class="col-md-9" >
                                                    <h4 class = "ml-3" style="opacity: 1;">Checklist Store<sup style="font-size: 20px"></sup></h4>
                                                </div>
                                                <div class="col-md-3" v-bind:style="{ backgroundImage: 'url(/images/clean.png)', backgroundSize: 'contain', backgroundRepeat:'no-repeat',backgroundPosition: 'top', marginLeft : '-20px'}">
                                                    <span></span>
                                                </div>
                                            </div>
                                            <b-row >
                                                <b-col lg="6" >
                                                    <div style="opacity: 1;" :class="alldata.items[curindex].ceklist_shift1 == alldata.allceklist ? 'mt-3 ml-3 info-box rounded-lg shadow-lg bg-success': 'mt-3 ml-3 info-box rounded-lg shadow-lg bg-red'">
                                                        <span class="info-box-icon">
                                                            <a v-if="$gate.hasAccess('cekliststore.update')" @click="openCeklist">
                                                                <i :class="alldata.items[curindex].ceklist_shift1 == alldata.allceklist ? 'fas fa-thumbs-up fas-solid yellow' : 'fas fa-exclamation-triangle fas-solid yellow'"></i>
                                                            </a>
                                                        </span>
                                                        <div class="info-box-content" style="margin-left:-10px;">
                                                            <span class="info-box-text">Shift 1</span>
                                                            <span class="info-box-number">{{ alldata.items[curindex].ceklist_shift1 && alldata.allceklist ? (alldata.items[curindex].ceklist_shift1 / alldata.allceklist) * 100 : 0 | toNumber }}&nbsp;<sup style="font-size: 10px;top: -5px !important;">%</sup></span>
                                                        </div>
                                                    </div>
                                                </b-col>
                                                <b-col lg="6">
                                                    <div style="opacity: 1;margin-left:-10px;" :class="alldata.items[curindex].ceklist_shift2 == alldata.allceklist ? 'mt-3 info-box rounded-lg shadow-lg bg-success': 'mt-3 info-box rounded-lg shadow-lg bg-red'">
                                                        <span class="info-box-icon">
                                                            <a v-if="$gate.hasAccess('cekliststore.update')" @click="openCeklist">
                                                                <i :class="alldata.items[curindex].ceklist_shift2 == alldata.allceklist ? 'fas fa-thumbs-up fas-solid yellow' : 'fas fa-exclamation-triangle fas-solid yellow'"></i>
                                                            </a>
                                                        </span>
                                                        <div class="info-box-content" style="margin-left:-10px;">
                                                            <span class="info-box-text">Shift 2</span>
                                                            <span class="info-box-number">{{ alldata.items[curindex].ceklist_shift2 && alldata.allceklist ? (alldata.items[curindex].ceklist_shift2 / alldata.allceklist) * 100 : 0 | toNumber }}&nbsp;<sup style="font-size: 10px;top: -5px !important;">%</sup></span>
                                                        </div>
                                                    </div>
                                                    
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <a href="#" class="small-box-footer" style="border-bottom-right-radius: 0.8rem !important;border-bottom-left-radius: 0.8rem !important;">
                                            <i class="fas fa-arrow-circle-right"></i>&nbsp;Checklist Store
                                        </a>
                                    </div>
                                -->
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12">
                                    <div class="card card-success" style="border-radius: 0.9rem;">
                                        <div class="card-header bg-info">
                                            <h5>Top 5 Product (Qty)</h5>
                                        </div>
                                        <div class="card-body p-0">
                                            <table class="table table-sm">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 10px">#</th>
                                                        <!--<th style="width: 40px">Type</th>-->
                                                        <th>Product</th>
                                                        <th style="width: 40px">Qty</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(product, index)  in alldata.items[curindex].rekap_sales_items" :key="product.code">
                                                        <td>{{ index + 1 }}&nbsp;</td>
                                                        <!--<td>{{product.category.name}}</td>-->
                                                        <td>
                                                            {{product.product.name}}
                                                        </td>
                                                        <td><span class="badge bg-success">{{product.sum_qty}}</span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <!--
                                    <div class="small-box bg-white" style="border-radius: 0.9rem;">
                                        <div class="inner">
                                            <h4 class="ml-3"><strong>Top 5 Product</strong></h4>
                                            
                                            <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
                                        </div>
                                        <a href="#" class="small-box-footer" style="margin-top: -15px;border-bottom-right-radius: 0.8rem !important;border-bottom-left-radius: 0.8rem !important;"><i class="fas fa-arrow-circle-right"></i>&nbsp; Top 5 Sales Product</a>
                                    </div>
                                    -->
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col lg="8">
                            <b-row>
                                <b-col lg="6">
                                    <div class="card card-primary direct-chat direct-chat-primary" style="border-radius: 0.9rem;border-bottom-right-radius: 0.25rem !important;border-bottom-left-radius: 0.25rem !important;">
                                        <div class="card-header bg-info" style="border-radius: 0.9rem;border-bottom-right-radius: 0rem !important; border-bottom-left-radius: 0rem !important;">
                                            <h5 class="text-center">{{ filter.method == 'daily' ? 'Today Actual, '+ moment().format('LL')  : filter.method == 'mtd' ? 'Month To Date Actual ' + moment().format('MMMM') : filter.method == 'monthly' ? 'Monthly Actual '+ moment().format('MMMM') : filter.method == 'ytd' ? 'Year To Date Actual ' + moment().format('YYYY') : 'Yearly Actual ' + moment().format('YYYY') }}</h5>
                                        </div>
                                        <!-- /.card-header -->
                                        <div class="card-body ml-3 mt-2 mr-2">
                                            <div class="row">
                                                <div class="col-md-9 ml-2" >
                                                    <h4 style="opacity: 1;"><strong>IDR</strong><br/> <span style="font-size: 20px">{{ alldata.items[curindex].sales_items_sum_amount ? alldata.items[curindex].sales_items_sum_amount : 0 | toCurrencyUnlabel }}</span></h4>
                                                </div>
                                                <div class="col-md-3" v-bind:style="{ backgroundImage: 'url(/images/actual.png)', backgroundSize: 'contain', backgroundRepeat:'no-repeat',backgroundPosition: 'top', marginLeft : '-20px'}">
                                                    <span></span>
                                                </div>
                                            </div>

                                            <b-row> 
                                                <b-col lg="6" >
                                                    <div :class="moment().hour() <= 14 ? 'info-box ml-2 bg-warning': 'info-box ml-2 bg-red'">
                                                        <div class="info-box-content" >
                                                            <span class="info-box-text">
                                                                <strong>Shift 1</strong>
                                                            </span> 
                                                            <span class="info-box-number">{{ alldata.items[curindex].sales_amount_shift1 ? alldata.items[curindex].sales_amount_shift1 : 0 | toCurrency }}</span>
                                                        </div>
                                                    </div>
                                                </b-col>
                                                <b-col lg="6">
                                                    <div style="margin-left:-10px;" :class="moment().hour() > 14 ? 'info-box bg-warning': 'info-box bg-red'">
                                                        <div class="info-box-content">
                                                            <span class="info-box-text">
                                                                <strong>Shift 2</strong>
                                                            </span>
                                                            <span class="info-box-number">{{ alldata.items[curindex].sales_amount_shift2 ? alldata.items[curindex].sales_amount_shift2 : 0 | toCurrency }}</span>
                                                        </div>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <div class="card-footer pt-2" style="border-bottom-right-radius: 0.8rem !important;border-bottom-left-radius: 0.8rem !important;">
                                            <a href="#" class="small-box-footer" style="border-bottom-right-radius: 0.8rem !important;border-bottom-left-radius: 0.8rem !important;">
                                            <i class="fas fa-arrow-circle-right"></i>&nbsp;<span>Actual Sales {{ alldata.items[curindex].name }}</span> 
                                            </a>
                                        </div>
                                    </div>

                                    <!--
                                    <div class="small-box bg-white" style="border-radius: 0.9rem;">
                                        <div class="inner">
                                            <div class="row">
                                                <div class="col-md-9" >
                                                    <h4 style="opacity: 1;" class="ml-3"><strong>IDR</strong><br/> <span style="font-size: 20px">{{ alldata.items[curindex].sales_items_sum_amount ? alldata.items[curindex].sales_items_sum_amount : 0 | toCurrencyUnlabel }}</span></h4>
                                                </div>
                                                <div class="col-md-3" v-bind:style="{ backgroundImage: 'url(/images/actual.png)', backgroundSize: 'contain', backgroundRepeat:'no-repeat',backgroundPosition: 'top', marginLeft : '-20px'}">
                                                    <span></span>
                                                </div>
                                            </div>

                                            <b-row> 
                                                <b-col lg="6" >
                                                    <div :class="moment().hour() <= 14 ? 'info-box ml-3 bg-warning': 'info-box ml-3 bg-red'">
                                                        <div class="info-box-content" >
                                                            <span class="info-box-text">
                                                                <strong>Shift 1</strong>
                                                            </span> 
                                                            <span class="info-box-number">{{ alldata.items[curindex].sales_amount_shift1 ? alldata.items[curindex].sales_amount_shift1 : 0 | toCurrency }}</span>
                                                        </div>
                                                    </div>
                                                </b-col>
                                                <b-col lg="6">
                                                    <div style="margin-left:-10px;" :class="moment().hour() > 14 ? 'info-box bg-warning': 'info-box bg-red'">
                                                        <div class="info-box-content">
                                                            <span class="info-box-text">
                                                                <strong>Shift 2</strong>
                                                            </span>
                                                            <span class="info-box-number">{{ alldata.items[curindex].sales_amount_shift2 ? alldata.items[curindex].sales_amount_shift2 : 0 | toCurrency }}</span>
                                                        </div>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <a href="#" class="small-box-footer" style="margin-top: -15px;border-bottom-right-radius: 0.8rem !important;border-bottom-left-radius: 0.8rem !important;"><i class="fas fa-arrow-circle-right"></i>&nbsp; Actual Sales</a>
                                    </div>
                                    -->
                                </b-col>
                                <b-col lg="6">
                                    <div class="card card-primary direct-chat direct-chat-primary" style="border-radius: 0.9rem;border-bottom-right-radius: 0.25rem !important;border-bottom-left-radius: 0.25rem !important;">
                                        <div class="card-header bg-info" style="border-radius: 0.9rem;border-bottom-right-radius: 0rem !important; border-bottom-left-radius: 0rem !important;">
                                            <h5 class="text-center">{{ filter.method == 'daily' ? 'Today Time Rate'  : filter.method == 'mtd' ? 'Month To Date Time Rate' : filter.method == 'monthly' ? 'Monthly Time Rate' : filter.method == 'ytd' ? 'Year To Date Time Rate' : 'Yearly Time Rate' }}</h5>
                                        </div>
                                        <!-- /.card-header -->
                                        <div class="card-body ml-3 mt-2 mr-2">
                                            <div class="row">
                                                <div class="col-md-9 ml-2" >
                                                    <h4 style="opacity: 1;"><strong>Time rate</strong><br>
                                                        <span style="font-size: 20px"><b>{{ (alldata.items[curindex].sales_items_sum_amount && target ? alldata.items[curindex].sales_items_sum_amount / target : 0) * 100 | toNumber }} %</b></span>
                                                    </h4>
                                                </div>
                                                <div class="col-md-3" v-bind:style="{ backgroundImage: 'url(/images/actual.png)', backgroundSize: 'contain', backgroundRepeat:'no-repeat',backgroundPosition: 'top', marginLeft : '-20px'}">
                                                    <span></span>
                                                </div>
                                            </div>
                                            <b-row > 
                                                <b-col lg="11" >
                                                    <div class="info-box ml-2">
                                                        <div class="info-box-content" style="margin-bottom:-15px;">
                                                            <div class="progress-group">
                                                                <div class="progress progress-lg" style="height: 32px;">
                                                                    <div class="progress-bar bg-warning" :style="`width: ${((alldata.items[curindex].sales_items_sum_amount && target ? alldata.items[curindex].sales_items_sum_amount / target : 0) * 100)}%;`"></div>
                                                                </div>
                                                                <span class="info-box-number">{{ (alldata.items[curindex].sales_items_sum_amount && target ? alldata.items[curindex].sales_items_sum_amount / target : 0) * 100 | toNumber }} % dari {{ target | toCurrencyUnlabel }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <div class="card-footer" style="border-bottom-right-radius: 0.8rem !important;border-bottom-left-radius: 0.8rem !important;">
                                            <a href="#" class="small-box-footer" style="border-bottom-right-radius: 0.8rem !important;border-bottom-left-radius: 0.8rem !important;">
                                            <i class="fas fa-arrow-circle-right"></i>&nbsp;<span >Time rate {{ alldata.items[curindex].name }} </span> 
                                            </a>
                                        </div>
                                    </div>

                                    <!--
                                    <div class="small-box bg-white" style="border-radius: 0.9rem;">
                                        <div class="inner">
                                            <div class="info-box border-0 shadow-none">
                                                <div class="info-box-content">
                                                    <div class="progress-group">
                                                        Time Rate
                                                        <span class="float-right"><b>{{ alldata.items[curindex].sales_items_sum_amount |toCurrencyUnlabel }} / {{ target | toCurrencyUnlabel }}</b></span>
                                                        <div class="progress progress-lg" style="height: 32px;">
                                                            <div class="progress-bar bg-warning" :style="`width: ${((alldata.items[curindex].sales_items_sum_amount && target ? alldata.items[curindex].sales_items_sum_amount / target : 0) * 100)}%;`"></div>
                                                        </div>
                                                        <span class="info-box-number">{{ (alldata.items[curindex].sales_items_sum_amount && target ? alldata.items[curindex].sales_items_sum_amount / target : 0) * 100 | toNumber }}% dari {{ target | toCurrencyUnlabel }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <a href="#" class="small-box-footer" style="margin-top:8px;border-bottom-right-radius: 0.8rem !important;border-bottom-left-radius: 0.8rem !important;"><i class="fas fa-arrow-circle-right"></i>&nbsp; Time Rate</a>
                                    </div>
                                    -->
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col  lg="12">
                                    <div class="card card-primary direct-chat direct-chat-primary" style="border-radius: 0.9rem;border-bottom-right-radius: 0.25rem !important;border-bottom-left-radius: 0.25rem !important;">
                                        <div class="card-header bg-info" style="border-radius: 0.9rem;border-bottom-right-radius: 0rem !important; border-bottom-left-radius: 0rem !important;">
                                            <h5 class="text-center">Promo hari ini</h5>
                                        </div>
                                        <!-- /.card-header -->
                                        <div class="card-body ml-2 mt-2 mr-2">
                                            <b-row>
                                                <b-col md="3" v-for="(promo, index)  in alldata.items[curindex].sales_promos" :key="promo.id">
                                                    <div class="card collapsed-card" style="background-color: #C8A2C8;">
                                                        <div class="card-header text-center" data-card-widget="collapse" data-aria-expanded="false">
                                                            <h3 class="card-title text-white" style="font-size: 15px;text-align: center;margin-bottom:0rem" >{{ promo.kode_promo }}</h3>
                                                        </div>

                                                        <div class="card-body ml-1 mr-1" style="font-size: 12px;">
                                                            <a class="text-white" :data-caption="`${promo.category_promo+' '+promo.nama_promo}`" data-fancybox :href="`storage/type_promo/${promo.category_promo}.png`">
                                                                {{ promo.nama_promo }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    
                                                    <!--
                                                    <div class="card shadow rounded collapsed-card" style="background-color: #C8A2C8;">
                                                        <div class="card-header">
                                                            <button type="button" class="btn btn-tool float-left" data-card-widget="collapse" data-aria-expanded="false" style="margin-left:-15px;">
                                                                <i class="fas fa-receipt fas-solid white"></i>
                                                            </button>
                                                            <span class="text-white" style="font-size: 10px;">{{ promo.kode_promo }}</span>
                                                        </div>
                                                        <div class="card-body" style="font-size: 14px;">
                                                            <a class="text-white" :data-caption="`${promo.category_promo+' '+promo.nama_promo}`" data-fancybox :href="`storage/type_promo/${promo.category_promo}.png`">
                                                                {{ promo.nama_promo }}
                                                            </a>
                                                        </div>
                                                    </div>
                                                    -->
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <div class="card-footer" style="border-bottom-right-radius: 0.8rem !important;border-bottom-left-radius: 0.8rem !important;">
                                            Promo hari ini
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="12">
                                    <div class="card card-primary" >
                                        <div class="card-header bg-info">
                                            <h3 class="card-title">Ordering stock</h3>
                                            <div class="card-tools">
                                                <div class="card-tools">
                                                    <b-form class="form form-inline form--rounded justify-content-end btn-sm" @submit.prevent="loadStock()">
                                                        <b-form-group class="form-inline ml-3" >
                                                            <b-input-group size="sm">
                                                                <b-form-input type="search" class="form-control form-control-navbar" v-model="filter.q" placeholder="Search" aria-label="Search" />
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-navbar" type="submit" >
                                                                        <i class="fa fa-search"></i>
                                                                    </button>
                                                                </div>
                                                            </b-input-group>
                                                        </b-form-group>
                                                    </b-form>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="col-12" v-if="!loadingitem">
                                                <b-table
                                                    id="stok-table"
                                                    style = "margin-top:-10px;"
                                                    show-empty responsive
                                                    :items="stocks.items"
                                                    :fields="tableFields"
                                                    :tbody-tr-class="rowHighlight"
                                                    :busy.sync="loadingitem">
                                                    <template v-slot:table-busy>
                                                        <div class="text-center text-primary my-2">
                                                            <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
                                                        </div>
                                                    </template>
                                                    <template v-slot:empty><div class="text-center text-muted">Data not found</div></template>

                                                    <template v-slot:cell(no)="data">{{ ((page > 0 ? (page - 1) : 0) * filter.limit) + (data.index +1 ) }}</template>
                                                </b-table>

                                                <b-row>
                                                    <b-col lg="8">
                                                        <div class="form form--small form-inline justify-content-lg-start">
                                                            <b-form-group label-class="text-primary mr-1" label="GO TO " label-for="page">
                                                                <b-form-input type="number" v-model="page" id="page" class="form-control"></b-form-input>
                                                            </b-form-group>
                                                            <b-form-group label-class="text-primary ml-2 mr-2" label="VIEW " label-for="filter_limit">
                                                                <select2 id="filter_limit" v-model="filter.limit" :options="[10, 20, 30]" :settings="{minimumResultsForSearch: -1}" />
                                                            </b-form-group>
                                                        </div>
                                                    </b-col>
                                                    <b-col  lg="4">
                                                        <b-pagination
                                                            align="right"
                                                            v-model="page"
                                                            :total-rows="stocks.total"
                                                            :per-page="filter.limit"
                                                            aria-controls="grade-table">
                                                        </b-pagination>
                                                    </b-col>
                                                </b-row>    
                                            </div>
                                            <div class="overlay" v-else>
                                                <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                                            </div>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col lg="3">
                    <Detail :article_id="alldata.article.id" :show_berita="false"></Detail>
                </b-col>
            </b-row>

            <div class="separator mt-2 mb-2"></div>
            <b-row>
                <b-col lg="12" v-if="finishloading"> <!--&& filter.method.substring(0,1) !== 'd'-->
                    <div class="small-box bg-white" style="border-radius: 0.9rem;">
                        <div class="inner">
                            <h4 class="ml-3"><strong>Sales vs Target</strong></h4>
                            <apexchart type="bar" :options="bydate" :series="dateseries" height='300px' ></apexchart>
                        </div>
                        <a href="#" class="small-box-footer" style="margin-top: -15px;border-bottom-right-radius: 0.8rem !important;border-bottom-left-radius: 0.8rem !important;"><i class="fas fa-arrow-circle-right"></i>&nbsp; Sales vs Actual</a>
                    </div>
                </b-col>
            </b-row>

            <BModal id="ceklist_modal" body-class="pt-4 pb-0" size="lg" centered :title="`Ceklist ${alldata.items[curindex].name + ' ' + moment().format('LL') }`" @hidden="loadmethod(filter.method)">
                <b-row class="align-items-center pb-1">
                    <b-col lg="12">
                        <Ceklist :branch_id="alldata.items[curindex].id" :tanggal="moment().format('YYYY-MM-DD')" class="mb-2"></Ceklist>
                    </b-col>    
                </b-row>
            </BModal>
        </div><!--/. container-fluid -->
        <div v-else>
            <form-loader></form-loader>
        </div>
        <!--
                            <span class="info-box-icon ml-2 d-none">
                                            <div style="display:inline;width:100px;height:50px;margin-top:-25px;">
                                                <knob-control
                                                    :min="0"
                                                    :max="target && alldata.items[curindex].sales_items_sum_amount ? (target > alldata.items[curindex].sales_items_sum_amount ? 100 : ((alldata.items[curindex].sales_items_sum_amount / target) * 100)) : 100"
                                                    :value = "(alldata.items[curindex].sales_items_sum_amount && target ? alldata.items[curindex].sales_items_sum_amount / target : 0) * 100"
                                                    :size="100"
                                                    :strokeWidth="20"
                                                    :primary-color="(alldata.items[curindex].sales_items_sum_amount && target ? alldata.items[curindex].sales_items_sum_amount / target : 0) * 100 >= 100 ? '#25be45' : '#E4f809'"
                                                    secondary-color="#C2d9dc"
                                                    :text-color="(alldata.items[curindex].sales_items_sum_amount && target ? alldata.items[curindex].sales_items_sum_amount / target : 0) * 100 >= 100 ? '#25be45' : '#E4f809'"
                                                    :animation="{
                                                        animated: true,
                                                        animateValue: true,
                                                        animationDuration: '5000',
                                                        animationFunction: 'linear'
                                                    }"
                                                    ></knob-control>
                                            </div>
                                        </span>    
                            <div class="card card-success" style="border-radius: 0.9rem;">
                                <div class="card-header bg-info" style="border-radius: 0.9rem;border-bottom-right-radius: 0rem !important;
                                                                border-bottom-left-radius: 0rem !important;">
                                    <h4>Top 5 Product (Qty)</h4>
                                </div>
                                <div class="card-body p-0">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>
                                                <th style="width: 10px">#</th>
                                                <th style="width: 40px">Type</th>
                                                <th>Product</th>
                                                <th style="width: 40px">Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(product, index)  in alldata.items[curindex].rekap_sales_items" :key="product.code">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{product.category.name}}</td>
                                                <td>
                                                    {{product.product.name}}
                                                </td>
                                                <td><span class="badge bg-success">{{product.sum_qty}}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            -->
    </section>
</template>

<script>
    import moment from 'moment'
    import KnobControl from 'vue-knob-control'
    import Detail from './article/Detail'
    import Ceklist from './setup/ceklist/Detail'
    import { eventBus } from '../../app.js'

    export default {
        data() {
            return {
                dateseries : [
                    { name: "Target",type: 'column', data: [44, 55, 41, 64, 22, 43, 21] }, 
                    { name: "Actual",type: 'line', data: [53, 32, 33, 52, 13, 44, 32] },
                    //{ name: 'Qty Target',type: 'line', data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]},
                    //{ name: 'Qty Actual', type: 'line', data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]}
                ], 
                bydate:{
                    chart: {
                        type: 'line',
                        height: 430
                    },
                    
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            dataLabels: {
                                position: 'top',
                            },
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        offsetX: -6,
                        style: {
                            fontSize: '12px',
                            colors: ['#fff']
                        },
                        formatter: function (value) {
                                return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                        }
                    },
                    stroke: {
                        show: true,
                        width: [0, 4],
                        //colors: ['#fff']
                    },
                    tooltip: {
                        shared: true,
                        intersect: false
                    },
                    xaxis: {
                        categories: [2001, 2002, 2003, 2004, 2005, 2006, 2007],
                    },
                    yaxis: {
                        labels: {
                            formatter: function (value) {
                                return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                            }
                        },
                    },
                },
                chartOptions: {
                    chart: {
                        id: 'vuechart-example'
                    },
                    xaxis: {
                        categories: []
                    },
                },
                series: [{
                    name: 'Sales Qty',
                    data: []
                }],
                target:0,
                curindex : 0,
                finishloading : false,
                loadingitem:true,
                mpage:1,
                stocks:[],
                filter : {
                    q: '',
                    branch_id:'',
                    method:'daily',
                    limit : 10
                },
                alldata:[],
                tableFields: [
                    { key: 'no', label: 'No.', tdClass: 'text-right', thAttr: { width: '30' } },
                    { key: 'product.name', label: 'Nama Produk' },
                    { key: 'qty', label: 'Stock', thAttr: { width: '60' } , tdClass: 'text-center stick-end', thClass: 'stick-end'}
                ]
            }
        },
        mounted(){
           // let elem = document.getElementsByClassName('main-header')[0];
            //elem.click();
        },
        created() {
            const vm = this
            moment.locale('id');

            vm.$nextTick(function() {
                vm.loadData();
            });

            eventBus.$on('reload_dashboard', function(payload) {
                this.loadmethod(this.filter.method ?? 'daily')
            });
        },
        computed: {
            page: {
                get() {
                    return this.mpage;
                },
                set(val) {
                    this.mpage = val;
                }
            },
            
        },
        methods: {
            rowHighlight(item, type) {
                if (!item || type !== 'row') return
            },
            loadStock(){
                this.loadingitem = true
                const getStock = new Promise((resolve) => {
                    axios({
                        method: 'get',
                        params: this.filter,
                        url: `/api/storestock/fetch?page=${this.page}`
                    }).then((data) => {
                        this.stocks = data.data.data;
                        resolve(data.data.data);
                    });
                })
                Promise.all([getStock]).then(() => {
                    this.loadingitem = false
                });
            },
            loadData(){
                const vm = this;
                this.finishloading = false
                this.loadingitem = true
                const getStoreData = new Promise((resolve) => {
                    axios({
                        method: 'get',
                        params: this.filter,
                        url: `/api/dashboardstore/store`
                    }).then((data) => {
                        vm.alldata = data.data.data;
                        vm.target = (vm.alldata.items[vm.curindex].sales_targets_sum_harian ? vm.alldata.items[vm.curindex].sales_targets_sum_harian : 0) 
                    
                        resolve(data.data.data);
                    });
                })
                const getStock = new Promise((resolve) => {
                    axios({
                        method: 'get',
                        params: this.filter,
                        url: `/api/storestock/fetch?page=${this.page}`
                    }).then((data) => {
                        vm.stocks = data.data.data;
                        resolve(data.data.data);
                    });
                })
                Promise.all([getStoreData,getStock]).then(() => {
                    //ini untuk top 5 product 
                    //this.chartOptions.xaxis.categories = this.alldata.items[this.curindex].rekap_sales_items.map((product) => {
                    //    return product.product.name;
                    //});
                    //this.series[0].data = this.alldata.items[this.curindex].rekap_sales_items.map((product) => {
                    //    return  product.sum_qty ;
                    //});

                    //if (this.filter.method.substring(0,1) != 'd'){
                        this.bydate.xaxis.categories = this.alldata.items[this.curindex].sales_items_bymonth.map((item) => {
                            return item.name;
                        });
                        this.dateseries[0].data = this.alldata.items[this.curindex].sales_items_bymonth.map((item) => {
                            return item.target_amount;
                        });
                        this.dateseries[1].data = this.alldata.items[this.curindex].sales_items_bymonth.map((item) => {
                            return item.amount;
                        });
                        //this.dateseries[2].data = this.alldata.items[this.curindex].sales_items_bymonth.map((item) => {
                        //    return item.target_qty;
                       // });
                        //this.dateseries[3].data = this.alldata.items[this.curindex].sales_items_bymonth.map((item) => {
                        //    return item.qty;
                        //});
                    //}
                    this.finishloading = true;
                    this.loadingitem = false;
                });
            },
            loadBranch(branch_id,index){
                const vm = this
                this.curindex = index
                this.filter.branch_id = branch_id;
                vm.target = (vm.alldata.items[vm.curindex].sales_targets_sum_harian ? vm.alldata.items[vm.curindex].sales_targets_sum_harian : 0) 
                this.loadStock();
            },
            loadmethod(method){
                this.filter.method = method;
                this.loadData();
            },
            openCeklist(){
                this.$bvModal.show('ceklist_modal')
            },
        },
        components:{
            KnobControl,
            Detail,
            Ceklist
        }
    }
</script>