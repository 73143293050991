<template>
    <ContentLoader :height="250" primaryColor="#f2f4f5" secondaryColor="#fafcfc">
        <rect x="0" y="6" width="30" height="6" />
        <rect x="0" y="16" width="calc(33.33% - 5px)" rx="2" height="10" />
        <rect x="calc(33.33% + 3px)" y="6" width="30" height="6" />
        <rect x="calc(33.33% + 3px)" y="16" width="calc(33.33% - 5px)" rx="2" height="10" />

        <rect x="0" y="32" width="30" height="6" />
        <rect x="0" y="42" width="calc(33.33% - 5px)" rx="2" height="10" />
        <rect x="calc(33.33% + 3px)" y="32" width="30" height="6" />
        <rect x="calc(33.33% + 3px)" y="42" width="calc(33.33% - 5px)" rx="2" height="10" />
        <rect x="calc(66.66% + 6px)" y="32" width="30" height="6" />
        <rect x="calc(66.66% + 6px)" y="42" width="calc(33.33% - 5px)" rx="2" height="10" />

        <rect x="0" y="58" width="30" height="6" />
        <rect x="0" y="68" width="calc(33.33% - 5px)" rx="2" height="10" />
        <rect x="calc(33.33% + 3px)" y="58" width="30" height="6" />
        <rect x="calc(33.33% + 3px)" y="68" width="calc(33.33% - 5px)" rx="2" height="10" />

        <rect x="0" y="84" width="30" height="6" />
        <rect x="0" y="94" width="calc(33.33% - 5px)" rx="2" height="10" />
        <rect x="calc(33.33% + 3px)" y="84" width="30" height="6" />
        <rect x="calc(33.33% + 3px)" y="94" width="calc(33.33% - 5px)" rx="2" height="10" />
        <rect x="calc(66.66% + 6px)" y="84" width="30" height="6" />
        <rect x="calc(66.66% + 6px)" y="94" width="calc(33.33% - 5px)" rx="2" height="10" />

        <rect x="0" y="110" width="30" height="6" />
        <rect x="0" y="120" width="50" height="10" />

        <rect x="0" y="156" width="100%" height="85" />
    </ContentLoader>
</template>
<script>
export default {
    
}
</script>