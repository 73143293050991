<template>
    <b-form v-if="!preparation" class="form" enctype="multipart/form-data">
        <b-row>
            <b-col lg="4" class="mr-1">
               <b-form-group label="Name *" label-for="subject">
                    <b-form-textarea
                        id="subject"
                        v-model.trim="article.subject"
                        required 
                        placeholder="Tulis Subject." :class="{ 'is-invalid': article.errors.has('subject') }"/>
                    <has-error :form="article" field="subject"></has-error>
                </b-form-group>
            </b-col>
            <b-col lg="3" class="mr-1">
              <b-form-group label="Publish" label-for="type">
                    <b-form-radio-group
                        size="sm"
                        id="type"
                        v-model="article.type"
                        :options="[{value:'public', text:'Public'},{value:'private', text:'Only selected employee'}]"
                        buttons
                        button-variant="outline-primary"
                    ></b-form-radio-group>
                    <div class="separator mt-2 mb-6"></div>
                    <treeselect v-if="article.type == 'private'"
                        :options="employees"
                        :show-count="true"
                        :default-expand-level="1"
                        v-model="listemployee"
                        :disable-branch-nodes="true"
                        search-nested
                        :valueFormat="'object'"
                        placeholder="Choose"
                        :multiple="true"
                    />
                    <has-error :form="article" field="type"></has-error>
                </b-form-group>
            </b-col>
            <b-col lg="4" class="mr-1">   
                <b-form-group label="Media*">
                    <b-form-radio-group
                        size="sm"
                        id="media_type"
                        v-model="article.media_type"
                        :options="[{value:'video', text:'Video url'},{value:'image', text:'Image'}]"
                        buttons
                        button-variant="outline-primary"
                    ></b-form-radio-group>
                    <div class="separator mt-2 mb-6"></div>
                    <uploader v-if="article.media_type === 'image'" name="upload_file" description="Maks 2MB dengan ekstensi (png, jpg, gif)." accept=".png,.jpg,.jpeg,.gif" v-bind:files.sync="article.media" id="upload_file" :class="{ 'is-invalid': article.errors.has('media') }"/>
                    <b-form-input v-else v-model="article.media" id="media" required placeholder="Media Url"  :class="{ 'is-invalid': article.errors.has('media') }"></b-form-input>
                    <has-error :form="article" field="media"></has-error>
                </b-form-group>
            </b-col>    
          </b-row>
          <b-row>
            <b-col lg="12" class="mr-2">
                <b-form-group label="Article *" label-for="berita">
                    <quill-editor
                        ref="myQuillEditor"
                        v-model="article.berita"
                        :options="editorOption"
                        @blur="onEditorBlur($event)"
                        @focus="onEditorFocus($event)"
                        @ready="onEditorReady($event)"
                    />
                </b-form-group>
            </b-col>
        </b-row>
        <div class="separator mt-2 mb-6"></div>
    </b-form>
    <div v-else class="text-center text-primary py-14">
        <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>  
    </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
    props: ['article','preparation','multivalue'],
    data () {
      return {
        employees :[],
        listemployee : [],
        editorOption: {
          // Some Quill options...
        },
      }
    },
    methods: {
      prosesarray(array){
            return array.map(element => {
                   return {
                        id: element.id,
                        label: element.first_name + ' ' + element.last_name
                    }
            });
      },
      onEditorBlur(quill) {
        console.log('editor blur!', quill)
      },
      onEditorFocus(quill) {
        console.log('editor focus!', quill)
      },
      onEditorReady(quill) {
        console.log('editor ready!', quill)
      },
      onEditorChange({ quill, html, text }) {
        console.log('editor change!', quill, html, text)
        this.content = html
      }
    },
    created() {
        const vm = this
        if (this.article_id){
            this.article.reset();
            const getArticleData = new Promise((resolve) => {
                axios.get(`/api/article/fetch/${this.article_id}`).then((data) => {
                    resolve(data.data.data);
                    this.article.fill(data.data.data);
                });
                resolve()
            })
            Promise.all([getArticleData]).then(() => {
                vm.preparation = false
            })
        }

        const getAllEmployeeData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/branch/fetch/all`,
                params: {groupby : 'employees'}
            }).then((data) => {
                vm.employees = data.data.data.items.map((branch) => {
                                    return {
                                        id: 'branch'+branch.id,
                                        label: branch.name,
                                        children : this.prosesarray(branch.employees)
                                    }
                                });
                resolve(data);
            });
        })
        Promise.all([getAllEmployeeData]).then(() => {
          vm.preparation = false
          this.listemployee = this.multivalue;
        });
    },
    computed: {
      editor() {
        return this.$refs.myQuillEditor.quill
      }
    },
    watch: {
        listemployee() {
          this.$emit('updateEmployee' , this.listemployee);
        }
    },
    components: {
        quillEditor
    }
}
</script>