<template>
    <div class="panel__body pt-4 ml-4">
        <div class="container-fluid">
            <b-row>
                <div class="col-12 col-sm-6 col-md-4" v-for="(doctype, index)  in documents" :key="doctype.id">
                    <div class="info-box">
                        <span class="info-box-icon bg-info elevation-1"><i class="fas fa-cog"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">
                                <p>
                                    <router-link v-if="$gate.hasAccess('doclist.data')" :to="{ name: 'doclist.detail', params:{id: doctype.id} ,query:{action:'all',expiry:'all'}}" class="text-primary mt-1"><strong>{{doctype.name}}</strong></router-link>

                                    <span class="badge badge-warning float-right">
                                        <small>{{doctype.document_count.all || 0}} in total</small>
                                    </span>
                                </p>
                                
                                <va-chart :chart-config="{type: 'bar',
                                data: {
                                    labels: ['On Proses', 'Approval','Final'], 
                                    datasets: [{
                                        label: 'Expired',
                                        data: [doctype.document_count.onprosesexpired, doctype.document_count.approvalexpired,doctype.document_count.finalexpired],
                                        backgroundColor: '#b82e2e'
                                    }, {
                                        label: 'Legal',
                                        data: [doctype.document_count.onproseslegal, doctype.document_count.approvallegal,doctype.document_count.finallegal],
                                        backgroundColor: '#109618'
                                    }]
                                },
                                options: {
                                    responsive: true,
                                    maintainAspectRatio: true,
                                    legend: {
                                        position: 'bottom',
                                        display: false
                                    },
                                    scales: {
                                        xAxes: [{
                                            stacked: true // this should be set to make the bars stacked
                                        }],
                                        yAxes: [{
                                            stacked: true // this also..
                                        }]
                                    }
                                }}"></va-chart>
                                <br>
                            </span>

                            <div v-if="$gate.hasAccess('doclist.data')">
                                <div class="progress-group">
                                    <router-link  :to="{ name: 'doclist.detail', params:{id: doctype.id}, query:{action:'approval',expiry:'all'}}" class="text-primary mt-1"><i class="fas fa-cog"></i> <span class="progress-text">On Approval</span></router-link>
                                    
                                    <span class="float-right"><b class="text-center badge" :style="{ backgroundColor:'#f56b12'}" data-toggle="tooltip" data-placement="right" title="Total Approval">{{doctype.document_count.approval || 0}} </b> | <b class="text-center badge" :style="{backgroundColor:'#109618'}"  data-toggle="tooltip" data-placement="right" title="on approval & legal"> {{doctype.document_count.approvallegal || 0}} </b> | <b class="text-center badge" :style="{backgroundColor:'#b82e2e'}" data-toggle="tooltip" data-placement="right" title="on Approval & expired">{{doctype.document_count.approvalexpired || 0}}</b></span>
                                    <div class="progress progress-sm ml-2">    
                                        <div class="progress-bar" :style="{ width: (doctype.document_count.approval ? (doctype.document_count.approvallegal / doctype.document_count.approval) * 100 : 0)  + '%',backgroundColor:'#109618'}" data-toggle="tooltip" data-placement="right" title="Persentase Legal on Approval"></div>
                                    </div>
                                    <div class="progress progress-sm ml-2">   
                                        <div class="progress-bar" :style="{ width: (doctype.document_count.approval ? (doctype.document_count.approvalexpired / doctype.document_count.approval) * 100 : 0) + '%',backgroundColor:'#b82e2e'}" data-toggle="tooltip" data-placement="right" title="Persentase Expired in Approval"></div>
                                    </div>
                                </div>
                                <div class="progress-group">
                                    <router-link :to="{ name: 'doclist.detail',params:{id: doctype.id},query:{action:'onproses',expiry:'all'}}" class="text-primary mt-1"><i class="fas fa-cog"></i> <span class="progress-text">On Proses</span></router-link>
                                    
                                    <span class="float-right"><b class="text-center badge" :style="{ backgroundColor:'#00c0ef'}" data-toggle="tooltip" data-placement="right" title="Total in proses">{{doctype.document_count.onproses || 0}} </b> | <b class="text-center badge" :style="{backgroundColor:'#109618'}" data-toggle="tooltip" data-placement="right" title="Legal on proses"> {{doctype.document_count.onproseslegal || 0}} </b> | <b class="text-center badge" :style="{backgroundColor:'#b82e2e'}" data-toggle="tooltip" data-placement="right" title="Expired on proses">{{doctype.document_count.onprosesexpired || 0}}</b></span>
                                    
                                    <div class="progress progress-sm ml-2">   
                                        <div class="progress-bar" :style="{ width: (doctype.document_count.onproseslegal / doctype.document_count.onproses) * 100 + '%', backgroundColor:'#109618'}"  data-toggle="tooltip" data-placement="right" title="Persentase Legal on proses"></div>
                                    </div>
                                    <div class="progress progress-sm ml-2">   
                                        <div class="progress-bar"  :style="{ width: (doctype.document_count.onprosesexpired / doctype.document_count.onproses) * 100 + '%', backgroundColor:'#b82e2e'}" data-toggle="tooltip" data-placement="right" title="Persentase Expired on proses"></div>
                                    </div>
                                </div>
                                <div class="progress-group">
                                    <router-link :to="{ name: 'doclist.detail', params:{id: doctype.id}, query:{action:'final',expiry:'all'}}" class="text-primary mt-1"><i class="fas fa-cog"></i> <span class="progress-text">Final Dokumen</span></router-link>
                                    
                                    <span class="float-right"><b class="text-center badge" :style="{ backgroundColor:'#00a65a'}" data-toggle="tooltip" data-placement="right" title="Total Finish">{{doctype.document_count.final ||0}} </b> | <b class="text-center badge" :style="{backgroundColor:'#109618'}"  data-toggle="tooltip" data-placement="right" title="Finish & Legal">{{doctype.document_count.finallegal ||0}} </b> | <b class="text-center badge" :style="{backgroundColor:'#b82e2e'}"  data-toggle="tooltip" data-placement="right" title="Finish & Expired">{{doctype.document_count.finalexpired ||0}}</b></span>
                                    
                                    <div class="progress progress-sm ml-2">       
                                        <div class="progress-bar" :style="{ width: (doctype.document_count.finallegal / doctype.document_count.final) * 100 + '%',backgroundColor:'#109618'}"  data-toggle="tooltip" data-placement="right" title="Persentase Legal in Finish"></div>
                                    </div>
                                    <div class="progress progress-sm ml-2">       
                                        <div class="progress-bar" :style="{ width: (doctype.document_count.finalexpired / doctype.document_count.final) * 100 + '%',backgroundColor:'#b82e2e'}" data-toggle="tooltip" data-placement="right" title="Persentase Expired in Finish"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-row>
            <b-row>
                <div class="col-12">
                    <b-form class="form form-inline form--rounded justify-content-end btn-sm" @submit.prevent="filterAllDocument()">
                        <b-form-group class="form-inline" >
                            <b-input-group>
                                <b-form-group >
                                    <b-form-radio-group
                                        size="sm"
                                        v-model="filter.action"
                                        :options="[{value:'all', text:'All'},{value:'approval', text:'Need Approval'},{value:'onproses', text:'On Process'},{value:'expired', text:'Almost Expired'},{value:'final', text:'Final Document'}]"
                                        buttons
                                        button-variant="outline-primary"
                                    ></b-form-radio-group>
                                </b-form-group>
                                <b-form-input type="search" class="form-control form-control-navbar" v-model="filter.q" placeholder="Search" aria-label="Search" />
                                <div class="input-group-append">
                                    <button class="btn btn-navbar" type="submit" >
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                            </b-input-group>
                        </b-form-group>
                    </b-form>
                    <b-table
                        id="document-table"
                        class="table--middle table--primary mt-2" show-empty responsive
                        :items="filterdocuments"
                        :fields="tableFields"
                        :tbody-tr-class="rowHighlight"
                        :busy.sync="preparation">
                        <template v-slot:table-busy>
                            <div class="text-center text-primary my-2">
                                <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
                            </div>
                        </template>
                        <template v-slot:empty><div class="text-center text-muted">Data not found</div></template>

                        <template v-slot:cell(no)="data">{{ ((page > 0 ? (page - 1) : 0) * filter.limit) + (data.index +1 ) }}</template>

                        <template v-slot:cell(action)="data">
                            <div class="btn-group" v-if="$gate.hasAccess('doclist.update') && data.item.progress == 'approval'">
                                <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                    <i class="fas fa-wrench"></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right" role="menu" x-placement="bottom-end" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-124px, 18px, 0px);">
                                    <a class="dropdown-item" @click="ProcessDocument(data.item.id,'draft')">
                                        <i class="fas fa-edit"></i>Draft
                                    </a>
                                    <a class="dropdown-divider" ></a>
                                    <a class="dropdown-item" @click="ProcessDocument(data.item.id,'approve')">
                                        <i class="fas fa-star"></i>Approve
                                    </a>
                                    <a class="dropdown-divider"></a>
                                    <a class="dropdown-item" @click="ProcessDocument(data.item.id),'cancel'" variant="danger">
                                        <i class="fas fa-times"></i> Cancel
                                    </a>
                                </div>
                            </div>
                            <span class="text-primary font-weight-medium" v-else>
                                Read only
                            </span>
                        </template>
                        <template v-slot:cell(no_doc)="data">
                            <span class="text-primary font-weight-medium">
                            {{ data.item.no_doc  }} <span class="badge badge-warning">{{data.item.doc_version}}</span></span>
                        </template>
                        <template v-slot:cell(doc_date)="data">
                            <span class="text-primary font-weight-medium">{{ moment(parseInt(data.item.doc_date.$date.$numberLong)).format("YYYY-MM-DD")   }} </span>
                        </template>
                        <template v-slot:cell(doc_name)="data">
                            <span class="text-primary font-weight-medium">{{ data.item.doc_type.name  }}</span>
                        </template>
                        <template v-slot:cell(status)="data">
                            <span class="text-primary font-weight-medium">{{ data.item.status  }}</span>
                        </template>
                         <template v-slot:cell(progress)="data">
                            <span class="text-primary font-weight-medium">{{ data.item.progress  }}</span>
                        </template>
                        <template v-slot:cell(creator)="data">
                            <span class="text-primary font-weight-medium">{{ data.item.creator.name  }}</span><br>
                            <span><small>{{ data.item.creator.branch.name + ' ' + data.item.creator.organization.name }}</cite></small></span>
                        </template>
                        <template v-slot:cell(reviewer)="data">
                            <span class="text-primary font-weight-medium">{{ data.item.reviewer.name  }}</span><br>
                            <span><small>{{ data.item.reviewer.branch.name + ' ' + data.item.reviewer.organization.name }}</small></span>
                        </template>
                    </b-table>

                    <b-row>
                        <b-col lg="6">
                             <div class="form form--small form-inline justify-content-center justify-content-lg-start">
                                <b-form-group label-class="text-primary mr-1" label="GO TO " label-for="page">
                                    <b-form-input type="number" v-model="page" id="page" class="form-control"></b-form-input>
                                </b-form-group>
                                <b-form-group label-class="text-primary ml-2 mr-2" label="VIEW " label-for="filter_limit">
                                    <select2 id="filter_limit" v-model="filter.limit" :options="[10, 20, 30]" :settings="{minimumResultsForSearch: -1}" />
                                </b-form-group>
                            </div>
                        </b-col>
                        <b-col lg="6">
                            <b-pagination
                                align="right"
                                v-model="page"
                                :total-rows="total"
                                :per-page="filter.limit"
                                aria-controls="document-table">
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </b-row>
        </div>
    </div>
</template>

<script>
  import moment from 'moment'
  export default {
    data () {
      return {
        preparation : true,
        documents : [],
        mydocuments :[],
        filterdocuments : [],
        total : 0,
        mpage : 1,
        find : {
            employee_id : window.user.employee_id,
        },
        filter: {
            q : '',
            action : 'all',
            limit : 30
        },
        tableFields: [
                { key: 'no', label: 'No.', tdClass: 'text-right', thAttr: { width: '50' } },
                { key: 'doc_name', label: 'Jenis' },
                { key: 'no_doc', label: 'No. Doc' },
                { key: 'doc_date', label: 'Doc. Date' },
                { key: 'status', label: 'Status' },
                { key: 'progress', label: 'Progress' },
                { key: 'expired_status', label: 'Expired Status' },
                { key: 'creator', label: 'Document Creator' },
                { key: 'reviewer', label: 'Document Reviewer', tdClass: 'text-center stick-end', thAttr: { width: '260' } ,thClass: 'stick-end' }
                //{ key: 'action', label: 'Action', tdClass: 'text-center stick-end', thAttr: { width: '260' } ,thClass: 'stick-end' }
            ],
        chartConfig: {
          type: 'pie',
          data: {
            labels: ['final', 'onproses','approval'],
            datasets: [{
              data: [50, 50, 50,50],
              backgroundColor: ['#00a65a', '#00c0ef','#f56b12'],
              hoverBackgroundColor: ['#00a65a', '#00c0ef','#f56b12']
            }]
          },
          options: {
            responsive: true,
            maintainAspectRatio: !this.isMobile,
            legend: {
              position: 'bottom',
              display: true
            }
          }
        }
      }
    },
    mounted(){
        // let elem = document.getElementsByClassName('main-header')[0];
        //elem.click();
    },
    created() {
        const vm = this
        vm.$nextTick(function() {
            vm.loadData();
        });
    },
    computed: {
        page: {
            get() {
                return this.mpage;
            },
            set(val) {
                if (val){
                    this.mpage = val;
                }
            }
        }
    },
    methods: {
        ProcessDocument(id,status){

        },
        filterAllDocument(){
            const vm = this
            vm.preparation = true;
            let tmp = ((vm.page > 0 ? (vm.page - 1) : 0) * vm.filter.limit) ;

            vm.filterdocuments = vm.mydocuments.filter((item,index) => {
                if (index >= tmp && index <= tmp + this.filter.limit){
                    if (this.filter.action != 'all' && this.filter.action != ''){
                        if (this.filter.q != ''){
                            if (item.progress == this.filter.action && 
                                item.no_doc.indexOf(filter.q) ){
                                    return item;
                            }
                        }else{
                            if (item.progress == this.filter.action) return item;
                        }
                    }else{
                        if (this.filter.q != ''){
                            if (item.no_doc.indexOf(filter.q) ){
                                    return item;
                            }
                        }else return item;
                    }
                }
            });
            this.total = this.filterdocuments.length
            vm.preparation = false;
        },
        rowHighlight(item, type) {
            if (!item || type !== 'row') return
        },
        loadData(){
            this.preparation = true
            const getData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    params: this.find,
                    url: `/api/document/fetch/dashboard`
                }).then((data) => {
                    this.documents = data.data.data.items;
                    this.mydocuments = data.data.data.mydocuments;
                    this.filterdocuments = this.mydocuments ? this.mydocuments : [];
                    this.total = data.data.data.total
                    //if (this.mydocuments){
                    //    this.chartConfig.data.datasets[0].data = [this.data.rate_resolved, this.data.rate_unresolved, this.data.rate_overdue];
                    //}
                    
                    resolve(data.data.data);
                });
            })
            Promise.all([getData]).then(() => {
                this.preparation = false
            });
        },
    },
    watch : {
        'filter.limit': function() {
            const vm = this
            vm.filterAllDocument()
        },
        page() {
            const vm = this
            vm.filterAllDocument()
        },
    }
  }
</script>
