<template>
    <b-form v-if="!preparation" class="form" enctype="multipart/form-data">
        <b-row>
            <b-col lg="5" class="mr-2">
               <b-form-group label="Name *" label-for="name">
                    <b-form-input  v-model="grade.name" id="name" required placeholder="Grade Name"  :class="{ 'is-invalid': grade.errors.has('name') }"></b-form-input>
                    <has-error :form="grade" field="name"></has-error>
                </b-form-group>
            </b-col>    
        </b-row>
        <div class="separator mt-2 mb-6"></div>
    </b-form>
    <div v-else class="text-center text-primary py-14">
        <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
<!--   COMPONENT NOT FOUND
<div>
    <not-found></not-found>
</div>
-->   
    </div>
</template>
<script>

export default {
    props: ['preparation','grade','state'],
}
</script>