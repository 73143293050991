<template>
    <div class="panel__body pt-4 ml-4" v-if="!isLoading">
        <div class="form">
            <EmplForm :preparation="isLoading" :access_group="access_group" :allgroups="allgroups" :state="true"></EmplForm>

            <div class="row mb-6">
                <div class="col-12 mb-2">
                    <button v-if="$gate.hasAccess('access') || $gate.hasAccess('developer')" @click="submit" :disabled="isLoading" class="btn btn-secondary btn-lg mr-2">Save</button>
                    <router-link :to="{ name : 'access'}" class="btn btn-outline-primary btn-lg">Back</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EmplForm from './Form'

export default {
    data(){
        return {
            isLoading: false,
            allgroups : [],
            access_group : new Form({
                 id : '', name : '', code : '', icon : '',  module_group_id  : '', nourut : ''
            }),
        }
    },
    created() {
        const vm = this
        this.isLoading = true;

        this.access_group.reset();
        const getAccessGroupData = new Promise((resolve) => {
            axios.get(`/api/access_group/fetch/${this.$route.params.id}`).then((data) => {
                this.access_group.fill(data.data.data);
                resolve(data);
            });
        })
        const getAllGroups = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/access_group/client/all`
            }).then((data) => {
                vm.allgroups = data.data.data.items.map((apps) => {
                    return {
                        id: apps.id,
                        label: apps.name,
                    }    
                });
                resolve(data);
            });
        })
        
        Promise.all([getAccessGroupData,getAllGroups]).then(() => {
            vm.isLoading = false
        })
    },
    methods: {
        submit() {
            this.isLoading = true;
            this.access_group.submit('post', '/api/access_group/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'access'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
                this.isLoading = false;
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                this.isLoading = false;
            });
        }
    },
    components: {
        EmplForm
    }
}
</script>