<template>
    <b-form class="form" @submit.prevent="generate">
        <b-row v-if="type == 'report'">
            <b-col lg="4">
                <b-form-group label="Pilih Report" label-for="report_type">
                    <Select2
                        id="report_type"
                        v-model="formReportType"
                        :settings="{
                            minimumResultsForSearch: -1
                        }"
                        :options="formReportTypes" />
                </b-form-group>
            </b-col>
        </b-row>

        <div class="separator mt-2 mb-6"></div>

        <div v-for="(row, i) in formFields" :key="i">
            <!-- Separator -->
            <div v-if="row.type === 'separator' && (row.reportTypes === undefined || (row.reportTypes && row.reportTypes.includes(formReportType)))" class="separator mt-2 mb-6"></div>

            <b-row v-else-if="row.reportTypes === undefined || (row.reportTypes && row.reportTypes.includes(formReportType))">
                <b-col v-for="(field, j) in row.fields.filter((field) => { return (field.reportTypes === undefined || field.reportTypes.includes(formReportType))})" :key="j" :lg="field.size">
                    <!-- Omni -->
                    <Omni
                        v-if="field.type === 'omni'"
                        :label="field.label"
                        :rounded="false"
                        size="sm"
                        floating
                        @change="field.onChange"
                        :multiple="field.multiple"
                        :disabled="field.disabled !== undefined && field.disabled === true"
                        :placeholder="field.placeholder"
                        v-model="filter[field.id]"
                        v-on:search="(search) => field.onSearch(search)"
                        :options="field.options" />
                    <b-form-group v-else :label="field.label" :label-for="field.id"
                        :state="$v.filter[field.id] && !($v.filter[field.id].$invalid && $v.filter[field.id].$dirty)"
                        :invalid-feedback="`${field.label} wajib diisi.`">
                        <!-- Select -->
                        <div v-if="field.type === 'select'">
                            <Select2
                                :settings="field.settings"
                                :multiple="field.multiple"
                                :id="field.id"
                                v-model="filter[field.id]"
                                @change="field.onChange"
                                :placeholder="field.placeholder"
                                :options="field.options" />
                        </div>

                        <!-- Number -->
                        <b-form-input  v-else-if="field.type === 'number'"
                            type="text"
                            v-model.number="filter[field.id]"
                            :min="field.min || 0"
                            :max="field.max || 100"
                            :minlength="field.minlength || 1"
                            :maxlength="field.maxlength || 3"
                            v-mask="field.mask || '###'" />
                        
                        <!--  tree -->
                        <treeselect v-else-if="field.type === 'tree'"
                            :options="field.options"
                            :show-count="true"
                            :default-expand-level="1"
                            v-model="filter[field.id]"
                            :disable-branch-nodes="true"
                            :placeholder="field.placeholder"
                            />
                        <!-- text -->
                        <b-form-input  v-else-if="field.type === 'text'"
                            type="text"
                            v-model="filter[field.id]" />

                        <!-- Range -->
                        <b-row v-else-if="field.type === 'range'">
                            <b-col lg="6">
                                <b-form-input v-model.number="filter[field.from]" v-mask="'######'" />
                            </b-col>
                            <b-col lg="6">
                                <b-form-input v-model.number="filter[field.to]" v-mask="'######'" />
                            </b-col>
                        </b-row>

                        <!-- Date Range :max-date="moment().format('YYYY-MM-DD')" -->
                        <date-range-picker
                            v-else-if="field.type === 'date-range'"
                            append-to-body
                            auto-apply
                            opens="right"
                            :locale-data="{
                                monthNames: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'Nopember', 'Desember'],
                                daysOfWeek: ['Min','Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                                firstDay: 0
                            }"
                            :ranges="{
                                'Hari Ini': [moment()._d, moment()._d],
                                'Minggu Ini': [moment().startOf('week')._d, moment()._d],
                                'Bulan Ini': [moment().startOf('month')._d, moment()._d],
                                'Tahun Ini': [moment().startOf('year')._d, moment()._d],
                            }"
                            @update="(date) => updateDate(date, field)"
                            v-model="dateRange">
                            <template v-slot:input="picker" style="min-width: 350px;">
                                <!-- {{ picker.startDate | moment('DD/MM/YYYY') }} - {{ picker.endDate | moment('DD/MM/YYYY') }} -->
                                <!-- <span>
                                    {{ picker.startDate | moment('DD/MM/YYYY') }} - {{ picker.endDate | moment('DD/MM/YYYY') }}
                                </span>
                                <b-button size="xs" @click.stop="clearDate(field)" variant="outline-dark"
                                style="position: absolute; right: 4px; bottom: 6px;">
                                    Clear
                                </b-button> -->
                                <div v-if="filter[field.from] && filter[field.to]">
                                    <span>
                                        {{ moment(picker.startDate).format('DD/MM/YYYY') }} - {{ moment(picker.endDate).format('DD/MM/YYYY') }}
                                    </span>
                                    <b-button size="xs" @click.stop="clearDate(field)" variant="outline-dark"
                                    style="position: absolute; right: 0px; bottom: 0px;">
                                        Clear
                                    </b-button>
                                </div>
                                <span v-else>-</span>
                            </template>
                        </date-range-picker>

                        <!-- Month Picker -->
                        <month-picker-input
                            v-else-if="field.type === 'period'"
                            @change="(date) => updatePeriod(date, field)"
                            :no-default="true" />
                        
                        <!-- Date picker -->
                        <date-range-picker
                            v-else-if="field.type === 'date'"
                            :max-date="moment().format('YYYY-MM-DD')"
                            append-to-body
                            auto-apply
                            opens="right"
                            :locale-data="{
                                monthNames: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'Nopember', 'Desember'],
                                daysOfWeek: ['Min','Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                                firstDay: 0
                            }"
                            :ranges="false"
                            @update="(date) => updateSingleDate(date, field)"
                            v-model="dateRangeSingle">
                            <template v-slot:input="picker">
                                <div v-if="filter[field.id]">
                                    <span>
                                        {{ moment(picker.startDate).format('DD/MM/YYYY') }} - {{ moment(picker.endDate).format('DD/MM/YYYY') }}
                                    </span>
                                    <b-button size="xs" @click.stop="filter[field.id] = ''" variant="outline-dark"
                                    style="position: absolute; right: 4px; bottom: 6px;">
                                        Clear
                                    </b-button>
                                </div>
                                <span v-else>-</span>
                            </template>
                        </date-range-picker>
                    </b-form-group>
                </b-col>
            </b-row>
        </div>

        <div v-if="formFields.length" class="separator mt-2 mb-6"></div>

        <b-row class="mb-6" v-if="type === 'report'">
            <b-col sm="12">
                <b-button v-if="hasUi"  @click.prevent="showTable('json')" variant="outline-primary" size="lg" class="mr-1">Show</b-button>
                <b-button :disabled="busy" @click.prevent="showTable('blob')" variant="secondary" size="lg">Generate</b-button>
            </b-col>
        </b-row>

        <div v-if="report_data.items">
          <b-table
              id="data-table"
              class="table--middle table--primary mt-2" show-empty responsive
              :items="report_data.items"
              :fields="tableFields"
              :busy.sync="busy">
              <template v-slot:table-busy>
                  <div class="text-center text-primary my-2">
                      <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
                  </div>
              </template>
              <template v-slot:empty><div class="text-center text-muted">No data</div></template>
              <template v-for="(field,index) in tableFields" #cell(field.key)="data">  
                {{ data.value }}
              </template>
          </b-table>
        </div>
    </b-form>
</template>
<script>
import Select2 from './Select2'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Omni from './Omni'
import { MonthPickerInput } from 'vue-month-picker'
import { Datetime } from 'vue-datetime'
import moment from 'moment'
import fileDownload from 'js-file-download';

export default {
    props: {
        type:{
            type: String,
            default: 'report'
        },
        reportType: String,
        reportTypes: Array,
        formFields: Array,
        category: String,
        tableFields : Array,
        hasUi: {
            type: Boolean,
            default: false
        },
        limit: {
            type: [Number, String],
            default: 10,
        }
    },
    data() {
        return {
            busy: false,
            formReportType: this.reportType,
            formReportTypes: this.reportTypes,
            formCategory: this.category,
            dateRange: {
                startDate: new Date(),
                endDate: new Date()
            },
            dateRangeSingle: {
                startDate: new Date(),
                endDate: new Date()
            },
            validations: {},
            filter: {},
            report_data : {},
            
        }
    },
    created() {
        const vm = this

        vm.resetFilters()
        if (vm.type != 'report'){
            vm.generate()
        }
    },
    methods: {
        CLEAR_FILTER(state, payload) {
            this.filter = {};
        },
        RESET_DATA(state) {
            this.report_data = {};
        },
        generate() {
            const vm = this

            if (!vm.filter.$anyError) {
                vm.busy = true
                
                const mfilter = {}
                for ( var key in vm.filter ) {
                    if ( typeof vm.filter[key] === 'object' ) {
                        vm.filter[key].map((val, i) => {
                            mfilter[`${key}[${i}]`] = val
                        })
                    } else {
                        mfilter[key] = vm.filter[key]
                    }
                }

                if (vm.type == 'report'){
                    let routeData = this.$router.resolve({ path: `report/${vm.formCategory}/${vm.formReportType}`, query: Object.assign(mfilter, {output: 'xls'})});
                    window.open(routeData.href, '_blank');
                    vm.busy = false
                }else{
                    vm.form.submit('post', `/api/${vm.formCategory}/${vm.formReportType}`, {
                        transformRequest: [function (data, headers) {
                            let mdata = new FormData();

                            for ( var key in mfilter ) {
                                if (key == 'files'){
                                    mdata.append(`${key}`, mfilter[key]);
                                }else{
                                    mdata.set(`${key}`,mfilter[key]);
                                }
                            }
                            return mdata;
                    }],
                    }).then((response) => {
                        Toast.fire({
                            icon: 'success',
                            title: response.data.message
                        });
                        vm.busy = false
                    }).catch((e) => {
                            Toast.fire({
                                icon: 'success',
                                title: e.message
                            });

                            vm.busy = false
                    });
                }
            }
        },
        clearDate({from, to}) {
            const vm = this
            vm.$delete(vm.filter, from)
            vm.$delete(vm.filter, to)
        },
        updateDate({endDate, startDate}, {from, to}) {
            const vm = this
            vm.$set(vm.filter, from, moment(startDate).format('YYYY-MM-DD'))
            vm.$set(vm.filter, to, moment(endDate).format('YYYY-MM-DD'))
        },
        updateSingleDate({endDate, startDate}, {id}) {
            const vm = this
            if (moment(startDate).format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD')) { // Single date
                vm.$set(vm.filter, id, moment(startDate).format('YYYY-MM-DD'))
            } else { // Range
                vm.$set(vm.filter, id, `${moment(startDate).format('YYYY-MM-DD')}_${moment(endDate).format('YYYY-MM-DD')}`)
            }
        },
        updatePeriod({ from }, {id}) {
            const vm = this
            vm.$set(vm.filter, id, moment(from).format('MM-YYYY'))
        },
        showTable(type) {
            const vm = this

            vm.$v.filter.$touch()
            if (!vm.$v.filter.$anyError) {
                vm.busy = true
                axios({
                    method: 'get',
                    url: `/api/${vm.formCategory}/${vm.formReportType}`,
                    params: Object.assign(vm.filter, {
                        output: type,
                        page: 1,
                        limit: this.limit
                    }),
                    responseType: type,
                }).then((response) => {
                    if (type == 'blob'){
                        fileDownload(response.data, 'laporan.xlsx');
                    }else{
                        vm.report_data = response.data.data
                        //vm.tableFields = report_data.items.map(m => (Object.keys(m).forEach(e => {
                        //    return {key: e};
                        //})));
                    }
                    vm.busy = false
                }).catch((error) => {
                    vm.busy = false
                })
            }
        },
        resetFilters() {
            const vm = this
            
            vm.CLEAR_FILTER()
            vm.validations = {}
            vm.formFields.filter((row) => {
                return row.type !== 'separator'
            }).map((row) => {
                row.fields.map((field) => {
                    if (row.reportTypes === undefined || (row.reportTypes && row.reportTypes.includes(vm.formReportType))) {
                        if (field.reportTypes === undefined || (field.reportTypes && field.reportTypes.includes(vm.formReportType))) {
                            if ( field.default !== undefined ) {
                                vm.$set(vm.filter, field.id, field.default)
                            } else if ( field.type === 'date' ) {
                                vm.$set(vm.filter, field.id, moment().format('YYYY-MM-DD'))
                            } else if ( field.type === 'date-range' ) {
                                vm.$set(vm.filter, field.from, moment().format('YYYY-MM-DD'))
                                vm.$set(vm.filter, field.to, moment().format('YYYY-MM-DD'))
                            }

                            // Generate validations
                            if ( field.rules ) {
                                vm.validations = Object.assign({}, vm.validations, { [field.id]:field.rules })
                            }
                        }
                    }
                })
            })
        }
    },
    watch: {
        formReportType(formReportType) {
            const vm = this
            vm.busy = false
            vm.$emit('typeChanged', formReportType)
            vm.RESET_DATA()
            vm.resetFilters()
            vm.$v.filter.$reset()
        }
    },
    components: {
        Select2,
        DateRangePicker,
        Omni,
        MonthPickerInput,
        Datetime
    },
    validations() {
        return {
            filter: this.validations
        }
    },
    destroyed() {
        this.RESET_DATA()
        this.CLEAR_FILTER()
    }
}
</script>