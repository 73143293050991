export default [
    { path: '/salesrating', name: 'salesrating', component: require('./components/pages/DashboardRating.vue').default, meta: { requiresAuth: true, title: 'Dashboard Sales Rating', action: 'salesrating'} },
    { path: '/calendar', name: 'event.data', component: require('./components/pages/calendar/Mycalendar.vue').default, meta: { requiresAuth: true, title: 'Calendar', action: 'event.data'} },
    { path: '/chart', name: 'chart', component: require('./components/pages/example/chartexample.vue').default, meta: { requiresAuth: true, title: 'Sample Chart', action: 'dashboard'} },
    { path: '/dashboard_training', name: 'dashboard.training', component: require('./components/pages/DashboardLMS.vue').default, meta: { requiresAuth: true, title: 'Training Dashboard', action: 'dashboard'} },
    { path: '/dashboard', name: 'dashboard', component: require('./components/pages/Dashboard.vue').default, meta: { requiresAuth: true, title: 'Dashboard', action: 'dashboard'} },
    { path: '/dashboard_store', name: 'dashboard.store', component: require('./components/pages/DashboardStore.vue').default, meta: { requiresAuth: true, title: 'Dashboard', action: 'dashboard.store'} },
    { path: '/profile', name: 'profil', component: require('./components/pages/Profile.vue').default, meta: { requiresAuth: true, title: 'Profile', action: 'profil'} },
    { path: '/developer', name: 'developer', component: require('./components/pages/Developer.vue').default, meta: { requiresAuth: true, title: 'Developer', action: 'developer'} },
    { path: '/users', name: 'user', component: require('./components/pages/user/Users.vue').default, meta: { requiresAuth: true, title: 'User' , action: 'user'} },
    { path: '/products', name: 'product', component: require('./components/pages/product/Products.vue').default, meta: { requiresAuth: true, title: 'Product' , action: 'product'} },
    { path: '/cashiers', name: 'cashier', component: require('./components/pages/cashier/Cashier.vue').default, meta: { requiresAuth: true, title: 'Cashier' , action: 'cashier'} },
    { path: '/product/tag', name: 'product.tag', component: require('./components/pages/product/Tag.vue').default, meta: { requiresAuth: true, title: 'Tag' , action: 'product.tag'} },
    { path: '/product/category', name: 'category', component: require('./components/pages/product/Category.vue').default, meta: { requiresAuth: true, title: 'Category', action: 'category'} },
    { path: '*', component: require('./components/NotFound.vue').default, meta: { title: 'Not Found'} },
    { path: '/periode', name: 'periode', component: require('./components/pages/setup/Periode.vue').default, meta: { requiresAuth: true, title: 'Periode', action: 'periode'} },
    {
        path: '/quiz/:id',
        name: 'quiz.create',
        component: require('./components/pages/setup/questionaire/Quizpage.vue').default,
        meta: {
            title: 'Questionaire',
            requiresAuth: true,
            action: 'quiz.create',
        }
    },
    /*
    ROLE
    */
    { 
        path: '/role',
        component: require('./components/pages/role/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'role.data'
        },
        children: [
            {
                path: '',
                name: 'role.data',
                component: require('./components/pages/role/Role.vue').default,
                meta: {
                    title: 'Roles and Access',
                    requiresAuth: true,
                    action: 'role.data'
                }
            },
            {
                path: 'create',
                name: 'role.create',
                component: require('./components/pages/role/Create.vue').default,
                meta: {
                    title: 'Create New Role',
                    requiresAuth: true,
                    action: 'role.create',
                }
            },
            {
                path: 'edit/:id',
                name: 'role.update',
                component: require('./components/pages/role/Edit.vue').default,
                meta: {
                    title: 'Edit Role',
                    requiresAuth: true,
                    action: 'role.update',
                }
            }
        ]
    },
    /*
    BRANCH
    */
    { 
        path: '/branch',
        component: require('./components/pages/branch/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'branch.data'
        },
        children: [
            {
                path: '',
                name: 'branch.data',
                component: require('./components/pages/branch/Branch.vue').default,
                meta: {
                    title: 'Branch',
                    requiresAuth: true,
                    action: 'branch.data'
                }
            },
            {
                path: 'create',
                name: 'branch.create',
                component: require('./components/pages/branch/Create.vue').default,
                meta: {
                    title: 'Create New Branch',
                    requiresAuth: true,
                    action: 'branch.create',
                }
            },
            {
                path: 'edit/:id',
                name: 'branch.update',
                component: require('./components/pages/branch/Edit.vue').default,
                meta: {
                    title: 'Edit Branch',
                    requiresAuth: true,
                    action: 'branch.update',
                }
            }
        ]
    },
    /*
    JOB POSITION
    */
    { 
        path: '/jobposition',
        component: require('./components/pages/jobposition/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'jobposition.data'
        },
        children: [
            {
                path: '',
                name: 'jobposition.data',
                component: require('./components/pages/jobposition/Job.vue').default,
                meta: {
                    title: 'Job Position',
                    requiresAuth: true,
                    action: 'jobposition.data'
                }
            },
            {
                path: 'create',
                name: 'jobposition.create',
                component: require('./components/pages/jobposition/Create.vue').default,
                meta: {
                    title: 'Create New Job Position',
                    requiresAuth: true,
                    action: 'jobposition.create',
                }
            },
            {
                path: 'edit/:id',
                name: 'jobposition.update',
                component: require('./components/pages/jobposition/Edit.vue').default,
                meta: {
                    title: 'Edit Job Position',
                    requiresAuth: true,
                    action: 'jobposition.update',
                }
            }
        ]
    },
    /*
    GRADE
    */
    { 
        path: '/grade',
        component: require('./components/pages/grade/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'grade.data'
        },
        children: [
            {
                path: '',
                name: 'grade.data',
                component: require('./components/pages/grade/Grade.vue').default,
                meta: {
                    title: 'Grade',
                    requiresAuth: true,
                    action: 'grade.data'
                }
            },
            {
                path: 'create',
                name: 'grade.create',
                component: require('./components/pages/grade/Create.vue').default,
                meta: {
                    title: 'Create New Grade',
                    requiresAuth: true,
                    action: 'grade.create',
                }
            },
            {
                path: 'edit/:id',
                name: 'grade.update',
                component: require('./components/pages/grade/Edit.vue').default,
                meta: {
                    title: 'Edit Grade',
                    requiresAuth: true,
                    action: 'grade.update',
                }
            }
        ]
    },
    /*
    ORGANIZATION
    */
    { 
        path: '/org',
        component: require('./components/pages/org/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'org.data'
        },
        children: [
            {
                path: '',
                name: 'org.data',
                component: require('./components/pages/org/Org.vue').default,
                meta: {
                    title: 'Organization',
                    requiresAuth: true,
                    action: 'org.data'
                }
            },
            {
                path: 'create',
                name: 'org.create',
                component: require('./components/pages/org/Create.vue').default,
                meta: {
                    title: 'Create New Organization',
                    requiresAuth: true,
                    action: 'org.create',
                }
            },
            {
                path: 'edit/:id',
                name: 'org.update',
                component: require('./components/pages/org/Edit.vue').default,
                meta: {
                    title: 'Edit Organization',
                    requiresAuth: true,
                    action: 'org.update',
                }
            }
        ]
    },
    /*
    LOCATION
    */
    { 
        path: '/location',
        component: require('./components/pages/location/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'location.data'
        },
        children: [
            {
                path: '',
                name: 'location.data',
                component: require('./components/pages/location/Location.vue').default,
                meta: {
                    title: 'Locations',
                    requiresAuth: true,
                    action: 'location.data'
                }
            },
            {
                path: 'create',
                name: 'location.create',
                component: require('./components/pages/location/Create.vue').default,
                meta: {
                    title: 'Create New Location',
                    requiresAuth: true,
                    action: 'location.create',
                }
            },
            {
                path: 'edit/:id',
                name: 'location.update',
                component: require('./components/pages/location/Edit.vue').default,
                meta: {
                    title: 'Edit Location',
                    requiresAuth: true,
                    action: 'location.update',
                }
            }
        ]
    },
    /*
    EMPLOYEE
    */
    { 
        path: '/empl',
        component: require('./components/pages/empl/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'empl.data'
        },
        children: [
            {
                path: '',
                name: 'empl.data',
                component: require('./components/pages/empl/Empl.vue').default,
                meta: {
                    title: 'Employees',
                    requiresAuth: true,
                    action: 'empl.data'
                }
            },
            {
                path: 'create',
                name: 'empl.create',
                component: require('./components/pages/empl/Create.vue').default,
                meta: {
                    title: 'Create New Employee',
                    requiresAuth: true,
                    action: 'empl.create',
                }
            },
            {
                path: 'edit/:id',
                name: 'empl.update',
                component: require('./components/pages/empl/Edit.vue').default,
                meta: {
                    title: 'Edit Employee',
                    requiresAuth: true,
                    action: 'empl.update',
                }
            }
        ]
    },
    /*
    POLLING
    */
    { 
        path: '/polling',
        component: require('./components/pages/setup/polling/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'polling.data'
        },
        children: [
            {
                path: '',
                name: 'polling.data',
                component: require('./components/pages/setup/polling/Polling.vue').default,
                meta: {
                    title: 'Polling',
                    requiresAuth: true,
                    action: 'polling.data'
                }
            },
            {
                path: 'create',
                name: 'polling.create',
                component: require('./components/pages/setup/polling/Create.vue').default,
                meta: {
                    title: 'Create New Polling',
                    requiresAuth: true,
                    action: 'polling.create',
                }
            },
            {
                path: 'edit/:id',
                name: 'polling.update',
                component: require('./components/pages/setup/polling/Edit.vue').default,
                meta: {
                    title: 'Edit Polling',
                    requiresAuth: true,
                    action: 'polling.update',
                }
            }
        ]
    },
    /*
    QUESTIONGROUP
    */
    { 
        path: '/questiongroup',
        component: require('./components/pages/setup/questiongroup/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'questiongroup.data'
        },
        children: [
            {
                path: '',
                name: 'questiongroup.data',
                component: require('./components/pages/setup/questiongroup/Questiongroup.vue').default,
                meta: {
                    title: 'Question Group',
                    requiresAuth: true,
                    action: 'questiongroup.data'
                }
            },
            {
                path: 'create',
                name: 'questiongroup.create',
                component: require('./components/pages/setup/questiongroup/Create.vue').default,
                meta: {
                    title: 'Create New Question Group',
                    requiresAuth: true,
                    action: 'questiongroup.create',
                }
            },
            {
                path: 'edit/:id',
                name: 'questiongroup.update',
                component: require('./components/pages/setup/questiongroup/Edit.vue').default,
                meta: {
                    title: 'Edit Question Group',
                    requiresAuth: true,
                    action: 'questiongroup.update',
                }
            }
        ]
    },
    /*
    QUESTION
    */
    { 
        path: '/question',
        component: require('./components/pages/setup/question/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'question.data'
        },
        children: [
            {
                path: '',
                name: 'question.data',
                component: require('./components/pages/setup/question/Question.vue').default,
                meta: {
                    title: 'Question List',
                    requiresAuth: true,
                    action: 'question.data'
                }
            },
            {
                path: 'create',
                name: 'question.create',
                component: require('./components/pages/setup/question/Create.vue').default,
                meta: {
                    title: 'Create New Question',
                    requiresAuth: true,
                    action: 'question.create',
                }
            },
            {
                path: 'edit/:id',
                name: 'question.update',
                component: require('./components/pages/setup/question/Edit.vue').default,
                meta: {
                    title: 'Edit Question',
                    requiresAuth: true,
                    action: 'question.update',
                }
            }
        ]
    },
    /*
    CHANEL
    */
    { 
        path: '/chanel',
        component: require('./components/pages/setup/chanel/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'chanel.data'
        },
        children: [
            {
                path: '',
                name: 'chanel.data',
                component: require('./components/pages/setup/chanel/Chanel.vue').default,
                meta: {
                    title: 'Chanel Penjualan',
                    requiresAuth: true,
                    action: 'chanel.data'
                }
            },
            {
                path: 'create',
                name: 'chanel.create',
                component: require('./components/pages/setup/chanel/Create.vue').default,
                meta: {
                    title: 'Create New Chanel Penjualan',
                    requiresAuth: true,
                    action: 'chanel.create',
                }
            },
            {
                path: 'edit/:id',
                name: 'chanel.update',
                component: require('./components/pages/setup/chanel/Edit.vue').default,
                meta: {
                    title: 'Edit Chanel Penjualan',
                    requiresAuth: true,
                    action: 'chanel.update',
                }
            }
        ]
    },
    /*
    CHECKLIST
    */
    { 
        path: '/ceklist',
        component: require('./components/pages/setup/ceklist/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'ceklist.data'
        },
        children: [
            {
                path: '',
                name: 'ceklist.data',
                component: require('./components/pages/setup/ceklist/Ceklist.vue').default,
                meta: {
                    title: 'Checklist Store',
                    requiresAuth: true,
                    action: 'ceklist.data'
                }
            },
            {
                path: 'create',
                name: 'ceklist.create',
                component: require('./components/pages/setup/ceklist/Create.vue').default,
                meta: {
                    title: 'Create New Checklist Store',
                    requiresAuth: true,
                    action: 'ceklist.create',
                }
            },
            {
                path: 'edit/:id',
                name: 'ceklist.update',
                component: require('./components/pages/setup/ceklist/Edit.vue').default,
                meta: {
                    title: 'Edit Checklist Store',
                    requiresAuth: true,
                    action: 'ceklist.update',
                }
            },
            {
                path: 'detail',
                name: 'ceklist.detail',
                component: require('./components/pages/setup/ceklist/Detail.vue').default,
                meta: {
                    title: 'Sample Checklist Store',
                    requiresAuth: true,
                    action: 'ceklist.data',
                }
            }
        ]
    },
    /*
    CHECKLISTGROUP
    */
    { 
        path: '/ceklistgroup',
        component: require('./components/pages/setup/ceklistgroup/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'ceklistgroup.data'
        },
        children: [
            {
                path: '',
                name: 'ceklistgroup.data',
                component: require('./components/pages/setup/ceklistgroup/CeklistGroup.vue').default,
                meta: {
                    title: 'Checklist Group',
                    requiresAuth: true,
                    action: 'ceklistgroup.data'
                }
            },
            {
                path: 'create',
                name: 'ceklistgroup.create',
                component: require('./components/pages/setup/ceklistgroup/Create.vue').default,
                meta: {
                    title: 'Create New Checklist Group',
                    requiresAuth: true,
                    action: 'ceklistgroup.create',
                }
            },
            {
                path: 'edit/:id',
                name: 'ceklistgroup.update',
                component: require('./components/pages/setup/ceklistgroup/Edit.vue').default,
                meta: {
                    title: 'Edit Checklist Group',
                    requiresAuth: true,
                    action: 'ceklistgroup.update',
                }
            },
        ]
    },
    /*
    SHIFTING
    */
    { 
        path: '/shifting',
        component: require('./components/pages/setup/shifting/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'shifting.data'
        },
        children: [
            {
                path: '',
                name: 'shifting.data',
                component: require('./components/pages/setup/shifting/Shifting.vue').default,
                meta: {
                    title: 'Shifting Store',
                    requiresAuth: true,
                    action: 'shifting.data'
                }
            }
        ]
    },
    /*
    TARGET
    */
    { 
        path: '/target',
        component: require('./components/pages/target/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'target.data'
        },
        children: [
            {
                path: '',
                name: 'target.data',
                component: require('./components/pages/target/Target.vue').default,
                meta: {
                    title: 'Target Store',
                    requiresAuth: true,
                    action: 'target.data'
                }
            }
        ]
    },
    /*
    ARTICLE
    */
    { 
        path: '/article',
        component: require('./components/pages/article/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'article.data'
        },
        children: [
            {
                path: '',
                name: 'article.data',
                component: require('./components/pages/article/Article.vue').default,
                meta: {
                    title: 'Articles',
                    requiresAuth: true,
                    action: 'article.data'
                }
            },
            {
                path: 'create',
                name: 'article.create',
                component: require('./components/pages/article/Create.vue').default,
                meta: {
                    title: 'Create New Article',
                    requiresAuth: true,
                    action: 'article.create',
                }
            },
            {
                path: 'edit/:id',
                name: 'article.update',
                component: require('./components/pages/article/Edit.vue').default,
                meta: {
                    title: 'Edit Article',
                    requiresAuth: true,
                    action: 'article.update',
                }
            },
            {
                path: 'detail/:id',
                name: 'article.detail',
                component: require('./components/pages/article/Update.vue').default,
                meta: {
                    title: 'Detail Article',
                    requiresAuth: true,
                    action: 'article.data',
                }
            }
        ]
    },
    /*
    REPORT
    */
    { 
        path: '/report',
        component: require('./components/pages/report/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'reportstore'
        },
        children: [
            {
                path: '',
                name: 'reportstore',
                component: require('./components/pages/report/ReportStore.vue').default,
                meta: {
                    title: 'Laporan Store',
                    requiresAuth: true,
                    action: 'reportstore'
                }
            }
        ]
    },
    /*
    ABSENSI
    */
    { 
        path: '/absensi',
        component: require('./components/pages/absen/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'absensi'
        },
        children: [
            {
                path: '',
                name: 'absensi',
                component: require('./components/pages/absen/Absensi.vue').default,
                meta: {
                    title: 'Absensi',
                    requiresAuth: true,
                    action: 'absensi'
                }
            }
        ]
    },
    /*
    TRAINER
    */
    { 
        path: '/trainer',
        component: require('./components/pages/trainer/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'trainer.data'
        },
        children: [
            {
                path: '',
                name: 'trainer.data',
                component: require('./components/pages/trainer/Trainer.vue').default,
                meta: {
                    title: 'Trainer',
                    requiresAuth: true,
                    action: 'trainer.data'
                }
            },
            {
                path: 'create',
                name: 'trainer.create',
                component: require('./components/pages/trainer/Create.vue').default,
                meta: {
                    title: 'Create New Trainer',
                    requiresAuth: true,
                    action: 'trainer.create',
                }
            },
            {
                path: 'edit/:id',
                name: 'trainer.update',
                component: require('./components/pages/trainer/Edit.vue').default,
                meta: {
                    title: 'Edit Trainer',
                    requiresAuth: true,
                    action: 'trainer.update',
                }
            }
        ]
    },
    /*
    ACCESS
    */
    { 
        path: '/access',
        component: require('./components/pages/accessgroup/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'developer'
        },
        children: [
            {
                path: '',
                name: 'access',
                component: require('./components/pages/accessgroup/Accessgroup.vue').default,
                meta: {
                    title: 'Access',
                    requiresAuth: true,
                    action: 'developer'
                }
            },
            {
                path: 'create',
                name: 'access.create',
                component: require('./components/pages/accessgroup/Create.vue').default,
                meta: {
                    title: 'Create New Access Group',
                    requiresAuth: true,
                    action: 'developer',
                }
            },
            {
                path: 'edit/:id',
                name: 'access.update',
                component: require('./components/pages/accessgroup/Edit.vue').default,
                meta: {
                    title: 'Edit Access Group',
                    requiresAuth: true,
                    action: 'developer',
                }
            }
        ]
    },
    /*
    DOCUMENT
    */
    { 
        path: '/document',
        component: require('./components/pages/document/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'document.data'
        },
        children: [
            {
                path: '',
                name: 'document.data',
                component: require('./components/pages/document/Document.vue').default,
                meta: {
                    title: 'Document Setup',
                    requiresAuth: true,
                    action: 'document.data'
                }
            },
            {
                path: 'create',
                name: 'document.create',
                component: require('./components/pages/document/Create.vue').default,
                meta: {
                    title: 'Create New Document Flow',
                    requiresAuth: true,
                    action: 'document.create',
                }
            },
            {
                path: 'edit/:id',
                name: 'document.update',
                component: require('./components/pages/document/Edit.vue').default,
                meta: {
                    title: 'Edit Document Flow',
                    requiresAuth: true,
                    action: 'document.update',
                }
            }
        ]
    },
    /*
    DOCUMENT LIST
    */
    { 
        path: '/doclist',
        component: require('./components/pages/doclist/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'doclist.data'
        },
        children: [
            {
                path: '',
                name: 'doclist.dashboard',
                component: require('./components/pages/doclist/Docdashboard.vue').default,
                meta: {
                    title: 'Document Dashboard',
                    requiresAuth: true,
                    action: 'doclist.dashboard',
                }
            },
            {
                path: 'detail/:id',
                name: 'doclist.detail',
                component: require('./components/pages/doclist/Doclist.vue').default,
                meta: {
                    title: 'Document List',
                    requiresAuth: true,
                    action: 'doclist.data'
                }
            },
            {
                path: 'create',
                name: 'doclist.create',
                component: require('./components/pages/doclist/Create.vue').default,
                meta: {
                    title: 'Create New Document',
                    requiresAuth: true,
                    action: 'doclist.create',
                }
            },
            {
                path: 'edit/:id',
                name: 'doclist.update',
                component: require('./components/pages/doclist/Edit.vue').default,
                meta: {
                    title: 'Edit Document',
                    requiresAuth: true,
                    action: 'doclist.update',
                }
            }
        ]
    },
    /*
    LoA
    */
    { 
        path: '/loa',
        component: require('./components/pages/loa/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'loa.data'
        },
        children: [
            {
                path: '',
                name: 'loa.data',
                component: require('./components/pages/loa/Loa.vue').default,
                meta: {
                    title: 'Loa Setup',
                    requiresAuth: true,
                    action: 'loa.data'
                }
            },
            {
                path: 'create',
                name: 'loa.create',
                component: require('./components/pages/loa/Create.vue').default,
                meta: {
                    title: 'Create New LoA Flow',
                    requiresAuth: true,
                    action: 'loa.create',
                }
            },
            {
                path: 'edit/:id',
                name: 'loa.update',
                component: require('./components/pages/loa/Edit.vue').default,
                meta: {
                    title: 'Edit LoA Flow',
                    requiresAuth: true,
                    action: 'loa.update',
                }
            }
        ]
    },
    /*
    Antrian
    */
    { 
        path: '/antrian',
        component: require('./components/pages/antrian/Index.vue').default,
        meta: {
            requiresAuth: true,
            action: 'antrian.data'
        },
        children: [
            {
                path: '',
                name: 'antrian.data',
                component: require('./components/pages/antrian/Antrian.vue').default,
                meta: {
                    title: 'Antrian Dashboard',
                    requiresAuth: true,
                    action: 'antrian.data'
                }
            },
            {
                path: 'antri/:id',
                name: 'antrian.create',
                component: require('./components/pages/antrian/Customer.vue').default,
                meta: {
                    title: 'Nomor Antrian',
                    requiresAuth: true,
                    action: 'antrian.create',
                }
            },
            {
                path: 'cashier/:id',
                name: 'antrian.update',
                component: require('./components/pages/antrian/Cashier.vue').default,
                meta: {
                    title: 'Cashier',
                    requiresAuth: true,
                    action: 'antrian.update',
                }
            }
        ]
    },
];
