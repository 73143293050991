var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"box-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_vm._m(0),_vm._v(" "),_c('va-chart',{attrs:{"chart-config":_vm.chartConfig}})],1),_vm._v(" "),_c('div',{staticClass:"col-md-4"},[_vm._m(1),_vm._v(" "),_c('va-chart',{attrs:{"chart-config":_vm.lineChartConfig}})],1),_vm._v(" "),_c('div',{staticClass:"col-md-4"},[_vm._m(2),_vm._v(" "),_c('va-chart',{attrs:{"chart-config":_vm.barChartConfig}})],1)])])]),_vm._v(" "),_c('div',{staticClass:"box"},[_c('div',{staticClass:"box-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_vm._m(3),_vm._v(" "),_c('va-chart',{attrs:{"chart-config":_vm.radarChartConfig}})],1),_vm._v(" "),_c('div',{staticClass:"col-md-4"},[_vm._m(4),_vm._v(" "),_c('va-chart',{attrs:{"chart-config":_vm.polarAreaChartConfig}})],1),_vm._v(" "),_c('div',{staticClass:"col-md-4"},[_vm._m(5),_vm._v(" "),_c('va-chart',{attrs:{"chart-config":_vm.doughnutChartConfig}})],1)])]),_vm._v(" "),_c('div',{staticClass:"box"},[_c('div',{staticClass:"box-body"},[_c('row',[_c('div',{staticClass:"col-md-4"},[_c('p',{staticClass:"text-center"},[_c('strong',[_vm._v("Test Bubble Chart")])]),_vm._v(" "),_c('va-chart',{attrs:{"chart-config":_vm.bubbleChartConfig}})],1)])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-center"},[_c('strong',[_vm._v("Web Traffic Overview")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-center"},[_c('strong',[_vm._v("Test Line Chart")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-center"},[_c('strong',[_vm._v("Test Bar Chart")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-center"},[_c('strong',[_vm._v("Test Radar Chart")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-center"},[_c('strong',[_vm._v("Test Polar Area Chart")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"text-center"},[_c('strong',[_vm._v("Test Doughnut Chart")])])
}]

export { render, staticRenderFns }