<template>
    <b-form v-if="!preparation" class="form" enctype="multipart/form-data">
        <b-row>
            <b-col md="5" class="mr-2">
                <b-form-group label="Name *" label-for="name">
                    <b-form-input  v-model="job.name" id="name" required placeholder="Job Name"  :class="{ 'is-invalid': job.errors.has('name') }"></b-form-input>
                    <has-error :form="job" field="name"></has-error>
                </b-form-group>
                <b-form-group label="Level" label-for="level">
                    <b-form-input  v-model="job.level" id="level" required placeholder="Job Level"  :class="{ 'is-invalid': job.errors.has('level') }"></b-form-input>
                    <has-error :form="job" field="level"></has-error>
                </b-form-group>
            </b-col>    
            <b-col md="5" class="mr-2">
                <b-form-group label="Parent">
                    <treeselect
                        :options="alljobs"
                        :show-count="true"
                        :default-expand-level="1"
                        v-model="job.parent_job_id"
                        @input="updateValueJob"
                        placeholder="Superior Job Position?"
                        />
                    <has-error :form="job" field="parent_job_id"></has-error>
                </b-form-group>
                <b-form-group label="Branch">
                    <treeselect
                        :options="allbranch"
                        :show-count="true"
                        :default-expand-level="1"
                        v-model="job.branch_id"
                        @input="updateValueBranch"
                        placeholder="Parent Branch?"
                        />
                    <has-error :form="job" field="branch_id"></has-error>
                </b-form-group>
                
            </b-col>  
        </b-row>
        <div class="separator mt-2 mb-6"></div>
    </b-form>
    <div v-else class="text-center text-primary py-14">
        <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
<!--   COMPONENT NOT FOUND
<div>
    <not-found></not-found>
</div>
-->   
    </div>
</template>
<script>

export default {
    props: ['preparation','job','allbranch','alljobs','state'],
    methods: {
        updateValueBranch(val){
            if (val) this.job.branch_id = val;
            else this.job.branch_id = ''
            Toast.fire({
                      icon: 'success',
                      title:val
                    });
        },
        updateValueJob(val){
            if (val) this.job.parent_job_id = val;
            else this.job.parent_job_id = ''
            Toast.fire({
                      icon: 'success',
                      title:val
                    });
        }
    }
}
</script>