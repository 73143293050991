<template>
    <div class="panel__body pt-4 ml-4 mr-4">
        <Detail :article_id="$route.params.id" :show_berita="true" class="mb-2"></Detail>
    </div>
</template>
<script>

import Detail from './Detail'
export default {
    components: {
        Detail
    }
}
</script>
