<template>
    <section class="content" >
        <b-container fluid>
            <b-row>
                <b-col v-if="!haschoose" class="lockscreen">
                    <div class="lockscreen-wrapper">
                        <div class="lockscreen-logo">
                            Welcome to Task
                        </div>

                        <div class="lockscreen-name"><b>{{ polling.name }}</b></div>

                        <div class="lockscreen-item">
                            <div class="lockscreen-image">
                                <img :src="photo" class="img-circle elevation-2" alt="User Image">
                            </div>

                            <form class="lockscreen-credentials">
                                <b-form-group >
                                    <b-form-radio-group
                                        size="sm"
                                        v-model="haschoose"
                                        :options="[{value:true, text:'Use Assistant'},{value:false, text:'Non Assistant'}]"
                                        buttons
                                        button-variant="outline-primary"
                                    ></b-form-radio-group>
                                </b-form-group>
                            </form>
                        </div>

                        <div class="help-block text-center">
                            Please choose to use assistant or not
                        </div>
                        <div class="card lockscreen mt-5 mb-15" >
                            <div class="lockscreen-footer text-center">
                                <strong>Copyright &copy; 2022 <a href="https://agrinesia.co.id">PT. Agrinesia</a>.</strong> All rights reserved.
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col v-else>
                    <div class="card" v-if="!isLoading">
                        <div class="card-header">
                            <div>This Task is open from {{ moment(from).format('YYYY-MM-DD HH:mm:ss')}} s.d {{ moment(to).format('YYYY-MM-DD HH:mm:ss') }}</div>
                            <div class="dropdown-divider"></div>
                            <Timer :isend="isend" :waktu = "waktu" v-on:timeEnd="timeEnd()"></Timer>
                        </div>  
                        <div class="card-body p-0 ml-2 mr-2 mt-2" v-if="polling.type == 'training'">
                            <Question :recanswer="recanswer" :index="curindex" :canfinish="allowfinish" :canskip="allowskip" :canback="allowback" :cannext="allownext" :Question="question" v-on:finish="finishEvent($event)" v-on:back="backEvent($event)" v-on:skip="skipEvent($event)" v-on:next="nextEvent($event)"></Question>
                        </div>
                        <div class="card-body p-0 ml-2 mr-2 mt-2" v-else>
                            <Viewer v-show="pdfpath !== ''" :filepath="pdfpath" :useassistant="haschoose" v-on:textloaded="textLoadEvent($event)" v-on:download="download()"></Viewer>
                        </div>
                        <div class="card-footer">
                            <b-row class = "mb-6 d-none">
                                <b-col class = "mb-2">
                                    <b-button @click.prevent="uploadFiles" class="btn btn-outline-primary btn-sm mr-2">Upload</b-button>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                    <div v-else>
                        <form-loader></form-loader>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
import Timer from './Timer'
import Question from './Question'
import Viewer from './Viewer'
import moment from 'moment'
import { eventBus } from '../../../../app.js'

export default {
    data(){
        return {
            photo : '',
            haschoose : false,
            isLoading : true,
            from : '',
            to :'',
            isend:false,
            waktu: '0',
            question: { },
            pollinganswer :{ 
                        employee_polling_id : this.$route.params.id,
                        polling_id : '',
                        employee_id : window.user.employee.id,
                        question_id : '',
                        gender : window.user.employee.gender,
                        branch_id : window.user.employee.branch_id,
                        grade_id : window.user.employee.grade_id,
                        organization_id : window.user.employee.organization_id,
                        job_position_id : window.user.employee.job_position_id,
                        answer : '' },
            alljawaban : [],
            curindex : 0,
            polling : {},
            allowskip : false,
            allowback : false,
            allownext : false,
            allowfinish : false,
            recanswer : '',
            alldata : [],
            pdfpath : ''
        }
    },
    created() {
        const vm = this
        vm.haschoose = false;
        eventBus.$on('get_answer', function(data) {
            if (data.answer && data.user_id == window.user.id){
                if (this.polling.type == 'training'){
                    if (this.polling.questions[ data.index].question_type == 'pilihan'){
                        if (['a', 'b', 'c','d','e'].includes(data.answer)){
                            if (this.updateanswer(data.answer)){
                                this.curindex = data.index + 1
                                this.question = this.polling.questions[ this.curindex]
                                this.updatestep(this.curindex);
                            }
                        }else{
                            eventBus.$emit('read_question',{idx : data.index, pertanyaan: 'Jawaban anda tidak valid, silakan tentukan jawaban anda',user_id : window.user.id});
                        }
                    }
                    if (this.polling.questions[ data.index].question_type == 'polling'){
                        if (['setuju', 'sangat setuju', 'kurang setuju','tidak setuju'].includes(data.answer)){
                            if (this.updateanswer(data.answer)){
                                this.curindex = data.index + 1
                                this.question = this.polling.questions[ this.curindex]
                                this.updatestep(this.curindex);
                            }
                        }else{
                            eventBus.$emit('read_question',{idx : data.index, pertanyaan: 'Jawaban anda tidak valid, silakan tentukan jawaban anda',user_id : window.user.id});
                        }
                    }
                    if (this.polling.questions[ data.index].question_type == 'essay'){
                        if (this.updateanswer(data.answer)){
                            this.curindex = data.index + 1
                            this.question = this.polling.questions[ this.curindex]
                            this.updatestep(this.curindex);
                        }
                    }
                }else{
                    if (this.updateanswer('done')){
                        this.curindex = data.index + 1
                        this.question = this.polling.questions[ this.curindex]
                        this.updatestep(this.curindex);
                    }
                }
            }
        })
        vm.waktu = 0;
        const getEmplPollingData = new Promise((resolve) => {
            axios.get(`/api/emplpolling/fetch/${this.$route.params.id}`).then((data) => {
                this.alldata = data.data.data;
                
                if (this.alldata.employee.id == window.user.employee.id && 
                    moment().isAfter(moment(this.alldata.polling.from)) && 
                    moment().isBefore(moment(this.alldata.polling.to)) && 
                    this.alldata.polling.is_active){
                        this.photo = window.user.photo
                        this.waktu = this.alldata.polling.time_limit
                        this.from = this.alldata.polling.from
                        this.to = this.alldata.polling.to
                        this.question = this.alldata.polling.questions[this.curindex];
                        this.polling = this.alldata.polling;
                        this.updatestep(this.curindex);
                }else{
                    this.$router.push({path: '../not-found'}, function() {
                        eventBus.$emit('read_notification',{message : 'Anda tidak terdaftar atau waktu penugasan telah berakhir'});
                    })
                }
                resolve(data);
            }).catch((error) => {
                this.$router.push({path: '../not-found'}, function() {
                    eventBus.$emit('read_notification',{message : 'Halaman tidak tersedia atau data yang anda cari tidak ada'});
                })
                resolve(false)
            })
        })

        Promise.all([getEmplPollingData]).then(() => {
            this.isLoading = false;
        });
    },
    methods: {
        submit(){
            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: '/api/emplpolling/answer',
                    data: {answers : this.alljawaban}
                })
                .then((response) => {
                    this.$router.push({name: 'emplpolling.data'}, function() {
                        Toast.fire({
                            icon: 'success',
                            title: 'Your answer has been recorded.'
                        });
                    })
                    resolve(response.data)
                })
                .catch((error) => {
                    Toast.fire({
                        icon: 'success',
                        title: error.message
                    });
                    resolve(false)
                })
            })
        },
        updatestep(index){
            if (index + 1 >= this.polling.questions.length ){   
                this.allowskip = false
                this.allownext = false
                this.allowfinish = true
            }else{
                this.allowskip = true
                this.allownext = true
                this.allowfinish = false
            }
            this.allowback = index === 0 ? false : true

            let findidx =  this.alljawaban.findIndex(element => element.question_id ===  this.question.id ); 
            if (findidx >= 0) this.recanswer = this.alljawaban[findidx].answer;
            else this.recanswer = '';

            if (this.haschoose){
                let kalimat = ''
                if (this.polling.type == 'training'){
                    if (this.question.question_type == 'pilihan'){
                        kalimat = `${this.question.question}.
                                    Pilihan A ${this.question.pil_a}. 
                                    Pilihan B ${this.question.pil_b}.
                                    Pilihan C ${this.question.pil_c}.
                                    Pilihan D ${this.question.pil_d}.
                                    Pilihan E ${this.question.pil_e}`;
                    }
                    if (this.question.question_type == 'polling'){
                        kalimat = `${this.question.question}. apakah anda sangat setuju dengan pernyataan ini, ataukah setuju, atau kurang setuju, atau tidak setuju`;
                            }
                    if (this.question.question_type == 'essay'){
                        kalimat = `${this.question.question} Silahkan anda menjawab.`;
                    }

                    eventBus.$emit('read_question',{idx : index, pertanyaan : kalimat,user_id : window.user.id});
                }else{
                    this.pdfpath = this.question.ilustration;
                }
            }
        },
        updateanswer(jawaban){
            let findidx = this.alljawaban.findIndex(element => element.question_id === this.question.id)
            let newitem  = Object.assign({}, newitem, {
                                    employee_polling_id : this.$route.params.id,
                                    polling_id : this.polling.id,
                                    employee_id : window.user.employee.id,
                                    question_id : this.question.id,
                                    gender : window.user.employee.gender,
                                    branch_id : window.user.employee.branch_id,
                                    grade_id : window.user.employee.grade_id,
                                    organization_id : window.user.employee.organization_id,
                                    job_position_id : window.user.employee.job_position_id,
                                    answer : jawaban
                                });
            if (findidx < 0){
                this.alljawaban.push(newitem);
            }else{
                this.$set(this.alljawaban, findidx, newitem);
            }
            return true;   
        },
        async textLoadEvent(kalimat){
           // await eventBus.$emit('read_question',{idx : this.curindex, pertanyaan : kalimat,user_id : window.user.id});
            eventBus.$emit('read_notification',{message : kalimat});
        },
        download(){
            axios.get(window.location.origin + '/' + this.pdfpath, { responseType: 'blob' })
			.then(response => {
				const blob = new Blob([response.data], { type: 'application/pdf' })
				const link = document.createElement('a')
				link.href = URL.createObjectURL(blob)
				link.setAttribute("download", "materi.pdf");
				link.click()
				URL.revokeObjectURL(link.href)

                if (this.updateanswer('done')){
                    this.isend = true;
                    this.submit();
                }
			}).catch(console.error)
        },
        timeEnd(){
            this.submit();
        },
        skipEvent(answer){
            if (this.updateanswer(answer[1])){
                this.curindex = answer[0] + 1
                this.question = this.polling.questions[ this.curindex]
                this.updatestep(this.curindex);
            }
        },
        backEvent(answer){
            if (this.updateanswer(answer[1])){
                this.curindex = answer[0] - 1
                this.question = this.polling.questions[ this.curindex]
                this.updatestep(this.curindex);
            }
        },
        nextEvent(answer){
            if (this.updateanswer(answer[1])){
                this.curindex = answer[0] + 1
                this.question = this.polling.questions[ this.curindex]
                this.updatestep(this.curindex);
            }
        },
        finishEvent(answer){
            Swal.fire({
                title: 'Are you sure to finish?',
                text: "You won't be able to revert thi task!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, post the data!'
                }).then((result) => {
                    if (result.value) {
                        if (this.updateanswer(answer[1])){
                            this.isend = true;
                            this.submit();
                        }
                    }
                });
        }
    },
    watch: {
        haschoose(haschoose) {
            if (haschoose) this.updatestep(this.curindex)
        }
    },
    components: {
        Timer,
        Question,
        Viewer
    }
}
</script>