<template>
    <b-form v-if="!preparation" class="form" enctype="multipart/form-data">
        <b-row>
            <b-col lg="4" class="mr-2">
                <b-form-group label="Name *" label-for="name">
                    <b-form-input  v-model="document.name" id="name" required placeholder="Document Name"  :class="{ 'is-invalid': document.errors.has('name') }"></b-form-input>
                    <has-error :form="document" field="name"></has-error>
                </b-form-group>
                <b-form-group label="Code *" label-for="path">
                    <b-form-input  v-model="document.code" id="path" required placeholder="Code"  :class="{ 'is-invalid': document.errors.has('code') }"></b-form-input>
                    <has-error :form="document" field="code"></has-error>
                </b-form-group>
                <b-form-group label="Branch" label-for="branch">
                    <treeselect
                        id="branch_id"
                        :options="branches"
                        :show-count="true"
                        :default-expand-level="1"
                        :searchable="true"
                        v-model="document.branch_id"
                        placeholder="Branch?"
                        />
                    <has-error :form="document" field="branch_id"></has-error>
                </b-form-group>
                <b-form-group label="Organization" label-for="organization_id">
                    <treeselect
                        id="organization_id"
                        :options="organizations"
                        :show-count="true"
                        :default-expand-level="1"
                        :searchable="true"
                        v-model="document.organization_id"
                        placeholder="Organization?"
                        />
                    <has-error :form="document" field="organization_id"></has-error>
                </b-form-group>
                <b-form-group label="Job Position" label-for="job_position_id">
                    <treeselect
                        id="job_position_id"
                        :options="jobpositions"
                        :show-count="true"
                        :default-expand-level="1"
                        :searchable="true"
                        v-model="document.job_position_id"
                        placeholder="Job Position?"
                        />
                    <has-error :form="document" field="job_position_id"></has-error>
                </b-form-group>
                <b-form-group label="Location" label-for="location_id">
                    <treeselect
                        id="location_id"
                        :options="locations"
                        :show-count="true"
                        :default-expand-level="1"
                        :searchable="true"
                        v-model="document.location_id"
                        placeholder="Location?"
                        />
                    <has-error :form="document" field="location_id"></has-error>
                </b-form-group>
                <b-form-group label="Grade" label-for="grade_id">
                    <treeselect
                        id="grade_id"
                        :options="grades"
                        :show-count="true"
                        :default-expand-level="1"
                        :searchable="true"
                        v-model="document.grade_id"
                        placeholder="Grade?"
                        />
                    <has-error :form="document" field="grade_id"></has-error>
                </b-form-group>
                <b-form-group label="Job Level" label-for="job_level">
                    <treeselect
                        id = "job_level"
                        :options="[{ id : '1',label : 'CEO'}, 
                                    {id : '2', label : 'Manager'},
                                    {id : '3', label : 'Supervisor'},
                                    {id : '4', label : 'Staff'},
                                    {id : '5', label : 'Other'}]"
                        v-model="document.job_level"
                        :searchable="true"
                        placeholder="Job Level?"
                        />
                    <has-error :form="document" field="job_level"></has-error>
                </b-form-group>

                <b-form-group label="Limit From" label-for="limit_from" class="mt-2">
                    <b-form-input  v-model="document.limit_from" id="limit_from" placeholder="Amount Limit From" :class="{ 'is-invalid': document.errors.has('limit_from') }"></b-form-input>
                    <has-error :form="document" field="limit_from"></has-error>
                </b-form-group>

                <b-form-group label="Limit To" label-for="limit_to" class="mt-2">
                    <b-form-input  v-model="document.limit_to" id="limit_to" placeholder="Amount Limit To" :class="{ 'is-invalid': document.errors.has('limit_to') }"></b-form-input>
                    <has-error :form="document" field="limit_to"></has-error>
                </b-form-group>

                <b-form-group label="Is Active" label-for="is_active" >
                    <b-form-radio-group
                        size="sm"
                        id="is_active"
                        v-model="document.active"
                        :options="[{value:'1', text:'Active'},{value:'0', text:'Inactive'}]"
                        required 
                        buttons
                        button-variant="outline-primary"
                        :class="{ 'is-invalid': document.errors.has('active') }"
                    ></b-form-radio-group>
                </b-form-group>
            </b-col>  
            <b-col lg="7" class="mr-2">
                <div class="card card-secondary">
                    <div class="card-header">
                        <h3 class="card-title">List of Flow.<br/> 
                            <span :title="'Selected data will be updated based on flowname'" class="badge badge-warning">Selected data will be updated base on the flowname</span>
                        </h3>
                    </div>
                    <div class="card-body">
                        <draggable v-model="document.flows" v-if="document.flows" @end="MoveFlow">
                            <div :class="'alert alert-danger alert-dismissible ' + (flowselindex == index ? 'shadow' : 'secondary')" v-for="(element, index) in document.flows" :key="element.name">
                                <div @click="ChooseFlow(index)">
                                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true" @click="deleteFlow(element,index)">×</button>
                                    <h5>{{element.name}}<br/>
                                        <span :title="element.pic ? element.pic.name : ''" class="badge badge-warning">Approver : {{element.pic ? element.pic.name : ''}}</span>
                                        <span :title="element.status == 'delegate' ? 'Delegates to ' + (element.delegations ? element.delegations.length : 0) : 'Approver'" class="badge badge-warning">{{ element.status == 'delegate' ? 'Delegates to ' + (element.delegations ? element.delegations.length : 0 ) : 'Approver'}} user</span>
                                    </h5> 
                                </div>

                                <div v-if="flowselindex != index && element.status == 'delegate'" class="alert alert-warning alert-dismissible" v-for="(item,indexitem) in element.delegations" :key="indexitem">
                                    <button type="button" class="close" data-dismiss="alert" aria-hidden="true" @click="deleteFlowItem(item,index,indexitem)">×</button>
                                    {{ item.employee.name }}
                                </div>
                                
                                <div v-if="flowselindex == index && element.status == 'delegate'">
                                    <b-form-group label="Delegate" label-for="empls" class="mt-2" >
                                        <treeselect 
                                            :options="empls"
                                            :show-count="true"
                                            :default-expand-level="1"
                                            v-model="tmpuser"
                                            :disable-branch-nodes="true"
                                            search-nested
                                            :value-consists-of="'LEAF_PRIORITY'"
                                            :valueFormat="'object'"
                                            placeholder="Choose"
                                            :multiple="true"
                                        />
                                    </b-form-group>
                                    <a slot="header" class="btn btn-success btm-sm" @click="addApprover(index)" variant="primary">
                                        <i class="fas fa-plus"></i> Add delegates
                                    </a>
                                </div>
                               
                            </div>
                        </draggable>
                        <b-row>
                            <b-col lg="6">
                                <b-form-group label="Name" label-for="image" class="mt-2">
                                    <b-form-input  v-model="flowname" id="flowname" required placeholder="Flow name"></b-form-input>
                                </b-form-group>
                                <b-form-group >
                                    <treeselect
                                        id="status"
                                        :options="[{ id : 'approval',label : 'Approval'},
                                                    {id : 'delegate', label : 'Delegate'}]"
                                        v-model="status"
                                        :searchable="true"
                                        placeholder="is Delegate?"
                                        />
                                </b-form-group>
                            </b-col>
                            <b-col lg="6">
                                <b-form-group label="Code" label-for="code" class="mt-2">
                                    <b-form-input  v-model="flowcode" id="code" required placeholder="Flow code"></b-form-input>
                                </b-form-group>
                                <!-- Date Range :max-date="moment().format('YYYY-MM-DD')" -->
                                <date-range-picker v-show="status !== 'approval'" class="justify-content-start" style="min-width:300px;inline-size: fit-content;"
                                    append-to-body
                                    auto-apply
                                    opens="right"
                                    :locale-data="{
                                        monthNames: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'Nopember', 'Desember'],
                                        daysOfWeek: ['Min','Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                                        firstDay: 0
                                    }"
                                    :ranges="{
                                        'Hari Ini': [moment()._d, moment()._d],
                                        'Minggu Ini': [moment().startOf('week')._d, moment()._d],
                                        'Bulan Ini': [moment().startOf('month')._d, moment()._d],
                                        'Tahun Ini': [moment().startOf('year')._d, moment()._d],
                                    }"
                                    @update="(date) => updateDate(date)"
                                    v-model="dateRange">
                                    <template v-slot:input="picker" style="min-width: 300px;">
                                        <div v-if="dateRange.startDate && dateRange.endDate">
                                            <span>
                                                {{ moment(picker.startDate).format('DD/MM/YYYY') }} - {{ moment(picker.endDate).format('DD/MM/YYYY') }}
                                            </span>
                                            <b-button size="xs" @click.stop="clearDate()" variant="outline-dark"
                                            style="position: absolute; right: 0px; bottom: 0px;">
                                                Clear
                                            </b-button>
                                        </div>
                                        <span v-else>-</span>
                                    </template>
                                </date-range-picker>
                            </b-col>
                        </b-row>
                        
                        <div class="dropdown-divider mt-2 mb-6"><h3 class="card-title">Limit Parameter</h3></div>

                        <b-form-group label="Main Approver" label-for="approver" class="mt-2">
                            <treeselect
                                :options="employees"
                                :show-count="true"
                                :default-expand-level="1"
                                v-model="value"
                                :valueFormat="'object'"
                                placeholder="Choose?"
                                @input="updateValue"
                                :disable-branch-nodes="true"
                                 :value-consists-of="'LEAF_PRIORITY'"
                                search-nested
                                />
                            
                        </b-form-group>
                        <a slot="header" class="btn btn-success btm-sm" @click="addFlow()" variant="primary">
                            <i class="fas fa-plus"></i> Add/Update Flow
                        </a>
                    </div>
                </div>
            
            </b-col>
        </b-row>
        <div class="separator mt-2 mb-6"></div>
    </b-form>
    <div v-else class="text-center text-primary py-14">
        <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
<!--   COMPONENT NOT FOUND
<div>
    <not-found></not-found>
</div>
-->   
    </div>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from 'moment'
import draggable from 'vuedraggable'
export default {
    data(){
        return {
            dateRange: {
                startDate: new Date(),
                endDate: new Date()
            },
            isLoading : true,
            flowselindex : -1,
            flowname :'',
            flowcode :'',
            status : 'approval',
            tmpuser:[],
            empls:[],
            value:{
                id:'',
                label:'',
                name:''
            },
        }
    },
    props: ['preparation','document', 'employees','branches','organizations','jobpositions','locations','grades'],
    methods: {
        clearDate() {
            const vm = this
            vm.dateRange.startDate = '';
            vm.dateRange.endDate = '';
        },
        updateDate({endDate, startDate}) {
            const vm = this
        },
        updateValue(val){
            if (val){
                this.value = val;
                this.value.name = val.label
            }
        },
        addFlow(){
            const vm = this
            
            let notexist = vm.document.flows.filter((flow) => flow.name === this.flowname);
            if (!notexist.length){
                this.document.flows.push({
                                            id: '',
                                            name: this.flowname,
                                            code: this.flowcode,
                                            employee_id:this.value.id,
                                            pic : this.value,
                                            status : this.status,
                                            from : this.dateRange.startDate,
                                            to : this.dateRange.endDate,
                                            no_urut : this.document.flows.length ,
                                            delegations : []
                                        });
                this.ChooseFlow(this.document.flows.length -1);
            }else{
                if (this.flowselindex !== -1){
                    this.document.flows[this.flowselindex].name = this.flowname;
                    this.document.flows[this.flowselindex].code = this.flowcode;
                    this.document.flows[this.flowselindex].employee_id = this.value.id;
                    this.document.flows[this.flowselindex].pic = this.value;
                    this.document.flows[this.flowselindex].status = this.status;
                    this.document.flows[this.flowselindex].from = moment(this.dateRange.startDate).format('YYYY-MM-DD');
                    this.document.flows[this.flowselindex].to = moment(this.dateRange.endDate).format('YYYY-MM-DD');
                    this.addApprover(this.flowselindex);
                }else{
                    Toast.fire({
                        icon: 'error',
                        title: 'Flow duplicate, pastikan nama berbeda atau anda pilih data yang akan diupdate'
                    });
                }
            }
        },
        addApprover(index){
            const vm = this
            
            if (index != -1){
                let tmp = this.tmpuser.filter(empl => !vm.document.flows[vm.flowselindex].delegations.map((item)=> item.employee_id).includes(empl.id));
                
                tmp.forEach(employee => {
                    this.document.flows[vm.flowselindex].delegations.push({
                        id : '',
                        employee_id : employee.id,
                        employee : { name : employee.label },
                        loa_flow_id : this.document.flows[vm.flowselindex].id,
                    });
                });

                this.ChooseFlow(index)
            }else{
                Toast.fire({
                    icon: 'error',
                    title: 'Please select flow first to add delegates user'
                });
            }
        },
        reloadempl(){
            const vm = this
            this.empls = vm.employees.map((branch) => {
                let cek  = []
                if (vm.document.flows[vm.flowselindex].delegations.length > 0){
                    cek = branch.children.filter((employee) => !vm.document.flows[vm.flowselindex].delegations.map(item => item.employee_id).includes(employee.employee_id));
                }else{
                    cek = branch.children
                }
                
                if (cek){
                    return {
                        id : branch.id,
                        label : branch.label,
                        children : cek
                    }
                }
            })
        },
        ChooseFlow(index){
            const vm = this
            vm.flowselindex = index
            vm.flowname = vm.document.flows[vm.flowselindex].name
            vm.flowcode = vm.document.flows[vm.flowselindex].code
            vm.status = vm.document.flows[vm.flowselindex].status
            this.dateRange.startDate = vm.document.flows[vm.flowselindex].from
            this.dateRange.endDate = vm.document.flows[vm.flowselindex].to
            vm.value = vm.document.flows[vm.flowselindex].pic || null;
            vm.value.label = vm.document.flows[vm.flowselindex].pic ? vm.document.flows[vm.flowselindex].pic.name : ''
            this.tmpuser = vm.document.flows[vm.flowselindex].delegations.map((user) => {
                return {
                    id : user.employee_id,
                    label : user.employee.name
                };
            })
            vm.reloadempl();                      
        },
        MoveFlow(event){
            const vm = this
            //console.log(event)
            //let to = event.newIndex;
            //let from = event.oldIndex;
            
            this.restructure_flows();
            vm.flowselindex = -1;
        },
        deleteFlowItem(item,index,indexitem){
            const vm = this;

            if (index != -1){
                vm.flowselindex = index;
                vm.document.flows[vm.flowselindex].delegations.splice(indexitem,1);
                this.tmpuser = vm.document.flows[vm.flowselindex].delegations.map((user) => {
                    return {
                        id : user.employee_id,
                        label : user.employee.name
                    };
                })
                this.restructure_flows();
            }else{
                Toast.fire({
                    icon: 'error',
                    title: 'Please select flow first to delete approvers'
                });
            }
        },
        deleteFlow(item,index){
            const vm = this;
            
            this.document.flows.splice(index, 1);
            vm.flowselindex = -1;
            vm.restructure_flows();
            Toast.fire({
                    icon: 'success',
                    title: 'Item has been deleted, make sure to submit for finalize your changed'
                });
        },
        restructure_flows(){
            //console.log(this.document)
            const vm = this
            this.document.flows.map((item,index) => {
                item.no_urut = index;
                return item;
            });
        }
    },
    watch: {
        'status' : function(val){
            if (val === 'approval') this.clearDate()

            let notexist = this.document.flows.filter((flow) => flow.name === this.flowname);
            if (notexist.length){
                if (this.flowselindex !== -1){
                    this.document.flows[this.flowselindex].status = val
                }
            }
        },
        'dateRange' : function(val){
            let notexist = this.document.flows.filter((flow) => flow.name === this.flowname);
            if (notexist.length){
                if (this.flowselindex !== -1){
                    this.document.flows[this.flowselindex].from = val.startDate
                    this.document.flows[this.flowselindex].to = val.endDate
                }
            }
        }
    },
    components: {
        draggable,
        DateRangePicker
    },
}
</script>