<template>
    <section class="content">
        <div class="container-fluid" v-if="!preparation">
            <div class="row ">
                <div class="col-12 col-sm-6 col-md-4">
                    <div class="info-box">
                        <span class="info-box-icon bg-info elevation-1"><i class="fas fa-cog"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Resolved</span>
                            <span class="info-box-number">
                                {{ data.learning_resolved }} <small>Training</small><br>
                                {{ data.waktu_resolved }} <small>Hour</small><br>
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-4">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-thumbs-up"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Unresolved</span>
                            <span class="info-box-number">
                                {{ data.learning_unresolved }} <small>Training</small><br>
                                {{ data.waktu_unresolved }} <small>Hour</small><br>
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->

                <!-- fix for small devices only -->
                <div class="clearfix hidden-md-up"></div>

                <div class="col-12 col-sm-6 col-md-4">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-success elevation-1"><i class="fas fa-shopping-cart"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Overdue</span>
                            <span class="info-box-number">
                                {{ data.learning_overdue }} <small>Training</small><br>
                                {{ data.waktu_overdue }} <small>Hour</small><br>
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
            <!-- /.col -->
            </div>
            <!-- /.row -->

            <div class="row">
                <!-- Left col -->
                <div class="col-md-6">
                    <!-- MAP & BOX PANE -->
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Rate Training karyawan</h3>

                            <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                <i class="fas fa-minus"></i>
                            </button>
                            <button type="button" class="btn btn-tool" data-card-widget="remove">
                                <i class="fas fa-times"></i>
                            </button>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body p-0">
                            <va-chart :chart-config='chartConfig'></va-chart>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>
                <!-- /.col -->
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">Next Training Schedule</h3>
                        </div>

                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Training</th>
                                            <th>Jadwal</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(polling, index)  in next_trainings.pollings" :key="polling.id">
                                            <td>{{ polling.name }}</td>
                                            <td>
                                                <span :class="`badge bg-success`">{{ polling.is_active ? polling.from : 'to be confirm' }}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>        
            </div>

            <div class="row">
            
            </div>
            <!-- /.row -->
        </div><!--/. container-fluid -->
        <div v-else>
            <form-loader></form-loader>
        </div>
    </section>
</template>

<script>
  export default {
    data () {
      return {
        preparation : true,
        data : [],
        next_trainings:[],
        chartConfig: {
          type: 'pie',
          data: {
            labels: ['Resolved', 'Unresolved','Overdue'],
            datasets: [{
              data: [50, 50, 50],
              backgroundColor: ['#00a65a', '#f39c12','#00c0ef'],
              hoverBackgroundColor: ['#00a65a', '#f39c12','#00c0ef']
            }]
          },
          options: {
            responsive: true,
            maintainAspectRatio: !this.isMobile,
            legend: {
              position: 'bottom',
              display: true
            }
          }
        }
      }
    },
    mounted(){
        // let elem = document.getElementsByClassName('main-header')[0];
        //elem.click();
    },
    created() {
        const vm = this
        vm.$nextTick(function() {
            vm.loadData();
        });
    },
    computed: {
        
    },
    methods: {
        rowHighlight(item, type) {
            if (!item || type !== 'row') return
        },
        loadData(){
            this.preparation = true
            const getData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    params: this.filter,
                    url: `/api/dashboardstore/training`
                }).then((data) => {
                    this.data = data.data.data.items;
                    this.next_trainings = data.data.data.next;
                    if (this.data){
                        this.chartConfig.data.datasets[0].data = [this.data.rate_resolved, this.data.rate_unresolved, this.data.rate_overdue];
                    }
                    
                    resolve(data.data.data);
                });
            })
            Promise.all([getData]).then(() => {
                this.preparation = false
            });
        },
    }
  }
</script>
