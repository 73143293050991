
<template>
 <div class="card card-primary direct-chat direct-chat-primary" v-if="is_finish" style="border-radius: 0.9rem;border-bottom-right-radius: 0.25rem !important;
                                                                border-bottom-left-radius: 0.25rem !important;">
    <div class="card-header bg-info" style="border-radius: 0.9rem;border-bottom-right-radius: 0rem !important;
                                                                border-bottom-left-radius: 0rem !important;">
      <h3 class="card-title">Info Agrinesia</h3>
      <div class="card-tools">
        <span data-toggle="tooltip" title="New Messages arrives" class="badge badge-light"></span>
        <button type="button" class="btn btn-tool" data-card-widget="collapse">
          <i class="fas fa-minus"></i>
        </button>
        <button type="button" class="btn btn-tool" data-toggle="tooltip" title="Contacts" data-widget="chat-pane-toggle">
          <i class="fas fa-comments"></i>
        </button>
      </div>
    </div>
    <!-- /.card-header -->
    <div class="card-body">
        <b-row>
            <b-col lg="12">
                <div class="card bg-white d-flex flex-fill mb-0">
                    <div class="card-body pt-0">
                        <b-row>
                            <div class="col-md-11 ml-3 mr-3 mt-3"> 
                                <router-link v-if="!show_berita" :to="{ name: 'article.detail', params: {id: tmparticle.id} }" style="box-sizing: content-box;height: 17px;">
                                    <p style="font-weight: bold;font-size: medium;"><i class="fas fa-arrow-circle-right"></i>&nbsp;{{ tmparticle.subject.charAt(0).toUpperCase() + tmparticle.subject.slice(1)  }}</p> 
                                </router-link>
                                <p v-else style="font-weight: bold;font-size: medium;">{{ tmparticle.subject.charAt(0).toUpperCase() + tmparticle.subject.slice(1)  }}</p>     
                                
                                <a style="float:left;width:330px;height:330px;" v-if="tmparticle.media_type === 'image'" :data-caption="tmparticle.subject" data-fancybox :href="base_url+'/' + tmparticle.media">
                                    <img  width="310px" height="310px" :src="base_url+'/'+tmparticle.media" alt="Media profile picture">
                                </a>
                                <a v-else style="float:left;width:330px;height:330px;" :data-caption="tmparticle.subject" data-fancybox :href="tmparticle.media" target="_blank">
                                    <LazyYoutubeVideo :src="tmparticle.media" preview-image-size="sddefault" aspect-ratio="1:1" style="position: relative;"/>
                                </a> 
                                <!--<p v-if="show_berita" class="text-muted text-sm" v-html="tmparticle.berita"></p>-->

                                <p class="text-justify" v-if="!readMoreActivated" v-html="tmparticle.berita.slice(0, 200)"></p>&nbsp;
                                <a class="" v-if="!readMoreActivated" @click="activateReadMore" href="#">
                                read more...
                                </a>
                                
                                <span class="text-justify" v-if="readMoreActivated" v-html="tmparticle.berita"></span>
                            </div>
                        </b-row>
                    </div>
                    <div class="card-footer bg-white" v-if="!is_sending">
                        <b-row class="mt-2">
                            <!-- Conversations are loaded here -->
                            <div class="direct-chat-messages ml-3 mr-3" style="min-width:-webkit-fill-available;max-height: 600px !important;" ref="msgContainer">
                                    <!-- Message. Default to the left -->
                                    <div v-for="comment in tmparticle.comments">
                                        <div :key="comment.id" :class="'direct-chat-msg ' + (comment.created_by == curuserid ? 'right' : '') ">
                                            <div class="direct-chat-infos clearfix">
                                                <span :class="'direct-chat-name ' +  (comment.created_by == curuserid ? 'float-right' : 'float-left')">{{ comment.creator.employee ? comment.creator.employee.name : '' }}</span>
                                                <span :class="'direct-chat-timestamp ' + (comment.created_by == curuserid ? 'float-left' : 'float-right')">{{ moment(comment.created_at).format('YYYY-MM-DD HH:mm:ss') }}</span>
                                            </div>
                                            <!-- /.direct-chat-infos -->
                                            <a :data-caption="comment.creator.employee.name" data-fancybox :href="comment.creator.photo ? base_url+'/'+comment.creator.photo : ''">
                                                <img  class="direct-chat-img" :src="comment.creator.photo ? base_url+'/'+comment.creator.photo : ''" alt="message user image">
                                            </a>     
                                            <!--<img class="direct-chat-img"  alt="user">
                                             /.direct-chat-img -->
                                            <div class="direct-chat-text">
                                                {{ comment.comment }}
                                            </div>
                                        <!-- /.direct-chat-text -->
                                        </div>
                                    </div>
                                <!-- /.direct-chat-msg -->
                            </div>
                            <!--/.direct-chat-messages-->
                            <!-- Contacts are loaded here -->
                            <div class="direct-chat-contacts" v-if="tmparticle.user_comments" style="width: 104%;height: 100%;">
                                <ul class="contacts-list ml-3 mr-5" >
                                    <li v-for="user in tmparticle.user_comments" >
                                        <a href="#" :key="user.id">
                                            <img class="contacts-list-img" :src="base_url+'/'+user.photo">
                                            <div class="contacts-list-info">
                                                <span class="contacts-list-name">
                                                {{ user.employee ? user.employee.name : ''}}
                                                <small class="contacts-list-date float-right">{{user.employee ? moment(user.employee.date_of_birth).format('YYYY-MM-DD') : ''}}</small>
                                                </span>
                                                <span class="contacts-list-msg">{{ user.email }}</span>
                                            </div>
                                        <!-- /.contacts-list-info -->
                                        </a>
                                    </li>
                                <!-- End Contact Item -->
                                </ul>
                                <!-- /.contacts-list -->
                            </div>
                            <!-- /.direct-chat-pane -->
                        </b-row>
                        <b-row>
                            <b-col lg="12">
                                <!--<form action="#" method="post" v-on:submit.prevent="comment">-->
                                    <div class="input-group">
                                    <input type="text" name="message" v-model="tmpcomment.comment" placeholder="Type Message ..." class="form-control" v-on:keyup.enter="comment">
                                    <span class="input-group-append">
                                        <button class="btn btn-primary" type="button" @click="comment">Send</button>
                                    </span>
                                    </div>
                                <!--</form>-->
                            </b-col>
                        </b-row>
                    </div>
                    <div class="overlay" v-else>
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                    </div>
                </div>
                <!--
                    <div class="text-right">
                            <a href="#" class="btn btn-sm bg-teal">
                                <i class="fas fa-comments"></i>
                            </a>
                            <a href="#" class="btn btn-sm btn-primary">
                                <i class="fas fa-user"></i> View Profile
                            </a>
                        </div>
                <div class="mt-4 product-share">
                    <a href="#" class="text-gray">
                        <i class="fab fa-facebook-square fa-2x"></i>
                    </a>
                    <a href="#" class="text-gray">
                        <i class="fab fa-twitter-square fa-2x"></i>
                    </a>
                    <a href="#" class="text-gray">
                        <i class="fas fa-envelope-square fa-2x"></i>
                    </a>
                    <a href="#" class="text-gray">
                        <i class="fas fa-rss-square fa-2x"></i>
                    </a>
                </div>
                -->
            </b-col>
        </b-row>
    </div>
</div>
<!--/.direct-chat -->
</template>

<script>
import VueVideoThumbnail from 'vue-video-thumbnail'
import LazyYoutubeVideo from 'vue-lazy-youtube-video'
import fancybox from '@fancyapps/fancybox'
import '@fancyapps/fancybox/dist/jquery.fancybox.css'
import { eventBus } from '../../../app.js'
export default {
    props: ['article_id','show_berita'],
    data () {
        return {
            base_url : window.location.origin,
            is_finish : false,
            is_sending : false,
            tmparticle : [],
            usercomments : [],
            curuserid :  window.user.id,
            readMoreActivated: false,
            tmpcomment : new Form({
                id:'',
                article_id: this.article_id,
                created_by: this.curuserid,
                comment: '',
            })
        }
    },
    mounted(){
        const vm = this;
        vm.is_finish = false
        const getArticleData = new Promise((resolve) => {
            axios.get(`/api/article/fetch/${this.article_id}`).then((data) => {
                vm.tmparticle = data.data.data;
                //const groupBy = (x,f)=>x.reduce((a,b,i)=>((a[f(b,i,x)]||=[]),a),{});
                vm.usercomments = this.groupBy(vm.tmparticle.user_comments, user => user.id);
                resolve(data.data.data);
            });
        })
        Promise.all([getArticleData]).then(() => {
            vm.is_finish = true
            vm.curuserid =  window.user.id
            vm.scrollToElement();
        })
    },
    created(){
        const vm = this;
        eventBus.$on('load_comment', function(payload) {
            if (vm.article_id == payload.subject_id){
                vm.is_sending = true
                axios.get(`/api/article/fetch/${vm.article_id}`).then((data) => {
                    vm.tmparticle = data.data.data; 
                    //const groupBy = (x,f)=>x.reduce((a,b,i)=>((a[f(b,i,x)]||=[]),a),{});
                    vm.usercomments = this.groupBy(vm.tmparticle.user_comments, user => user.id);
                    vm.is_sending = false
                    this.$nextTick(function() {
                        vm.scrollToElement();
                    })
                }).catch((e) => {
                    vm.is_sending = false
                });
            }
        });
    },
    methods: {
        activateReadMore(){
            this.readMoreActivated = true;
        },
        groupBy(list, keyGetter) {
            const map = new Map();
            list.forEach((item) => {
                const key = keyGetter(item);
                const collection = map.get(key);
                if (!collection) {
                    map.set(key, [item]);
                } 
            });
            return map;
        },
        scrollToElement() {
            const el = this.$refs.msgContainer;

            if (el) {
                el.scrollIntoView({behavior: 'smooth'});
                el.scrollTop = el.scrollHeight;
            }
        },
        reload(){
            const vm = this;
            vm.is_sending = true
            const getArticleData = new Promise((resolve) => {
                axios.get(`/api/article/fetch/${this.article_id}`).then((data) => {
                    this.tmparticle = data.data.data;
                    //const groupBy = (x,f)=>x.reduce((a,b,i)=>((a[f(b,i,x)]||=[]),a),{});
                    vm.usercomments = this.groupBy(vm.tmparticle.user_comments, user => user.id);
                    resolve(data.data.data);
                });
            })
            Promise.all([getArticleData]).then(() => {
                vm.is_sending = false
                this.curuserid =  window.user.id
                this.scrollToElement();
            })
        },
        comment(e){
            if (this.tmpcomment.comment != ''){
                this.is_sending = true;
                this.tmpcomment.article_id = this.article_id
                this.tmpcomment.created_by = this.curuserid
                this.tmpcomment.submit('post', '/api/article/comment', {
                transformRequest: [function (data, headers) {
                        let mdata = new FormData();
                        for ( var key in data ) {
                            mdata.set(`${key}`,data[key]);
                        }
                        return mdata;
                }],
                }).then((response) => {
                    this.reload()
                    this.tmpcomment.comment = "";
                    Toast.fire({
                        icon: 'success',
                        title: e.message
                    });
                }).catch((e) => {
                    this.is_sending = false;
                });
            }
            this.is_sending = false;
            e.preventDefault();
        }
    },
    components: { VueVideoThumbnail,
        LazyYoutubeVideo }

}
</script>
