<template>
	<div>
		<select class="form-control" :multiple="multiple" :id="id" :name="name" :disabled="disabled" :required="required"></select>
	</div>
</template>

<script>
import $ from 'jquery';
import 'select2/dist/js/select2.full';

export default {
	name: 'Select2',
	data() {
		return {
			select2: null,
			ajaxOptions: {
				url: 'http://localhost:8000/api/warehouse/fetch',
				dataType: 'json',
				delay: 250,
				tags: true,
				headers: {
					"Authorization": `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODAwMFwvYXBpXC9hdXRoXC9sb2dpbiIsImlhdCI6MTYwMzcxOTk0NSwiZXhwIjoxNjk5NzE5OTQ1LCJuYmYiOjE2MDM3MTk5NDUsImp0aSI6IkxyTWQzVkhHUHJDbTJoVWYiLCJzdWIiOjUxLCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.4wqzxogZJIfI30yu6oyS9jbCh7iGQtl3uA2kZI_xYtY`
				},
				data: function(params) {
					return {
						q: params.term, // search term
						page: params.page,
						limit: 10,
						active: 'all'
					};
				},
				processResults: function(data, params) {
					const newData = data.data
					params.page = params.page || 1;
					return {
						results: newData.items.map((workshop) => {
							return {
								id: workshop.id,
								text: workshop.name
							}
						}),
						pagination: {
							more: (params.page * 30) < newData.total
						}
					};
				},
				cache: true
			}
		}
	},
	model: {
		event: 'change',
		prop: 'value'
	},
	props: {
		id: {
			type: String,
			default: ''
		},
		name: {
			type: String,
			default: ''
		},
		insideModal: {
			type: Boolean,
			default: false
		},
		placeholder: {
			type: String,
			default: ''
		},
		options: {
			type: Array,
			default: () => []
		},
		disabled: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		},
		settings: {
			type: Object,
			default: () => {
				return {
					width: '100%'
				}
			}
		},
		multiple: {
			type: Boolean,
			default: false
		},
		url: null,
		value: null,
		searchable: {
			type: Boolean,
			default: true
		},
	},
	watch: {
		options(val) {
			this.setOption(val)
		},
		value(val) {
			this.setValue(val);
		},
		url: function(value) {
			this.ajaxOptions.url = this.url;
			$(this.$el).select2({ ajax: this.ajaxOptions});
		}
	},
	methods: {
		setOption(val = []) {
			this.select2.empty()
			this.select2.select2({
				dropdownParent: this.insideModal ? $(this.$el).closest('.modal-content') : $(document.body),
				placeholder: this.placeholder,
				...this.settings,
				data: val,
				escapeMarkup: function(markup) {
					return markup;
				},
				templateResult: function(data) {
					return data.html || data.text;
				},
				templateSelection: function(data) {
					return data.text;
				}
				// ajax: this.ajaxOptions
			});
			this.setValue(this.value);
		},
		setValue(val) {
			if (val instanceof Array) {
				this.select2.val([...val])
			} else {
				this.select2.val([val]);
			}
			this.select2.trigger('change')
		}
	},
	mounted() {
		this.select2 = $(this.$el)
			.find('select')
			.select2({
				dropdownParent: this.insideModal ? $(this.$el).closest('.modal-content') : $(document.body),
				placeholder: this.placeholder,
				...this.settings,
				data: this.options,
				escapeMarkup: function(markup) {
					return markup;
				},
				templateResult: function(data) {
					return data.html || data.text;
				},
				templateSelection: function(data) {
					return data.text;
				}
				// ajax: this.ajaxOptions
			})
			.on('select2:select select2:unselect', ev => {
				this.$emit('change', this.select2.val())
				this.$emit('select', ev['params']['data'])
			});
		this.setValue(this.value);
	},
	beforeDestroy() {
		this.select2.select2('destroy')
	}
};
</script>