<template>
    <div v-if="!isLoading" class="panel__body pt-4 ml-4">
        <BranchForm :preparation="isLoading" :document="document" :employees="employees"></BranchForm>

        <div class="row mb-6">
            <div class="col-12 mb-2">
                <button type="submit" :disabled="isLoading" @click="submit" class="btn btn-secondary btn-lg mr-2">Save</button>
                <router-link :to="{ name : 'document.data'}" class="btn btn-outline-primary btn-lg">Back</router-link>
            </div>
        </div>
            
    </div>
</template>
<script>

import BranchForm from './Form'
import moment from 'moment';

export default {
    data(){
        return {
            isLoading : true,
            document : new Form({
                id:'',
                name: '',
                path:'',
                image:'',
                active :'',
                lifetime : 0,
                lifeperiod : 'day',
                expiredoffset : 0,
                expiredperiod: 'day',
                users:[],
                flows:[],
            }),
            tempdata : new FormData(),
            employees : [],
        }
    },
    created() {
        const vm = this
        const getAllEmployeeData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/branch/fetch/all`,
                params: {groupby : 'employees'}
            }).then((data) => {
                vm.employees = data.data.data.items.map((branch) => {
                                    return {
                                        id: 'branch'+branch.id,
                                        label: branch.name,
                                        children : this.prosesarray(branch.employees)
                                    }
                                });
                resolve(data);
            });
        })
        Promise.all([getAllEmployeeData]).then(() => {
            vm.isLoading = false
            console.log(this.employees)
        })
    },
    methods: {
        prosesarray(array){
            return array.map(element => {
                return {
                        id: element.id,
                        label: element.first_name + ' ' + element.last_name
                    }
            });
        },
        objectToFormData(model, form, namespace = '') {
            let formData = form || new FormData();
            let formKey;

            for (let propertyName in model) {
                if (!model[propertyName]) continue;
                let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
                if (Array.isArray(model[propertyName])) {
                    model[propertyName].forEach((element, index) => {
                        const tempFormKey = `${formKey}[${index}]`;
                        this.objectToFormData(element, formData, tempFormKey);
                    });
                }else if (typeof model[propertyName] === 'object'){
                    this.objectToFormData(model[propertyName], formData, formKey);
                }else if (model[propertyName] instanceof File){
                    formData.append(formKey, model[propertyName]);
                }else
                    formData.set(formKey, model[propertyName].toString());
            }
            return formData;
        },
        submit() {
            this.isLoading = true;
            
            this.document.submit('post', '/api/doc/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (data[key] instanceof File){                                                                     //name
                            mdata.append(`${key}`, data[key]);
                        }else if (Array.isArray(data[key])) {                                                               //flows
                            data[key].forEach((element, index) => {
                                const tempFormKey = `${key}[${index}]`;                                                     //flows[0]
                                for ( var key1 in element ) {                           
                                    if (element[key1] instanceof File){
                                        mdata.append(`${tempFormKey}[${key1}]`, element[key1]);                               //flows[0]['files']
                                    }else if (Array.isArray(element[key1])) {                                               //flowsitems
                                        element[key1].forEach((element1, index1) => {
                                            const tempFormKey1 = `${tempFormKey}[${key1}][${index1}]`;                      //flows[0]['items'][0]
                                            for ( var key2 in element1 ) {
                                                if (!Array.isArray(element1[key2]) && typeof element1[key2]!== 'object'){
                                                    if (element1[key2] instanceof File){
                                                        mdata.append(`${tempFormKey1}[${key2}]`,element1[key2]);              ////flows[0]['items'][0]['user_id']
                                                    }else{
                                                        mdata.set(`${tempFormKey1}[${key2}]`,element1[key2]);                 ////flows[0]['items'][0]['user_id']
                                                    }
                                                }
                                            }
                                        });
                                    }else if (typeof element[key1]  === 'object') {
                                        const tempFormKey1 = `${tempFormKey}[${key1}]`;
                                        for ( var key2 in element[key1]) {
                                            if (!Array.isArray(element[key1][key2]) && typeof element[key1][key2]!== 'object'){
                                                if (element[key1][key2] instanceof File){
                                                    mdata.append(`${tempFormKey1}[${key2}]`,element[key1][key2]);             //flows[0]['objdata']
                                                }else{
                                                    mdata.set(`${tempFormKey1}[${key2}]`,element[key1][key2]);                //flows[0]['objdata']
                                                }
                                            }
                                        }
                                    }else{
                                        mdata.set(`${tempFormKey}[${key1}]`, element[key1]); 
                                    }
                                }
                            });
                        }else if (typeof data[key] === 'object') {
                            const tempFormKey = `[${key}]`;
                            for ( var key1 in data[key] ) {
                                if (!Array.isArray(data[key][key1]) && typeof data[key][key1] !== 'object'){
                                    if (data[key][key1] instanceof File){
                                        mdata.append(`${tempFormKey}[${key1}]`, data[key][key1]);
                                    }else{
                                        mdata.set(`${tempFormKey}[${key1}]`,data[key][key1]);
                                    }
                                }
                            }
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                console.log(response)

                this.$router.push({name: 'document.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })

            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
            });

            this.isLoading = false;
        }
    },
    components: {
        BranchForm
    }
}
</script>