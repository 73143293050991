<template>
    <b-form v-if="!preparation" class="form" enctype="multipart/form-data">
        <b-row>
            <b-col lg="12" class="mr-1">
                <b-form-group label="Name *" label-for="name">
                    <the-mask class="form-control" mask="WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW" :tokens="{W:{pattern: /^[0-9a-zA-Z\s]+/}}" :class="{ 'is-invalid': access_group.errors.has('name') }" v-model="access_group.name" id="name"></the-mask>
                    <has-error :form="access_group" field="name"></has-error>
                </b-form-group>
                <b-form-group label="Code *" label-for="code">
                    <the-mask class="form-control" mask="WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW" :tokens="{W:{pattern: /^[0-9a-zA-Z\s]+/}}" :class="{ 'is-invalid': access_group.errors.has('code') }"  v-model="access_group.code" id="first_name"></the-mask>
                    <has-error :form="access_group" field="code"></has-error>
                </b-form-group>
                <b-form-group label="Indexing *" label-for="nourut">
                    <b-form-input  v-model="access_group.nourut" id="nourut" required placeholder="Nomor Urut"  :class="{ 'is-invalid': access_group.errors.has('nourut') }"></b-form-input>
                    <has-error :form="access_group" field="nourut"></has-error>
                </b-form-group>
                <b-form-group label="Apps *">
                    <treeselect
                        :options="allgroups"
                        :show-count="true"
                        :default-expand-level="1"
                        v-model="access_group.module_group_id"
                        @input="updateValueModuleGroupId"
                        placeholder="Application?"
                        :searchable="true"
                        />
                    <has-error :form="access_group" field="module_group_id"></has-error>
                </b-form-group>
                <b-form-group label="Icon *">
                    <treeselect 
                        :options="$gate.iconlist()"
                        :show-count="true"
                        :default-expand-level="1"
                        v-model="access_group.icon"
                        @input="updateValueModuleGroupId"
                        :searchable="true"
                        placeholder="Choose">
                        <div slot="option-label" slot-scope="{ node }"><i :class="'fa ' + node.raw.customLabel +' red'"></i>&nbsp;{{ node.raw.label }}</div>
                    </treeselect>
                    
                    <has-error :form="access_group" field="icon"></has-error>
                </b-form-group>
            </b-col>
        </b-row>
    </b-form>
    <div v-else class="text-center text-primary py-14">
        <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
    </div>
<!--   COMPONENT NOT FOUND
<div>
    <not-found></not-found>
</div>
-->   
</template>
<script>
import { Datetime } from 'vue-datetime'
export default {
    props: ['preparation','allgroups','access_group','state'],
    methods: {
        updateValueModuleGroupId(val){
            if (val) this.access_group.module_group_id = val;
            else this.access_group.module_group_id = ''
        },
        updateValueIcon(val){
            if (val) this.access_group.icon = val;
            else this.access_group.icon = ''
        },
    },
    components: {
        Datetime
    }
}
</script>