<template>
    <div class="card">
        <div class="card-header">
            <b-form v-if="!isLoading" class="form" enctype="multipart/form-data">
                <b-row>
                    <b-col lg="4" class="mr-2">
                        <b-form-group label="Periode *" label-for="periode">
                            <div class="form-group form-inline">                            
                                <month-picker-input id="periode" lang = "id" :no-default="true" :show-year="true" :editable-year="true"
                                @change="showDate"
                                @clear="showClearText"
                                style ="z-index : 999 !important"
                                :class="{ 'is-invalid': shifting.errors.has('tahun') || shifting.errors.has('bulan') }"
                                ></month-picker-input>
                                <span class="ml-2 inline text-primary font-weight-medium">{{ (date.year ? date.year : '') + ' ' + (date.month ? date.month : '')  }}</span>
                            </div>
                            <has-error :form="shifting" field="tahun"></has-error>
                        </b-form-group>
                        <b-form-group label="Branch *" label-for="branch_code">
                            <treeselect
                                :options="allbranches"
                                :show-count="true"
                                :default-expand-level="1"
                                v-model="filter.branch_code"
                                :disable-branch-nodes="true"
                                placeholder="Pilih Branch?" 
                                :class="{ 'is-invalid': shifting.errors.has('branch_code') }"
                                />
                                <has-error :form="shifting" field="branch_code"></has-error>
                        </b-form-group>                    
                    </b-col>
                    <b-col lg="3">
                        <b-form-group label="Chanel " label-for="chanel">
                            <treeselect
                                :options="allchanels"
                                :show-count="true"
                                :default-expand-level="1"
                                :disable-branch-nodes="true"
                                v-model="filter.chanel_code"
                                placeholder="Pilih Chanel?" 
                                :class="{ 'is-invalid': shifting.errors.has('chanel_code') }"
                                />
                            <has-error :form="shifting" field="chanel_code"></has-error>
                        </b-form-group>
                        <b-form-group label="Type *" label-for="type">
                            <treeselect
                                id="type"
                                :options="[{ id : 'sales',label : 'Target Sales'},
                                            {id : 'production', label : 'Target Production', isDisabled : true},
                                            {id : 'distribusi', label : 'Target Distribusi', isDisabled : true},
                                            {id : 'pembelian', label : 'Target Pembelian', isDisabled : true}]"
                                v-model="filter.type"
                                :searchable="true"
                                placeholder="Type Target?"
                                />
                            <has-error :form="shifting" field="type"></has-error>
                        </b-form-group>
                        <div class="mt-4">
                            <div class="dropdown-divider"></div>
                            <button @click.prevent="getShifting()" :disabled="isLoading" class="btn btn-primary btn-lg mr-2">Preview Data</button>
                        </div>
                    </b-col>
                    <b-col lg="4" v-if="$gate.hasAccess('target.update')">    
                        <b-form-group label="Upload*" label-for="upload_file">
                            <uploader name="upload_file" description="Maks 2MB dengan ekstensi (xls, xlsx)." accept=".xls,.xlsx" v-bind:files.sync="shifting.files" id="upload_file" :class="{ 'is-invalid': shifting.errors.has('files') }"/>
                            <has-error :form="shifting" field="files"></has-error>
                        </b-form-group>
                        <div class="mt-4">
                            <div class="dropdown-divider"></div>
                            <b-button @click.prevent="uploadFiles" :disabled="isLoading"  class="btn btn-outline-primary btn-lg mr-2">Upload</b-button>
                            <export-excel  class="btn btn-outline-primary btn-lg"
                                :data  = "[{ branch_code : 'diisi dengan kode branch (pastikan code sama antara diweb dan POS)',
                                             chanel_code : 'diisi dengan kode chanel (pastikan code sama antara diweb dan POS)',
                                             item_code : 'diisi dengan kode item (pastikan code sama antara diweb dan POS)',
                                             tahun : 'diisi dengan tahun (4 digit)',
                                             bulan : 'diisi dengan nama bulan dalam bahasa indonesia (Januari, Februari, dlsb)',
                                             tanggal : 'diisi dengan tanggal (Format : 2020-12-31)',
                                             target : 'diisi dengan jumlah target dalam satu tanggal/hari (Format : 100000)',
                                             shift1 : 'diisi dengan jumlah target shift1 (Format : 100000)',
                                             shift2 : 'diisi dengan jumlah target shift2 (Format : 100000)'
                                        }]">
                                <i class="fa fa-download red"></i> Template
                            </export-excel>
                        </div>
                    </b-col>
                </b-row>
                <div class="separator mt-2 mb-6"></div>
            </b-form>
        </div>  
        <div class="card-body p-0 ml-4 mt-4" >
            <b-row class="align-items-center pb-1">
                <b-col  lg="4" >
                    <export-excel v-if="shiftings.items"  class="btn btn-primary btm-sm" size="lg"
                        :data  = "shiftings.items.map((item) => {
                                return { branch : item.branch.name,
                                        branch_code : item.branch_code,
                                        chanel : item.chanel.name,
                                        chanel_code : item.chanel_code,
                                        tahun : item.tahun,
                                        bulan : item.bulan,
                                        tanggal : item.tanggal,
                                        target : item.target,
                                        shift1 : item.shift1,
                                        shift2 : item.shift2,
                        }})">
                        <!--harian : item.harian,
                            bulanan : item.bulanan
                        -->
                        <i class="fa fa-download red"></i> Download
                    </export-excel>
                </b-col>
                <b-col lg="8">
                    <b-form class="form form-inline form--rounded justify-content-end btn-sm" @submit.prevent="getShifting()">
                        <b-form-group v-if="checked.length && $gate.hasAccess('shifting.update')">
                            <b-button @click="bulkDelete" class="btn btn-danger btm-sm" size="sm"><i class="fa fa-trash red"></i>Bulk Delete</b-button>
                        </b-form-group>
                        
                        <b-form-group class="form-inline ml-3" >
                            <b-input-group size="sm">
                                <b-form-input type="search" class="form-control form-control-navbar" v-model="filter.q" placeholder="Search" aria-label="Search" />
                                <div class="input-group-append">
                                    <button class="btn btn-navbar" type="submit" >
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                                
                            </b-input-group>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>

            <b-table
                id="grade-table"
                class="table--middle table--primary mt-2" show-empty responsive
                :items="shiftings.items"
                :fields="tableFields"
                :tbody-tr-class="rowHighlight"
                :busy.sync="isLoading"
                style="overflow: visible !important">
                <template v-slot:table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner type="grow" small label="Loading..." class="align-middle"></b-spinner>
                    </div>
                </template>
                <template v-slot:empty><div class="text-center text-muted">Data not found</div></template>

                <template v-slot:head(check)>
                    <div class="checkbox checkbox--all">
                        <input id="check-all" v-model="checkall" type="checkbox">
                        <label for="check-all"></label>
                    </div>
                </template>
                
                <template v-slot:cell(no)="data">{{ ((page > 0 ? (page - 1) : 0) * filter.limit) + (data.index +1 ) }}</template>
                
                <template v-slot:cell(periode)="data">
                    <span class="text-primary font-weight-medium">{{ data.item.tahun + '/' + data.item.bulan  }}</span>
                </template>
                <template v-slot:cell(branch_code)="data">
                    <div v-if="selectedindex == data.index">
                        <treeselect 
                        id="branch_code"
                        :options="allbranches"
                        :searchable="true"
                        :disable-branch-nodes="true"
                        v-model="shift.branch_code"
                        placeholder="Branch"
                        :class="{ 'is-invalid': shift.errors.has('branch_code') }"
                        />
                        <has-error :form="shift" field="branch_code"></has-error>
                    </div>
                    <span class="text-primary font-weight-medium" v-else>{{ data.item.branch.name  }}</span>
                </template>
                <template v-slot:cell(chanel_code)="data">
                    <div v-if="selectedindex == data.index">
                        <treeselect 
                        id="chanel_code"
                        :options="allchanels"
                        :searchable="true"
                        :disable-branch-nodes="true"
                        v-model="shift.chanel_code"
                        placeholder="Nama Chanel"
                        :class="{ 'is-invalid': shift.errors.has('chanel_code') }"
                        />
                        <has-error :form="shift" field="chanel_code"></has-error>
                    </div>
                    
                    <span class="text-primary font-weight-medium" v-else>{{ data.item.chanel.name  }}</span>
                </template>
                <template v-slot:cell(item_code)="data">
                    <div v-if="selectedindex == data.index">
                        <treeselect v-if="selectedindex == data.index"
                        id="item_code"
                        :options="allproducts"
                        :searchable="true"
                        :disable-branch-nodes="true"
                        v-model="shift.item_code"
                        placeholder="Nama Product"
                        :class="{ 'is-invalid': shift.errors.has('item_code') }"
                        />
                        <has-error :form="shift" field="item_code"></has-error>
                    </div>
                    <span class="text-primary font-weight-medium" v-else>{{ data.item.product ? data.item.product.name : ''  }}</span>
                </template>
                <!--
                <template v-slot:cell(bulanan)="data">
                    <b-input-group :prepend="'Rp.'" v-if="selectedindex == data.index">
                        <money class="form-control" v-money v-model.number="shift.bulanan"></money>
                    </b-input-group>
                    <span class="text-primary font-weight-medium" v-else>{{ data.item.bulanan | toCurrency }}</span>
                </template>
                <template v-slot:cell(harian)="data">
                    <b-input-group :prepend="'Rp.'" v-if="selectedindex == data.index">
                        <money class="form-control" v-money v-model.number="shift.harian"></money>
                    </b-input-group>
                    <span class="text-primary font-weight-medium" v-else>{{ data.item.harian | toCurrency }}</span>
                </template>
                -->
                <template v-slot:cell(tanggal)="data">
                    <div v-if="selectedindex == data.index">
                        <Datetime 
                            :class="{ 'is-invalid': shift.errors.has('tanggal') }"
                            value-zone="UTC+7"
                            v-on:input="shift.tanggal = moment(shift.tanggal).format('YYYY-MM-DD')" input-class="form-control" class="input-group" v-model.lazy="shift.tanggal">
                            <div class="input-group-append" slot="after">
                                <div class="input-group-text">
                                    <i class="fas fa-calendar" @click="shift.tanggal = '1900-00-00'"></i>
                                </div>
                            </div>
                        </Datetime>
                        <has-error :form="shift" field="tanggal"></has-error>
                    </div>
                    <span class="text-primary font-weight-medium" v-else>{{ data.item.tanggal | toCurrency }}</span>
                </template>
                <template v-slot:cell(target)="data">
                    <div v-if="selectedindex == data.index">
                        <b-input-group :prepend="'Rp.'">
                            <money v-money v-model.number="shift.target"
                            :class="{ 'is-invalid': shift.errors.has('target'), 'form-control' : true }"
                            />
                            <has-error :form="shift" field="target"></has-error>
                        </b-input-group>
                    </div>
                    
                    <span class="text-primary font-weight-medium" v-else>{{ data.item.target | toCurrency }}</span>
                </template>
                <template v-slot:cell(sales_amount_shift1)="data">
                    <div v-if="selectedindex == data.index">
                        <b-input-group :prepend="'Rp.'">
                            <money v-money v-model.number="shift.shift1"
                            :class="{ 'is-invalid': shift.errors.has('shift1'), 'form-control' : true }"
                            />
                            <has-error :form="shift" field="shift1"></has-error>
                        </b-input-group>
                    </div>
                    
                    <span class="text-primary font-weight-medium" v-else>{{ data.item.shift1 | toCurrency }}</span>
                </template>
                <template v-slot:cell(shift2)="data">
                    <div v-if="selectedindex == data.index">
                        <b-input-group :prepend="'Rp.'">
                            <money v-money v-model.number="shift.shift2"
                            :class="{ 'is-invalid': shift.errors.has('shift2'), 'form-control' : true }"
                            />
                            <has-error :form="shift" field="shift2"></has-error>
                        </b-input-group>
                    </div>
                    
                    <span class="text-primary font-weight-medium" v-else>{{ data.item.shift2 | toCurrency }}</span>
                </template>
                <template v-slot:cell(action)="data" v-if="$gate.hasAccess('target.update')">
                    <div class="btn-group">
                    <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-wrench"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" role="menu" x-placement="bottom-end" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-124px, 18px, 0px);">
                        <a class="dropdown-item" @click="edit(data.item, data.index)" v-if="selectedindex != data.index" variant="danger">
                            <i class="fas fa-times"></i>Edit
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" @click="save()" v-if="selectedindex != -1" variant="danger">
                           <i class="fas fa-times"></i>Save
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" v-if="$gate.hasAccess('question.create')" @click="addQuestion(data.item.id,data.item.name)" variant="primary">
                            <i class="fas fa-plus"></i>Add Question
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" @click="cancel()" v-if="selectedindex != -1" variant="danger">
                            <i class="fas fa-times"></i>Cancel
                        </a>
                        <a class="dropdown-divider"></a>
                        <a class="dropdown-item" @click="remove(data.item.id)" variant="danger">
                            <i class="fas fa-times"></i> Delete
                        </a>
                    </div>
                </div>
                </template>
                <template v-slot:cell(check)="data">
                    <div class="checkbox">
                        <input :id="`checkbox-${data.item.id}`" :value="data.item.id" v-model="checked" type="checkbox">
                        <label :for="`checkbox-${data.item.id}`"></label>
                    </div>
                </template>
            </b-table>
        </div>
        <div class="card-footer">
            <b-row>
                <b-col lg="6">
                    <div class="form form--small form-inline justify-content-center justify-content-lg-start">
                        <b-form-group label-class="text-primary mr-1" label="GO TO " label-for="page">
                            <b-form-input type="number" v-model="page" id="page" class="form-control"></b-form-input>
                        </b-form-group>
                        <b-form-group label-class="text-primary ml-2 mr-2" label="VIEW " label-for="filter_limit">
                            <select2 id="filter_limit" v-model="filter.limit" :options="[10, 20, 30]" :settings="{minimumResultsForSearch: -1}" />
                        </b-form-group>
                    </div>
                </b-col>
                <b-col lg="6">
                    <b-pagination
                        align="right"
                        v-model="page"
                        :total-rows="shiftings.total"
                        :per-page="filter.limit"
                        aria-controls="grade-table">
                    </b-pagination>
                </b-col>
            </b-row>   
        </div>
    </div>
</template>
<script>
import { Datetime } from 'vue-datetime'
import { MonthPickerInput } from 'vue-month-picker'
import moment from 'moment'
export default {
    name: 'Shifting',
    data(){
        return {
            selectedindex : -1,
            isLoading : true,
            clearEmittedText: null,
            shift : new Form({
                id : '',
                branch_code : '',
                item_code : '',
                tahun : '',
                bulan : '',
                tanggal : '',
                target : '',
                shift1 : '',
                shift2 : '',
                chanel_code : '',
                type : ''
            }),
            /*  bulanan : '',
                harian : '',
            */
            shifting : new Form({
                branch_code : '',
                tahun : '',
                bulan : '',
                chanel_code : '',
                type : '',
                files : '',
            }),
            filter : {    
                branch_code : '',
                tahun : '',
                bulan : '',
                type : '',
                chanel_code : '',
                q : '',
                limit : 30
            },
            date: {
                from: null,
                to: null,
                month: null,
                year: null,
            },
            allproducts : [],
            allchanels : [],
            branches: [],
            lstbranch : [],
            shiftings : [],
            allbranches : [],
            tableFields: [
                { key: 'no', label: 'No.', tdClass: 'text-right', thAttr: { width: '50' } },
                { key: 'chanel_code', label: 'Chanel' },
                { key: 'branch_code', label: 'Branch' },
                { key: 'item_code', label: 'Item' },
                { key: 'periode', label: 'Periode' },
                { key: 'tanggal', label: 'Tanggal' , tdClass: 'text-center text-wrap', thAttr: { width: '200' }  },
                { key: 'target', label: 'Target' , tdClass: 'text-center text-wrap', thAttr: { width: '200' }  },
                { key: 'shift1', label: 'Shift1' , tdClass: 'text-center text-wrap', thAttr: { width: '200' }  },
                { key: 'shift2', label: 'Shift2' , tdClass: 'text-center text-wrap', thAttr: { width: '200' }  },
                { key: 'action', label: 'Action', tdClass: 'text-center', thAttr: { width: '160' } },
                { key: 'check', thAttr: { width: '30' } , tdClass: 'text-center stick-end', thClass: 'stick-end' }
            ],
            /*{ key: 'bulanan', label: 'Monthly Target' , tdClass: 'text-center text-wrap', thAttr: { width: '200' }  },
                { key: 'harian', label: 'Daily Target' , tdClass: 'text-center text-wrap', thAttr: { width: '200' }  },
                */
            mpage:1,
            checkall: false,
            checked: [],
        }
    },
    created() {
        const vm = this;
        vm.isLoading = true
        this.lstbranch =  window.user.branches;
        const getAllBranchData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/branch/fetch/all`
            }).then((data) => {
                vm.branches = data.data.data.items;
                resolve(data);
            });
        })
        const getProductData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/product/list`
            }).then((data) => {
                this.allproducts = data.data.data.map((product) => {
                            return {
                                id: product.code,
                                label: product.name
                            }
                        });         
                resolve(data);
            });
        });
        const getChanelData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/chanel/fetch/all`
            }).then((data) => {
                this.allchanels = data.data.data.items.filter((chanel) => !chanel.parent_chanel_id );
                this.allchanels = vm.allchanels.map((chanel) => {
                            return {
                                id: chanel.code,
                                label: chanel.name,
                                children : chanel.children ? this.$helper.prosesarraychanelcode(chanel.children,data.data.data.items) : []
                            }
                        });         
                resolve(data);
            });
        });
        Promise.all([getAllBranchData,getChanelData,getProductData]).then(() => {
            vm.allbranches = vm.branches.filter((branch) => !branch.parent_branch_id );
            vm.allbranches = vm.allbranches.map((branch) => {
                            if (branch.children){
                                return {
                                    id: branch.code,
                                    label: branch.name,
                                    children : branch.children ? this.$helper.prosesarraybranchcodedisabled(branch.children,vm.branches,this.lstbranch) : []
                                }
                            }else{
                                return {
                                    id: branch.code,
                                    label: branch.name,
                                    isDisabled : this.lstbranch.find((lst) => lst.branch_id == branch.id) ? false : true
                                }
                            }
                        });             
            vm.isLoading = false
        })
    },
    computed: {
        page: {
            get() {
                return this.mpage;
            },
            set(val) {
                this.mpage = val;
            }
        }
    },
    components: {
		MonthPickerInput,
        Datetime
	},
    methods: {
        getShifting(){
            console.log('get shift')
            this.isLoading = true;
            if (!this.filter.branch_code){
                Toast.fire({
                    icon: 'error' ,
                    title: 'Please choose one of branch!!'
                });
            }
            if (!this.filter.type){
                Toast.fire({
                    icon: 'error' ,
                    title: 'Please choose one of type!!'
                });
            }
            const getShiftingData = new Promise((resolve) => {
                axios({
                    method: 'get',
                    params: this.filter,
                    url: `/api/target/fetch?page=${this.page}`
                }).then((data) => {
                    this.shiftings = data.data.data;
                    resolve(data.data.data);
                });
            })
            Promise.all([getShiftingData]).then(() => {
                this.isLoading = false;
                this.shift.reset();
            });
        },
        edit(data,index){
            this.shift.fill(data);
            this.selectedindex = index
        },
        save(){
            if (!this.shift.id){
                Toast.fire({
                    icon: 'error' ,
                    title: 'Please choose one of branch!!'
                });
            }else{
                this.isLoading = true;
                this.shift.submit('post', '/api/target/store', {
                transformRequest: [function (data, headers) {
                        let mdata = new FormData();
                        for ( var key in data ) {
                            if (key == 'files'){
                                mdata.append(`${key}`, data[key]);
                            }else{
                                mdata.set(`${key}`,data[key]);
                            }
                        }
                        return mdata;
                }],
                }).then((response) => {
                    Toast.fire({
                        icon: 'success',
                        title: response.message
                    });
                    this.getShifting();
                    this.isLoading = false;
                    this.selectedindex = -1;
                }).catch((e) => {
                    Toast.fire({
                        icon: 'error',
                        title: e.message
                    });
                    this.isLoading = false;
                    this.selectedindex = -1;
                });
            }
        },
        cancel(){
            this.shift.reset();
            this.selectedindex = -1
        },
        remove(id){
            Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    const DeleteRoles = new Promise((resolve, reject) => {
                        axios.post('/api/target/delete', {id: id})
                        .then((response) => {
                            resolve(response.data)
                        })
                        .catch((error) => {
                            resolve(error.data)
                        })
                    });
                    Promise.all([DeleteRoles]).then((response) => {
                        this.getShifting()
                        Toast.fire({
                            icon: response.code === 200 ? 'success' : 'error',
                            title: response.message
                        });
                    });
                }
            });
        },
        rowHighlight(item, type) {
            if (!item || type !== 'row') return
            if (item.index === this.selectionindex) return 'highlight'
        },
        updateValue(val){
            Toast.fire({
                icon: 'success' ,
                title: 'Selecting item of '.val
            });
        },
        uploadFiles(){
            const vm = this
            vm.isLoading = true;
            this.shifting.submit('post', '/api/target/upload', {
                transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            if (key == 'from' || key == 'to'){
                                mdata.set(`${key}`,moment(data[key]).format('YYYY-MM-DD HH:mm:ss'));
                            }else  mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
            }],
            }).then((response) => {
                this.getShifting();
                this.$router.push({name: 'target.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
                this.isLoading = false
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                this.isLoading = false
            });
        },
        showClearText() {
            this.clearEmittedText = 'emitted'
            window.setTimeout(() => {
                this.clearEmittedText = null
            }, 1000)
        },
        showDate (date) {
			this.date = date
            this.filter.tahun = date.year
            this.filter.bulan = date.month
		}
    },
    watch: {
        /*
        'shift.bulanan': function(bulanan) {
            let jmlhari = new Date(this.date.year, this.date.monthIndex, 0).getDate();
            this.shift.harian = (bulanan ? bulanan / jmlhari : 0);
        },
        */
        'filter.type': function(type) {
            this.shifting.type = type;
        },
        'filter.tahun': function(tahun) {
            this.shifting.tahun = tahun;
        },
        'filter.bulan': function(bulan) {
            this.shifting.bulan = bulan;
        },
        'filter.chanel_code': function(chanel_code) {
            this.shifting.chanel_code = chanel_code;
        },
        'filter.branch_code' : function (branch_code){
            this.shifting.branch_code = branch_code;
        },
        'filter.limit': function() {
            this.getShifting()
        },
        page() {
            this.shift.reset();
            this.checked = []
            this.getShifting()
            this.selectedindex = -1;
        },
        checkall(checkall) {
            if ( checkall ) {
                this.shiftings.items.forEach(role => {
                    this.checked.push(role.id)
                });
            } else {
                this.checked = []
            }
        }
    }
}
</script>