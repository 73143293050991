<template>
    <div v-html="data.message" @click="goToNotification(data.slug, data.id)"></div>
</template>
<script>

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
    },
    methods: {
        goToNotification(slug, id) {
            return new Promise((resolve, reject) => {
                axios.post(`/api/notification/store`, {id: id, isRead:true})
                .then((response) => {
                    this.$router.push({
                        name: `${slug}.data`,
                        query: {
                            id: id
                        }
                    })
                })
                .catch((response) => {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            })
            this.$emit('click')
        },
    }
}
</script>