<template>
	<div>
		<input v-model.number="page" type="number" style="width: 5em"> /{{numPages}}
		<button @click="rotate += 90">&#x27F3;</button>
		<button @click="rotate -= 90">&#x27F2;</button>
		<button @click="$refs.pdf.print()">print</button>
		<button @click="download">Download</button>
		<div style="width: 100%">
			<div v-if="loadedRatio > 0 && loadedRatio < 1" style="background-color: green; color: white; text-align: center" :style="{ width: loadedRatio * 100 + '%' }">{{ Math.floor(loadedRatio * 100) }}%</div>
			<pdf ref="pdf" style="border: 1px solid red" :src="src" :page="page" :rotate="rotate" @password="password" @progress="loadedRatio = $event" @error="error" @num-pages="numPages = $event" @link-clicked="page = $event" @loaded="logContent"></pdf>
		</div>
	</div>
</template>

<script>
import pdf from 'vue-pdf'

export default {
	components: {
		pdf: pdf
	},
    props: ['filepath','useassistant'],
	data () {
		return {
			src: window.location.origin + '/' + this.filepath,
			loadedRatio: 0,
			page: 1,
			numPages: 0,
			rotate: 0,
			readtext : ''
		}
	},
	methods: {
		download : function(){
			this.$emit('download');
		},
		password: function(updatePassword, reason) {
			updatePassword(prompt('password is "test"'));
		},
		error: function(err) {
			console.log(err);
		},
        logContent() {
			const vm = this
            vm.$refs.pdf.pdf.forEachPage(function(page) {
				var textContent = page.getTextContent();
				textContent.then((text) => {
					let tmp = text.items.filter(function (el) {
						return el.str.trim() != '';
					});
					tmp = tmp.map(function (s) { return s.str; });
					vm.readtext = tmp.join(' '); 
				});
            });
        }
    },
	watch : {
		async readtext(readtext) {
			await this.$emit('textloaded' , readtext);
		}
	}
}
</script>