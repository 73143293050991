<template>
    <div v-if="!preparation" class="panel__body pt-4 ml-4">
        <div class="form">
            <BranchForm :preparation="preparation" :document="document" :employees="employees"></BranchForm>

            <div class="row mb-6">
                <div class="col-12 mb-2">
                    <button @click="submit" :disabled="preparation" class="btn btn-secondary btn-lg mr-2">Save</button>
                    <router-link :to="{ name : 'document.data'}" class="btn btn-outline-primary btn-lg">Back</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BranchForm from './Form'
export default {
    data(){
        return {
            preparation: true,
            allperiode : [],
            alltrainers : [],
            document: new Form({
                id:'',
                name: '',
                path:'',
                image:'',
                active:'',
                lifetime : 0,
                lifeperiod : 'day',
                expiredoffset : 0,
                expiredperiod: 'day',
                users:[],
                flows:[],
            })
        }
    },
    created() {
        const vm = this
        this.document.reset();
        const getAllEmployeeData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/branch/fetch/all`,
                params: {groupby : 'employees'}
            }).then((data) => {
                vm.employees = data.data.data.items.map((branch) => {
                                    return {
                                        id: 'branch'+branch.id,
                                        label: branch.name,
                                        children : this.prosesarray(branch.employees)
                                    }
                                });
                resolve(data);
            });
        })

        const getDocumentData = new Promise((resolve) => {
            axios.get(`/api/doc/fetch/${this.$route.params.id}`).then((data) => {
                this.document.fill(data.data.data);
                this.document.users = data.data.data.doc_users.map((user) => {
                    return {id :user.employee_id, label : user.employee.name};
                });
                this.document.flows = data.data.data.doc_flows.map((flow,index) => {
                    return {  
                        id: flow.id,
                        name: flow.name,
                        idx : flow.idx,
                        doc_flowitems : flow.doc_flowitems
                    };
                });
                resolve(data.data.data);
            });
            resolve()
        })
        
        Promise.all([getDocumentData,getAllEmployeeData]).then(() => {
            vm.preparation = false
        })
    },
    methods: {
        prosesarray(array){
            return array.map(element => {
                return {
                        id: element.id,
                        label: element.first_name + ' ' + element.last_name
                    }
            });
        },
        submit() {
            this.preparation = true;
            
            this.document.submit('post', '/api/doc/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (data[key] instanceof File){                                                                     //name
                            mdata.append(`${key}`, data[key]);
                        }else if (Array.isArray(data[key])) {                                                               //flows
                            data[key].forEach((element, index) => {
                                const tempFormKey = `${key}[${index}]`;                                                     //flows[0]
                                for ( var key1 in element ) {                           
                                    if (element[key1] instanceof File){
                                        mdata.append(`${tempFormKey}[${key1}]`, element[key1]);                               //flows[0]['files']
                                    }else if (Array.isArray(element[key1])) {                                               //flowsitems
                                        element[key1].forEach((element1, index1) => {
                                            const tempFormKey1 = `${tempFormKey}[${key1}][${index1}]`;                      //flows[0]['items'][0]
                                            for ( var key2 in element1 ) {
                                                if (!Array.isArray(element1[key2]) && typeof element1[key2]!== 'object'){
                                                    if (element1[key2] instanceof File){
                                                        mdata.append(`${tempFormKey1}[${key2}]`,element1[key2]);              ////flows[0]['items'][0]['user_id']
                                                    }else{
                                                        mdata.set(`${tempFormKey1}[${key2}]`,element1[key2]);                 ////flows[0]['items'][0]['user_id']
                                                    }
                                                }
                                            }
                                        });
                                    }else if (typeof element[key1]  === 'object') {
                                        const tempFormKey1 = `${tempFormKey}[${key1}]`;
                                        for ( var key2 in element[key1]) {
                                            if (!Array.isArray(element[key1][key2]) && typeof element[key1][key2]!== 'object'){
                                                if (element[key1][key2] instanceof File){
                                                    mdata.append(`${tempFormKey1}[${key2}]`,element[key1][key2]);             //flows[0]['objdata']
                                                }else{
                                                    mdata.set(`${tempFormKey1}[${key2}]`,element[key1][key2]);                //flows[0]['objdata']
                                                }
                                            }
                                        }
                                    }else{
                                        mdata.set(`${tempFormKey}[${key1}]`, element[key1]); 
                                    }
                                }
                            });
                        }else if (typeof data[key] === 'object') {
                            const tempFormKey = `[${key}]`;
                            for ( var key1 in data[key] ) {
                                if (!Array.isArray(data[key][key1]) && typeof data[key][key1] !== 'object'){
                                    if (data[key][key1] instanceof File){
                                        mdata.append(`${tempFormKey}[${key1}]`, data[key][key1]);
                                    }else{
                                        mdata.set(`${tempFormKey}[${key1}]`,data[key][key1]);
                                    }
                                }
                            }
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'document.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                this.preparation = false;
            });
        }
    },
    components: {
        BranchForm
    }
}
</script>