<template>
    <div class="card">
        <div class="card-header">
            <b-card v-if="Question.ilustration"
                :img-src="Question.ilustration"
                img-alt="Image"
                img-top
                :title="Question.question"
                style="max-width: 20rem;"
                class="mb-2">
            </b-card>
            <b-card-text v-html="Question.question"></b-card-text>
        </div>  
        <div class="card-body p-0 ml-4 mt-4" >
            <div v-if="Question.question_type == 'pilihan'">
                <input type="radio"  name="ans" v-model="answer" value="a" >
                <label for="a">{{ Question.pil_a }}</label><br>
                <input type="radio"  name="ans" v-model="answer" value="b">
                <label for="b">{{ Question.pil_b }}</label><br>
                <input type="radio"  name="ans" v-model="answer" value="c">
                <label for="c">{{ Question.pil_c }}</label><br>
                <input type="radio"  name="ans" v-model="answer" value="d">
                <label for="d">{{ Question.pil_d }}</label><br>
                <input type="radio"  name="ans" v-model="answer" value="e">
                <label for="e">{{ Question.pil_e }}</label>
            </div>
            <div v-else-if="Question.question_type == 'polling'">
                <input type="radio"  name="ans" v-model="answer" value="sangat setuju" >
                <label for="sangat setuju">Sangat setuju</label><br>
                <input type="radio"  name="ans" v-model="answer" value="setuju">
                <label for="setuju">Setuju</label><br>
                <input type="radio"  name="ans" v-model="answer" value="kurang setuju">
                <label for="kurang setuju">Kurang Setuju</label><br>
                <input type="radio"  name="ans" v-model="answer" value="tidak setuju">
                <label for="tidak setuju">Tidak Setuju</label>
            </div>
            <div v-else>
                <b-form-group label="Jawaban" label-for="answer">
                    <b-form-textarea
                        id="answer"
                        v-model.trim="answer"
                        placeholder="Tulis Jawaban anda"/>
                </b-form-group>
            </div>
        </div>
        <div class="card-footer">
            <b-row class = "mb-6">
                <b-col class = "mb-2">
                    <b-button v-if="canskip" @click.prevent="skip(index)" class="btn btn-outline-primary btn-lg mr-2" size="sm">Skip</b-button>
                    <b-button v-if="canback" @click.prevent="back(index)" class="btn btn-outline-primary btn-lg mr-2" size="sm">Back</b-button>
                    <b-button v-if="cannext" @click.prevent="next(index)" class="btn btn-outline-primary btn-lg mr-2" size="sm">Next</b-button>
                    <b-button v-if="canfinish" variant="danger"  @click.prevent="finish(index)" class="btn btn-danger btn-lg mr-2" size="sm"><i class="fas fa-times"></i>Finish</b-button>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>

export default {
    name: 'Question',
    props: ['recanswer','index','canfinish','canskip','canback','cannext',"Question"],
    data(){
        return {
            useSpeech : false,
            answer : ''
        }
    },
    methods: {
        async skip(mindex){
            this.answer = ''
            await this.$emit('skip' , [mindex,this.answer]);
        },
        async back(mindex){
            await this.$emit('back' ,[mindex,this.answer]);
        },
        async next(mindex){
            await this.$emit('next' , [mindex,this.answer]);
        },
        async finish(mindex){
            await this.$emit('finish' , [mindex,this.answer]);
        }
    },
    watch: {
        recanswer(val) {
            this.answer = val;
        }
    }
}
</script>