document.addEventListener('scroll', function (e) {
    if (typeof e.target.className != 'undefined') {
        if ( e.target.classList.contains('table-responsive') ) {
            let target = e.target
            let scrollLeft = target.scrollLeft
            let scrollWidth = target.scrollWidth
            let clientWidth = target.clientWidth

            if ( (scrollWidth - clientWidth) === scrollLeft ) {
                target.querySelectorAll('th.stick-end, td.stick-end').forEach((el, i) => {
                    el.classList.add('stuck')
                })
            } else {
                target.querySelectorAll('th.stick-end, td.stick-end').forEach((el, i) => {
                    el.classList.remove('stuck')
                })
            }
        }
    }
}, true /*Capture event*/);

document.addEventListener('click', function (e) {
    if (typeof e.target.className != 'undefined') {
        if ( e.target.classList.contains('style-items') ) {
            e.target.addEventListener('click', myFunction, false);
        }
    }
}, true /*Capture event*/);

document.addEventListener("load", function (e) {
    //sessionStorage.setItem("ceknotification", true ); 
    e.target.addEventListener('load', applytheme, false);
    if (!sessionStorage.getItem("periode")){
        sessionStorage.setItem("periode", new Date().getFullYear() ); 
    }
}, true /*Capture event*/);

var myFunction = function() {
    var attribute = this.getAttribute("data-style");
    document.documentElement.setAttribute('data-theme', attribute);
    sessionStorage.setItem("theme", attribute);    
    applytheme();
};
var applytheme = function() {
    var theme = "standar";
    if (sessionStorage.getItem("theme")){
        theme = sessionStorage.getItem("theme");
    }
    const elements = document.querySelectorAll('[data-theme]');
    elements.forEach(element => {
        element.setAttribute('data-theme',theme);
    });    
}