<template>
    <div class="panel__body pt-4 ml-4" v-if="!isLoading">
        <div class="form">
            <EmplForm :preparation="isLoading" :empl="empl" :allbranch="allbranch" :allgrade="allgrade" :alljob="alljob" :allorganization="allorganization" :state="true"></EmplForm>

            <div class="row mb-6">
                <div class="col-12 mb-2">
                    <button v-if="$gate.hasAccess('empl.update')" @click="submit" :disabled="isLoading" class="btn btn-secondary btn-lg mr-2">Save</button>
                    <router-link :to="{ name : 'empl.data'}" class="btn btn-outline-primary btn-lg">Back</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EmplForm from './Form'

export default {
    data(){
        return {
            isLoading: false,
            allbranch : [],
            allgrade : [],
            allorganization : [],
            alljob : [],
            empl : new Form({
                 id : '',employee_id : '', created_by : '', first_name : '', last_name : '', email  : '', date_of_birth  : '1900-00-00', gender : '1', 
                 marital_status : '1', place_of_birth : '', mobile_phone_number : '', home_phone_number : '', religion  : '2',
                 blood_type : '1',  branch_id : '', organization_id : '', job_position_id : '', job_level : '5',
                 employment_status : '1', join_date : '1900-00-00', end_employment_status_date  : '1900-00-00', schedule  : '', citizen_id_type : '1', 
                 citizen_id : '', citizen_address : '', residential_address : '', postal_code : '', grade_id  : '', class : '', approval_line  : '', basic_salary  : '0', 
                 ptkp_status  : '1', tax_configuration   : '1', type_salary  : '1', salary_configuration  : '1', jht_configuration  : '0', 
                 employee_tax_status  : '0', jp_configuration  : '0', npp_bpjs_ketenagakerjaan  : '', overtime_status  : '1', 
                 bpjs_kesehatan_config  : '0', payment_schedule  : '', npwp  : '', bank_name  : '', bank_account  : '', bank_account_holder  : '',
                 bpjs_ketenagakerjaan  : '', bpjs_kesehatan  : '', bpjs_kesehatan_family  : '0', taxable_date  : '1900-00-00', overtime_working_day_default  : '0',
                 overtime_day_off_default  : '0', overtime_national_holiday_default  : '0', beginning_netto  : '0', pph21_paid  : '0', bpjs_ketenagakerjaan_date  : '1900-00-00',
                 bpjs_kesehatan_date  : '1900-00-00', jp_date  : '1900-00-00', prorate_type  : '', invite_ess : '',nik:''
            }),
        }
    },
    created() {
        const vm = this
        this.isLoading = true;

        this.empl.reset();
        const getEmplData = new Promise((resolve) => {
            axios.get(`/api/empl/fetch/${this.$route.params.id}`).then((data) => {
                this.empl.fill(data.data.data);
                resolve(data);
            });
        })
        const getAllBranchData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/branch/fetch/all`
            }).then((data) => {
                let tmp = data.data.data.items.filter((branch) => !branch.parent_branch_id );
                vm.allbranch = tmp.map((branch) => {
                                    return {
                                        id: branch.id,
                                        label: branch.name,
                                        children : branch.children ? this.$helper.prosesarraybranch(branch.children,data.data.data.items) : []
                                    }
                                });
                                resolve(data);
            });
        })
        const getAllJobsData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/jobposition/fetch/all`
            }).then((data) => {
                let tmp = data.data.data.items.filter((job) => !job.parent_job_id );
                vm.alljob = tmp.map((job) => {
                    return {
                        id: job.id,
                        label: job.name,
                        children : job.children ? this.$helper.prosesarrayjob(job.children,data.data.data.items) : []
                    }    
                });
                resolve(data);
            });
        })
        const getAllGradeData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/grade/fetch/all`
            }).then((data) => {
                vm.allgrade = data.data.data.items.map((grade) => {
                    return {
                        id: grade.id,
                        label: grade.name,
                    }    
                });
                resolve(data);
            });
        })
        const getAllOrgsData = new Promise((resolve) => {
            axios({
                method: 'get',
                url: `/api/org/fetch/all`
            }).then((data) => {
                let tmp = data.data.data.items.filter((org) => !org.parent_organization_id );
                vm.allorganization = tmp.map((org) => {
                                    return {
                                        id: org.id,
                                        label: org.name,
                                        children : org.children ? this.$helper.prosesarrayorg(org.children,data.data.data.items) : []
                                    }
                                });
                resolve(data);
            });
        })
        Promise.all([getEmplData,getAllBranchData,getAllJobsData,getAllGradeData,getAllOrgsData]).then(() => {
            vm.isLoading = false
        })
    },
    methods: {
        submit() {
            this.isLoading = true;
            this.empl.submit('post', '/api/empl/store', {
              transformRequest: [function (data, headers) {
                    let mdata = new FormData();

                    for ( var key in data ) {
                        if (key == 'files'){
                            mdata.append(`${key}`, data[key]);
                        }else{
                            mdata.set(`${key}`,data[key]);
                        }
                    }
                    return mdata;
              }],
            }).then((response) => {
                this.$router.push({name: 'empl.data'}, function() {
                    Toast.fire({
                        icon: 'success',
                        title: response.data.message
                    });
                })
                this.isLoading = false;
            }).catch((e) => {
                Toast.fire({
                    icon: 'error',
                    title: e.message
                });
                this.isLoading = false;
            });
        }
    },
    components: {
        EmplForm
    }
}
</script>